import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import Table from '../helpers/display/table'
import TabLink from '../helpers/tablink'
import AddressDisplay from '../helpers/display/address'
import ArrayDisplay from '../helpers/display/arraydisplay'
import NotesDisplay from '../helpers/display/notesdisplay'
import Circles from '../../assets/images/circle.png';
import CompanyForm from '../forms/companyform'
import ChangeImage from '../forms/changeimage'
import EmailCompose from '../forms/email-compose';
import EmailView from '../views/email-view'
import CredentialDisplay from '../helpers/display/credentialdisplay'
import Error from './404'
import LoadingData from '../helpers/display/loadingdata'
class FullCompany extends Component {
    state = {
        edit: false,
        changeImage: false,
        view: 'details',
        notFound: true
    }
    componentDidMount() {
        this.setState({ _id: this.props._id }, this.initTitle)
    }
    componentDidUpdate() {
        if (this.props._id !== this.state._id) this.setState({ _id: this.props._id }, this.initTitle)
    }
    initTitle = () => {
        let { _id } = this.state
        if (!_id) return this.setState({notFound: true})
        this.setState({loaded: true})
        let t = window.app.state.companies?.find(a => a._id === _id)
        if (!t) return this.setState({notFound:true})
        let m = Object.keys(this.state || {}).reduce((a, v) => ({ ...a, [v]: undefined }), {})
        let j = { ...m, ...t, edit: this.state.edit, changeImage: this.state.changeImage, view: this.state.view, _id: this.props._id, notFound: false }
        this.setState(j, () => {
            if (!this.state.actions) this.getActions()
            if (!this.state._emails) this.getEmails(this.state._id)
        })
    }
    getEmails = _id => {
        if (this.getEmail) return false
        this.getEmail = true
        window.app.getCompanyEmails(_id).then(emails => {
            this.getEmail = false
            this.setState({ _emails: emails })
        }).catch(e => {
            this.getEmail = false
            window.flash(e)
        })
    }
    getAgreements() {
        if (this.gettingAgreements) return
        this.gettingAgreements = true
        if (window.app.state.agreements) {
            let agreements = window.app.state.agreements?.filter(a => a.associate === this.state._id || a.company === this.state._id) || []
            this.gettingAgreements = false
            this.setState({ agreements })
        } else {
            window.app.request('/company-agreements', 'post', { _id: this.state._id }).then(r => {
                this.gettingAgreements = false
                this.setState({ agreements: r.agreements })
            }).catch(e => {
                this.gettingAgreements = false
                window.flash(e)
            })
        }
    }
    getActions() {
        if (this.gettingActions) return
        this.gettingActions = true
        window.app.request('/actions', 'post', { _id: this.state._id }).then(r => {
            this.gettingActions = false
            this.setState({ gotActions: true, actions: r.actions })
        }).catch(e => {
            this.gettingActions = false
            window.flash(e)
        })
    }
    render() {
        window.companyfull = this
        if (this.state.notFound) return <Error />
        let activity = () => {
            let categories = ['Notes', 'Actions', 'Emails', 'Drafts', 'Agreements'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Agreements': {
                        items = window.app.state.agreements?.filter(a => [a.company, a.associate].includes(this.state._id)) || []
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/agreement?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                            }
                        ]
                        break
                    }
                    case 'Actions': {
                        items = this.state.actions || []
                        columns = [
                            {
                                title: "Type",
                                dataIndex: "reminderType",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.reminderType, b.reminderType)
                            },
                            {
                                title: "Associated",
                                dataIndex: "associate",
                                render: (text, record) => (
                                    <>{(() => {
                                        switch (record.associateType) {
                                            case 'deal': {
                                                return <Link to={`/deal?_id=${record.associate}`}><div className="squareButton">{window.app.state.deals?.find(a => a._id === record.associate)?.name || 'Go to Deal'}</div></Link>
                                            }
                                            case 'company': {
                                                return <Link to={`/company?_id=${record.associate}`}><div className="squareButton">{window.app.state.companies?.find(a => a._id === record.associate)?.name || 'Go To Company'}</div></Link>
                                            }
                                            case 'title': {
                                                return <Link to={`/title?_id=${record.associate}`}><div className="squareButton">{window.app.state.titles?.find(a => a._id === record.associate)?.title || 'Go To Title'}</div></Link>
                                            }
                                            case 'user': {
                                                return <Link to={`/profile?_id=${record.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === record.associate)?.name || 'Go To User Profile'}</div></Link>
                                            }
                                            default: {
                                                return ''
                                            }
                                        }
                                    })()}</>
                                )
                            },
                            {
                                title: "User",
                                dataIndex: "user",
                                render: (text, record) => {
                                    let user = window.app.state.users?.find(a => a._id === record.user)?.name || record.user
                                    if (!user) return (<>{record.user}</>)
                                    return (
                                        <><Link to={`/profile?_id=${record.user}`}>{user}</Link></>
                                    )
                                },
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Created",
                                dateIndex: "created",
                                render: (text, record) => (<>{record.created && window.formatDate(record.created)}</>)
                            },
                            {
                                title: "Follow Up",
                                dateIndex: "date",
                                render: (text, record) => (<>{record.date && window.formatDate(record.date)}</>)
                            }
                        ]
                        break
                    }
                    case 'Emails': {
                        items = this.state._emails || []
                        columns = [
                            {
                                title: "Date",
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                            },
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ currentEmail: record._id }) }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                        ]
                        break
                    }
                    case 'Drafts': {
                        items = window.app.state.draftEmails?.filter(u => (u.associate === this.state._id)) || []
                        columns = [
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({ currentDraft: record._id, emailMessage: record.message, emailSubject: record.subject, emailCc: record.cc, emailBcc: record.bcc, emailAttachments: record.attachments, emailSendAt: record.sendAt, emailFrom: record.from, emailAppendContent: record.appendContent, emailTo: record.to, emailThread: record.thread })
                                    }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                window.app.request('/delete-draft-email', 'post', { _id: record._id }).then(d => {
                                                    if (d?.r?.nModified) {
                                                        window.app.setState({ draftEmails: [...window.app.state.draftEmails].filter(a => a._id !== record._id) })
                                                    } else {
                                                        window.flash('Failed to delete draft')
                                                    }
                                                }).catch(e => {
                                                    window.flash(e)
                                                })
                                            }}>Delete Draft</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                        break
                    }
                    case 'Notes': {
                        items = this.state.notes || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table columns={columns} data={items} mainKey={record => record._id} />} />
            return <div className="tab-pane show active" id="task-activity">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (u.name === 'Notes' ? <Accordian header={'Notes'} count={u.items?.length || 0} content={<NotesDisplay type="table" notes={u.items} />} /> : accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let related = () => {
            let categories = ['Titles', 'Users', 'Deals', 'Agreements'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Agreements': {
                        items = window.app.state.agreements?.filter(a => [a.company, a.associate].includes(this.state._id)) || []
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/agreement?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.company === this.state._id ? 'Company' : a.associate === this.state._id ? 'Associate' : 'uknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        break
                    }
                    case 'Titles': {
                        columns = [
                            {
                                title: "Title",
                                dataIndex: "title",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            }, {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.company === this.state._id ? 'Company' : this.state.titles?.find(b => b === a._id) ? 'Title' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        items = window.app.state.titles?.filter(u => {
                            if (u.company === this.state._id || this.state.titles?.find(a => a === u._id)) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Users': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/profile?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                                render: (text, record) => (
                                    <><TabLink to={`/profile?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },

                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.company === this.state._id ? 'Company' : a._id === this.state.owner ? 'Owner' : a._id === this.state.createdBy ? 'Created By' : this.state.users?.find(b => b === a._id) ? 'Users' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        items = window.app.state.users?.filter(u => {
                            if (u.company === this.state._id || this.state._id === u.company || u._id === this.state.owner || u._id === this.state.createdBy || this.state.users?.find(a => a === u._id)) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Deals': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/deal?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.company === this.state._id ? 'Company' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        items = window.app.state.deals?.filter(u => {
                            if (u.company === this.state._id) return true
                            return false
                        }) || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            }) || []
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table data={items} columns={columns} mainKey={record => record._id} />} />
            return <div className="tab-pane task-related show active" id="task-related">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let shippingInfo = () => <Accordian header={'Shipping Information'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Shipping Company Name</td>
                    <td className="border-0">{this.state.shippingCompany}</td>
                </tr>
                <tr>
                    <td className="border-0">Shipping Contact</td>
                    <td className="border-0">{this.state.shippingContact}</td>
                </tr>
                <tr>
                    <td className="border-0">Shipping Address</td>
                    <td className="border-0"><AddressDisplay address={this.state.shippingAddress} /> </td>
                </tr>
            </tbody>
        </table>} />
        let generalInfo = () => (<Accordian open={true} header={'General Information'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Name</td>
                    <td className="border-0">{this.state.name}</td>
                </tr>
                <tr>
                    <td className="border-0">Phone</td>
                    <td className="border-0">{this.state.phone}</td>
                </tr>
                <tr>
                    <td className="border-0">Email</td>
                    <td className="border-0">{this.state.email}</td>
                </tr>
                <tr>
                    <td className="border-0">Notes</td>
                    <td className="border-0"><NotesDisplay notes={this.state.notes} /></td>
                </tr>
                {this.state.parentCompany && <tr>
                    <td className="border-0">Parent Company</td>
                    <td className="border-0"><TabLink to={`/company?_id=${this.state.parentCompany}`}>{window.app.state.companies?.find(a => a._id === this.state.parentCompany)?.name}</TabLink></td>
                </tr>}
                <tr>
                    <td className="border-0">Website</td>
                    <td className="border-0">{this.state.website}</td>
                </tr>
            </tbody>
        </table>} />)
        let socialMedia = () => (<Accordian header={'Social Media'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Cinando</td>
                    <td className="border-0">{this.state.cinando && window.isLink(this.state.cinando) ? <TabLink to={this.state.cinando} text={'VIEW CINANDO'}/> : this.state.cinando}</td>
                </tr>
                <tr>
                    <td className="border-0">Instagram</td>
                    <td className="border-0">{this.state.instagram && window.isLink(this.state.instagram) ? <TabLink to={this.state.instagram} text={'View Instagram'}/> : this.state.instagram}</td>
                </tr>
                <tr>
                    <td className="border-0">Facebook</td>
                    <td className="border-0">{this.state.facebook && window.isLink(this.state.facebook) ? <TabLink to={this.state.facebook} text={'view Facebook'}/> : this.state.facebook}</td>
                </tr>
                <tr>
                    <td className="border-0">IMDB</td>
                    <td className="border-0">{this.state.imdb && window.isLink(this.state.imdb) ? <TabLink to={this.state.imdb} text={'View Imdb'} /> : this.state.imdb}</td>
                </tr>
                <tr>
                    <td className="border-0">LinkedIn</td>
                    <td className="border-0">{this.state.linkedIn && window.isLink(this.state.linkedIn) ? <TabLink to={this.state.linkedIn} text="View LinkedIn"/> : this.state.linkedIn}</td>
                </tr>
                <tr>
                    <td className="border-0">Twitter</td>
                    <td className="border-0">{this.state.twitter && window.isLink(this.state.twitter) ? <TabLink to={this.state.twitter} text="View Twitter" /> : this.state.twitter}</td>
                </tr>
                <tr>
                    <td className="border-0">Youtube</td>
                    <td className="border-0">{this.state.youtube && window.isLink(this.state.youtube) ? <TabLink to={this.state.youtube} text={"View Youtube"} /> : this.state.youtube}</td>
                </tr>
                <tr>
                    <td className="border-0">Tiktok</td>
                    <td className="border-0">{this.state.tiktok && window.isLink(this.state.tiktok) ? <TabLink to={this.state.tiktok} text="View Tiktok" /> : this.state.tiktok}</td>
                </tr>
            </tbody>
        </table>} />)
        let buyingInfo = () => (<Accordian header={'Buying Information'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Country of Acquisition</td>
                    <td className="border-0">{this.state.countryOfAcquisition}</td>
                </tr>
                <tr>
                    <td className="border-0">Rights of Interest</td>
                    <td className="border-0"><ArrayDisplay items={this.state.rightsOfInterest} /></td>
                </tr>
                <tr>
                    <td className="border-0">Genres of Interest</td>
                    <td className="border-0"><ArrayDisplay items={this.state.genresOfInterest} /></td>
                </tr>
                <tr>
                    <td className="border-0">Exclusivity Needed</td>
                    <td className="border-0">{this.state.exclusivityNeeded}</td>
                </tr>
                <tr>
                    <td className="border-0">Source Code</td>
                    <td className="border-0">{this.state.sourceCode}</td>
                </tr>
                <tr>
                    <td className="border-0">Territories</td>
                    <td className="border-0"><ArrayDisplay items={this.state.territories} /></td>
                </tr>
            </tbody>
        </table>} />)
        let deliveryInfo = () => (<Accordian header={'Delivery Information'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Delivery Contact Name</td>
                    <td className="border-0">{this.state.deliveryContactName}</td>
                </tr>
                <tr>
                    <td className="border-0">Email</td>
                    <td className="border-0">{this.state.deliveryContactEmail}</td>
                </tr>
                <tr>
                    <td className="border-0">Delivery Specs</td>
                    <td className="border-0">{this.state.deliverySpecs}</td>
                </tr>
                <tr>
                    <td className="border-0">Delivery Specs Sheets</td>
                    <td className="border-0">{this.state.deliverySpecsSheets && this.state.deliverySpecsSheets.map((u, i) => <><TabLink key={i} to={u} text={'View Spec Sheet '+(i+1)}></TabLink><br></br></>)}</td>
                </tr>
                {this.state.fileTransferLogin && <tr>
                    <td className="border-0">File Transfer Dashboard Login</td>
                    <td className="border-0"><CredentialDisplay value={this.state.fileTransferLogin} /></td>
                </tr>}
                {this.state.servicingLogin && <tr>
                    <td className="border-0">Servicing Dashboard Login</td>
                    <td className="border-0"><CredentialDisplay value={this.state.servicingLogin} /></td>
                </tr>}
                {this.state.reportingLogin && <tr>
                    <td className="border-0">Reporting Dashboard Login</td>
                    <td className="border-0"><CredentialDisplay value={this.state.reportingLogin} /></td>
                </tr>}
            </tbody>
        </table>} />)
        // let accountingInfo = () => (<Accordian header={'Accounting Information'} content={<table className="table">
        //     <tbody>
        //         <tr>
        //             <td className="border-0">Accounting Contact</td>
        //             <td className="border-0">{this.state.accountingContact}</td>
        //         </tr>
        //         <tr>
        //             <td className="border-0">Billing Address</td>
        //             <td className="border-0"><AddressDisplay address={this.state.billingAddress} /></td>
        //         </tr>
        //     </tbody>
        // </table>} />)
        // let paymentInfo = () => (<Accordian header={'Payment Information'} content={<table className="table">
        //     <tbody>
        //             <tr>
        //                 <td className="border-0">Bank Name</td>
        //                 <td className="border-0">{this.state.paymentInfo?.bankName}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Bank Account Name</td>
        //                 <td className="border-0">{this.state.paymentInfo?.name}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Bank Account Number</td>
        //                 <td className="border-0">{this.state.paymentInfo?.accountNumber && <Censored value={this.state.paymentInfo.accountNumber} />}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Bank Routing Number</td>
        //                 <td className="border-0">{this.state.paymentInfo?.routingNumber && <Censored value={this.state.paymentInfo.routingNumber} />}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">IBAN</td>
        //                 <td className="border-0">{this.state.paymentInfo?.IBAN}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">SIC Code</td>
        //                 <td className="border-0">{this.state.paymentInfo?.SICCode}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Sort Code</td>
        //                 <td className="border-0">{this.state.paymentInfo?.sortCode}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Bank Address</td>
        //                 <td className="border-0"><AddressDisplay address={this.state.paymentInfo?.bankAddress} /></td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Tax ID Number</td>
        //                 <td className="border-0">{this.state.paymentInfo?.taxID}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">W9 or W8 BEN</td>
        //                 <td className="border-0">{this.state.paymentInfo?.W9 || this.state.paymentInfo?.W8BEN}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">VAT</td>
        //                 <td className="border-0">{this.state.paymentInfo?.VAT}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Currency Type</td>
        //                 <td className="border-0">{this.state.paymentInfo?.currencyType}</td>
        //             </tr>
        //             <tr>
        //                 <td className="border-0">Accounting Notes</td>
        //                 <td className="border-0">
        //                     {this.state.paymentInfo?.accountingNotes && <ArrayDisplay items={this.state.paymentInfo?.accountingNotes} />}
        //                 </td>
        //             </tr>
        //     </tbody>
        // </table>} />)
        let distributorInfo = () => (<Accordian header={'Distributor Information'} content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Distributor Type</td>
                    <td className="border-0"><ArrayDisplay items={this.state.distributorTypes}/></td>
                </tr>
                <tr>
                    <td className="border-0">Pitch Requirements</td>
                    <td className="border-0"><ArrayDisplay items={this.state.pitchRequirements} template={(u,i) => <><TabLink to={u} key={i} text={`View Requirements ${i+1}`}></TabLink><br></br></>} /></td>
                </tr>
                <tr>
                    <td className="border-0">Submissions Due By</td>
                    <td className="border-0">{this.state.submissionsDueBy}</td>
                </tr>
                <tr>
                    <td className="border-0">Territories of Interest</td>
                    <td className="border-0"><ArrayDisplay items={this.state.territoriesOfInterest} /></td>
                </tr>
                <tr>
                    <td className="border-0">Fees & Percentages</td>
                    <td className="border-0"><ArrayDisplay items={this.state.feesAndPercentages} /></td>
                </tr>
                <tr>
                    <td className="border-0">Months Out</td>
                    <td className="border-0">{this.state.monthsOut ? `${this.state.monthsOut} months` : ''}</td>
                </tr>
                <tr>
                    <td className="border-0">Placement Costs</td>
                    <td className="border-0"><ArrayDisplay items={this.state.placementCosts} /></td>
                </tr>
                <tr>
                    <td className="border-0">Max Age for Title</td>
                    <td className="border-0">{this.state.maxAgeForTitle ? `${this.state.maxAgeForTitle} months` : ''}</td>
                </tr>
                <tr>
                    <td className="border-0">Ad Buy Options</td>
                    <td className="border-0">{this.state.adBuyOptions && (window.isLink(this.state.adBuyOptions) ? <TabLink to={this.state.adBuyOptions} text={'View Ad Buy Options'}/> : this.state.adBuyOptions)}</td>
                </tr>
                <tr>
                    <td className="border-0">Previously Released Titles</td>
                    <td className="border-0"><ArrayDisplay items={this.state.previouslyReleasedTitles} template={(u, i) => (<li key={i}><TabLink to={`/title?_id=${u}`} text={window.app.state.titles?.find(a => a._id === u)?.title || u}></TabLink></li>)} /></td>
                </tr>
            </tbody>
        </table>} />)
        let email = this.state._emails?.find(a => a._id === this.state.currentEmail)
        return (<>
            {this.state.currentDraft ? (<div><EmailCompose to={this.state.emailTo} from={this.state.emailFrom} attachments={this.state.emailAttachments} bcc={this.state.emailBcc} cc={this.state.emailCc} subject={this.state.emailSubject} message={this.state.emailMessage} appendContent={this.state.emailAppendContent} _id={this.state.currentDraft} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ currentDraft: '', emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>) : this.state.compose ? (<div><EmailCompose to={this.state.emailTo || email?.to} from={this.state.emailFrom || email?.from} attachments={this.state.emailAttachments || email?.attachments} bcc={this.state.emailBcc || email?.bcc} cc={this.state.emailCc || email?.cc} subject={this.state.emailSubject || email?.subject} message={this.state.emailMessage || email?.message} appendContent={this.state.emailAppendContent} _id={this.state.currentEmail} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ compose: false, emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>) : this.state.currentEmail ? <EmailView  set={k => {
            this.setState({_emails: this.state._emails.map(a => a._id === this.state.currentEmail ? { ...a, title: k } : a)})
        }}  email={email} close={() => this.setState({ currentEmail: '' })} _id={this.state.currentEmail} /> : ''}
            {this.state.edit && <CompanyForm crm={this.state.crm} _id={this.state._id} add={(form) => {
                return new Promise((res, rej) => {
                    if (!form) return rej('Missing form')
                    window.app.companyForm(form).then(company => {
                        this.initTitle()
                        res(company)
                    }).catch(e => rej(e))
                })
            }} close={() => this.setState({ edit: false })} />}
            {this.state.changeImage && <ChangeImage _id={this.state._id} company={true} close={() => this.setState({ changeImage: false }, () => this.initTitle())} />}
            <Helmet>
                <title>{this.state.name || 'Company'}</title>
                <meta name="description" content="An Octane Multimedia film" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> {this.state.name || 'Company'}</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to={(this.props.crm ? `/${this.props.crm}` : this.state.crm ? `/${this.state.crm}` : '') + "/companies"}>Companies</Link></li>
                        <li className="breadcrumb-item active">{this.state.name || 'Company'}</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {/* <div className="profile-img-wrap col-md-5">
                                </div> */}
                            <div className="col-md-5 account d-flex">
                                <div className="profile-img">
                                    <a className="avatar" href="#" onClick={e => {
                                        e.preventDefault()
                                        this.setState({ changeImage: true })
                                    }}>
                                        <img className="avatar" onClick={e => {
                                            e.preventDefault()
                                            this.setState({ changeImage: true })
                                        }} alt="" src={this.state.profilePic}></img>
                                    </a>
                                </div>
                                <div>
                                    <p className="mb-0">Company</p>
                                    {this.state._id && <span className="modal-title">{this.state.name} <a onClick={() => this.setState({ edit: true })}><i className="fa fa-pencil"></i></a></span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="task-infos">
                            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                <li className="nav-item"><a className={this.state.view === 'details' ? "nav-link active" : 'nav-link'} href="#task-details" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'details' })
                                }}>Details</a></li>
                                <li className="nav-item"><a className={this.state.view === 'related' ? "nav-link active" : 'nav-link'} href="#task-related" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'related' })
                                }}>Related</a></li>
                                <li className="nav-item"><a className={this.state.view === 'activity' ? "nav-link active" : 'nav-link'} href="#task-activity" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'activity' })
                                }}>Activity</a></li>
                            </ul>
                            <div className="tab-content">
                                {this.state.view === 'details' ? <div className="tab-pane show active" id="task-details">
                                    <div className="crms-tasks">
                                        {this.state.crm === 'film-sales' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {shippingInfo()}
                                            {/* {accountingInfo()} */}
                                        </> : this.state.crm === 'acquisitions' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {shippingInfo()}
                                            {/* {paymentInfo()}
                                            {accountingInfo()} */}
                                        </> : this.state.crm === 'coporate-sales' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {shippingInfo()}
                                        </> : this.state.crm === 'film-distribution' ? <>
                                        {generalInfo()}
                                            {socialMedia()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {distributorInfo()}
                                            {shippingInfo()}
                                            {/* {paymentInfo()}
                                            {accountingInfo()} */}
                                        </> : ''}
                                    </div>
                                </div> : this.state.view === 'related' ? related() : activity()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default FullCompany




import React, { Component } from 'react'
class AddressDisplay extends Component {
    render() {
        if (!this.props.address) return <div></div>
        let address
        try {
            if (typeof this.props.address === 'object') {
                address = this.props.address
            } else if (typeof this.props.address === 'string') {
                address = JSON.parse(this.props.address)
            }
        } catch(e) {
            return (<div></div>)
        }
        if (!address) return (<div></div>)
        let {city = '', state = '', country = '', region = '', postalCode = '', street = '', className = ''} = address
        return (<div className={className}>
            <span>{street}</span><br></br>
            <span>{city && `${city}, `}{state && `${state}, `}{region && `${region} `}{postalCode && `${postalCode} `}{country && ` - ${country}`}</span>
        </div>)
    }
}
export default AddressDisplay
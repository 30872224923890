import { Component } from 'react'
import Model from '../main/model'
import ModelView from '../main/model-view'
class Film extends Component {
    state = { dragTarget: null, selectedFields: [], mode: 'insert' }
    componentDidMount() {
        window.app.getTitles()
        window.app.getModels()
    }
    addField(name) {
        this.setState({ selectedFields: [...this.state.selectedFields, this.props.fields.find(u => u.name === name)] })
    }
    dragStart = (e) => {
        e.dataTransfer.setData('text/plain', e.target.getAttribute(['data']));
        e.dataTransfer.dropEffect = 'copy';
        this.setState({ dragTarget: e.target?.getAttribute(['data']) })
    }
    dragEnd = (e) => {
        e.preventDefault()
        this.setState({ dragTarget: null })
    }
    dragExit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.remove('sele')
    }
    dragOver = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.add('sele')
    }
    drop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let id = e.dataTransfer.getData('text');
        let eid = e.target?.id || e.target?.parentElement.id
        e.target.classList.remove('sele')
        if (eid === 'selectedFields') {
            if (this.state.selectedFields.find(u => u.name === id)) {
                let data = e.target.getAttribute(['data'])
                if (data) {
                    let d = [...this.state.selectedFields]
                    let n
                    let k
                    for (let i = 0; i < d.length; i++) {
                        if (d[i].name === data) {
                            n = i
                        } else if (d[i].name === id) {
                            k = i
                        }
                    }
                    if (!isNaN(n) && !isNaN(k)) {
                        let st = this.state.mode === 'swap' ? window.swap([...this.state.selectedFields], n, k) : window.insert([...this.state.selectedFields], k, n)
                        this.setState({ selectedFields: st })
                    }
                }
            } else {
                this.setState({
                    selectedFields: [...this.state.selectedFields, (this.props.fields?.find(u => u.name === id) || null)].reduce((a, b) => {
                        if (b && !a.find(u => u.name === b.name)) a.push(b)
                        return a
                    }, [])
                })
            }
        } else if (eid === 'fieldList') {
            this.setState({
                selectedFields: [...this.state.selectedFields].filter(u => u.name !== id)
            })
        }
    }
    setModel(id) {
        this.setState({ model: new Model(this.props.models.find(u => u.id === id)), viewModel: true })
    }
    render() {
        if (this.state.viewModel) return (<>
            <button className="top-right-abs" onClick={e => this.setState({ viewModel: false })}>Close</button>
            <ModelView mode={this.state.editModel} model={new Model(this.state.model instanceof Model ? this.state.model : { fields: this.state.selectedFields, items: this.props.movies.map(u => u._id) })} fields={this.props.fields} titles={this.props.movies}></ModelView>
        </>)
        return (<div className="b1">
            <h1>Film Report</h1>
            <div className="c2" style={{ gridGap: '20px', maxWidht: '80%' }}>
                <div className="b1">
                    <h2>Fields</h2>
                    <div className="b1">
                        <div id="fieldList" onDragEnter={this.dragOver} onDragOver={this.dragOver} onDrop={this.drop} onDragLeave={this.dragExit} onDragExit={this.dragExit}>
                            {this.props.fields.filter(u => !this.state.selectedFields.find(a => a.name === u.name)).map((u, i) => (<div key={i} onClick={e => {
                                this.setState({
                                    selectedFields: [...this.state.selectedFields, this.props.fields.find(u => u.name === e.target.getAttribute(['data']))].reduce((a, b) => {
                                        if (b && !a.find(u => u.name === b.name)) a.push(b)
                                        return a
                                    }, [])
                                })
                            }} data={u.name} className="field" draggable={true} onDragEnd={this.dragEnd} onDragStart={this.dragStart}>{u.name}</div>))}
                        </div>
                    </div>
                </div>
                <div className="b1 rel">
                    <div className="b2">
                        <h2>Selected Fields</h2>
                        <div className="b2 jfe" style={{ width: 'auto' }}>
                            <button className={'fixedtoggle' + (this.state.mode !== 'swap' ? '' : ' active')} onClick={e => this.setState({ mode: 'swap' })}>Swap</button>
                            <button className={'fixedtoggle' + (this.state.mode !== 'insert' ? '' : ' active')} onClick={e => this.setState({ mode: 'insert' })}>Insert</button>
                        </div>
                    </div>
                    <div id="selectedFields" className="rel" onDragEnter={this.dragOver} onDragOver={this.dragOver} onDrop={this.drop} onDragLeave={this.dragExit} onDragExit={this.dragExit}>
                        {this.state.selectedFields?.length > 0 && this.state.selectedFields.map((u, i) => (<div key={i} className="field" onClick={e => {
                            this.setState({ selectedFields: [...this.state.selectedFields].filter(u => u.name !== e.target.getAttribute(['data'])) })
                        }} data={u.name} draggable={true} onDragEnd={this.dragEnd} onDragStart={this.dragStart}>{u.name}</div>))}
                    </div>
                </div>
            </div>
            <button style={{ width: '40%' }} onClick={e => this.setState({ viewModel: true, model: new Model({fields: this.state.selectedFields, items: this.props.movies.map(u => u._id)}) })}>Run Report</button>
            <div className="b1 rel">
                <h2>Templates</h2>
                <div className="c4" style={{maxWidth: '80%'}}>{this.props.models.map((u, i) => (<button className="reportButton" key={i} id={u.id} onClick={e => {
                    this.setModel(e.target.id)
                }}>{u.modelName || 'New Template'}</button>))}</div>
                <button onClick={u => {
                    this.setState({ model: new Model({ fields: this.state.aFields ? this.props.fields : this.state.selectedFields, items: [...this.props.movies].map(u => u._id) }), viewModel: true, editModel: true })
                }}>New Template</button>
            </div>
        </div>)
    }
}
export default Film
import React from 'react'
export default function SidePop(props) {
    return (<><div className="modal right fade show" style={{ display: 'block' }} onClick={props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
        <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close xs-close" onClick={props.close} data-dismiss="modal">×</button>
                    <div className="row w-100">
                        <div className="col-md-7 account">

                        </div>
                        <div className="col-md-5 text-right">

                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="row" style={{ margin: '10px 0px' }}>
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="modal-backdrop fade show"></div>
    </>)
}
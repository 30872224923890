import React, { Component } from 'react'
import * as JsSearch from 'js-search';
class SearchInput extends Component {
    state = {
        list: [],
        searching: ''
    }
    componentDidMount() {
        this.setState({ key: this.props.mainKey || '_id' })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    componentDidUpdate() {
        if (this.props.mainKey !== this.state.key) this.setState({ key: this.props.mainKey })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    search(value) {
        if (!this.props.items || this.props.items.length < 1) return []
        let a = []
        let s = new JsSearch.Search(this.state.key);
        s.indexStrategy = new JsSearch.PrefixIndexStrategy()
        for (let i = 0; i < this.props.indexes.length; i++) s.addIndex(this.props.indexes[i])
        s.addDocuments(this.props.items)
        a.push(...s.search(value))
        if (typeof this.props.onSearch === 'function') this.props.onSearch(value)
        return a
    }
    list = (u, i) => {
        console.log(u)
        return (<div key={i} className="b2 jsb searchResult">
            <h3>{u[this.state.key]}</h3>
            <button id={u[this.state.key]} className="smallbut" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.props.callback(this.props.items.find(u => u[this.state.key] === e.target.id))
                this.setState({ searching: '' })
            }}>VIEW</button></div>)
    }
    render() {
        return (<div id="searchbar" ><form onInput={e => {
            if (e.target) return
        }} onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            this.props.callback(e.target['search'].value)
            this.setState({ searching: '' })
        }}></form>
            <input id="searchbarInput" placeholder={this.props.placeholder || "Search"} onKeyUp={e => {
                if (e.key === 'Enter' || e.keyCode === 13) {this.props.callback(e.target.value); this.setState({searching: ''})}
            }} type="search" autoComplete="off" value={this.state.searching} onChange={(e) => {
                this.setState({ searching: e.target.value, list: this.search(e.target.value) })
            }}></input>
            <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                <div className="b1 jsa">Search Results</div>
                {this.state.list.length < 1 ? (<h3>No Results for {this.state.searching || 'Null'}</h3>) : (<div className="b1">{this.state.list.map(this.props.template || this.list)}</div>)}
            </div>
        </div>)
    }
}
export default SearchInput
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import DotDot from './components/helpers/display/dotdot'
import Spinner from './components/helpers/display/spinner'
import Toggle from './components/helpers/inputs/toggle2'
class Settings extends Component {
    state = {
        company: '',
        role: '',
        firstName: '',
        lastName: '',
        messageCount: undefined
    }
    componentDidMount() {
        if (!this.state.messageCount) {
            this.getMessageCount()
        }
    }
    componentDidUpdate() {
        if (typeof this.state.messageCount !== 'number') this.getMessageCount()
    }
    getMessageCount = () => {
        if (this.getting) return
        this.getting = true
        window.app.request('/get-message-count', 'post', { _id: window.app.state.userID }).then(result => {
            this.getting = false
            this.setState({ messageCount: result.messageCount })
        }).catch(e => window.flash(e))
    }
    render() {
        return (<>
            <Helmet>
                <title>Settings</title>
                <meta name="description" content="Reactify Blank Page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header pt-3 mb-0 ">
                    <div className="crms-title row bg-white">
                        <div className="col  p-0">
                            <h3 className="page-title m-0">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="fa fa-cog" aria-hidden="true"></i>
                                </span> Settings</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Settings</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div>
                                        <span>Sync Email</span>
                                        <Toggle tog={window.app.state.mailsync} change={e => {
                                            if (e) {
                                                window.app.startMailSync()
                                            } else {
                                                window.app.stopMailSync()
                                            }
                                        }} />
                                        {window.app.state.mailsync ? (window.app.state.lastMailSync ? <>
                                            <br></br>
                                            <span>Last Synced: {window.formatDate(window.app.state.lastMailSync)} @ {window.formatTime(window.app.state.lastMailSync)}</span>
                                            <br></br>
                                            <span>Message Count: {this.state.messageCount ? this.state.messageCount : <DotDot />}</span>
                                            <br></br>
                                            {/* <span style={{ color: 'var(--p)', cursor: 'pointer' }} onClick={() => {

                                            }}>Sync Now</span> */}
                                        </> : <><br></br>
                                            <div style={{ display: 'inline-flex', flexDirection: 'column' }}><Spinner /><span>Currently Syncing Messages<DotDot /></span></div>
                                        </>) :''}
                                    </div>
                                    {window.isSuper(window.app.state.type) && <div>
                                        <span>Archived Mode <Toggle tog={window.app.state.archiveMode} change={() => window.app.setState({archiveMode: !window.app.state.archiveMode})} /></span>
                                    </div>}
                                    <div>
                                        <span>Allow Sounds <Toggle tog={!window.app.state.NOSOUND} change={() => window.app.setState({NOSOUND: !window.app.state.NOSOUND}, () => {
                                            if (window.app.state.NOSOUND) {
                                                localStorage.setItem("NOSOUND", 'TRUE')
                                            } else {
                                                localStorage.removeItem('NOSOUND')
                                            }
                                        })} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Settings
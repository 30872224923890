import React, { Component } from 'react'
import '../components/helpers/dotdot'
import Spinner from '../components/helpers/spinner'
import Table from '../../helpers/display/table'
import calc from './calculate'
import PForm from './paymentform'
import TabLink from '../../helpers/tablink'
class Report extends Component {
    state = { data: [], init: false }
    componentDidMount() {
        console.log('report mounted')
        this.calculate()
    }
    async calculate() {
        let points = await calc(this.props.type, this.props.data, { ...this.props })
        return this.setState({ data: points, init: true })
    }
    sort(name) {
        if (name === 'received_total' || name === 'agreement_total' || name === 'deal_count') {
            return (a, b) => b[name] - a[name]
        } else if (name === 'title') {
            return (a, b) => {
                let c = a[name]
                let d = b[name]
                let e = c ? this.props.movies.find(u => u._id === c) : null
                let f = d ? this.props.movies.find(u => u._id === d) : null
                return window.nameCompare(e ? e.title : e, f ? f.title : f)
            }
        } else if (name === 'platform' || name === 'buyer') {
            return (a, b) => window.nameCompare(window.getCompany(a[name]), window.getCompany(b[name]))
        } else {
            return (a, b) => a[name] < b[name] ? -1 : a[name] === b[name] ? 0 : 1
        }
    }
    renderFunc = (name) => {
        if (name === 'received_total' || name === 'agreement_total' || name === 'amount' || name === 'income' || name === 'received_amount' || name === 'agreement_amount') {
            return (text, record) => <>${window.currency(text)}</>
        } else if (name === 'title') {
            return (text, record) => <><TabLink to={`/title?_id=${this.props.movies?.find(a => (a._id === record.title || a.title === record.title))?._id || text}`}>{this.props.movies?.find(a => a._id === record.title)?.title || text}</TabLink></>
        } else if (name === 'platform' || name === 'buyer') {
            return (text, record) => <><TabLink to={`/company?_id=${window.app.state?.companyList?.find(a => (a._id === text) || a.name === text)?._id || text}`}>{window.getCompany(text)}</TabLink></>
        } else if (name === 'date') {
            return (text) => <>{window.formatDate(text)}</>
        } else if (name === 'published' || name === 'thirdParty') {
            return (text) => <>{window.bool(text)}</>
        } else {
            return (text, record) => <>{text}</>
        }
    }
    export() {
        window.app.request('/run-report', 'post', { data: this.state.data.map((u) => window.fok(u, ['data'])) }).then(r => {
            if (!r.link) window.flash('Failed to load resource')
            let a = document.createElement('a')
            a.href = r.link
            a.target = '_blank'
            document.querySelector('body').append(a)
            a.click()
            a.remove()
        }).catch(e => window.flash(e))
    }
    commas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    columns() {
        let d = (title, index, render, sorter) => ({ title, dataIndex: index, render: render || this.renderFunc(index), sorter: sorter || this.sort(index) })
        return Object.values(this.state.data.reduce((a, b) => {
            let k = Object.keys(b).filter(a => !['data', 'id'].includes(a))
            k.forEach(u => {
                if (!a[u]) a[u] = d(window.parseKey(u), u)
            })
            return a
        }, {}))
    }
    render() {
        let mainKey = this.props.mainKey || (this.state.data ? this.state.data[0] && Object.keys(this.state.data[0])[0] : '_id')
        window.report = this
        let columns = this.columns()
        if (!this.state.init) return (<div className="b1"><h3>Creating Report<dot-dot></dot-dot></h3><Spinner></Spinner></div>)
        return (<div className="b1">
            {this.state.edit && this.state.ptype && <PForm _id={this.state.viewing} type={this.state.ptype} close={() => this.setState({ edit: false, viewing: '' })} />}
            <div id="tempButs" className="bottom-left-float">
                <button onClick={e => {
                    this.export()
                }}>Export CSV</button>
            </div>
            <h2>{window.capitalize(this.props.type)}</h2>
            {this.props.dateRange && <span>From: {window.formatDate(this.props.dateRange['a'])} - To: {window.formatDate(this.props.dateRange['b'])}</span>}
            <div className="rel b1">
                <div id="fixedtoggles" className="b2 jfe" style={{ top: 0, right: 0 }}>
                    <button className={'fixedtoggle' + (this.state.mode !== 'table' ? '' : ' active')} onClick={e => this.setState({ mode: 'table' })}>Table</button>
                    <button className={'fixedtoggle' + (this.state.mode !== 'grid' ? '' : ' active')} onClick={e => this.setState({ mode: 'grid' })}>Grid</button>
                </div>
                {(this.state.mode === 'grid' ? <div className="c3" style={{ maxWidth: '80%' }}>
                    {this.state.data.map((u, i) => (<div className="datapoint" key={i}>
                        {Object.keys(u).map((a, i) => (<div key={i}>{a.toUpperCase()} : {u[a]}</div>))}
                    </div>))}
                </div> : (<Table
                    className="table"
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    data={this.state.data}
                    mainKey={mainKey}
                    expandable={{
                        expandedRowRender: ({ data = [] }, rowIndex) => {
                            let columns = () => {
                                let d = (title, index, render, sorter) => ({ title, dataIndex: index, render: render || this.renderFunc(index), sorter: sorter || this.sort(index) })
                                return Object.values(data.reduce((a, b) => {
                                    let k = Object.keys(b).filter(a => !['data', '_id', 'visible', '__v'].includes(a))
                                    k.forEach(u => {
                                        if (!a[u]) a[u] = d(window.parseKey(u), u)
                                    })
                                    return a
                                }, {}))
                            }
                            return (<Table
                                style={{ overflowX: "auto" }}
                                columns={columns() || Object.keys(data[0]).map((u) => ({ title: window.parseKey(u), dataIndex: u, render: text => <>{text}</> }))}
                                data={data}
                                mainKey={'_id'}
                                rowClassName={'pointer'}
                                onRow={(record) => ({ onClick: () => this.setState({ viewing: record._id, edit: true, ptype: window.app.state.sales?.find(a => a._id === record._id) ? 'sale' : window.app.state.payments?.find(a => a._id === record._id) ? (window.app.state.payments?.find(a => a._id === record._id).amount >= 0 ? 'payment' : 'expense') : '' }) })}
                            />)
                        }
                    }}
                />))}
            </div>
        </div>)
    }
}
export default Report
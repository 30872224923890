import React, { Component } from 'react'
import ArrayInput from '../helpers/inputs/arrayinput'
import YesNo from '../helpers/inputs/yesno'
import TitleAllocations from '../helpers/inputs/titleallocations'
import NotesInput from '../helpers/inputs/notesinput'
import DateInput from '../helpers/inputs/dateinput'
import Label from '../helpers/inputs/label'
import TextInput from '../helpers/inputs/textinput'
import TextArea from '../helpers/inputs/textarea'
import NumberInput from '../helpers/inputs/numberinput'
import SelectInput from '../helpers/inputs/select-input'
import Accordian from '../helpers/display/accordian'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class ProductForm extends Component {
    state = { type: '', onSale: false, available: true }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.products?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile })
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.products?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id })
            }
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({ subbing: true })
        window.app.productForm(e.target).then(r => {
            this.pending = []
            this.setState({ ...r, subbing: false })
            window.flash('Product/service Saved')
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        let productInfo = () => (<>
            <Accordian open={true} header="Product Information" content={<div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Product Type</label>
                    <SelectInput name="productType" placeholder="Select a Type" value={this.state.productType} change={e => this.setState({ productType: e })} options={['physical', 'digital'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Price</label>
                    <NumberInput name="price" prepend={'$'} value={this.state.price} change={e => this.setState({ price: e })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Quantity</label>
                    <NumberInput name="quantity" append={'units'} value={this.state.quantity} change={e => this.setState({ quantity: e })} />
                </div>
                <div className={`col-md-${this.state.onSale ? '6' : '12'}`}>
                    <label className="col-form-label">Sale</label>
                    <YesNo value={this.state.onSale} change={e => this.setState({ onSale: e })} />
                </div>
                {this.state.onSale && <>
                    <div className="col-md-6">
                        <label className="col-form-label">Sale Price</label>
                        <NumberInput name="salePrice" prepend={'$'} value={this.state.salePrice} change={e => this.setState({ salePrice: e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Sale Starts</label>
                        <DateInput name="saleStarts" value={this.state.saleStarts} change={e => this.setState({ saleStarts: e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Sale Ends</label>
                        <DateInput name="saleEnds" value={this.state.saleEnds} change={e => this.setState({ saleEnds: e })} />
                    </div>
                </>}
                <div className="col-md-6">
                    <label className="col-form-label">Shipping Rate</label>
                    <NumberInput name="shippingRate" prepend={'$'} value={this.state.shippingRate} change={e => this.setState({ shippingRate: e })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Blacklisted Countries</label>
                    <ArrayInput name="blacklistedCountries" selected={this.state.blacklistedCountries} items={window.countries || []} listTemplate={(u, i) => (<li key={i}>{window.countries?.find(a => a.code === u)?.name || u}</li>)} template={(u, i) => (<option key={i} value={u.code}>{u.name}</option>)} change={e => this.setState({ blacklistedCountries: e })} />
                </div>
            </div>} />
        </>)
        let subscription = () => (<>
            <div className="col-md-6">
                <Label>Frequency</Label>
                <SelectInput name="frequency" value={this.state.frequency} change={e => this.setState({ frequency: e })} options={['daily', 'weekly', 'monthly', 'annually'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
            </div>
            <div className="col-md-6">
                <Label>Amount</Label>
                <NumberInput name="price" prepend={'$'} value={this.state.price} change={e => this.setState({ price: e })} />
            </div>
            <div className="col-md-6">
                <Label>Trial Period</Label>
                <SelectInput name="trialPeriod" value={this.state.trialPeriod} change={e => this.setState({ trialPeriod: e })} options={['week', 'month', '3m'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
            </div>
        </>)
        let serviceInfo = () => (<>
            <Accordian open={true} header="Service Information" content={<div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Service Type</label>
                    <SelectInput name="serviceType" value={this.state.serviceType} change={e => this.setState({ serviceType: e })} options={['hourly', 'flat fee', 'subscription'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
                </div>
                {this.state.serviceType === 'subscription' ? subscription() : this.state.serviceType === 'hourly' ? <>
                    <div className="col-md-6">
                        <label className="col-form-label">Hourly Rate</label>
                        <NumberInput name="price" value={this.state.price} change={e => this.setState({ price: e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Max Number of Hours</label>
                        <NumberInput name="quantity" value={this.state.quantity} change={e => this.setState({ quantity: e })} />
                    </div>
                </> : this.state.serviceType === 'flat fee' ? <>
                    <div className="col-md-6">
                        <label className="col-form-label">Fee Amount</label>
                        <NumberInput name="price" value={this.state.price} change={e => this.setState({ price: e })} />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Installments</label>
                        <NumberInput name="quantity" value={this.state.quantity || 1} change={e => this.setState({ quantity: e })} />
                    </div>
                </> : ''}
            </div>} />
        </>)
        let offeringInfo = () => (<>
            <Accordian open={true} header="Offering Information" content={<div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Offering Type</label>
                    <SelectInput name="offeringType" value={this.state.offeringType} change={e => this.setState({ offeringType: e })} options={['special', 'seasonal', 'conditional'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
                </div>

            </div>} />
        </>)
        window.productform = this
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Product</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    {<Accordian open={true} header="General Information" content={<div className="form-group row">
                                        <div className="col-md-6">
                                            <Label>Name</Label>
                                            <TextInput name="name" value={this.state.name} change={e => this.setState({ name: e })} placeholder="Name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Type</label>
                                            <SelectInput name="type" placeholder="Select a Type" value={this.state.type} change={e => this.setState({ type: e })} options={['product', 'service', 'offering'].map((u, i) => (<option key={i} value={u}>{u?.toUpperCase()}</option>))} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Description</label>
                                            <TextArea name="description" value={this.state.description} change={e => this.setState({ description: e })} />
                                        </div>
                                        <div className={`col-md-${this.state.available ? '12' : '6'}`}>
                                            <label className="col-form-label">Currently Available</label>
                                            <YesNo name="available" value={this.state.available} change={e => this.setState({ available: e })} />
                                        </div>
                                        {!this.state.available && <div className="col-md-6">
                                            <label className="col-form-label">Available After</label>
                                            <DateInput name="availableAfter" value={this.state.availableAfter} change={e => this.setState({ availableAfter: e })} />
                                        </div>}
                                        <div className="col-md-6">
                                            <label className="col-form-label">Categories</label>
                                            <ArrayInput name="categories" type="search" selected={this.state.categories} items={window.productCategories || []} change={e => this.setState({ categories: e })} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Tags</label>
                                            <ArrayInput name="tags" type="search" selected={this.state.tags} items={window.app.state.products?.reduce((a, b) => {
                                                if (b.tags) b.tags.forEach(u => { if (!a.includes(u)) a.push(u) })
                                                return a
                                            }, []) || []} change={e => this.setState({ categories: e })} />
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="col-form-label">Notes</label>
                                            <NotesInput _id={this.state._id} name="notes" notes={this.state.notes} change={note => {
                                                this.handlePending(note)
                                                this.setState({
                                                    notes: [...(this.state.notes || []), note]
                                                })
                                            }} remove={_id => {
                                                this.handlePending(_id)
                                                this.setState({ notes: [...this.state.notes].filter(u => u !== _id) })
                                            }}></NotesInput>
                                        </div>
                                    </div>} />}
                                    {this.state.type === 'product' ? productInfo() : this.state.type === 'service' ? serviceInfo() : this.state.type === 'offering' ? offeringInfo() : ''}
                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    <input type="hidden" name="owner" value={this.state.owner} />
                                    <input type="hidden" name="crm" value={this.props.crm} />
                                    <input type="hidden" name="_id" value={this.state._id} />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default ProductForm
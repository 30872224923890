import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import AgreementForm from '../forms/agreement-form'
import { Agreement as AgreementDisplay, Accounting, PaymentInfo } from '../helpers/display/agreement'
import Error from './404'
import LoadingData from '../helpers/display/loadingdata'
class Agreement extends Component {
    state = {
        edit: false,
        changeImage: false,
        view: 'details',
        notes: [],
        noteData: [],
        notFound: true,
    }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.agreements.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.agreements.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        }
    }
    getPaymentInfo = _id => {
        if (this.getPI) return
        this.getPI = true
        window.app.getPaymentInfo(_id).then(pi => {
            this.getPI = false
            this.setState({ paymentInfo: pi })
        }).catch(e => {
            this.getPI = false
            window.flash(e)
        })
    }
    render() {
        window.agreement = this
        if (this.state.notFound) return <Error />
        return (<>
            {this.state.edit && <AgreementForm _id={this.state._id} add={form => {
                return new Promise((res, rej) => {
                    if (!form) return rej('Unable to submit')
                    window.app.agreementForm(form).then(agreement => {
                        this.setState({ ...agreement }, () => {
                            if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                            res(agreement)
                        })
                    }).catch(e => rej(e))
                })
            }} crm={this.state.crm || window.app.state.crm} close={() => this.setState({ edit: false })} />}
            <Helmet>
                <title>{this.state.name || 'Agreement'}</title>
                <meta name="description" content="An Octane Multimedia film" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> {this.state.name || 'Agreement'}</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/agreements">Agreements</Link></li>
                        <li className="breadcrumb-item active">{this.state.name || 'Agreement'}</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <span>Name</span>
                                <p>{this.state.name} <a onClick={() => this.setState({ edit: true })}><i className="fa fa-pencil"></i></a></p>
                            </div>
                            <div className="col-md-8">
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-md-12">
                                <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                    {window.dealStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                        let status = e.target.getAttribute('data-status')
                                        if (!status) return window.flash('Status issue')
                                        window.app.addAgreement({ fields: { _id: this.state._id, status } })
                                        this.setState({
                                            status, closed: (() => {
                                                if (window.closedStates.includes(status)) {
                                                    if (window.closedStates.includes(this.state.status)) return this.state.closed
                                                    return new Date()
                                                }
                                                return undefined
                                            })()
                                        })
                                    }}>
                                        <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                            <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                        </a>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        {AgreementDisplay({...this.state, open: true})}
                        {Accounting(this.state)}
                        {PaymentInfo(typeof this.state.paymentInfo === 'object' ? this.state.paymentInfo : {})}
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Agreement




export default async function Calculate(type, list, options) {
    if (!list) window.flash('EMPTY LIST')
    let { territories, dateRange, genres, titles, movies, buyers, total, agreed, deals, platforms, distributors } = options
    let points = []
    let checkDateRange = time => {
        let x = new Date(dateRange['a']).getTime()
        let y = new Date(dateRange['b']).getTime()
        let t = new Date(time).getTime()
        return (t && ((x && y) ? ((t > x) && (t < y)) : x ? (t > x) : y ? (t < y) : true))
    }
    switch (type) {
        case 'sales-buyer': {
            console.log('sales-buyer calculation')
            if (!genres && !titles) {
                let data = list.reduce((a, b) => {
                    if (buyers.includes(b.buyer)) {
                        if (!a[b.buyer]) a[b.buyer] = []
                        if (dateRange) {
                            if (checkDateRange(b.date)) a[b.buyer].push(b)
                        } else {
                            a[b.buyer].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        buyer: window.getCompany(keys[i]),
                        data: d
                    }
                    if (total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    }
                    if (agreed) {
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (!agreed && !total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (deals) point.deal_count = d.length
                    points.push(point)
                }
            } else {
                let data = list.reduce((a, b) => {
                    if (buyers.includes(b.buyer)) {
                        if (!a[b.buyer]) a[b.buyer] = []
                        if (dateRange) {
                            if (checkDateRange(b.date)) a[b.buyer].push({ ...b })
                        } else {
                            a[b.buyer].push({ ...b })
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    if (genres || titles) {
                        let point = { buyer: window.getCompany(keys[i]), data: d }
                        if (titles) {
                            let g = d.reduce((a, b) => {
                                if (b.title && titles.includes(b.title) && !a[b.title]) a[b.title] = []
                                if (b.title && titles.includes(b.title)) a[b.title].push(b)
                                return a
                            }, {})
                            let k = Object.keys(g)
                            for (let i = 0; i < k.length; i++) {
                                if (!titles.includes(k[i])) continue
                                let p = { ...point }
                                let t = movies.find(u => u._id == k[i])
                                if (t) {
                                    p.title = t.title
                                    if (genres) p.genre = t.genre.join('/')
                                    if (total) {
                                        p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                    }
                                    if (agreed) {
                                        p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (!agreed && !total) {
                                        p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                        p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (deals) p.deal_count = g[k[i]].length
                                    points.push(p)
                                }
                            }
                        } else {
                            let g = d.reduce((a, b) => {
                                let t = movies.find(u => u._id == b.title)
                                if (t && t.genre) {
                                    for (let i = 0; i < t.genre.length; i++) {
                                        if (genres.includes(t.genre[i])) {
                                            if (!a[t.genre[i]]) a[t.genre[i]] = []
                                            a[t.genre[i]].push(b)
                                        }
                                    }
                                }
                                return a
                            }, {})
                            let k = Object.keys(g)
                            for (let i = 0; i < k.length; i++) {
                                let p = { ...point, genre: k[i], data: g }
                                if (total) {
                                    p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                }
                                if (agreed) {
                                    p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (!agreed && !total) {
                                    p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                    p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (deals) p.deal_count = g[k[i]].length
                                points.push(p)
                            }
                        }

                    } else {
                        let point = {
                            buyer: window.getCompany(keys[i]),
                            data: d
                        }
                        if (total) {
                            point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                        }
                        if (agreed) {
                            point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                        }
                        if (!agreed && !total) {
                            point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                            point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                        }
                        if (deals) point.deal_count = d.length
                        points.push(point)
                    }
                }
            }
            break
        }
        case 'platform': {
            console.log('platform calculation')
            let data = list.reduce((a, b) => {
                if (platforms.includes(b.platform)) {
                    if (!a[b.platform]) a[b.platform] = []
                    if (dateRange) {
                        if (checkDateRange(b.date)) a[b.platform].push(b)
                    } else {
                        a[b.platform].push(b)
                    }
                }
                return a
            }, {})
            let keys = Object.keys(data)
            if (!genres && !titles) {
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        platform: window.getCompany(keys[i]),
                        income: window.sum(d.map(u => u ? (u.amount || 0) : 0)),
                        data: d
                    }
                    points.push(point)
                }
            } else {
                let genreObject = {}
                if (genres && !titles) { for (let i = 0; i < genres.length; i++) genreObject[genres[i]] = [] }
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = { platform: window.getCompany(keys[i]), data: d }
                    if (titles) {
                        let g = d.reduce((a, b) => {
                            if (titles.includes(b.title)) {
                                if (!a[b.title]) a[b.title] = []
                                a[b.title].push(b)
                            }
                            return a
                        }, {})
                        let k = Object.keys(g)
                        for (let i = 0; i < k.length; i++) {
                            let p = { ...point }
                            let t = movies.find(u => u._id == titles[i])
                            if (t) {
                                p.title = t.title
                                if (genres) p.genre = t.genre
                                p.income = window.sum(g[k[i]].map(u => u.amount))
                                points.push(p)
                            }
                        }
                    } else {
                        let g = d.reduce((a, b) => {
                            let t = movies.find((u) => u._id === b.title)
                            if (t) {
                                if (t.genre && genres.find(a => t.genre.includes(a))) {
                                    for (let i = 0; i < t.genre.length; i++) {
                                        if (!a[t.genre[i]] || !a[t.genre[i]] instanceof Array) continue
                                        a[t.genre[i]].push(b)
                                    }
                                }
                            }
                            return a
                        }, genreObject)
                        let k = Object.keys(g)
                        for (let i = 0; i < k.length; i++) {
                            points.push({ ...point, data: g, genre: k[i], income: window.sum(g[k[i]].map(u => u.amount)) })
                        }
                    }
                }
            }
            break
        }
        case 'movie': {
            console.log('movie calculation')
            let data = list.reduce((a, b) => {
                if (titles.includes(b.title)) {
                    if (!a[b.title]) a[b.title] = []
                    if (dateRange) {
                        let x = new Date(dateRange['a']).getTime()
                        let y = new Date(dateRange['b']).getTime()
                        let t = new Date(b.date).getTime()
                        if (t && ((x && y) ? ((t > x) && (t < y)) : x ? (t > x) : y ? (t < y) : true)) a[b.title].push(b)
                    } else {
                        a[b.title].push(b)
                    }
                }
                return a
            }, {})
            let keys = Object.keys(data)
            for (let i = 0; i < keys.length; i++) {
                let d = data[keys[i]]
                let title = movies.find(u => u._id == keys[i])
                let point = {
                    id: keys[i],
                    title: title.title,
                    data: d
                }
                if (genres) point.genre = title.genre
                point.income = window.sum(d.map(u => u ? (u.amount || 0) : 0))
                points.push(point)
            }
            break
        }
        case 'subdistributor': {
            console.log('subdistributor calculation')
            let data = list.reduce((a, b) => {
                if (distributors.includes(b.subdistributor)) {
                    if (!a[b.subdistributor]) a[b.subdistributor] = []
                    if (titles || genres) {
                        let t = movies.find(u => u._id == b.title)
                        if (t) {
                            if (genres) {
                                let o = false
                                if (t.genre) for (let i = 0; i < t.genre.length; i++) if (genres.includes(t.genre[i])) {
                                    o = true
                                    i = Infinity
                                }
                                if (!o) return a
                            }
                            if (titles && !titles.includes(t._id)) return a
                            if (dateRange) {
                                if (checkDateRange(b.date)) a[b.subdistributor].push({ ...b })
                            } else {
                                a[b.subdistributor].push({ ...b })
                            }
                        }
                    } else {
                        if (dateRange) {
                            if (checkDateRange(b.date)) a[b.subdistributor].push(b)
                        } else {
                            a[b.subdistributor].push(b)
                        }
                    }
                }
                return a
            }, {})
            let keys = Object.keys(data)
            if (!titles && !genres) {
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        subdistributor: keys[i],
                        income: window.sum(d.map(u => u ? (u.amount || 0) : 0)),
                        data: d
                    }
                    points.push(point)
                }
            } else {
                let genreObject = {}
                if (!titles && genres) {
                    for (let i = 0; i < genres.length; i++) genreObject[genres[i]] = []
                }
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = { subdistributor: keys[i], data: d }
                    if (titles) {
                        let g = d.reduce((a, b) => {

                        }, {})
                        for (let i = 0; i < titles.length; i++) {
                            let p = { ...point }
                            let t = movies.find(u => u._id == titles[i]) || {}
                            p.title = t.title
                            if (genres) p.genre = t.genre
                            p.income = window.sum([...d].filter(b => b.title === titles[i]).map(u => u.amount))
                            points.push(p)
                        }
                    } else {
                        let g = d.reduce((a, b) => {
                            let t = movies.find(u => u._id == b.title)
                            if (t) {
                                for (let i = 0; i < t.genre.length; i++) {
                                    if (genres.includes(t.genre[i])) a[t.genre[i]].push(b)
                                }
                            }
                            return a
                        }, genreObject)
                        let k = Object.keys(g)
                        for (let i = 0; i < k.length; i++) {
                            points.push({ ...point, data: g, genre: k[i], income: window.sum(g[k[i]].map(u => u.amount)) })
                        }
                    }
                }
                for (let i = 0; i < distributors.length; i++) {
                    if (distributors[i] && !points.find(u => u.subdistributor === distributors[i])) {
                        if (titles || genres) {
                            let point = { subdistributor: distributors[i] }
                            if (titles) {
                                for (let i = 0; i < titles.length; i++) {
                                    let p = { ...point, data: [] }
                                    let t = movies.find(u => u._id == titles[i]) || {}
                                    p.title = t.title
                                    if (genres) p.genre = t.genre
                                    p.income = 0
                                    points.push(p)
                                }
                            } else {
                                for (let i = 0; i < genres.length; i++) points.push({ ...point, genre: genres[i], income: 0 })
                            }
                        } else {
                            let point = {
                                subdistributor: distributors[i],
                                income: 0,
                                data: []
                            }
                            points.push(point)
                        }
                    }
                }
            }
            break
        }
        case 'genre': {
            console.log('genre calculation')
            if (!genres) {
                let data = list.reduce((a, b) => {
                    let t = movies.find(u => u._id == b.title)
                    if (!t.genre) return a
                    for (let i = 0; i < t.genere.length; i++) {
                        if (!a[t.genre[i]]) a[t.genre[i]] = []
                        if (dateRange) {
                            if (checkDateRange(b.date)) a[t.genre[i]].push(b)
                        } else {
                            a[t.genre[i]].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        genre: keys[i],
                        income: window.sum(d.map(u => u ? (u.amount || 0) : 0)),
                        data: d
                    }
                    points.push(point)
                }
            } else {
                let data = list.reduce((a, b) => {
                    let t = movies.find(u => u._id == b.title)
                    if (t && t.genre) {
                        let mike = false
                        for (let i = 0; i < t.genre.length; i++) if (genres.includes(t.genre[i])) {
                            mike = true
                            i = Infinity
                        }
                        if (mike) {
                            for (let i = 0; i < t.genre.length; i++) if (genres.includes(t.genre[i])) {
                                if (!a[t.genre[i]]) a[t.genre[i]] = []
                                if (dateRange) {
                                    if (checkDateRange(b.date)) a[t.genre[i]].push({ ...b, title: t._id })
                                } else {
                                    a[t.genre[i]].push({ ...b, title: t._id })
                                }
                            }
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        genre: keys[i],
                        income: window.sum(d.map(u => u ? (u.income || 0) : 0)),
                        data: d
                    }
                    points.push(point)
                }
                for (let i = 0; i < genres.length; i++) {
                    if (!points.find(u => u.genre === genres[i])) points.push({ genre: genres[i], income: 0, data: [] })
                }
            }
            break
        }
        case 'sales-genre': {
            console.log('sales-genre calculation')
            if (!genres) {
                let data = list.reduce((a, b) => {
                    let t = movies.find(u => u._id == b.title)
                    if (!t.genre) return a
                    for (let i = 0; i < t.genre.length; i++) {
                        if (!a[t.genre[i]]) a[t.genre[i]] = []
                        if (dateRange) {
                            if (checkDateRange(b.date)) a[t.genre[i]].push(b)
                        } else {
                            a[t.genre[i]].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        genre: keys[i],
                        data: d
                    }
                    if (total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    }
                    if (agreed) {
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (!agreed && !total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (deals) point.deal_count = d.length
                    points.push(point)
                }
            } else {
                let data = list.reduce((a, b) => {
                    let t = movies.find(u => u._id == b.title)
                    if (!t.genre) return a
                    for (let i = 0; i < t.genre.length; i++) {
                        if (genres.includes(t.genre[i])) {
                            if (!a[t.genre[i]]) a[t.genre[i]] = []
                            if (dateRange) {
                                if (checkDateRange(b.date)) a[t.genre[i]].push(b)
                            } else {
                                a[t.genre[i]].push(b)
                            }
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        genre: keys[i],
                        data: d
                    }
                    if (total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    }
                    if (agreed) {
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (!agreed && !total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (deals) point.deal_count = d.length
                    points.push(point)
                }
                for (let i = 0; i < genres.length; i++) {
                    if (!points.find(u => u.genre === genres[i])) {
                        let point = { genre: genres[i], data: [] }
                        if (total) {
                            point.received_total = 0
                        }
                        if (agreed) {
                            point.agreement_total = 0
                        }
                        if (!agreed && !total) {
                            point.received_total = 0
                            point.agreement_total = 0
                        }
                        if (deals) point.deal_count = 0
                        points.push(point)
                    }
                }
            }
            break
        }
        case 'sales-movie': {
            console.log('sales-movie calculation')
            let data = list.reduce((a, b) => {
                if (titles.includes(b.title)) {
                    if (!a[b.title]) a[b.title] = []
                    if (dateRange) {
                        let x = new Date(dateRange['a']).getTime()
                        let y = new Date(dateRange['b']).getTime()
                        let t = new Date(b.date).getTime()
                        if (t && ((x && y) ? ((t > x) && (t < y)) : x ? (t > x) : y ? (t < y) : true)) a[b.title].push(b)
                    } else {
                        a[b.title].push(b)
                    }
                }
                return a
            }, {})
            let keys = Object.keys(data)
            for (let i = 0; i < keys.length; i++) {
                let d = data[keys[i]]
                let title = movies.find(u => u._id == keys[i])
                let point = {
                    id: keys[i],
                    title: title.title,
                    data: d
                }
                if (genres) point.genre = title.genre.join('/')
                if (total) {
                    point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                }
                if (agreed) {
                    point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                }
                if (!agreed && !total) {
                    point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                }
                if (deals) point.deal_count = d.length
                points.push(point)
            }
            break
        }
        case 'sales-territory': {
            console.log('sales-territory calculation')
            let data = list.reduce((a, b) => {
                if (territories.includes(b.territory)) {
                    if (!a[b.territory]) a[b.territory] = []
                    if (dateRange) {
                        if (checkDateRange(b.date)) a[b.territory].push(b)
                    } else {
                        a[b.territory].push(b)
                    }
                }
                return a
            }, {})
            let keys = Object.keys(data)
            if (!genres && !titles && !buyers) {
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        territory: keys[i],
                        data: d
                    }
                    if (total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    }
                    if (agreed) {
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (!agreed && !total) {
                        point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                        point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    }
                    if (deals) point.deal_count = d.length
                    points.push(point)
                }
            } else {
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = { territory: keys[i], data: d }
                    if (titles) {
                        let g = d.reduce((a, b) => {
                            if (b.title && !a[b.title]) a[b.title] = []
                            if (b.title) a[b.title].push(b)
                            return a
                        }, {})
                        let k = Object.keys(g)
                        for (let i = 0; i < k.length; i++) {
                            let p = { ...point, title: k[i], data: g[k[i]] }
                            let t = movies.find(u => u._id == k[i])
                            if (genres) p.genre = t.genre.join('/')
                            if (buyers) {
                                let l = g[k[i]].reduce((a, b) => {
                                    if (b.buyer && buyers.includes(b.buyer)) {
                                        if (!a[b.buyer]) a[b.buyer] = []
                                        a[b.buyer].push(b)
                                    }
                                    return a
                                }, {})
                                let keys = Object.keys(l)
                                for (let num = 0; num < keys.length; num++) {
                                    let z = { ...p, data: l[keys[num]] }
                                    z.buyer = window.getCompany(keys[num])
                                    if (total) {
                                        z.received_total = window.sum(l[keys[num]].map(u => u ? (u.received_amount || 0) : 0))
                                    }
                                    if (agreed) {
                                        z.agreement_total = window.sum(l[keys[num]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (!agreed && !total) {
                                        z.received_total = window.sum(l[keys[num]].map(u => u ? (u.received_amount || 0) : 0))
                                        z.agreement_total = window.sum(l[keys[num]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (deals) z.deal_count = l[keys[num]].length
                                    points.push(z)
                                }
                            } else {
                                if (total) {
                                    p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                }
                                if (agreed) {
                                    p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (!agreed && !total) {
                                    p.received_total = window.sum(g[k[i]].map(u => u ? (u.received_amount || 0) : 0))
                                    p.agreement_total = window.sum(g[k[i]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (deals) p.deal_count = g[k[i]].length
                                points.push(p)
                            }
                        }
                    } else if (genres) {
                        let tR = d.reduce((a, b) => {
                            if (b.title && !a[b.title]) a[b.title] = []
                            if (b.title) a[b.title].push(b)
                            return a
                        }, {})
                        let k = Object.keys(tR)
                        let j = {}
                        for (let i = 0; i < k.length; i++) {
                            let t = movies.find(a => a._id == k[i])
                            if (t) {
                                for (let a = 0; a < t.genre.length; a++) {
                                    if (!genres.includes(t.genre[a])) continue
                                    if (!j[t.genre[a]]) j[t.genre[a]] = []
                                    j[t.genre[a]].push(...tR[k[i]])
                                }
                            }
                        }
                        k = Object.keys(j)
                        for (let m = 0; m < k.length; m++) {
                            let p = { ...point, genre: k[m], data: j[k[m]] }
                            if (buyers) {
                                let l = j[k[m]].reduce((a, b) => {
                                    if (b.buyer && buyers.includes(b.buyer)) {
                                        if (!a[b.buyer]) a[b.buyer] = []
                                        a[b.buyer].push(b)
                                    }
                                    return a
                                }, {})
                                let keys = Object.keys(l)
                                for (let num = 0; num < keys.length; num++) {
                                    let z = { ...p, data: l[keys[num]] }
                                    z.buyer = window.getCompany(keys[num])
                                    if (total) {
                                        z.received_total = window.sum(l[keys[num]].map(u => u ? (u.received_amount || 0) : 0))
                                    }
                                    if (agreed) {
                                        z.agreement_total = window.sum(l[keys[num]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (!agreed && !total) {
                                        z.received_total = window.sum(l[keys[num]].map(u => u ? (u.received_amount || 0) : 0))
                                        z.agreement_total = window.sum(l[keys[num]].map(u => u ? (u.agreement_amount || 0) : 0))
                                    }
                                    if (deals) z.deal_count = l[keys[num]].length
                                    points.push(z)
                                }
                            } else {
                                if (total) {
                                    p.received_total = window.sum(j[k[m]].map(u => u ? (u.received_amount || 0) : 0))
                                }
                                if (agreed) {
                                    p.agreement_total = window.sum(j[k[m]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (!agreed && !total) {
                                    p.received_total = window.sum(j[k[m]].map(u => u ? (u.received_amount || 0) : 0))
                                    p.agreement_total = window.sum(j[k[m]].map(u => u ? (u.agreement_amount || 0) : 0))
                                }
                                if (deals) p.deal_count = j[k[m]].length
                                points.push(p)
                            }
                        }
                    } else {
                        for (let i = 0; i < buyers.length; i++) {
                            let z = { ...point, data: d.filter(a => a.buyer === buyers[i]) }
                            z.buyer = window.getCompany(buyers[i])
                            if (total) {
                                z.received_total = window.sum(d.filter(u => u.buyer === buyers[i]).map(u => u ? (u.received_amount || 0) : 0))
                            }
                            if (agreed) {
                                z.agreement_total = window.sum(d.filter(u => u.buyer === buyers[i]).map(u => u ? (u.agreement_amount || 0) : 0))
                            }
                            if (!agreed && !total) {
                                z.received_total = window.sum(d.filter(u => u.buyer ===buyers[i]).map(u => u ? (u.received_amount || 0) : 0))
                                z.agreement_total = window.sum(d.filter(u => u.buyer === buyers[i]).map(u => u ? (u.agreement_amount || 0) : 0))
                            }
                            if (deals) z.deal_count = d.filter(u => u.buyer === buyers[i]).length
                            points.push(z)
                        }
                    }
                }
            }
            break
        }
        default: return points
    }
    console.log('TOTAL', points.length, points)
    return points
}
import React, { Component } from 'react'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import TabLink from '../helpers/tablink'
import PForm from '../reporting/main/paymentform'
import ArrayDisplay from '../helpers/display/arraydisplay'
import NotesDisplay from '../helpers/display/notesdisplay'
import Circles from '../../assets/images/circle.png';
import CountDown from '../helpers/timer'
import Table from '../helpers/display/table'
import Censored2 from '../helpers/display/censored2'
import EmailCompose from '../forms/email-compose';
import EmailView from '../views/email-view'
import { Agreement, Accounting, PaymentInfo } from '../helpers/display/agreement'
import NotFound from '../helpers/display/notfound'
import SidePop from '../helpers/display/sidepop'
import ST from '../helpers/domain-redirect'
class TitleView extends Component {
    state = { mode: 'details' }
    getting = false
    componentDidMount() {
        if (this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedTitles : window.app.state.titles).find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (this.state.hardDrive && !this.state._hard && !this.getting) {
                        this.getDrive(this.state.hardDrive)
                    }
                    if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
                    if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                    if (window.isAdmin(window.app.state.type)) {
                        this.getPayments(this.state._id)
                        this.getSales(this.state._id)
                    }
                    this.getEmails(this.state._id)
                })
            } else { this.setState({ notFound: true }) }
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedTitles : window.app.state.titles).find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (this.state.hardDrive && !this.state._hard && !this.getting) {
                        this.getDrive(this.state.hardDrive)
                    }
                    if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
                    if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                    if (window.isAdmin(window.app.state.type)) {
                        if (!this.state.payments) this.getPayments(this.state._id)
                        if (!this.state.sales) this.getSales(this.state._id)
                    }
                    if (!this.state._emails) this.getEmails(this.state_id)
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, mode: this.state.mode, notFound: true })
            }
        }
    }
    getEmails = _id => {
        if (this.getEmail) return false
        this.getEmail = true
        window.app.getTitleEmails(_id).then(emails => {
            this.getEmail = false
            this.setState({ _emails: emails })
        }).catch(e => {
            this.getEmail = false
            window.flash(e)
        })
    }
    getPaymentInfo = _id => {
        if (this.getPI) return
        this.getPI = true
        window.app.getPaymentInfo(_id).then(pi => {
            this.getPI = false
            this.setState({ paymentInfo: pi })
        }).catch(e => {
            this.getPI = false
            window.flash(e)
        })
    }
    getAgreement = _id => {
        if (this.gainfo) return
        this.gainfo = true
        let agreement = window.app.state.agreements?.find(u => u._id === _id)
        if (agreement) {
            this.gainfo = false
            this.setState({ agreement: agreement })
        } else {
            window.app.request('/get-agreement', 'post', { _id }).then(result => {
                this.gainfo = false
                this.setState({ agreement: result.agreement })
            }).catch(e => {
                this.gainfo = false
                window.flash(e)
            })
        }
    }
    getDrive = drive => {
        this.getting = true
        window.app.request('/hard-drive', 'post', { id: drive }).then(result => {
            this.getting = false
            this.setState({ _hard: result.hd })
        }).catch(e => {
            this.getting = false
            window.flash(e)
        })
    }
    getPayments = _id => {
        if (!this.getPay) {
            this.getPay = true
            window.app.request('/title-payments', 'post', { _id }).then(r => {
                this.getPay = false
                this.setState({ payments: r.payments })
            }).catch(e => { window.flash(e); this.getPay = false })
        }
    }
    getSales = _id => {
        if (!this.getSale) {
            this.getSale = true
            window.app.request('/title-sales', 'post', { _id }).then(r => {
                this.getSale = false
                this.setState({ sales: r.sales })
            }).catch(e => { window.flash(e); this.getSale = false })
        }
    }
    sendPayout = () => {
        if (this.sending) return
        this.sending = true
        window.app.request('/send-payout', 'post', { _id: this.state._id }).then(result => {
            this.sending = false
            this.setState({ lastPayout: result.lastPayout }, () => {
                window.flash('Payout sent')
            })
        }).catch(e => {
            this.sending = false
            window.flash(e)
        })
    }
    render() {
        window.title = this
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        let sales = this.state.sales || []
        let expenses = this.state.payments?.filter(u => u.income < 0) || []
        let income = this.state.payments?.filter(u => u.income >= 0) || []
        let activity = () => {
            let categories = ['Notes', 'Emails', 'Drafts'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Notes': {
                        items = this.state.notes || []
                        break
                    }
                    case 'Emails': {
                        items = this.state._emails || []
                        columns = [
                            {
                                title: "Date",
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                            },
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ currentEmail: record._id }) }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                        ]
                        break
                    }
                    case 'Drafts': {
                        items = window.app.state.draftEmails?.filter(u => (u.associate === this.state._id)) || []
                        columns = [
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({ currentDraft: record._id, emailMessage: record.message, emailSubject: record.subject, emailCc: record.cc, emailBcc: record.bcc, emailAttachments: record.attachments, emailSendAt: record.sendAt, emailFrom: record.from, emailAppendContent: record.appendContent, emailTo: record.to, emailThread: record.thread })
                                    }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                window.app.request('/delete-draft-email', 'post', { _id: record._id }).then(d => {
                                                    if (d?.r?.nModified) {
                                                        window.app.setState({ draftEmails: [...window.app.state.draftEmails].filter(a => a._id !== record._id) })
                                                    } else {
                                                        window.flash('Failed to delete draft')
                                                    }
                                                }).catch(e => {
                                                    window.flash(e)
                                                })
                                            }}>Delete Draft</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table columns={columns} data={items} mainKey={record => record._id} />} />
            return <div className="tab-pane show active" id="task-activity">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (u.name === 'Notes' ? <Accordian header={'Notes'} count={u.items?.length || 0} content={<NotesDisplay type="table" notes={u.items} />} /> : accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let related = () => {
            let categories = ['Companies', 'Users', 'Deals'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Companies': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/company?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a._id === this.state.company ? 'Company' : a.titles?.find(a => a === this.state._id) ? 'Title' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            }
                        ]
                        items = window.app.state.companies?.filter(u => {
                            if (u._id === this.state.company || u.titles?.find(a => a === this.state._id)) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Users': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/profile?_id=${record._id}`} text={record.name || (record.firstName ? `${record.firstName} ${record.lastName}` : '')} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.email, b.email)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.titles?.find(b => b === this.state._id) ? 'Title' : this.state.createdBy === a._id ? 'Created By' : a._id === this.state.contact ? 'Contact' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            }
                        ]
                        items = window.app.state.users?.filter(u => {
                            if (u.titles?.find(a => a === this.state._id) || this.state.createdBy === u._id || this.state.contact === u._id) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Deals': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/deal?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.titles?.includes(this.state._id) ? 'Title' : a.titleAllocations?.find(a => a.title === this.state._id) ? 'Title Allocations' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            }
                        ]
                        items = window.app.state.deals?.filter(u => {
                            if (u.titles?.includes(this.state._id) || u.titleAllocations?.find(a => a.title === this.state._id)) return true
                            return false
                        }) || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table data={items} columns={columns} mainKey={record => record._id} />} />
            return <div className="tab-pane task-related show active" id="task-related">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let agreement = Agreement
        let accounting = Accounting
        let paymentInfo = PaymentInfo
        let email = this.state._emails?.find(a => a._id === this.state.currentEmail)
        let totalIncome = this.state._id ? window.sum(this.state.payments?.map(u => u.amount >= 0 ? u.income : u.amount)) : 0
        if (this.state.currentDraft) return (<div><EmailCompose to={this.state.emailTo} from={this.state.emailFrom} attachments={this.state.emailAttachments} bcc={this.state.emailBcc} cc={this.state.emailCc} subject={this.state.emailSubject} message={this.state.emailMessage} appendContent={this.state.emailAppendContent} _id={this.state.currentDraft} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ currentDraft: '', emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>)
        if (this.state.compose) return (<div><EmailCompose to={this.state.emailTo || email?.to} from={this.state.emailFrom || email?.from} attachments={this.state.emailAttachments || email?.attachments} bcc={this.state.emailBcc || email?.bcc} cc={this.state.emailCc || email?.cc} subject={this.state.emailSubject || email?.subject} message={this.state.emailMessage || email?.message} appendContent={this.state.emailAppendContent} _id={this.state.currentEmail} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ compose: false, emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>)
        if (this.state.currentEmail) return <EmailView email={email} set={k => {
            this.setState({ _emails: this.state._emails.map(a => a._id === this.state.currentEmail ? { ...a, title: k } : a) })
        }} close={() => this.setState({ currentEmail: '' })} _id={this.state.currentEmail} />
        return (<>
            <div onClick={this.props.close} className="modal right fade show" style={{ display: 'block' }} id="system-title" tabIndex={-1} role="dialog" aria-modal="true">
                <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                    <button type="button" className="close md-close" onClick={this.props.close} aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close xs-close" onClick={this.props.close} >×</button>
                            <div className="row w-100">
                                <div className="col-md-7 account">
                                    <div className="avatar"><img src={this.state.keyArt} alt="" className="avatar" /></div>
                                    <span className="modal-title users">{this.state.title} <a onClick={this.props.edit}><i style={{ margin: '3px' }} className="fa fa-pencil"></i></a> <Link to={`/title?_id=${this.state._id}`}><i style={{ margin: '3px', color: 'var(--d)' }} className="fa fa-expand"></i></Link></span>&nbsp;
                                </div>
                                <div className="col-md-5 text-right">
                                    <ul className="list-unstyled list-style-none">
                                        <li className="dropdown list-inline-item"><br />
                                            <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Actions </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">Do Something</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow p-2 due-dates">
                            <div className="row m-0">
                                <div className="col-md-2">
                                    <span>Title</span>
                                    <p>{this.state.title}</p>
                                </div>
                                <div className="col-md-2">
                                    {window.isAdmin(window.app.state.type) && <><span>Total Income</span>
                                        <p>{totalIncome < 0 && '-'}${window.currency(Math.abs(totalIncome))}</p></>}
                                </div>
                                <div className={`col-md-${window.isAdmin(window.app.state.type) && (this.state.producerPayments?.length > 0 && (!this.state.lastPayout || this.state.producerPayments.find(a => new Date(a.date).getTime() - new Date(this.state.lastPayout).getTime() > 0))) ? '2' : '4'}`}>
                                    <span>Contract Expires In</span>
                                    <p>{this.state.contractEndDate && <CountDown expires={new Date(this.state.contractEndDate)} />}</p>
                                </div>
                                {window.isAdmin(window.app.state.type) && (this.state.producerPayments?.length > 0 && (!this.state.lastPayout || this.state.producerPayments.find(a => new Date(a.date).getTime() - new Date(this.state.lastPayout).getTime() > 0))) && <div className="col-md-2">
                                    <button onClick={() => {
                                        this.sendPayout()
                                    }}>Send Payment Update</button>
                                </div>}
                                <div className="col-md-2">
                                    <div className="b1">
                                        {window.isAdmin(window.app.state.type) && <><ST domain={'app.octanemultimedia.com'} path={`/title?_id=${this.state._id}`}><span>View On Client Portal</span></ST></>}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="b1">
                                        {window.isAdmin(window.app.state.type) && <><ST domain={'portal.deliveryminds.com'} path={`/title?_id=${this.state._id}`}><span>View On DM Portal</span></ST></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ margin: '10px 0' }}>
                            <div className="col-md-12">
                                <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                    {window.titleStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                        let status = e.target.getAttribute('data-status')
                                        if (!status) return window.flash('Status issue')
                                        window.app.addTitle({ fields: { _id: this.state._id, status } })
                                        this.setState({ status })
                                    }}>
                                        <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                            <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                        </a>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="task-infos">
                                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                    <li className="nav-item">
                                        <a className={this.state.mode === 'details' ? "nav-link active" : 'nav-link'} href="#task-details" onClick={e => {
                                            e.preventDefault();
                                            this.setState({ mode: 'details' })
                                        }}>Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={this.state.mode === 'related' ? "nav-link active" : 'nav-link'} href="#task-related" onClick={e => {
                                            e.preventDefault();
                                            this.setState({ mode: 'related' })
                                        }}>Related</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={this.state.mode === 'activity' ? "nav-link active" : 'nav-link'} href="#task-activity" onClick={e => {
                                            e.preventDefault();
                                            this.setState({ mode: 'activity' })
                                        }}>Activity</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {this.state.mode === 'details' ? <div className="tab-pane show active" id="task-details">
                                        {this.state.view ? <PForm close={() => this.setState({ view: '' })} _id={this.state._payment} type={this.state.view} title={this.state._id} /> : <>
                                            <Accordian open={true} header="Primary Details" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Title</td>
                                                            <td className="border-0">{this.state.title}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title AKA</td>
                                                            <td className="border-0">{this.state.titleAKA}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title Status</td>
                                                            <td className="border-0">{this.state.status}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Contact</td>
                                                            <td className="border-0">{this.state.contact && <TabLink to={`/profile?_id=${this.state.contact}`} text={window.app.state.users?.find(a => a._id === this.state.contact)?.name || this.state.contact} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Company</td>
                                                            <td className="border-0">{this.state.company && <TabLink to={`/company?_id=${this.state.company}`} text={window.app.state.companies?.find(a => a._id === this.state.company)?.name || this.state.company} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Start Date</td>
                                                            <td className="border-0">{this.state.contractStartDate && window.formatDate(this.state.contractStartDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">End Date</td>
                                                            <td className="border-0">{this.state.contractEndDate && window.formatDate(this.state.contractEndDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title Category</td>
                                                            <td className="border-0">{this.state.titleCategory}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="border-0">Assignment Rights</td>
                                                            <td className="border-0">{window.bool(this.state.assignmentRights)}</td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <td className="border-0">Acquisitions Agreement</td>
                                                            <td className="border-0">{this.state.acquisitionsAgreement && <TabLink to={this.state.acquisitionsAgreement} text="VIEW ACQUISITIONS AGREEMENT" />}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="border-0">Primary Genre</td>
                                                            <td className="border-0">{this.state.primaryGenre}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Other Genres</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.otherGenres} /></td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="border-0">Primary Genre</td>
                                                            <td className="border-0">{this.state.genre && this.state.genre[0]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Other Genres</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.genre?.filter((u, i) => i !== 0)} /></td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="border-0">Suggested Rating</td>
                                                            <td className="border-0">{this.state.rating}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Runtime with Credits</td>
                                                            <td className="border-0">{this.state.runTimeWithCredits}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Runtime without Credits</td>
                                                            <td className="border-0">{this.state.runTimeWithoutCredits}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Primary Language</td>
                                                            <td className="border-0">{this.state.language}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Languages</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.additionalLanguages} /></td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="border-0">Reserved Rights</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.reservedRights} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Reserved Territories</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.reservedTerritories} /></td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="border-0">Country of Origin</td>
                                                            <td className="border-0">{this.state.countryOrigin}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Estimated Budget</td>
                                                            <td className="border-0">{this.state.estimatedBudget}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">IMDBPro Page</td>
                                                            <td className="border-0">{this.state.imdb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">IMDB Production Year</td>
                                                            <td className="border-0">{this.state.productionYear}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title Change Control</td>
                                                            <td className="border-0">{window.bool(this.state.titleChangeControl)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Right of Refusal Control</td>
                                                            <td className="border-0">{window.capitalize(this.state.rightOfRefusalControl)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Technical Information" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Date Master / Hard Drive Received</td>
                                                            <td className="border-0">{this.state._hard?.dateReceived && window.formatDate(this.state._hard.dateReceived)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Frames Per Second (FPS)</td>
                                                            <td className="border-0">{this.state.fps}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">VR Ready</td>
                                                            <td className="border-0">{window.bool(this.state.VRReady)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Resolution</td>
                                                            <td className="border-0">{this.state.resolution}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Aspect Ratio</td>
                                                            <td className="border-0">{this.state.aspectRatio}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Audio Configuration</td>
                                                            <td className="border-0">{this.state.audio}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Technical Notes</td>
                                                            <td className="border-0"><NotesDisplay notes={this.state.technicalNotes} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            {this.state._hard && <Accordian header="Hard Drive" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">_id</td>
                                                            <td className="border-0">{this.state._hard._id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Received</td>
                                                            <td className="border-0">{window.formatDate(this.state._hard.dateReceived)} by {this.state._hard.receiverEmail}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Checked Out</td>
                                                            <td className="border-0">{window.bool(this.state._hard.checkedOut)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Check List</td>
                                                            <td className="border-0">
                                                                <ul>
                                                                    {Object.entries(this.state._hard.checkList).map((u, i) => (<li key={i}>
                                                                        <span>{window.parseKey(u[0])}: {window.parseValue(u[1])}</span>
                                                                    </li>))}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Notes</td>
                                                            <td className="border-0"><NotesDisplay notes={this.state._hard.notes} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />}
                                            {this.state.assetList && <Accordian header="Digital Assets" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        {Object.entries(this.state.assetList).map(([k, v], i) => (<tr key={i}>
                                                            <td  className="border-0"></td>
                                                            <td className="border-0"><ST domain={'portal.deliveryminds.com'} path={`/digital-download?_id=${v}`}>{window.capitalize(k)}</ST></td>
                                                        </tr>))}
                                                    </tbody>
                                                </table>
                                            </>} />}

                                            <Accordian header="Approved Assets" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Is all media in the film cleared</td>
                                                            <td className="border-0">{this.state.mediaCleared}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Copyright Line</td>
                                                            <td className="border-0">{this.state.copyrightLine}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Using Producer's Key Art</td>
                                                            <td className="border-0">{window.bool(this.state.usingProducersKeyArt)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Using Producer's Trailer</td>
                                                            <td className="border-0">{window.bool(this.state.usingProducersTrailer)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Key Art</td>
                                                            <td className="border-0">{this.state.keyArt && <TabLink to={this.state.keyArt} text="VIEW KEY ART" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Trailer</td>
                                                            <td className="border-0">{this.state.trailer && <TabLink to={this.state.trailer} text="VIEW TRAILER" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Youtube Trailer</td>
                                                            <td className="border-0">{this.state.youtubeTrailer && <TabLink to={this.state.youtubeTrailer} text="VIEW TRAILER" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Tagline</td>
                                                            <td className="border-0">{this.state.tagline}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Screener</td>
                                                            <td className="border-0">{this.state.screener && <TabLink to={this.state.screener} text="VIEW SCREENER" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Screener Password</td>
                                                            <td className="border-0">{this.state.screenerPassword && <Censored2 value={this.state.screenerPassword} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Short Synopsis</td>
                                                            <td className="border-0">{this.state.synopsisShort}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Medium Synopsis</td>
                                                            <td className="border-0">{this.state.synopsisMedium}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Long Synopsis</td>
                                                            <td className="border-0">{this.state.synopsisLong}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Comparable Titles</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.comparableTitles} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Film Stills</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.stills} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Tags/Keywords</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.keywords} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Selling Points</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.sellingPoints} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Cast/Crew" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Billing Block</td>
                                                            <td className="border-0">{this.state.billingBlock && <TabLink to={this.state.billingBlock} text="VIEW BILLING BLOCK" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Credit Obligation Statement</td>
                                                            <td className="border-0">{this.state.creditObligationStatement && <TabLink to={this.state.creditObligationStatement} text="VIEW CREDIT OBLIGATION STATEMENT" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producers</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.producers} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Director</td>
                                                            <td className="border-0">{this.state.director}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Writers</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.writers}></ArrayDisplay></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Notable Cast</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.cast} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Notable Crew</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.crew} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Marketing" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Cinando</td>
                                                            <td className="border-0">{this.state.cinando && window.isLink(this.state.cinando) ? <TabLink to={this.state.cinando} text={'VIEW CINANDO'} /> : this.state.cinando}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Instagram</td>
                                                            <td className="border-0">{this.state.instagram && window.isLink(this.state.instagram) ? <TabLink to={this.state.instagram} text={'View Instagram'} /> : this.state.instagram}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Facebook</td>
                                                            <td className="border-0">{this.state.facebook && window.isLink(this.state.facebook) ? <TabLink to={this.state.facebook} text={'view Facebook'} /> : this.state.facebook}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">IMDB</td>
                                                            <td className="border-0">{this.state.imdb && window.isLink(this.state.imdb) ? <TabLink to={this.state.imdb} text={'View Imdb'} /> : this.state.imdb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Vuulr</td>
                                                            <td className="border-0">{this.state.vuulr && window.isLink(this.state.vuulr) ? <TabLink to={this.state.vuulr} text={'View Vuulr'} /> : this.state.vuulr}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Film Catalogue</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.filmCatalogue} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer's Key Art</td>
                                                            <td className="border-0">{this.state.producerKeyArt && <TabLink to={this.state.producerKeyArt} text="VIEW KEY ART" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Foreign Key Art</td>
                                                            <td className="border-0">{this.state.foreignKeyArt && <TabLink to={this.state.foreignKeyArt} text="VIEW FOREIGN KEY ART" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">DVD Box Art</td>
                                                            <td className="border-0">{this.state.DVDBoxArt && <TabLink to={this.state.DVDBoxArt} text="VIEW DVD BOX ART" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Published Reviews</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.reviews} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Festivals</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.festivals} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Festival Wins</td>
                                                            <td className="border-0">{this.state.festivalWins}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Festival Laurels</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.festivalLaurels} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Onboarding" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Delivery Contact Name</td>
                                                            <td className="border-0">{this.state.deliveryContactName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Assets Folder</td>
                                                            <td className="border-0">{this.state.producerAssetsFolder}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Certificate of Origin</td>
                                                            <td className="border-0">{this.state.certificateOfOrigin && <TabLink to={this.state.certificateOfOrigin} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title Search Report</td>
                                                            <td className="border-0">{this.state.titleSearchReport && <TabLink to={this.state.titleSearchReport} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Onboarding Start Date</td>
                                                            <td className="border-0">{this.state.onboardingStartDate && window.formatDate(this.state.onboardingStartDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 1 Due Date</td>
                                                            <td className="border-0">{this.state.stage1DueDate && window.formatDate(this.state.stage1DueDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 1 Status</td>
                                                            <td className="border-0">{this.state.stage1 == 'false' ? 'pending start' : this.state.stage1}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 2 Due Date</td>
                                                            <td className="border-0">{this.state.stage2DueDate && window.formatDate(this.state.stage2DueDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 2 Status</td>
                                                            <td className="border-0">{this.state.stage2 == 'false' ? 'pending start' : this.state.stage2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 3 Due Date</td>
                                                            <td className="border-0">{this.state.stage3DueDate && window.formatDate(this.state.stage3DueDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Stage 3 Status</td>
                                                            <td className="border-0">{this.state.stage3 == 'false' ? 'pending start' : this.state.stage3}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Title ID</td>
                                                            <td className="border-0">{this.state.titleID}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Client Credentials</td>
                                                            <td className="border-0">{this.state.credentials && <ArrayDisplay items={this.state.credentials} />}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Sales Info" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">US Release Date</td>
                                                            <td className="border-0">{this.state.USReleaseDate && window.formatDate(this.state.USReleaseDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">International Release Dates</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.internationalReleaseDates} template={(u, i) => (<li key={i}><strong>{u.country}</strong>: {window.formatDate(u.date)}</li>)} /></td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="border-0">Strategy Packet</td>
                                                            <td className="border-0">{this.state.salesStrategyPacket && <TabLink to={this.state.salesStrategyPacket} text="VIEW STRATEGY PACKET" />}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="border-0">Revenue Estimates</td>
                                                            <td className="border-0">{this.state.revenueEstimates}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            <Accordian header="Acquisitions Details" content={<>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">Stategy Packet</td>
                                                            <td className="border-0">{this.state.acquisitionsStrategyPacket && <TabLink to={this.state.acquisitionsStrategyPacket} text="VIEW STATEGY PACKET" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Costing Analysis</td>
                                                            <td className="border-0">{this.state.costingAnalysis && <TabLink to={this.state.costingAnalysis} text="VIEW COSTING ANALYSIS" />}</td>
                                                        </tr>
                                                        {this.state.company && window.app.state.companies?.find(a => a._id === this.state.company)?.website && <tr>
                                                            <td className="border-0">Production Company Website</td>
                                                            <td className="border-0">{this.state.company && window.app.state.companies?.find(a => a._id === this.state.company)?.website && <TabLink to={window.app.state.companies?.find(a => a._id === this.state.company).website} text="VISIT WEBSITE" />}</td>
                                                        </tr>}
                                                        <tr>
                                                            <td className="border-0">Client Information Form</td>
                                                            <td className="border-0">{this.state.clientInformationForm}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Client Metadata Form</td>
                                                            <td className="border-0">{this.state.clientMetadataForm && <TabLink to={this.state.clientMetadataForm} text="VIEW METADATA" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Dropbox Folder</td>
                                                            <td className="border-0">{this.state.dropboxFolder}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="border-0">Format</td>
                                                            <td className="border-0">{this.state.resolution}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="border-0">Producer Trailer</td>
                                                            <td className="border-0">{this.state.producerTrailer && <TabLink to={this.state.producerTrailer} text="Producer Trailer" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Trailer Password</td>
                                                            <td className="border-0">{this.state.producerTrailerPassword && <Censored2 value={this.state.producerTrailerPassword} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Screener</td>
                                                            <td className="border-0">{this.state.producerScreener && <TabLink to={this.state.producerScreener} text="Producer Screener" />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Screener Password</td>
                                                            <td className="border-0">{this.state.producerScreenerPassword && <Censored2 value={this.state.producerScreenerPassword} />}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Synopsis Short</td>
                                                            <td className="border-0">{this.state.producerSynopsisShort}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Synopsis Medium</td>
                                                            <td className="border-0">{this.state.producerSynopsisMedium}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Synopsis Long</td>
                                                            <td className="border-0">{this.state.producerSynopsisLong}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Cast List</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.producerCastList} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Contra Piracy Submitted</td>
                                                            <td className="border-0">{window.bool(this.state.contraPiracySubmitted)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Producer Comp Titles</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.producerComparableTitles} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Description</td>
                                                            <td className="border-0">{this.state.description}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Music Releases</td>
                                                            <td className="border-0"><ArrayDisplay items={this.state.musicReleases} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0">Chain of Title Notes</td>
                                                            <td className="border-0"><NotesDisplay notes={this.state.chainOfTitleNotes}></NotesDisplay></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>} />
                                            {typeof this.state.agreement === 'object' && agreement(this.state.agreement)}
                                            {typeof this.state.agreement === 'object' && accounting(this.state.agreement)}
                                            {typeof this.state.paymentInfo === 'object' && paymentInfo(this.state.paymentInfo)}
                                            {window.isAdmin(window.app.state.type) && <>

                                                <Accordian header={`Sales (${sales?.length || 0})`} content={<>
                                                    {this.state._id && <Table columns={[
                                                        {
                                                            title: `Date Closed`,
                                                            dataIndex: "dateClosed",
                                                            render: (text, record) => (
                                                                <>{record.dateClosed && window.formatDate(record.dateClosed)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.dateClosed).getTime() - new Date(b.dateClosed).getTime(),
                                                        }, {
                                                            title: `Date Received`,
                                                            dataIndex: "date",
                                                            render: (text, record) => (
                                                                <>{record.date && window.formatDate(record.date)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                                        },
                                                        {
                                                            title: `Buyer`,
                                                            dataIndex: "buyer",
                                                            render: (text, record) => (
                                                                <><TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink></>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(window.getCompany(a.buyer), window.getCompany(b.buyer)),
                                                        },
                                                        {
                                                            title: `Territory`,
                                                            dataIndex: "territory",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.territory, b.territory),
                                                        },
                                                        {
                                                            title: `Received Amount`,
                                                            dataIndex: "received_amount",
                                                            render: (text, record) => (
                                                                <>${window.currency(record.received_amount)}</>
                                                            ),
                                                            sorter: (a, b) => a.received_amount || 0 - b.received_amount || 0,
                                                        },
                                                        {
                                                            title: `Agreement Amount`,
                                                            dataIndex: "agreement_amount",
                                                            render: (text, record) => (
                                                                <>${window.currency(record.agreement_amount)}</>
                                                            ),
                                                            sorter: (a, b) => a.agreement_amount || 0 - b.agreement_amount || 0,
                                                        },
                                                    ]} data={sales} mainKey={'_id'} onRow={(record) => {
                                                        return {
                                                            onClick: () => this.setState({ view: 'sale', _payment: record._id }),
                                                        }
                                                    }} rowClassName={'pointer'} />}
                                                    <button onClick={() => this.setState({ view: 'sale', _payment: null })}>Enter a Sale</button>
                                                </>} />
                                                <Accordian header={`Income (${income?.length || 0})`} content={<>
                                                    {this.state._id && <Table columns={[
                                                        {
                                                            title: `Date Received`,
                                                            dataIndex: "dateReceived",
                                                            render: (text, record) => (
                                                                <>{record.dateReceived && window.formatDate(record.dateReceived)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.dateReceived).getTime() - new Date(b.dateReceived).getTime(),
                                                        },
                                                        {
                                                            title: `Date Entered`,
                                                            dataIndex: "date",
                                                            render: (text, record) => (
                                                                <>{window.formatDate(record.date)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                                        },
                                                        {
                                                            title: `Platform`,
                                                            dataIndex: "platform",
                                                            render: (text, record) => (
                                                                <><TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink></>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)),
                                                        },
                                                        {
                                                            title: `Amount`,
                                                            dataIndex: "income",
                                                            render: (text, record) => (
                                                                <>${window.currency(record.income)}</>
                                                            ),
                                                            sorter: (a, b) => a.income || 0 - b.income || 0,
                                                        }
                                                    ]} data={income} mainKey={'_id'} onRow={(record) => {
                                                        return {
                                                            onClick: () => this.setState({ view: 'payment', _payment: record._id }),
                                                        }
                                                    }} rowClassName={'pointer'} />}
                                                    <button onClick={() => this.setState({ view: 'payment', _payment: null })}>Enter a Payment</button>
                                                </>} />
                                                <Accordian header={`Expenses (${expenses?.length || 0})`} content={<>
                                                    {this.state._id && <Table columns={[
                                                        {
                                                            title: `Date Entered`,
                                                            dataIndex: "date",
                                                            render: (text, record) => (
                                                                <>{window.formatDate(record.date)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                                        },
                                                        {
                                                            title: `Category`,
                                                            dataIndex: "category",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.category, b.category),
                                                        },
                                                        {
                                                            title: `WO No.`,
                                                            dataIndex: "workorder",
                                                            render: (text, record) => (
                                                                <>{record.workorder && <TabLink to={`/work-order?_id=${record.workorder}`}>{window.app.state.workOrders?.find(a => a._id === record.workorder)?.name || record.workorder}</TabLink>}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(b.workorder, a.workorder),
                                                        },
                                                        {
                                                            title: `Invoice No.`,
                                                            dataIndex: "invoiceNumber",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(b.invoiceNumber, a.invoiceNumber),
                                                        },
                                                        {
                                                            title: `Platform`,
                                                            dataIndex: "platform",
                                                            render: (text, record) => (
                                                                <><TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink></>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)),
                                                        },
                                                        {
                                                            title: `Amount`,
                                                            dataIndex: "income",
                                                            render: (text, record) => (
                                                                <>-${window.currency(record.income * -1)}</>
                                                            ),
                                                            sorter: (a, b) => a.income || 0 - b.income || 0,
                                                        }
                                                    ]} onRow={(record) => {
                                                        return {
                                                            onClick: () => this.setState({ view: 'expense', _payment: record._id })
                                                        }
                                                    }} rowClassName={'pointer'} data={expenses} mainKey={'_id'} />}
                                                    <button onClick={() => this.setState({ view: 'expense', _payment: null })}>Enter an Expense</button>
                                                </>} />
                                                <Accordian header={`Payouts (${this.state.producerPayments?.length || 0})`} content={<>
                                                    <span><strong>Last Payout:</strong> {window.formatDate(this.state.lastPayout)}</span><br></br>
                                                    <span><strong>Next Payout:</strong> {window.formatDate(this.state.nextPayout)}</span>
                                                    {this.state._id && <Table columns={[
                                                        {
                                                            title: `Date`,
                                                            dataIndex: "date",
                                                            render: (text, record) => (
                                                                <>{window.formatDate(record.date)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                                        },
                                                        {
                                                            title: `Amount`,
                                                            dataIndex: "amount",
                                                            render: (text, record) => (
                                                                <>${window.currency(record.amount)}</>
                                                            ),
                                                            sorter: (a, b) => a.amount || 0 - b.amount || 0,
                                                        },
                                                        {
                                                            title: `Description`,
                                                            dataIndex: "description",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.description, b.description),
                                                        },

                                                    ]} data={this.state.producerPayments || []} mainKey={'_id'} />}
                                                </>} />
                                            </>}
                                        </>}
                                    </div> : this.state.mode === 'related' ? related() : activity()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default TitleView
import React, { Component } from 'react'
import NumberInput from './numberinput'
import DateInput from './dateinput2'
class ProducerPayments extends Component {
    state = { newline: '', select: '', amount: 0 }
    componentDidMount() {
        if (this.props.selected?.length > 0) this.priorWatch = true
        this.setState({ selected: this.props.selected || [], delimiter: this.props.type === 'text' ? window.textdelim : ',' })
    }
    componentDidUpdate() {
        if (this.props.selected?.length > 0 && this.state.selected?.length < 1 && !this.priorWatch) { this.priorWatch = true; this.setState({ selected: this.props.selected || [], delimiter: this.props.type === 'text' ? window.textdelim : ',' }) }
    }
    render() {
        let { name } = this.props
        let { selected, newline } = this.state
        let change = () => {
            if (this.state.amount <= 0) return window.flash('Please enter a positive amount')
            if (this.props.total) {
                let current = this.state.selected.reduce((a, b) => a + b.amount, 0)
                if (current + this.state.amount > this.props.total) return window.flash('The total payout is to great')
            }
            this.setState({ selected: [...this.state.selected, { date: this.state.select || new Date(), amount: this.state.amount, description: this.state.newline }], newline: '', select: '', amount: 0 }, () => {
                if (typeof this.props.change === 'function') this.props.change(this.state.selected)
            })
        }
        return (<>
            <input type="text" value={selected && selected instanceof Array && JSON.stringify(selected) || 'null-input'} onChange={e => { }} className="hidden" name={name} />
            {selected && selected?.length > 0 && <ul>
                {selected?.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : `${window.formatDate(u.date)} - $${window.currency(u.amount)}${u.description ? ` - ${u.description}` : ''}`}<i className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    this.setState({ selected: [...this.state.selected].filter(a => !(a.date === u.date && a.description === u.description && a.amount === u.amount)) })
                }}></i></span></li>))}
            </ul>}
            <br></br><span>Payment Date:</span>
            <DateInput placeholder="Payment Date" value={this.state.select} change={e => this.setState({ select: e })} />
            <span>Payment Description:</span>
            <input className="form-control" type="text" value={newline} onChange={e => this.setState({ newline: e.target.value })} />
            <NumberInput value={this.state.amount} min={0} prepend={"Amount: $"} append={<button className="btn btn-primary" style={{ margin: 0, padding: '4px', cursor: 'pointer' }} onClick={e => { e.preventDefault(); change(); }}>Add</button>} change={v => this.setState({ amount: v })} />
        </>)
    }
}
export default ProducerPayments
window.namefilter = name => {
    if (!name) return name
    if (name === 'THE BASEMENT (Laszlo)') name = 'basement, the'
    if (name === 'BASEMENT, THE (Keith)') name = 'basement, the'
    if (name === 'The Basement') name = 'basement, the'
    if (name === "THE BASEMENT (Laszlo)") name = 'basement, the'
    if (name === 'American Has Fallen') name = 'America Has Fallen'
    if (name === 'Ouija Séance') name = 'OUIJA SEANCE: THE FINAL GAME'
    if (name === 'OUIJA SÉANCE') name = 'OUIJA SEANCE: THE FINAL GAME'
    if (name === 'Oujia Craft') name = 'OUIJA CRAFT'
    if (name === 'OUJIA CRAFT') name = 'OUIJA CRAFT'
    if (name === 'House of Violent Desire') name = 'HOUSE OF VIOLENT DESIRE, THE'
    if (name === 'The House of Violent Desire') name = 'HOUSE OF VIOLENT DESIRE, THE'
    if (name === 'Assassin\'s Target') name = `ASSASSIN'S TARGET (fka: IMPOSSIBLE MISSION)`
    if (name === 'Curse of the Witches Doll') name = `CURSE OF THE WITCH'S DOLL`
    if (name === 'Dwarves of Dragon') name = `Dwarves of Dragon Mountain`
    if (name === "DWARVES OF DRAGON MOUNTAIN") name = `Dwarves of Dragon Mountain`
    if (name === 'The Dwarves of Dragon Mountain') name = `Dwarves of Dragon Mountain`
    if (name === 'Crisis Hotline') name = `CRISIS HOTLINE (fka: SHADOWS IN MIND)`
    if (name === 'Impossible Mission') name = `ASSASSIN'S TARGET (fka: IMPOSSIBLE MISSION)`
    if (name === 'The Vibe') name = `ASSASSIN'S TARGET (fka: IMPOSSIBLE MISSION)`
    if (name === 'The Answer') name = `ANSWER, THE`
    if (name === 'THE ANSWER') name = `ANSWER, THE`
    if (name === `High Octane:Devil's Acid`) name = `Devil\'s Acid`
    if (name === `Cannibale Farm`) name = `CANNIBAL FARM`
    if (name === `Drowing Echo`) name = `DROWNING ECHO (fka:NEREUS)`
    if (name === `Drowning Echo`) name = `DROWNING ECHO (fka:NEREUS)`
    if (name === 'Robot Awakening') name = 'ROBOT AWAKENING (fka:OMG I\'M A ROBOT)'
    if (name === 'The Assassin\'s Target (The Vibe)') name = 'ASSASSIN\'S TARGET (fka: IMPOSSIBLE MISSION)'
    if (name === 'A Wakefield Project') name = 'WAKEFIELD PROJECT'
    if (name === 'A WAKEFIELD PROJECT') name = 'WAKEFIELD PROJECT'
    if (name === 'A Little Water') name = 'LITTLE WATER, A'
    if (name === 'The Cabin') name = 'cabin, the'
    if (name === '2025: BLOOD, WHITE, AND BLUE') name = '2050'
    if (name === 'The Last Conception') name = 'LAST CONCEPTION, THE'
    if (name === 'The Haunting of Grady Farm') name = 'HAUNTING OF GRADY FARM, THE'
    if (name === `Baltic Tribes (Last Pagans of Europe)`) name = `BALTIC TRIBES`
    if (name === `American Hunt (The Hunt)`) name = `AMERICAN HUNT`
    if (name === `Bobot (Solar Flair)`) name = `BOBOT`
    if (name === `Red Handed (Children of Moloch)`) name = `red handed`
    if (name === `CRISIS HOTLINE`) name = `CRISIS HOTLINE (fka: SHADOWS IN MIND)`
    if (name === `Assassin's Target (The Vibe)`) name = `ASSASSIN'S TARGET (fka: IMPOSSIBLE MISSION)`
    if (name === `Fantasma (Bloody Ballet)`) name = `BLOODY BALLET`
    if (name === `Assassin's Code`) name = `ASSASSIN'S CODE, THE`
    if (name === `Labyrinthia (Deadman Apocalypse)`) name = `LABYRINTHIA`
    if (name === `The Creature Below`) name = `CREATURE BELOW, THE`
    if (name === `Delirium`) name = `DELIRUIM`
    if (name === `DELIRIUM`) name = `DELIRUIM`
    if (name === `A Haunting In Cawdor`) name = `HAUNTING IN CAWDOR`
    if (name === `Army of Frankensteins`) name = `ARMY OF FRANKESTEIN`
    if (name === `ARMY OF FRANKENSTEIN`) name = `ARMY OF FRANKESTEIN`
    if (name === `The Door`) name = `DOOR, THE`
    if (name === `Escape from Cannibal Farm`) name = `CANNIBAL FARM`
    if (name === `Death and Compromise`) name = `DEATH & COMPROMISE`
    if (name === `The Rebels`) name = `REBELS, THE`
    if (name === `Robot Awakening`) name = `ROBOT AWAKENING (fka:OMG I'M A ROBOT)`
    if (name === `Shaken: The Great Sylmar Earthquake`) name = `shaken`
    if (name === `Izzy Lion`) name = `izzy lyon`
    if (name === 'The Pond') name = 'POND, THE'
    if (name === 'The Thing Inside Us') name = 'THING INSIDE US, THE'
    if (name === 'The Last Compartment') name = 'LAST COMPARTMENT, THE'
    if (name === 'The Shepherd') name = 'SHEPHERD, THE'
    if (name === 'The Unkind') name = 'UNKIND, THE'
    if (name === 'THE UNKIND') name = 'UNKIND, THE'
    if (name === 'Captive (Katherine\'s Lullaby)') name = 'CAPTIVE (fka KATHERINE\'S LULLABY)'
    if (name === 'Calm Like a Bomb (C.L.A.B.)') name = 'CALM LIKE A BOMB'
    if (name === 'A Rough Draft') name = 'ROUGH DRAFT, A'
    if (name === 'Drowning Echo') name = 'DROWNING ECHO (fka:NEREUS)'
    if (name === 'Carnivore: Werewolf of London') name = 'CARNIVORE'
    if (name === 'Carnivore') name = 'CARNIVORE'
    if (name === 'Atomic Apocalypse') name = 'ATOMIC APOCALYPSE (fka: BLACK FLOWERS)'
    if (name === 'Mummy Rebirth') name = 'MUMMY REBIRTH, THE'
    if (name === 'Canaries (Alien Party Crashers)') name = 'ALIEN PARTY CRASHERS'
    if (name === 'The Magical World of Andrew Bennett') name = 'MAGICAL WORLD OF ANDREW BENNETT, THE'
    if (name === 'The Unlit') name = 'UNLIT, THE'
    if (name === 'The Last Laugh') name = 'last laugh, the'
    if (name === 'MAGICAL WORLD OF ANDREW BENNETT') name = 'MAGICAL WORLD OF ANDREW BENNETT, THE'
    if (name === 'THE UNLIT') name = 'UNLIT, THE'
    if (name === 'LAST LAUGH') name = 'last laugh, the'
    if (name === 'The Legend of the Five') name = 'LEGEND OF THE FIVE, THE'
    if (name === 'The Cage') name = 'cage, THE'
    if (name === "THE CAGE") name = 'cage, THE'
    if (name === 'The Alpha Test') name = 'alpha test, THE'
    if (name === 'Legend of the Ghost Dagger (Benjamin Falck)') name = 'LEGEND OF THE GHOST DAGGER'
    if (name === 'A Wakefield Project') name = 'WAKEFIELD PROJECT'
    if (name === 'The Answer') name = 'answer, the'
    if (name === 'The Beast (Suburban Cowboy)') name = 'TERRIBLE TWO, THE'
    if (name === 'THE TERRIBLE TWO') name = 'TERRIBLE TWO, THE'
    if (name === 'Beyond the Trek (Teleios)') name = 'BEYOND THE TREK (TELIOS)'
    if (name === 'The Creature Below') name = 'CREATURE BELOW, THE'
    if (name === 'A Little Water') name = 'LITTLE WATER, A'
    if (name === 'Dinosaur World') name = 'DINO WORLD'
    if (name === 'Immortal Combat: The Code') name = 'IMMORTAL KOMBAT (fka:QU XIA 2 THE CODE)'
    if (name === 'IMMORTAL COMBAT: THE CODE') name = 'IMMORTAL KOMBAT (fka:QU XIA 2 THE CODE)'
    if (name === 'Assassin\'s Game') name = 'ASSASSINS GAME (fka: MAXIMILIAN)'
    if (name === 'The Haunting of Grady Farm') name = 'HAUNTING OF GRADY FARM, THE'
    if (name === 'HAUNTING OF GRADY FARM') name = 'HAUNTING OF GRADY FARM, THE'
    if (name === 'A Deadly Legend') name = 'DEADLY LEGEND, A'
    if (name === 'DEADLY LEGEND') name = 'DEADLY LEGEND, A'
    if (name === 'An English Haunting') name = 'ENGLISH HAUNTING, AN'
    if (name === 'Crisis Hotline') name = 'CRISIS HOTLINE (fka: SHADOWS IN MIND)'
    if (name === 'The Snare') name = 'SNARE, THE'
    if (name === 'Terrible Two') name = 'TERRIBLE TWO, THE'
    if (name === 'Sasq-Watch! (Nigel &amp; Oscar vs. The Sasquatch)') name = 'SASQ-WATCH!'
    if (name === 'The Cabin') name = 'CABIN, THE'
    if (name === 'THE CABIN') name = 'CABIN, THE'
    if (name === 'The Tribe') name = 'TRIBE, THE'
    if (name === 'THE TRIBE') name = 'TRIBE, THE'
    if (name === 'The House of Violent Desire') name = 'HOUSE OF VIOLENT DESIRE, THE'
    if (name === 'Dark Exorcism (In the Dark)') name = 'DARK EXORCISM'
    if (name === 'An American Terror') name = 'AMERICAN TERROR, AN'
    if (name === 'AMERICAN TERROR') name = 'AMERICAN TERROR, AN'
    if (name === 'The Elephant King') name = 'ELEPHANT KING, THE'
    if (name === 'ELEPHANT KING') name = 'ELEPHANT KING, THE'
    if (name === 'America Has Fallen (Rising Fear)') name = 'AMERICA HAS FALLEN'
    if (name === 'The Last Conception') name = 'LAST CONCEPTION, THE'
    if (name === "ALPHA TEST") name = 'alpha test, THE'
    if (name === "ASSASSIN'S GAME") name = 'ASSASSINS GAME (fka: MAXIMILIAN)'
    if (name === "ATOMIC APOCALYPSE") name = 'ATOMIC APOCALYPSE (fka: BLACK FLOWERS)'
    if (name === "DEATH AND COMPROMISE") name = 'DEATH & COMPROMISE'
    if (name === "IMMORTAL COMBAT THE CODE") name = 'IMMORTAL KOMBAT (fka:QU XIA 2 THE CODE)'
    if (name === "INTERPRETERS EARTH CHRONOCLES") name = 'INTERPRETERS'
    if (name === "MAN FROM KATHMANDU, THE") name = 'MAN FROM KATHMANDU'
    if (name === "ROUGH DRAFT") name = 'ROUGH DRAFT, A'
    if (name === "SWEETEST KISS,  A") name = 'A SWEETEST KISS'
    if (name === "SUBURBAN COWBOY") name = 'BEAST, THE (SUBURBAN COWBOY)'
    if (name === "ESCAPE FROM CANNIBAL FARM") name = 'CANNIBAL FARM'
    if (name === 'Cannibale Farm') name = 'CANNIBAL FARM'
    if (name === 'Cannibal Farm') name = 'CANNIBAL FARM'
    if (name === "ARMY OF FRANKENSTEINS") name = 'ARMY OF FRANKESTEIN'
    if (name === "BEAST, THE") name = 'BEAST, THE (SUBURBAN COWBOY)'
    if (name === "HOUSE OF VIOLENT DESIRE") name = 'HOUSE OF VIOLENT DESIRE, THE'
    if (name === "JURASSIC GAMES, THE") name = 'JURASSIC GAMES'
    if (name === "LABYRIRINTHIA") name = 'LABYRINTHIA'
    if (name === "RISING FEAR") name = 'america has fallen'
    if (name === "ASSASSIN'S TARGET") name = 'ASSASSIN\'S TARGET (fka: IMPOSSIBLE MISSION)'
    if (name === "BEYOND THE TREK") name = 'BEYOND THE TREK (TELIOS)'
    if (name === "C.L.A.B.") name = 'CALM LIKE A BOMB'
    if (name === "DROWNING ECHO") name = 'DROWNING ECHO (fka:NEREUS)'
    if (name === "ROBOT AWAKENING") name = 'ROBOT AWAKENING (fka:OMG I\'M A ROBOT)'
    if (name === "YOUR NOT THINKING STRAIGHT") name = 'You\'re Not Thinking Straight'
    if (name === `Baltic Tribes (Last Pagans of Europe)`) name = `BALTIC TRIBES`
    if (name === `American Hunt (The Hunt)`) name = `AMERICAN HUNT`
    if (name === `Bobot (Solar Flair)`) name = `BOBOT`
    if (name === `Red Handed (Children of Moloch)`) name = `red handed`
    if (name === `CRISIS HOTLINE`) name = `CRISIS HOTLINE (fka: SHADOWS IN MIND)`
    if (name === `Assassin's Target (The Vibe)`) name = `ASSASSIN'S TARGET (fka: IMPOSSIBLE MISSION)`
    if (name === `Fantasma (Bloody Ballet)`) name = `BLOODY BALLET`
    if (name === `Assassin's Code`) name = `ASSASSIN'S CODE, THE`
    if (name === `Labyrinthia (Deadman Apocalypse)`) name = `LABYRINTHIA"`
    if (name === `The Creature Below`) name = `CREATURE BELOW, THE`
    if (name === `CREATURE BELOW`) name = `CREATURE BELOW, THE`
    if (name === `Delirium`) name = `DELIRUIM`
    if (name === `A Haunting In Cawdor`) name = `HAUNTING IN CAWDOR`
    if (name === 'DARK EXPERIMENT') name = `DARK EXPERIMENT (BLOOD HUNTERS)`
    if (name === 'BETHANY') name = `BETHANY (AKA IMAGINARY FRIEND)`
    if (name === 'ELITE') name = `ELITE (PD)`
    if (name === 'CHALLENGER DISASTER') name = `CHALLENGER DISASTER, THE`
    if (name === `Army of Frankensteins`) name = `ARMY OF FRANKESTEIN`
    if (name === `GHOST HUNTER (AKA GHOST OF DARKNESS)`) name = `GHOST HUNTER (AKA GHOSTS OF DARKNESS)`
    if (name === `The Door`) name = `DOOR, THE`
    if (name === 'MUMMY REBORN, THE') name = `MUMMY REBORN`
    if (name === 'ROBOT WARS') name = `ROBOT WARS (KILL BOX)`
    if (name === 'SPEARHEAD EFFECT, THE') name = 'SPEARHEAD EFFECT'
    if (name === 'ZOMBIE SPRING BREAKERS') name = 'ZOMBIE SPRING BREAKERS (IBIZA UNDEAD)'
    if (name === `Escape from Cannibal Farm`) name = `CANNIBAL FARM`
    if (name === `Death and Compromise`) name = `DEATH & COMPROMISE`
    if (name === `The Rebels`) name = `REBELS, THE`
    if (name === `Robot Awakening`) name = `ROBOT AWAKENING (fka:OMG I'M A ROBOT)`
    if (name === `Shaken: The Great Sylmar Earthquake`) name = `shaken`
    if (name === `Izzy Lion`) name = `izzy lyon`
    if (name === 'The Pond') name = 'POND, THE'
    if (name === 'The Thing Inside Us') name = 'THING INSIDE US, THE'
    if (name === 'THE THING INSIDE US') name = 'THING INSIDE US, THE'
    if (name === 'The Last Compartment') name = 'LAST COMPARTMENT, THE'
    if (name === 'The Shepherd') name = 'SHEPHERD, THE'
    if (name === 'The Unkind') name = 'UNKIND, THE'
    if (name === 'Captive (Katherine\'s Lullaby)') name = 'CAPTIVE (fka KATHERINE\'S LULLABY)'
    if (name === 'Calm Like a Bomb (C.L.A.B.)') name = 'CALM LIKE A BOMB'
    if (name === 'A Rough Draft') name = 'ROUGH DRAFT, A'
    if (name === 'Drowning Echo') name = 'DROWNING ECHO (fka:NEREUS)'
    if (name === 'Carnivore: Werewolf of London') name = 'CARNIVORE'
    if (name === 'Atomic Apocalypse') name = 'ATOMIC APOCALYPSE (fka: BLACK FLOWERS)'
    if (name === 'Mummy Rebirth') name = 'MUMMY REBIRTH, THE'
    if (name === 'Canaries (Alien Party Crashers)') name = 'ALIEN PARTY CRASHERS'
    if (name === 'The Magical World of Andrew Bennett') name = 'MAGICAL WORLD OF ANDREW BENNETT, THE'
    if (name === 'The Unlit') name = 'UNLIT, THE'
    if (name === 'The Last Laugh') name = 'last laugh, the'
    if (name === "LAST LAUGH") name = 'last laugh, the'
    if (name === 'The Legend of the Five') name = 'LEGEND OF THE FIVE, THE'
    if (name === 'The Cage') name = 'cage, THE'
    if (name === "THE CAGE") name = 'cage, THE'
    if (name === 'The Alpha Test') name = 'alpha test, THE'
    if (name === 'Legend of the Ghost Dagger (Benjamin Falck)') name = 'LEGEND OF THE GHOST DAGGER'
    if (name === 'A Wakefield Project') name = 'WAKEFIELD PROJECT'
    if (name === 'The Answer') name = 'answer, the'
    if (name === 'The Beast (Suburban Cowboy)') name = 'TERRIBLE TWO, THE'
    if (name === 'THE BEAST') name = 'TERRIBLE TWO, THE'
    if (name === 'Beyond the Trek (Teleios)') name = 'BEYOND THE TREK (TELIOS)'
    if (name === 'The Creature Below') name = 'CREATURE BELOW, THE'
    if (name === 'A Little Water') name = 'LITTLE WATER, A'
    if (name === 'Dinosaur World') name = 'DINO WORLD'
    if (name === 'DINOSAUR WORLD') name = 'DINO WORLD'
    if (name === 'DINOSAUR WORLD') name = 'DINO WORLD'
    if (name === 'Immortal Combat: The Code') name = 'IMMORTAL KOMBAT (fka:QU XIA 2 THE CODE)'
    if (name === 'Assassin\'s Game') name = 'ASSASSINS GAME (fka: MAXIMILIAN)'
    if (name === 'The Haunting of Grady Farm') name = 'HAUNTING OF GRADY FARM, THE'
    if (name === 'A Deadly Legend') name = 'DEADLY LEGEND, A'
    if (name === 'An English Haunting') name = 'ENGLISH HAUNTING, AN'
    if (name === 'Crisis Hotline') name = 'CRISIS HOTLINE (fka: SHADOWS IN MIND)'
    if (name === 'The Snare') name = 'SNARE, THE'
    if (name === 'Terrible Two') name = 'TERRIBLE TWO, THE'
    if (name === 'Sasq-Watch! (Nigel &amp; Oscar vs. The Sasquatch)') name = 'SASQ-WATCH!'
    if (name === 'The Cabin') name = 'CABIN, THE'
    if (name === 'The Tribe') name = 'TRIBE, THE'
    if (name === 'BASEMENT, THE (UC)') name = 'BASEMENT, THE'
    if (name === 'basement, the') name = 'BASEMENT, THE'
    if (name === 'The House of Violent Desire') name = 'HOUSE OF VIOLENT DESIRE, THE'
    if (name === 'Dark Exorcism (In the Dark)') name = 'DARK EXORCISM'
    if (name === 'An American Terror') name = 'AMERICAN TERROR, AN'
    if (name === 'The Elephant King') name = 'ELEPHANT KING, THE'
    if (name === 'America Has Fallen (Rising Fear)') name = 'AMERICA HAS FALLEN'
    if (name === 'SIXTY MINUTES TO MIDNIGHT') name = 'Sixty Minutes to Midnight'
    if (name === 'alpha test, THE') name = "ALPHA TEST, THE"
    if (name === 'ALPHA TEST') name = "ALPHA TEST, THE"
    if (name === "cage, THE") name = "CAGE, THE"
    if (name === 'The Last Conception') name = 'LAST CONCEPTION, THE'
    if (name === "last laugh, the") name = "LAST LAUGH, THE"
    if (name === `ANSWER, THE`) name = 'THE ANSWER'
    if (name === 'Inferno: Skyscraper Escape') name = 'Inferno- Skyscraper Escape'
    if (name === 'Inferno Skyscraper Escape') name = 'Inferno- Skyscraper Escape'
    if (name === 'Ouija Séance') name = 'OUIJA SEANCE- THE FINAL GAME'
    if (name === 'Ouija Séance') name = 'OUIJA SEANCE- THE FINAL GAME'
    if (name === 'The House of Violent Desire') name = 'THE HOUSE OF VIOLENT DESIRE'
    if (name === 'Bloody Ballet') name = 'FANTASMA (BLOODY BALLET)'
    if (name === 'The Cabin') name = 'THE CABIN'
    if (name === 'Alien Party Crashers') name = 'CANARIES (ALIEN PARTY CRASHERS)'
    if (name === 'Drowning Echo') name = 'DROWNING ECHO'
    if (name === 'Crisis Hotline') name = 'CRISIS HOTLINE'
    if (name === 'Impossible Mission') name = 'ASSASSIN\'S TARGET (THE VIBE)'
    if (name === 'Blood Hound') name = 'BLOODHOUND'
    if (name === 'Robot Awakening') name = 'ROBOT AWAKENING'
    if (name === 'Assassin\'s Target') name = 'ASSASSIN\'S TARGET (THE VIBE)'
    if (name === 'The Wakefield Project') name = 'A WAKEFIELD PROJECT'
    if (name === 'The Last Conception') name = 'THE LAST CONCEPTION'
    if (name === 'The Vibe') name = 'ASSASSIN\'S TARGET (THE VIBE)'
    if (name === 'The Haunting of Grady Farm') name = 'THE HAUNTING OF GRADY FARM'
    if (name === 'A Little Water') name = 'A LITTLE WATER'
    if (name === 'Red Handed') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'Cannibal Farm') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'Cannibal Farm') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'Carnivore') name = 'CARNIVORE- WEREWOLF OF LONDON'
    if (name === 'ALPHA TEST') name = 'ALPHA TEST, THE'
    if (name === 'AMERICAN HUNT') name = 'AMERICAN HUNT'
    if (name === 'AMERICAN TERROR') name = 'AMERICAN TERROR, AN'
    if (name === 'ARMY OF FRANKENSTEIN') name = 'ARMY OF FRANKESTEIN`'
    if (name === "ASSASSIN'S CODE, THE") name = 'ASSASSIN\'S CODE, THE'
    if (name === "ASSASSIN'S GAME") name = 'ASSASSINS GAME (fka: MAXIMILIAN)'
    if (name === "ASSASSIN'S TARGET") name = 'ASSASSIN\'S TARGET (THE VIBE)'
    if (name === 'BEYOND THE TREK') name = 'BEYOND THE TREK (TELIOS)'
    if (name === 'BALTIC TRIBES') name = 'BALTIC TRIBES'
    if (name === 'THE BASEMENT (Laszlo)') name = 'BASEMENT, THE'
    if (name === 'THE BEAST') name = 'TERRIBLE TWO, THE'
    if (name === 'BLOODY BALLET') name = 'FANTASMA (BLOODY BALLET)'
    if (name === 'CREATURE BELOW') name = 'CREATURE BELOW, THE'
    if (name === 'CANNIBAL FARM') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'THE CABIN') name = 'THE CABIN'
    if (name === 'THE CAGE') name = 'cage, THE'
    if (name === 'CALM LIKE A BOMB') name = 'CALM LIKE A BOMB'
    if (name === 'CRISIS HOTLINE') name = 'CRISIS HOTLINE'
    if (name === 'CANNIBAL FARM') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'DARK EXORCISM') name = 'DARK EXORCISM'
    if (name === 'DINOSAUR WORLD') name = 'DINO WORLD'
    if (name === 'DEATH & COMPROMISE') name = 'DEATH & COMPROMISE'
    if (name === 'DELIRIUM') name = 'DELIRUIM'
    if (name === 'A WAKEFIELD PROJECT') name = 'A WAKEFIELD PROJECT'
    if (name === 'BASEMENT, THE (Keith)') name = 'BASEMENT, THE'
    if (name === 'ELEPHANT KING') name = 'ELEPHANT KING, THE'
    if (name === 'ENGLISH HAUNTING, AN') name = 'ENGLISH HAUNTING, AN'
    if (name === 'HAUNTING IN CAWDOR') name = `HAUNTING IN CAWDOR`
    if (name === 'HAUNTING OF GRADY FARM') name = 'HAUNTING OF GRADY FARM, THE'
    if (name === 'House of Violent Desire') name = 'THE HOUSE OF VIOLENT DESIRE'
    if (name === 'IMMORTAL COMBAT: THE CODE') name = 'IMMORTAL KOMBAT (fka:QU XIA 2 THE CODE)'
    if (name === 'BASEMENT, THE (Keith)') name = 'BASEMENT, THE'
    if (name === 'BASEMENT, THE (Keith)') name = 'BASEMENT, THE'
    if (name === 'RED HANDED') name = 'RED HANDED (CHILDREN OF MOLOCH)'
    if (name === 'LABYRINTHIA') name = `LABYRINTHIA`
    if (name === 'LAST CONCEPTION, THE') name = 'LAST CONCEPTION, THE'
    if (name === 'LAST LAUGH') name = "LAST LAUGH, THE"
    if (name === 'LEGEND OF THE FIVE, THE') name = 'LEGEND OF THE FIVE, THE'
    if (name === 'LEGEND OF THE GHOST DAGGER') name = 'LEGEND OF THE GHOST DAGGER'
    if (name === 'MAGICAL WORLD OF ANDREW BENNETT') name = 'MAGICAL WORLD OF ANDREW BENNETT, THE'
    if (name === 'MUMMY REBIRTH, THE') name = 'MUMMY REBIRTH, THE'
    if (name === 'ROUGH DRAFT, A') name = 'ROUGH DRAFT, A'
    if (name === 'SASQ-WATCH!') name = 'SASQ-WATCH!'
    if (name === 'SNARE, THE') name = 'SNARE, THE'
    if (name === 'THE TERRIBLE TWO') name = 'TERRIBLE TWO, THE'
    if (name === 'THE THING INSIDE US') name = 'THING INSIDE US, THE'
    if (name === 'THE TRIBE') name = 'TRIBE, THE'
    if (name === 'THE UNKIND') name = 'UNKIND, THE'
    if (name === 'THE UNLIT') name = 'UNLIT, THE'
    // if (name === 'DARK/WEB') name = ''
    // if (name === 'MAVERICK: MANHUNT BRAZIL') name = ''
    // if (name === 'INFINITUM: SUBJECT UNKNOWN') name = ''
    return name ? name.toUpperCase() : name
  }
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "../../helpers/display/table";
import 'antd/dist/antd.css'
import "../../helpers/display/antdstyle.css"
import { Link } from "react-router-dom";
import User from '../../views/user'
import Filters from '../../helpers/filters'
class Users extends Component {
  state = {
    currentList: [],
    currentFilters: []
  };

  componentDidMount() {
    window.users = this
  }
  restore = (_id) => {
    window.app.restoreUser(_id).then(() => {
      this.setState({ view: '' })
    }).catch(e => window.flash(e))
  }
  render() {
    let helmet = <Helmet>
      <title>{this.props.type ? window.capitalize(this.props.type+'s') : 'Users'}</title>
      <meta name="description" content="Reactify Blank Page" />
    </Helmet>
    const { add, type, data } = this.props;
    const currentList = window.filter(data, this.state.currentFilter || [])
    const columns = [
      {
        title: "Full Name",
        dataIndex: "name",
        render: (text, record) => (
          <><a onClick={e => { e.preventDefault(); this.setState({view: record._id}) }} className="avatar"><img alt="" src={record.profilePic} /></a>
            <a onClick={e => { e.preventDefault(); this.setState({view: record._id}) }} href="#">{record.firstName + ' ' + record.lastName}</a></>
        ),
        sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (text, record) => (
          <>{text}</>
        ),
        sorter: (a, b) => window.nameCompare(a.type, b.type),
      },
      {
        title: "CRM",
        dataIndex: "crm",
        render: (text, record) => (
          <>{text}</>
        ),
        sorter: (a, b) => window.nameCompare(a.crm, b.crm),
      },
      {
        title: "Company",
        dataIndex: "company",
        render: (text, record) => (
          <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
        ),
        sorter: (a, b) => window.nameCompare((() => {
          return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
        })(), (() => {
          return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
        })())
      },
      {
        title: "Phone",
        dataIndex: "phone",
        render: (text, record) => (
          <>{text}</>
        ),
        sorter: (a, b) => a.phone.length - b.phone.length,
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (text, record) => (
          <>{text}</>
        ),
        sorter: (a, b) => window.nameCompare(a.email, b.email),
      },
      {
        title: "Relationship",
        dataIndex: "relationshipType",
        render: (text, record) => (
          <span className="badge badge-gradient-success">{text}</span>
        ),
        sorter: (a, b) => a.relationshipType - b.relationshipType,
      },
      {
        title: "Action",
        dataIndex: "status",
        render: (text, record) => (
          <div className="dropdown dropdown-action">
            <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
            <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#" onClick={e => {e.preventDefault(); this.restore(record._id)}}>Restore User</a>
                <a className="dropdown-item" href="#" onClick={e => {e.preventDefault(); window.sure(`Delete User: ${record.name}`, () => window.app.permDeleteUser(record._id))}}>Delete User</a>
            </div>
          </div>
        ),
      },
    ]
    return (
      <>
        {helmet}
        <div className="content container-fluid">
          <div className="page-header pt-3 mb-0 ">
            <div className="crms-title row bg-white">
              <div className="col ">
                <h3 className="page-title m-0">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2">
                    <i className="feather-smartphone" />
                  </span> {type ? window.capitalize(type) : ''} </h3>
              </div>
              <div className="col text-right">
                <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                  <li className="breadcrumb-item active">{type ? window.capitalize(type+'s') : ''}</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{!this.state.showFilters ? 'Show' : 'Hide'} Filters</span>
              </div>
              <div className="col text-right">
                <ul className="list-inline-item pl-0">
                  <li className="list-inline-item">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c1" style={{gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr'}}>
            {this.state.showFilters && <Filters height={'70vh'} list={data} change={(list, filters) => { this.setState({ currentFilters: filters }) }} />}
      
                      <Table
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        data={currentList}
                        mainKey={(record) => record._id}
                      />
          </div>
        </div>
        {this.state.view ? <User _id={this.state.view} restore={() => this.restore(this.state.view)} close={() => this.setState({ view: '' })}></User> : ''}
      </>
    );
  }
}

export default Users;

import React, { Component } from 'react'
class ChangeImage extends Component {
    render() {
        return (<>
            <div onClick={this.props.close} className="modal fade show" style={{ display: 'block' }} id="change-picture">
                <div onClick={e => e.stopPropagation()} className="modal-dialog">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title">Change Picture</h4>
                            <button type="button" id="exit-pictures" className="close" onClick={this.props.close}>×</button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="forms-sample">
                                <div className="form-group row">
                                    <label htmlFor="view-name" className="col-sm-4 col-form-label">Select an Image</label>
                                    <form className="b2" onSubmit={e => {
                                        e.preventDefault()
                                        let warn = e.target.querySelector('.warn')
                                        let warning = e => {
                                            warn.innerHTML = e
                                            setTimeout(() => warn.innerHTML = '', 3000)
                                        }
                                        let send = data => {
                                            let that = new XMLHttpRequest()
                                            that.onabort = e => { window.flash(e); warning(e) }
                                            that.onerror = e => { window.flash(e); warning(e) }
                                            that.onreadystatechange = e => {
                                                if (that.readyState === 4) {
                                                    let data
                                                    try {
                                                        data = JSON.parse(that.responseText)
                                                    } catch (e) {
                                                        return window.flash(e)
                                                    }
                                                    if (!data || data.error) { warning(data.message || JSON.stringify(data)); return window.flash(data.message || JSON.stringify(data)) }
                                                    if (this.props.title) {
                                                        window.app.setState({
                                                            titles: [...window.app.state.titles].map(u => {
                                                                if (u._id === this.props._id) return ({ ...u, profilePic: data.profilePic })
                                                                return u
                                                            })
                                                        })
                                                    } else if (this.props.company) {
                                                        window.app.setState({
                                                            companies: [...window.app.state.companies].map(u => {
                                                                if (u._id === this.props._id) return ({ ...u, profilePic: data.profilePic })
                                                                return u
                                                            })
                                                        })
                                                    } else {
                                                        let updated = {
                                                            users: [...window.app.state.users].map(u => {
                                                                if (u._id === this.props._id) return ({ ...u, profilePic: data.profilePic })
                                                                return u
                                                            })
                                                        }
                                                        if (this.props._id === window.app.state.userID) updated.profilePic = data.profilePic
                                                        window.app.setState(updated)
                                                    }
                                                    window.flash('Image updated!')
                                                    if (typeof this.props.close === 'function') this.props.close()
                                                    document.querySelector('#exit-pictures')?.click()
                                                }
                                            }
                                            that.open('POST', window.API + (this.props.title ? '/set-title-image' : this.props.company ? '/set-company-image' : '/set-user-image'), true)
                                            that.setRequestHeader('userid', window.app.state.userID)
                                            that.setRequestHeader('authorization', window.app.state.auth)
                                            warning('Uploading Image')
                                            that.send(data)
                                        }
                                        let form = e.target
                                        let file = form['image'].files[0]
                                        if (!file) return warning('Missing image for upload')
                                        if (file?.size > 1000 * 1000 * 10) return warning('Selected File Is Too Large! (Files must be under 10Mb)')
                                        let fd = new FormData(form)
                                        fd.append('_id', this.props._id)
                                        send(fd)
                                    }}><span className="warn"></span>
                                        <input type="file" accept="image/png,image/jpeg" onChange={e => {
                                            let it = e.currentTarget
                                            if (it.files && it.files[0]?.size > 1000 * 1000 * 10) {
                                                window.flash('SELECTED FILE IS TOO LARGE')
                                                it.value = ''
                                            }
                                        }} name="image" />
                                        <div className="b2">
                                            <button type="submit">Submit</button>
                                            <button type="button" onClick={() => this.props.close()} data-dismiss="modal">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>)
    }
}
export default ChangeImage
import React, { Component } from 'react'
import SearchInput from './searchinput'
class ArrayInput extends Component {
    state = { newline: '', selected: [] }
    componentDidMount() {
        if (this.props.selected?.length > 0) this.priorWatch = true
        this.setState({ selected: this.props.selected || [], delimiter: (this.props.type === 'text' || this.props.type === 'search') ? window.textdelim : ',' })
    }
    componentDidUpdate() {
        if (this.props.selected?.length > 0 && this.state.selected?.length < 1 && !this.priorWatch) { this.priorWatch = true; this.setState({ selected: this.props.selected || [], delimiter: (this.props.type === 'text' || this.props.type === 'search') ? window.textdelim : ',' }) }
    }
    render() {
        let { items, name, type } = this.props
        let { selected, newline, delimiter } = this.state
        let change = value => {
            if (value) this.setState({ selected: this.state.selected.includes(value) ? [...this.state.selected] : [...this.state.selected, value] }, () => {
                if (typeof this.props.change === 'function') this.props.change(value, this.state.selected)
            })
        }
        let rm = value => {
            if (value) this.setState({ selected: this.state.selected.includes(value) ? [...this.state.selected].filter(u => u !== value) : [] }, () => {
                if (typeof this.props.change === 'function') this.props.change(value, this.state.selected)
            })
        }
        return (<>
            <input type="text" value={selected && selected instanceof Array && selected?.join(delimiter || ',') || 'null-input'} onChange={e => { }} className="hidden" name={name} />
            {selected && selected?.length > 0 && selected instanceof Array && <ul>
                {selected?.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : u}<i className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    rm(u)
                }}></i></span></li>))}
            </ul>}
            {type === 'text' ?
                <>
                    <div className="input-group">
                        <input placeholder={this.props.placeholder} className="form-control" type="text" value={newline} onKeyUp={e => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                e.preventDefault()
                                e.stopPropagation()
                                if (this.state.newline) {
                                    change(this.state.newline)
                                    this.setState({ newline: '' })
                                }
                            }
                        }} onKeyDown={e => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                e.preventDefault()
                                e.stopPropagation()
                                if (this.state.newline) {
                                    change(this.state.newline)
                                    this.setState({ newline: '' })
                                }
                            }
                        }} onChange={e => this.setState({ newline: e.target.value })} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" style={{ margin: 0, padding: '4px', cursor: 'pointer' }} onClick={e => { e.preventDefault(); change(this.state.newline); this.setState({ newline: '' }); }}>Add</button>
                        </div>
                    </div>
                </> : type === 'search' ? (<>
                    <SearchInput placeholder={this.props.placeholder || 'Search/Enter'} multiple={true} items={items?.filter(a => !selected.includes(a)) || []} change={e => this.setState({ selected: [...this.state.selected, e] }, () => {
                        if (typeof this.props.change === 'function') this.props.change(this.state.selected)
                    })} />
                </>) : <select className="form-control" value="" onChange={e => {
                    change(e.target.value)
                    e.target.value = ''
                }}>
                    <option value="">Select One</option>
                    {(items instanceof Array || (items?.length && typeof items.splice === 'function')) ? items.filter(a => a && (a._id ? !selected?.includes(a._id) : !selected?.includes(a))).map(typeof this.props.template === 'function' ? this.props.template : (u, i) => u && (<option key={i} value={u._id ? u._id : u}>{u}</option>)) : typeof items === 'object' ? (Object.entries(items).reduce((a, b) => {
                        let [k, v] = b
                        if (!v || !(v instanceof Array)) return [...a, [k,null]]
                        return [...a, [k, v.filter(u => !selected.map(u => /:/.test(u) ? u.split(':')[1].trim() : u).includes(u))]]
                    }, []).map(([k, v], i) => (<optgroup key={i} label={k}>
                        {v?.map((u, i) => (<option key={i} value={`${k}: ${u}`}>{u}</option>))}
                    </optgroup>))) : ''}
                </select>}
        </>)
    }
}
export default ArrayInput
import React from 'react'
import Censored2 from './censored2'
import TabLink from '../tablink'
function CredentialDisplay(props) {
    return (<div>
        <span className="b2 jfs">Username:   {props.value.email}</span><br></br>
        <span className="b2 jfs">Password:   <div><Censored2 value={props.value.password} /></div></span><br></br>
        <span className="b2 jfs">Link:   <TabLink to={props.value.link} text={props.value.link} /></span><br></br>
        <span className="b2 jfs">Directions:   {props.value.directions}</span><br></br>
    </div>)
}
export default CredentialDisplay
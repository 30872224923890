window.primaryGenres = [
  'Action',
  'African American',
  'Animation',
  'Anime',
  'Arthouse',
  'Asian',
  'Comedy',
  'Documentary',
  'Drama',
  'Faith',
  'Family',
  'Fantasy',
  'Horror',
  'Horror Comedy',
  'LGBTQ',
  'Musical',
  'Reality',
  'Romance',
  'RomCom',
  'Sci-Fi',
  'Thriller',
  'War',
  'Western',
  'Crime',
  'Educational',
  'Bollywood',
  'Animation - Adult'
]
window.secondaryGenres = [
  'Adventure',
  'Aliens',
  'Apocalyptic',
  'Biographical',
  'Black and White',
  'Cast-A',
  'Cast-B',
  'Cast-C',
  'CGI',
  'Child Lead',
  'Classic',
  'Cooking/Food',
  'Creature',
  'Creepy Children',
  'Dinosaurs',
  'Disaster',
  'Disease',
  'Dragons',
  'Drugs',
  'Erotic',
  'Exorcism',
  'Female Lead',
  'Found Footage',
  'Ghosts',
  'Giant Creatures',
  'Gory',
  'History',
  'Holiday',
  'In Space',
  'Kids (0-10)',
  'Lifestyle',
  'Magic',
  'Male Lead',
  'Martial Arts',
  'Mobster',
  'Mockbuster',
  'Mockumentary',
  'Mummies',
  'Mystery',
  'Nazi',
  'Parody',
  'Period Piece',
  'Possession',
  'Psychological',
  'Robots',
  'Series',
  'Sex Scenes',
  'Shorts',
  'Silent',
  'Slapstick',
  'Slasher',
  'Sports',
  'Spy',
  'Steampunk',
  'Superhero',
  'Supernatural',
  'Suspense',
  'Talking Animals',
  'Teen',
  'Tentpole',
  'Terrorists',
  'Vampires',
  'Werewolf',
  'WWII',
  'Zombies',
  'Tween',
  'Foreign'
]
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Agreement as AgreementDisplay, Accounting, PaymentInfo } from '../helpers/display/agreement'
import NotFound from '../helpers/display/notfound'
import SidePop from '../helpers/display/sidepop'
class Agreement extends Component {
    state = { notes: [], noteData: [], view: 'details' }
    componentDidMount() {
        if (this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedAgreements : window.app.state.agreements)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (!this.props.restore) {
                        if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                    }
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedAgreements : window.app.state.agreements)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (!this.props.restore) {
                        if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                    }
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        }
    }
    getPaymentInfo = _id => {
        if (this.getPI) return
        this.getPI = true
        window.app.getPaymentInfo(_id).then(pi => {
            this.getPI = false
            this.setState({ paymentInfo: pi })
        }).catch(e => {
            this.getPI = false
            window.flash(e)
        })
    }
    getActions() {
        if (this.gettingActions) return
        this.gettingActions = true
        window.app.request('/actions', 'post', { _id: this.state._id }).then(r => {
            this.gettingActions = false
            this.setState({ gotActions: true, actions: r.actions })
        }).catch(e => {
            this.gettingActions = false
            window.flash(e)
        })
    }
    render() {
        window.agreement = this
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        return (<>
            <div className="modal right fade show" style={{ display: 'block' }} onClick={this.props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
                <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                    <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close xs-close" onClick={this.props.close} data-dismiss="modal">×</button>
                            <div className="row w-100">
                                <div className="col-md-7 account">
                                    <span className="modal-title users">{this.state.name} {this.props.restore && <a onClick={this.props.restore}><i style={{ margin: '3px' }} className="fa fa-pencil"></i></a>}{this.props.edit && <a onClick={this.props.edit}><i style={{ margin: '3px' }} className="fa fa-pencil"></i></a>}{this.props.edit && <Link to={`/agreement?_id=${this.state._id}`}><i style={{ margin: '3px', color: 'var(--d)' }} className="fa fa-expand"></i></Link>}</span>&nbsp;
                                </div>
                                <div className="col-md-5 text-right">
                                    <ul className="list-unstyled list-style-none">
                                        <li className="dropdown list-inline-item"><br />
                                            <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Actions </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">Do Something</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow p-2 due-dates">
                            <div className="row m-0">
                                <div className="col">
                                    <span>Company</span>
                                    <p>{window.app.state.companies?.find(a => a._id === this.state.company)?.name}</p>
                                </div>
                                <div className="col">
                                    <span>Contact</span>
                                    <p>{window.app.state.users?.find(a => a._id === this.state.contact)?.name}</p>
                                </div>
                                <div className="col">
                                    <span>Owner</span>
                                    <p>{window.app.state.users?.find(a => a._id === this.state.owner)?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: '10px 0px' }}>
                                <div className="col-md-12">
                                    <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                        {window.agreementStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                            let status = e.target.getAttribute('data-status')
                                            if (!status) return window.flash('Status issue')
                                            window.app.addAgreement({ fields: { _id: this.state._id, status } })
                                            this.setState({
                                                status, closed: (() => {
                                                    if (window.closedStates.includes(status)) {
                                                        if (window.closedStates.includes(this.state.status)) return this.state.closed
                                                        return new Date()
                                                    }
                                                    return undefined
                                                })()
                                            })
                                        }}>
                                            <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                                <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                            </a>
                                        </li>))}
                                    </ul>
                                </div>
                            </div>
                                {AgreementDisplay({...this.state, open: true})}
                                {Accounting(this.state)}
                                {PaymentInfo(typeof this.state.paymentInfo === 'object' ? this.state.paymentInfo : {})}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>)
    }
}
export default Agreement
import React, { Component } from "react";
import { Link} from "react-router-dom";
import SearchBar from './components/helpers/universalsearch'
import ArrayDisplay from './components/helpers/display/arraydisplay'
class SidebarNav extends Component {
  handleClick = e => {
    let item = e.currentTarget
    let parent = item.parentElement
    if (parent.classList.contains('submenu')) {
      e.preventDefault()
      let sub = parent.querySelector('.sub-menus')
      item.classList.toggle('subdrop')
      sub.classList.toggle('hidden')
    }
  }
  render() {
    let pathname = window.app.state.location
    let { crm, archived } = this.props
    let filmSales = () => (<>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-sales/contacts" ? "active" : ""} to="/film-sales/contacts"><i className="feather-user" /> <span>General Contacts</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-sales/customers" ? "active" : ""} to="/film-sales/customers"><i className="feather-package" /> <span>Customers</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-sales/companies" ? "active" : ""} to="/film-sales/companies"><i className="feather-database" /> <span>Companies</span></Link>
      </li>

      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-sales/leads" ? "active" : ""} to="/film-sales/leads"><i className="feather-phone" /> <span>Leads</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-sales/prospects" ? "active" : ""} to="/film-sales/prospects"><i className="feather-smartphone" /> <span>Prospects</span></Link>
      </li>
    </>)
    let corporateSales = () => (<>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/corporate-sales/contacts" ? "active" : ""} to="/corporate-sales/contacts"><i className="feather-user" /> <span>General Contacts</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/corporate-sales/customers" ? "active" : ""} to="/corporate-sales/customers"><i className="feather-package" /> <span>Customers</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/corporate-sales/companies" ? "active" : ""} to="/corporate-sales/companies"><i className="feather-database" /> <span>Companies</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/corporate-sales/leads" ? "active" : ""} to="/corporate-sales/leads"><i className="feather-phone" /> <span>Leads</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/corporate-sales/prospects" ? "active" : ""} to="/corporate-sales/prospects"><i className="feather-smartphone" /> <span>Prospects</span></Link>
      </li>
    </>)
    let distribution = () => (<>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-distribution/contacts" ? "active" : ""} to="/film-distribution/contacts"><i className="feather-user" /> <span>General Contacts</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-distribution/customers" ? "active" : ""} to="/film-distribution/customers"><i className="feather-package" /> <span>Customers</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-distribution/companies" ? "active" : ""} to="/film-distribution/companies"><i className="feather-database" /> <span>Companies</span></Link>
      </li>
      {!window.isSuper(window.app.state.type) && <li>
        <Link className={pathname === "/pitches" ? "active" : ""} to="/pitches"><i className="feather-crosshair" /> <span>Pitches</span></Link>
      </li>}
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-distribution/leads" ? "active" : ""} to="/film-distribution/leads"><i className="feather-phone" /> <span>Leads</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/film-distribution/prospects" ? "active" : ""} to="/film-distribution/prospects"><i className="feather-smartphone" /> <span>Prospects</span></Link>
      </li>

    </>)
    let acquisitions = () => (<>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/contacts" ? "active" : ""} to="/acquisitions/contacts"><i className="feather-user" /> <span>General Contacts</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/clients" ? "active" : ""} to="/acquisitions/clients"><i className="feather-package" /> <span>Clients</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/companies" ? "active" : ""} to="/acquisitions/companies"><i className="feather-database" /> <span>Companies</span></Link>
      </li>
      {!window.isSuper(window.app.state.type) && <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/titles" ? "active" : ""} to="/acquisitions/titles"><i className="feather-film" /> <span>Films</span></Link>
      </li>}
      <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/leads" ? "active" : ""} to="/acquisitions/leads"><i className="feather-phone" /> <span>Leads</span></Link>
      </li>
      <li>
        <Link onClick={this.handleClick} className={pathname === "/acquisitions/prospects" ? "active" : ""} to="/acquisitions/prospects"><i className="feather-smartphone" /> <span>Prospects</span></Link>
      </li>
    </>)
    return (
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div className="mobile-view">
            <SearchBar bar={bar => this.bar = bar} callback={(_id, key) => {
              window.redirect(`/${key === 'Titles' ? 'title' : key === 'Companies' ? 'company' : key === 'Deals' ? 'deal' : 'profile'}?_id=${_id}`)
              this.bar?.setState({ list: [], searching: '' })
            }} placeholder="Search Titles, Leads, Companies and more" mainKey={'_id'} items={[
              {
                key: 'Users', indexes: ['name', 'email', 'phone', 'website', 'facebook', 'twitter', 'youtube', 'instagram', 'tiktok', 'linkedIn', 'title', 'otherEmail', 'assistantName', 'assistantEmail', 'assistantPhone', 'partnerName', 'partnerEmail', 'partnerPhone'], items: window.app.state.users, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}{u.email && ` - ${u.email}`}{u.type && ` - ${u.type}`}{u.crm && ` - ${u.crm}`}{key !== 'email' && key !== 'name' && key !== 'crm' && key !== 'type' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/profile?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '' })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Titles', indexes: ['title', 'titleAKA', 'producers', 'director', 'cast', 'writers'], items: window.app.state.titles, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u.title}{u.titleAKA ? `- ${u.titleAKA}` : ''}{key !== 'titleAKA' && key !== 'title' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/title?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '' })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Deals', indexes: ['name'], items: window.app.state.deals, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/deal?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '' })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Companies', indexes: ['name', 'email', 'phone', 'facebook', 'twitter', 'instagram', 'cinando', 'website', 'deliveryContactName', 'deliveryContactEmail', 'dashboardURL', 'imdb', 'fax'], items: window.app.state.companies, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}{key !== 'name' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/company?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '' })
                    }}>VIEW</button></div>)
                }
              }
            ]} indexes={['name']} ></SearchBar>
          </div>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="nav-item nav-profile">
                <Link to={`/profile?_id=${this.props._id}`} className="nav-link">
                  <div className="nav-profile-image avatar">
                    <img src={this.props.profilePic} alt="" />
                  </div>
                  <div className="nav-profile-text d-flex flex-column">
                    {this.props.name && <span className="font-weight-bold mb-2">{this.props.name || 'First Name'}</span>}
                    {this.props.role && <span className="text-white text-small">{this.props.role || 'Role'}</span>}
                  </div>
                  <i className="mdi mdi-bookmark-check text-success nav-profile-badge" />
                </Link>
              </li>
              <li className="menu-title">
                <span>Main</span>
              </li>
              <li>
                <Link className={pathname === "/" ? "active" : ""} to="/"><i className="feather-home" /> <span>Dashboard</span></Link>
              </li>
              <li>
                <Link className={pathname === "/actions" ? "active" : ""} to="/actions"><i className="feather-check-circle" /> <span>Actions</span></Link>
              </li>
              {!archived && <>
                {window.isSuper(window.app.state.type) && <li>
                  <Link className={pathname === "/pitches" ? "active" : ""} to="/pitches"><i className="feather-crosshair" /> <span>Pitches</span></Link>
                </li>}
                {(window.isSuper(window.app.state.type) || crm === 'film-sales') && <li>
                  <Link className={pathname === "/deals" ? "active" : ""} to="/deals"><i className="feather-target" /> <span>Deals</span></Link>
                </li>}
                {window.isAdmin(window.app.state.type) && <li>
                  <Link className={pathname === "/agreements" ? "active" : ""} to="/agreements"><i className="feather-paperclip" /> <span>Agreements</span></Link>
                </li>}
                {/* <li>
                  <Link className={pathname === "/products" ? "active" : ""} to="/products"><i className="feather-box" /> <span>Products & Services</span></Link>
                </li> */}
                <li>
                  <Link className={pathname === "/vendors" ? "active" : ""} to="/vendors"><i className="feather-truck" /> <span>Vendors</span></Link>
                </li>
                <li>
                  <Link className={pathname === "/work-orders" ? "active" : ""} to="/work-orders"><i className="feather-list" /> <span>Work Orders</span></Link>
                </li>
                {window.isAdmin(window.app.state.type) && <>
                  <li>
                    <Link onClick={this.handleClick} className={pathname === "/titles" ? "active" : ""} to="/titles"><i className="feather-film" /> <span>Films</span></Link>
                  </li>
                  <li className="submenu">
                    <a submenu={'reporting'} onClick={this.handleClick} className={`${['/sales', '/payments', '/expenses'].includes(pathname) || /reporting/.test(pathname) ? "active subdrop" : ""}`}><i className="feather-hash" /> <span> Reporting </span> <span className="menu-arrow" /></a>
                    <ul className={"sub-menus " + (['/payments', '/sales', '/expenses'].includes(pathname) || /reporting/.test(pathname) ? '' : 'hidden')}>
                      <li>
                        <Link className={pathname === "/reporting" ? "active" : ""} to="/reporting"><i className="feather-pie-chart" /> <span>Generate Report</span></Link>
                      </li>
                      <li>
                        <Link className={pathname === "/sales" ? "active" : ""} to="/sales"><i className="feather-tag" /> <span>Sales Revenue</span></Link>
                      </li>
                      <li>
                        <Link className={pathname === "/payments" ? "active" : ""} to="/payments"><i className="feather-heart" /> <span>Distribution Revenue</span></Link>
                      </li>
                      <li>
                        <Link className={pathname === "/expenses" ? "active" : ""} to="/expenses"><i className="feather-credit-card" /> <span>Title Expenses</span></Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link onClick={this.handleClick} className={pathname === "/users" ? "active" : ""} to="/users"><i className="feather-users" /> <span>Users</span></Link>
                  </li>
                </>}
                <li className="menu-title">
                  <span>CRM</span>
                </li>
              </>}
              {archived ? (<>
                <li className="menu-title">
                  <span>Archived</span>
                </li>
                <li>
                  <Link className={pathname === "/archived-pitches" ? "active" : ""} to="/archived-pitches"><i className="feather-crosshair" /> <span>Pitches</span></Link>
                </li>
                <li>
                  <Link className={pathname === "/archived-work-orders" ? "active" : ""} to="/archived-work-orders"><i className="feather-list" /> <span>Work Orders</span></Link>
                </li>
                <li>
                  <Link className={pathname === "/archived-deals" ? "active" : ""} to="/archived-deals"><i className="feather-target" /> <span>Deals</span></Link>
                </li>
                <li>
                  <Link className={pathname === "/archived-agreements" ? "active" : ""} to="/archived-agreements"><i className="feather-paperclip" /> <span>Agreements</span></Link>
                </li>
                <li>
                  <Link className={pathname === "/archived-products" ? "active" : ""} to="/archived-products"><i className="feather-box" /> <span>Products</span></Link>
                </li>
                <li>
                  <Link onClick={this.handleClick} className={pathname === "/archived-users" ? "active" : ""} to="/archived-users"><i className="feather-package" /> <span>Users</span></Link>
                </li>
                <li>
                  <Link onClick={this.handleClick} className={pathname === "/archived-companies" ? "active" : ""} to="/archived-companies"><i className="feather-database" /> <span>Companies</span></Link>
                </li>
                <li>
                  <Link onClick={this.handleClick} className={pathname === "/archived-titles" ? "active" : ""} to="/archived-titles"><i className="feather-film" /> <span>Films</span></Link>
                </li>
              </>) : crm ? (<>
                {crm === 'film-sales' ? filmSales() : crm === 'acquisitions' ? acquisitions() : crm === 'corporate-sales' ? corporateSales() : crm === 'film-distribution' ? distribution() : ''}
              </>) : <>
                <li className="submenu">
                  <a submenu={'film-sales'} onClick={this.handleClick} className={`${[].includes(pathname) || /film-sales/.test(pathname) ? "active subdrop" : ""}`}><i className="feather-camera" /> <span> Film Sales </span> <span className="menu-arrow" /></a>
                  <ul className={"sub-menus " + ([].includes(pathname) || /film-sales/.test(pathname) ? '' : 'hidden')}>
                    {filmSales()}
                  </ul>
                </li>
                <li className="submenu">
                  <a submenu={'corporate-sales'} onClick={this.handleClick} className={`${[].includes(pathname) || /corporate-sales/.test(pathname) ? "active subdrop" : ""}`}><i className="feather-briefcase" /> <span> Corporate Sales </span> <span className="menu-arrow" /></a>
                  <ul className={"sub-menus " + ([].includes(pathname) || /corporate-sales/.test(pathname) ? '' : 'hidden')}>
                    {corporateSales()}
                  </ul>
                </li>
                <li className="submenu">
                  <a submenu={'film-distribution'} onClick={this.handleClick} className={`${[].includes(pathname) || /film-distribution/.test(pathname) ? "active subdrop" : ""}`}><i className="feather-dollar-sign" /> <span> Film Distribution </span> <span className="menu-arrow" /></a>
                  <ul className={"sub-menus " + ([].includes(pathname) || /film-distribution/.test(pathname) ? '' : 'hidden')}>
                    {distribution()}
                  </ul>
                </li>
                <li className="submenu">
                  <a submenu={'acquisitions'} onClick={this.handleClick} className={`${[].includes(pathname) || /acquisitions/.test(pathname) ? "active subdrop" : ""}`}><i className="feather-video" /> <span> Acquisitions </span> <span className="menu-arrow" /></a>
                  <ul className={"sub-menus " + ([].includes(pathname) || /acquisitions/.test(pathname) ? '' : 'hidden')}>
                    {acquisitions()}
                  </ul>
                </li>
              </>}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarNav;

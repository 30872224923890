import React, { Component } from 'react'
import Model from './model'
import Mark from '../components/helpers/mark'
import Arrow from '../components/helpers/arrow'
import FilterSelect from './filter-select'
import FilterField from './filter-field'
import Table from '../../helpers/display/table'
class ModelView extends Component {
    state = { model: null, mode: 'table', fields: [], items: [], changeName: false, addFields: false, aFields: false, chooseFilters: false }
    componentDidMount() {
        this.setState({
            model: new Model(this.props.model), fields: this.props.fields, items: this.props.titles, reducedFields: [...this.props.fields].reduce((a, b) => {
                if (!this.props.model.fields.find(u => u.name === b.name)) a.push(b)
                return a
            }, [])
        })
    }
    renderFunc = (name) => {
        if (name === 'received_total' || name === 'agreement_total') {
            return (text, record) => <>${window.currency(text)}</>
        } else if (name === 'title') {
            return (text, record) => <>{this.props.movies?.find(a => a._id === record)?.title || text}</>
        } else {
            return (text, record) => <>{text}</>
        }
    }
    sort(name) {
        if (name === 'received_total' || name === 'agreement_total' || name === 'deal_count') {
            return (a, b) => b[name] - a[name]
        } else if (name === 'title') {
            return (a, b) => {
                return window.nameCompare(a.title, b.title)
            }
        } else {
            return (a, b) => a[name] < b[name] ? -1 : a[name] === b[name] ? 0 : 1
        }
    }
    columns() {
        let d = (title, index, render, sorter) => ({ title, dataIndex: index, render: render || this.renderFunc(index), sorter: sorter || this.sort(index) })
        let k = Object.values([{ name: 'title', type: 'string' }, ...this.state.model.fields, { name: 'last_updated', type: 'date' }].map(a => ({ [a.name]: a.name })).reduce((a, b) => {
            let k = Object.keys(b)
            k.forEach((u) => {
                if (!a[u]) a[u] = d(window.parseKey(u), u)
            })
            return a
        }, {}))
        return k
    }
    render() {
        window.model = this
        return (<div className="b1">
            {this.state.model && <div className="c2">
                <h1>{this.state.model?.modelName || 'New Template'}</h1>
                <div className="b2">
                    <span className="roser" onClick={e => { this.setState({ changeName: !this.state.changeName }) }}>{this.state.changeName ? 'Close Rename' : 'Rename'}</span>
                    <span className="roser" onClick={() => this.setState({ addFields: !this.state.addFields })}>{!this.state.addFields ? 'Add Fields' : 'Close Fields'}</span>
                    <span className="roser" onClick={() => this.setState({ chooseFilters: !this.state.chooseFilters })}>{!this.state.chooseFilters ? 'Choose Filters' : 'Close Filters'}</span>
                </div>
            </div>}
            {this.state.changeName && this.state.model && <form>
                <h3>Set Template Name</h3>
                <input placeholder={'New Template'} type="text" value={this.state.model.modelName} onChange={e => {
                    this.setState({ changeName: true, model: new Model({ ...this.state.model, modelName: e.target.value }) })
                }}></input>
                <button onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ changeName: false })
                }}>Confirm</button>
            </form>}
            <div id="selectedOptions" className="b1 jfs" style={{ width: 'auto', padding: '0px 5%', minWidth: '90%', alignItems: 'flex-start', borderBottom: 'var(--border)' }}>
                {!this.state.addFields && this.state.model?.fields?.length > 0 && <div className="b1 jfs" style={{ alignItems: 'flex-start' }}>
                    <span><strong>Fields</strong></span>
                    <div className="b2 jfs wrap">{this.state.model && this.state.model.fields.map((u, i) => (<div className="b2" style={{ width: 'auto', margin: '7px' }} key={i}>
                        <span>{u.name.toUpperCase().split('_').join(' ')}</span>
                        <div className="removeField" name={u.name} style={{ cursor: 'pointer' }} onClick={e => {
                            let key = e.target.getAttribute(['name'])
                            this.state.model.removeField(key)
                            let fields = [...this.state.model.fields].filter(u => u.name !== key)
                            this.setState({
                                model: new Model({ ...this.state.model, fields }),
                                reducedFields: this.state.fields.reduce((a, b) => {
                                    if (!fields.find(u => u.name === b.name)) a.push(b)
                                    return a
                                }, [])
                            })
                        }}><Mark x={false} hover={true} onClick={window.parentClick}></Mark></div></div>))}</div></div>}
                {!this.state.chooseFilters && this.state.model?.filterFields?.length > 0 && <div className="b1 jfs" style={{ alignItems: 'flex-start' }}>
                    <span><strong>Filters</strong></span>
                    <div className="b2 jfs wrap">
                        {this.state.model.filterFields.map((u, i) => (<div className="b2 jfs" style={{ width: 'auto', margin: '7px' }} key={i}>
                            <p onClick={window.parentClick}>{u.name.split('_').join(' ').toUpperCase()} ({u.mode.name}): {u.mode.name === 'exists' ? '' : (u.mode.value || 'Undefined')}</p>
                            <div name={u.name} index={i} onClick={e => {
                                let name = e.target.getAttribute(['name'])
                                let index = e.target.getAttribute(['index'])
                                console.log('reare', name, index)
                                this.setState({ model: new Model({ ...this.state.model, filterFields: [...this.state.model.filterFields].filter((u, i) => index ? i != index : u.name !== name) }) })
                            }} className="removeField"><Mark x={false} hover={true} onClick={window.parentClick}></Mark></div></div>))}
                    </div>
                </div>}
            </div>
            {(this.state.addFields || this.state.fields?.length < 1) && <div className="b1">
                <h3>Add Fields</h3>
                <button onClick={() => this.setState({ aFields: !this.state.aFields })}>{this.state.aFields ? 'Unselect All' : 'Select All Fields'}</button>
                <div className="b2 wrap" style={{ maxWidth: '90%' }}>{this.state.fields?.length > 0 && this.state.fields.map((u, i) => {
                    return (<div key={i} className="b2 jfs" style={{ width: 'auto', padding: '7px' }}><Mark hover={false} x={(this.state.aFields || this.state.model.fields.find(a => a.name === u.name)) ? false : true} /><button type={u.type} className={"reportButton" + ((this.state.aFields || this.state.model.fields.find(a => a.name === u.name)) ? ' selectedButton' : '')} name={u.name} onClick={e => {
                        let key = e.target.getAttribute(['name'])
                        if (this.state.aFields) {
                            this.setState({ aFields: false, model: new Model({ ...this.state.model, reducedFields: [...this.state.model.fields].filter(u => u.name !== key) }) })
                        } else {
                            if (this.state.model.fields.find(u => u.name === key)) {
                                this.state.model.removeField(key)
                                this.setState({
                                    reducedFields: this.state.fields.reduce((a, b) => {
                                        if (!this.state.model.fields.find(u => u.name === b.name)) a.push(b)
                                        return a
                                    }, [])
                                })
                            } else {
                                this.state.model.addField(key, e.target.getAttribute(['type']))
                                this.setState({
                                    reducedFields: this.state.fields.reduce((a, b) => {
                                        if (!this.state.model.fields.find(u => u.name === b.name)) a.push(b)
                                        return a
                                    }, [])
                                })
                            }
                        }
                    }}>{u.name.toUpperCase().split('_').join(' ')}</button></div>)
                })}</div><button onClick={() => {
                    let s = { addFields: false }
                    if (this.state.aFields) s.model = new Model({ ...this.state.model, fields: [...this.state.fields] })
                    this.setState(s)
                }}>Accept Fields</button>
            </div>}
            {this.state.chooseFilters && this.state.model && <div className="b1" style={{ maxWidth: '90%' }}>
                <h3>Choose Filters</h3>
                {this.state.model?.filterFields?.length > 0 && <div className="b1">
                    <span>Selected</span>
                    <div className="b2wrap ">
                        {this.state.model.filterFields.map((u, i) => <FilterField index={i} name={u.name} type={u.type} mode={u.mode} remove={(name, index) => {
                            this.setState({ model: new Model({ ...this.state.model, filterFields: [...this.state.model.filterFields].filter((u, i) => typeof index !== 'undefined' ? i != index : u.name !== name) }) })
                        }} change={(name, value, index, key) => {
                            this.setState({
                                model: new Model({
                                    ...this.state.model, filterFields: [...this.state.model.filterFields].map((u, i) => {
                                        if (typeof index !== 'undefined' && i == index) {
                                            if (u.type === 'date' && u.mode.name === 'between' && key) {
                                                u.mode[key] = value
                                            } else {
                                                u.mode.value = value
                                            }
                                            return u
                                        }
                                        return u
                                    })
                                })
                            })
                        }}></FilterField>)}
                    </div>
                </div>}
                <div style={{ borderBottom: 'var(--border)', width: '60%', margin: '10px 0' }}></div>
                <span>Filters</span>
                <div className="b2 wrap">
                    {this.props.fields.filter(u => this.state.model.fields.find(a => a.name === u.name)).map((u, i) => <FilterSelect name={u.name} change={(name, filter, value, index) => {
                        let field = this.props.fields.find(u => u.name === name)
                        this.setState({ model: new Model({ ...this.state.model, filterFields: [...this.state.model.filterFields, { ...field, mode: typeof value === 'object' ? { ...value, name: filter } : { name: filter, value } }] }) })
                    }} type={u.type} value={this.state.model.filterFields.find(a => a.name === u.name)?.mode.value}></FilterSelect>)}
                </div>
                <button onClick={() => this.setState({ chooseFilters: !this.state.chooseFilters })}>Accept Filters</button>
            </div>}
            <div id="tempButs" className="bottom-left-float">
                <button onClick={e => {
                    this.state.model.saveModel()
                }}>Save Template</button>
                <button onClick={e => {
                    this.state.model.exportModel(this.state.items)
                }}>Export CSV</button>
            </div>
            {this.state.model && <>
                <h2>Titles</h2>
                <div className="rel b1">
                    <div id="fixedtoggles" className="b2 jfe" style={{ top: 0, right: 0 }}>
                        <button className={'fixedtoggle' + (this.state.mode !== 'table' ? '' : ' active')} onClick={e => this.setState({ mode: 'table' })}>Table</button>
                        <button className={'fixedtoggle' + (this.state.mode !== 'grid' ? '' : ' active')} onClick={e => this.setState({ mode: 'grid' })}>Grid</button>
                    </div>
                    {this.state.items && (this.state.mode === 'grid' ? <div className="c3" style={{ maxWidth: '80%' }}>
                        {this.state.model.filter(this.state.model.sorter(this.state.items.filter(u => this.state.model.items.includes(u._id)))).map((u, i) => (<div className="datapoint" key={i}>
                            <h3>{u.title.toUpperCase()}</h3>
                            <ul style={{ textAlign: 'left' }} key={i} id={u._id}>
                                {this.state.model.fields?.length > 0 && this.state.model.fields.map((a, i) => (<li style={{ textAlign: 'left' }} key={i}><strong>{a.name.toUpperCase().split('_').join(' ')}</strong>: {u[a.name] && (a.type === 'string' ? u[a.name] : a.type === 'number' ? u[a.name] : a.type === 'object' ? JSON.stringify(u[a.name]) : JSON.stringify(u[a.name]))}</li>))}
                            </ul>
                        </div>))}
                    </div> : <Table className="table"
                        style={{ overflowX: "auto" }}
                        columns={this.columns()}
                        data={this.state.model?.filter(this.state.model.sorter(this.state.items.filter(u => this.state.model.items.includes(u._id)))).map(u => {
                            let k = Object.keys(u)
                            let o = {}
                            for (let i = 0; i < k.length; i++) {
                                o[k[i]] = JSON.stringify(u[k[i]])
                            }
                            return o
                        })}
                        mainKey={'title'} />)}
                </div>
            </>}
        </div>)
    }
}
export default ModelView
{/* (<div className="tableContain">
                        <table className="excel">
                            <thead><tr>{[{ name: 'title', type: 'string' }, ...this.state.model.fields, { name: 'last_updated', type: 'date' }].map((u, i) => {
                                return (<th onClick={e => {
                                    let n = e.target.getAttribute(['name'])
                                    if (n === 'last_updated') n = '_u'
                                    let a = this.state.model.sortFields.find(u => u.name === n)
                                    let s = [...this.state.model.sortFields]
                                    if (a) {
                                        for (let i = 0; i < s.length; i++) {
                                            let b = s[i]
                                            if (b && b.name === n) {
                                                if (!a.direction) {
                                                    s = s.map(u => {
                                                        if (u.name === a.name) return ({ ...u, direction: true })
                                                        return u
                                                    })
                                                } else {
                                                    let o = s.splice(i, 1)
                                                }
                                                i = Infinity
                                            }
                                        }
                                    } else {
                                        if (n === '_u') {
                                            s.push({ name: '_u', type: 'date' })
                                        } else if (n === 'title') {
                                            s.push({ name: 'title', type: 'string' })
                                        } else {
                                            s.push(this.state.model.fields.find(u => u.name === n))
                                        }
                                    }
                                    this.setState({ model: new Model({ ...this.state.model, sortFields: s }), mode: 'table' })
                                }} name={u ? u.name : ''} key={i}><strong onClick={window.parentClick}>{u ? u.name : ''}</strong>{this.state.model?.sortFields.find(a => a && (a.name === (u && u.name === 'last_updated' ? '_u' : u ? u.name : ''))) ? <>({this.state.model.sortFields.map(u => (u && u.name === 'last_updated' ? '_u' : u ? u.name : '')).indexOf((u && u.name === 'last_updated' ? '_u' : u ? u.name : '')) + 1})<Arrow direction={this.state.model?.sortFields?.find(a => a && (a.name === (u && u.name === 'last_updated' ? '_u' : u ? u.name : ''))).direction ? true : false}></Arrow></> : ''}</th>)
                            })}</tr></thead>
                            <tbody>
                                {this.state.model.filter(this.state.model.sorter(this.state.items.filter(u => this.state.model.items.includes(u._id)))).map((u, i) => (<tr key={i}>
                                    <td>{u.title}</td>
                                    {this.state.model.fields?.length > 0 && this.state.model.fields.map((a, i) => (<td style={{ textAlign: 'left' }} key={i}>{(a.type === 'date' ? !isNaN(Date.parse(u[a.name])) : true) && u[a.name] && (a.type === 'string' ? u[a.name] : a.type === 'number' ? u[a.name] : a.type === 'date' ? window.formatDate(u[a.name]) : a.type === 'object' ? JSON.stringify(u[a.name]) : JSON.stringify(u[a.name]))}</td>))}
                                    <td>{u._u}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>)) */}
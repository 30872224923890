(() => {
  let cookies = (() => {
    return document.cookie?.split('; ').reduce((a, b) => {
      if (b) {
        let c = b.split('=')
        a[c[0]] = c[1]
      }
      return a
    }, {})
  })()
  let auth = localStorage.getItem('auth')
  if ((cookies.auth && auth !== cookies.auth) && cookies.userID && cookies.type) {
    localStorage.setItem('auth', cookies['auth'])
    localStorage.setItem('userID', cookies['userID'])
    localStorage.setItem('type', cookies['type'])
    console.log(`AUTO SET FRONTEND CREDS BASED ON BACK END RESPONSES`)
  }
})();
import Accordian from './accordian'
import ArrayDisplay from './arraydisplay'
import Censored from './censoredText'
import AddressDisplay from './address';
import NotesDisplay from './notesdisplay';
import TabLink from '../tablink'
function Agreement(props = {}) {
    let { type } = props
    return (<Accordian open={props.open} header="Agreement" content={(<>
        <table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Name</td>
                    <td className="border-0">{props.name}</td>
                </tr>
                {props.type && props.associate && (props.type !== 'title' ? (<>
                    <tr>
                        <td className="border-0">Type</td>
                        <td className="border-0">{window.capitalize(props.type)}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Associated To</td>
                        <td className="border-0">{(() => {
                            switch (type) {
                                case 'title': {
                                    let text = window.app.state.titles?.find(u => u._id === props.associate)?.title || props.associate
                                    return <TabLink to={`/title?_id=${props.associate}`} text={text}></TabLink>
                                }
                                case 'deal': {
                                    let text = window.app.state.deals?.find(u => u._id === props.associate)?.name || props.associate
                                    return <TabLink to={`/deal?_id=${props.associate}`} text={text}></TabLink>
                                }
                                case 'user': {
                                    let text = window.app.state.users?.find(u => u._id === props.associate)?.name || props.associate
                                    return <TabLink to={`/profile?_id=${props.associate}`} text={text}></TabLink>
                                }
                                case 'company': {
                                    let text = window.app.state.companies?.find(u => u._id === props.associate)?.name || props.associate
                                    return <TabLink to={`/company?_id=${props.associate}`} text={text}></TabLink>
                                }
                                default: return ''
                            }
                        })()}</td>
                    </tr>
                </>) : props.type === 'title' ? (<tr>
                    <td className="border-0">Associated To</td>
                    <td className="border-0">{(() => {
                        let text = window.app.state.titles?.find(u => u._id === props.associate)?.title || props.associate
                        return <TabLink to={`/title?_id=${props.associate}`} text={text}></TabLink>
                    })()}</td>
                </tr>) : <></>)}
                <tr>
                    <td className="border-0">Contact</td>
                    <td className="border-0">{props.contact && <TabLink to={`/profile?_id=${props.contact}`} text={(window.app.state.users?.find(u => u._id === props.contact)?.name || props.contact)}></TabLink>}</td>
                </tr>
                <tr>
                    <td className="border-0">Signatory</td>
                    <td className="border-0">{props.signatory && <TabLink to={`/profile?_id=${props.signatory}`} text={(window.app.state.users?.find(u => u._id === props.signatory)?.name || props.signatory)}></TabLink>}</td>
                </tr>
                <tr>
                    <td className="border-0">Company</td>
                    <td className="border-0">{props.company && <TabLink to={`/company?_id=${props.company}`} text={(window.app.state.companies?.find(u => u._id === props.company)?.name || props.company)}></TabLink>}</td>
                </tr>
                <tr>
                    <td className="border-0">Owner</td>
                    <td className="border-0">{props.owner && <TabLink to={`/profile?_id=${props.owner}`} text={(window.app.state.users?.find(u => u._id === props.owner)?.name || props.owner)}></TabLink>}</td>
                </tr>
                <tr>
                    <td className="border-0">Organization</td>
                    <td className="border-0">{props.organization}</td>
                </tr>
                {type === 'title' && <tr>
                    <td className="border-0">Assignment Rights</td>
                    <td className="border-0">{props.assignmentRights ? 'Yes' : 'No'}</td>
                </tr>}
                <tr>
                    <td className="border-0">Status</td>
                    <td className="border-0">{props.status}</td>
                </tr>
                <tr>
                    <td className="border-0">Notes</td>
                    <td className="border-0"><NotesDisplay notes={props.notes || []} /></td>
                </tr>
                <tr>
                    <td className="border-0">Termination Date</td>
                    <td className="border-0">{props.terminationDate && window.formatDate(props.terminationDate)}</td>
                </tr>
                <tr>
                    <td className="border-0">CMA</td>
                    <td className="border-0">{props.CMA ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <td className="border-0">Country of Law</td>
                    <td className="border-0">{props.countryOfLaw}</td>
                </tr>
                <tr>
                    <td className="border-0">State of Law</td>
                    <td className="border-0">{props.stateOfLaw}</td>
                </tr>
                <tr>
                    <td className="border-0">Effective Date</td>
                    <td className="border-0">{props.effectiveDate && window.formatDate(props.effectiveDate)}</td>
                </tr>
                <tr>
                    <td className="border-0">Date of Signature</td>
                    <td className="border-0">{props.dateOfSignature && window.formatDate(props.dateOfSignature)}</td>
                </tr>
                <tr>
                    <td className="border-0">Renewal Date</td>
                    <td className="border-0">{props.renewalDate && window.formatDate(props.renewalDate)}</td>
                </tr>
                <tr>
                    <td className="border-0">Distribution Term End Date</td>
                    <td className="border-0">{props.distributionTermEndDate && window.formatDate(props.distributionTermEndDate)}</td>
                </tr>
                <tr>
                    <td className="border-0">Sales Term End Date</td>
                    <td className="border-0">{props.salesTermEndDate && window.formatDate(props.salesTermEndDate)}</td>
                </tr>
                <tr>
                    <td className="border-0">Delivery Due Date</td>
                    <td className="border-0">{props.deliveryDueDate}</td>
                </tr>
                <tr>
                    <td className="border-0">Perpetuity</td>
                    <td className="border-0">{props.perpetuity ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <td className="border-0">Auto Renew</td>
                    <td className="border-0">{props.autoRenew ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <td className="border-0">Auto Renew Notes</td>
                    <td className="border-0"><NotesDisplay notes={props.autoRenewNotes || []} /></td>
                </tr>
                <tr>
                    <td className="border-0">Licensed Rights</td>
                    <td className="border-0"><ArrayDisplay items={props.licensedRights} /></td>
                </tr>
                <tr>
                    <td className="border-0">Reserved Rights</td>
                    <td className="border-0"><ArrayDisplay items={props.reservedRights} /></td>
                </tr>
                <tr>
                    <td className="border-0">Reserved Territories</td>
                    <td className="border-0"><ArrayDisplay items={props.reservedTerritories} /></td>
                </tr>
                <tr>
                    <td className="border-0">Holdbacks</td>
                    <td className="border-0"><ArrayDisplay items={props.holdbacks} /></td>
                </tr>
                <tr>
                    <td className="border-0">Holdback Date</td>
                    <td className="border-0">{props.holdbackDate && window.formatDate(props.holdbackDate)}</td>
                </tr>
                {type === 'title' && <><tr>
                    <td className="border-0">Title Change Approval</td>
                    <td className="border-0">{props.titleChangeApproval ? 'Yes' : 'No'}</td>
                </tr>
                    <tr>
                        <td className="border-0">Title Change Final</td>
                        <td className="border-0">{props.titleChangeFinal ? 'Yes' : 'No'}</td>
                    </tr></>}
                <tr>
                    <td className="border-0">Gross Corridor Percentage</td>
                    <td className="border-0">{props.grossCorridorPercentage ? `${props.grossCorridorPercentage}%` : `N/A`}</td>
                </tr>
                <tr>
                    <td className="border-0">Gross Corridor Rights</td>
                    <td className="border-0"><ArrayDisplay items={props.grossCorridorRights} /></td>
                </tr>
                <tr>
                    <td className="border-0">Additional Bonuses</td>
                    <td className="border-0"><ArrayDisplay items={props.additionalBonuses} /></td>
                </tr>
                <tr>
                    <td className="border-0">Trigger Deadline</td>
                    <td className="border-0">{props.triggerDeadline && window.formatDate(props.triggerDeadline)}</td>
                </tr>
                <tr>
                    <td className="border-0">Trigger Amount</td>
                    <td className="border-0">{props.triggerAmount && `$${window.currency(props.triggerAmount)}`}</td>
                </tr>
            </tbody>
        </table>
    </>)} />)
}
function Accounting(props = {}) {
    let { type } = props
    return (<Accordian header="Accounting" content={<>
        <table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Accounting Contact</td>
                    <td className="border-0">{props.accountingContact}</td>
                </tr>
                <tr>
                    <td className="border-0">Accounting Cycle</td>
                    <td className="border-0">{props.accountingCycle}</td>
                </tr>
                <tr>
                    <td className="border-0">Reports Due When</td>
                    <td className="border-0">{props.reportsDueWhen}</td>
                </tr>
                <tr>
                    <td className="border-0">Net Terms</td>
                    <td className="border-0">{props.netTerms}</td>
                </tr>
                <tr>
                    <td className="border-0">Flat Deal Only</td>
                    <td className="border-0">{window.bool(props.flatDealOnly)}</td>
                </tr>
                <tr>
                    <td className="border-0">QB Item Code</td>
                    <td className="border-0">{props.QBItemCode}</td>
                </tr>
                {type === 'title' && <><tr>
                    <td className="border-0">IFTA Collections Submitted</td>
                    <td className="border-0">{window.bool(props.IFTACollectionsSubmitted)}</td>
                </tr>
                    <tr>
                        <td className="border-0">MG Amount</td>
                        <td className="border-0">{props.MGAmount ? `$${window.currency(props.MGAmount)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Expense Cap Marketing</td>
                        <td className="border-0">{props.expenseCapMarketing ? `$${window.currency(props.expenseCapMarketing)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Expense Cap Sales</td>
                        <td className="border-0">{props.expenseCapSales ? `$${window.currency(props.expenseCapSales)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Expense Cap Distribution</td>
                        <td className="border-0">{props.expenseCapDistribution ? `$${window.currency(props.expenseCapDistribution)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Trailer Cost Cap</td>
                        <td className="border-0">{props.trailerCostCap ? `$${window.currency(props.trailerCostCap)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Poster Cost Cap</td>
                        <td className="border-0">{props.posterCostCap ? `$${window.currency(props.posterCostCap)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Press Cost Cap</td>
                        <td className="border-0">{props.pressCostCap ? `$${window.currency(props.pressCostCap)}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Sales Fee Percentage</td>
                        <td className="border-0">{props.salesFeePercentage ? `${props.salesFeePercentage}%` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Distribution Fee Percentage</td>
                        <td className="border-0">{props.distributionFeePercentage ? `${props.distributionFeePercentage}%` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Income Reserves Percentage</td>
                        <td className="border-0">{props.incomeReservesPercentage ? `${props.incomeReservesPercentage}%` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Payments Become Due When</td>
                        <td className="border-0">{props.paymentsBecomeDue && window.formatDate(props.paymentsBecomeDue)}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Other Expense Caps</td>
                        <td className="border-0"><ArrayDisplay items={props.otherExpenseCaps} /></td>
                    </tr>
                    <tr>
                        <td className="border-0">Accounting Notes</td>
                        <td className="border-0">
                            {props.accountingNotes && <NotesDisplay notes={props.accountingNotes} />}
                        </td>
                    </tr>
                </>}
            </tbody>
        </table>
    </>} />)
}
function PaymentInfo(props = {}) {
    return (<Accordian header={'Payment Information'} content={<table className="table">
        <tbody>
            <tr>
                <td className="border-0">Bank Name</td>
                <td className="border-0">{props.bankName}</td>
            </tr>
            <tr>
                <td className="border-0">Bank Account Name</td>
                <td className="border-0">{props.name}</td>
            </tr>
            <tr>
                <td className="border-0">Bank Account Number</td>
                <td className="border-0">{props.accountNumber && <Censored value={props.accountNumber} />}</td>
            </tr>
            <tr>
                <td className="border-0">Bank Routing Number</td>
                <td className="border-0">{props.routingNumber && <Censored value={props.routingNumber} />}</td>
            </tr>
            <tr>
                <td className="border-0">IBAN</td>
                <td className="border-0">{props.IBAN}</td>
            </tr>
            <tr>
                <td className="border-0">SIC Code</td>
                <td className="border-0">{props.SICCode}</td>
            </tr>
            <tr>
                <td className="border-0">Sort Code</td>
                <td className="border-0">{props.sortCode}</td>
            </tr>
            <tr>
                <td className="border-0">Bank Address</td>
                <td className="border-0"><AddressDisplay address={props.bankAddress} /></td>
            </tr>
            <tr>
                <td className="border-0">Billing Address</td>
                <td className="border-0"><AddressDisplay address={props.billingAddress} /></td>
            </tr>
            <tr>
                <td className="border-0">Tax ID Number</td>
                <td className="border-0">{props.taxID}</td>
            </tr>
            <tr>
                <td className="border-0">W9 or W8 BEN</td>
                <td className="border-0">{(props.W9 || props.W8BEN) && <TabLink to={window.API+(props.W9 || props.W8BEN)}></TabLink>}</td>
            </tr>
            <tr>
                <td className="border-0">VAT</td>
                <td className="border-0">{props.VAT}</td>
            </tr>
            <tr>
                <td className="border-0">Currency Type</td>
                <td className="border-0">{props.currencyType}</td>
            </tr>
        </tbody>
    </table>} />)
}
export { Agreement, Accounting, PaymentInfo }
import React, { Component } from 'react'
import ArrayInput from '../helpers/inputs/arrayinput'
import YesNo from '../helpers/inputs/yesno'
import DateInput from '../helpers/inputs/dateinput'
import Accordian from '../helpers/display/accordian'
import NotesInput from '../helpers/inputs/notesinput'
import SensitiveInput from '../helpers/inputs/sensitiveinput2'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class AgreementForm extends Component {
    state = { titles: [], dealNotes: [] }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.agreements?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile }, () => {
                if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
                if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
            })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.agreements?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile }, () => {
                    if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
                    if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id })
            }
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    getPaymentInfo = _id => {
        if (this.getPI) return
        this.getPI = true
        window.app.getPaymentInfo(_id).then(pi => {
            this.getPI = false
            this.setState({ paymentInfo: pi })
        }).catch(e => {
            this.getPI = false
            window.flash(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing || !typeof this.props.add === 'function') return
        this.setState({ subbing: true })
        this.props.add(e.target).then(r => {
            this.pending = []
            this.setState({ ...r, subbing: false }, () => {
                if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
            })
            window.flash('Agreement Saved')
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        window.agreementform = this
        let agreement = () => <Accordian open={true} header="Agreement" content={<div className="form-group row">
            <div className="col-md-6">
                <label className="col-form-label">Name</label>
                <input type="text" name="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} className="form-control" placeholder="Agreement Name" />
            </div>
            {this.state.type !== 'title' && <>
                <div className="col-md-4">
                    <label className="col-form-label">Type</label>
                    <select className="form-control" value={this.state.type} onChange={e => this.setState({ type: e.target.value })} name="type">
                        <option value="">Select One</option>
                        <option value="user">User</option>
                        <option value="company">Company</option>
                        <option value="deal">Deal</option>
                        {/* <option value="title">Title</option> */}
                    </select>
                </div>
                {this.state.type && <div className="col-md-8">
                    <label className="col-form-label">Associated To</label>
                    {this.state.type && (() => {
                        switch (this.state.type) {
                            case 'company': {
                                return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                    <option value="">Select One</option>
                                    {window.app.state.companies?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                                </select>)
                            }
                            case 'deal': {
                                return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                    <option value="">Select One</option>
                                    {window.app.state.deals?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                                </select>)
                            }
                            case 'title': {
                                return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                    <option value="">Select One</option>
                                    {window.app.state.titles?.map((u, i) => (<option key={i} value={u._id}>{u.title}</option>))}
                                </select>)
                            }
                            case 'user': {
                                return <select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                    <option value="">Select One</option>
                                    {window.app.state.users?.filter(u => !window.isInternal(u.type) && !['user', 'film'].includes(u.type)).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.type} - {u.crm || u.type}</option>))}
                                </select>
                            }
                            default: {
                                return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                    <option value="">Select One</option>
                                    {window.app.state.users?.filter(u => !window.isInternal(u.type) && !['user', 'film'].includes(u.type)).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.type} - {u.crm || u.type}</option>))}
                                </select>)
                            }
                        }
                    })()}
                </div>}
            </>}
            <div className="col-md-6">
                <label className="col-form-label">Contact</label>
                <select className="form-control" autoComplete="none" value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} name="contact">
                    <option value="">Select One</option>
                    {window.app.state.users?.filter(a => !window.isInternal(a.type) && a.crm).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.crm} - {a.type}</option>)}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Signatory Contact</label>
                <select className="form-control" autoComplete="none" value={this.state.signatory} onChange={e => this.setState({ signatory: e.target.value })} name="signatory">
                    <option value="">Select One</option>
                    {window.app.state.users?.filter(a => !window.isInternal(a.type) && a.crm).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.crm} - {a.type}</option>)}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Company</label>
                <select className="form-control" autoComplete="none" value={this.state.company} onChange={e => this.setState({ company: e.target.value })} name="company">
                    <option value="">Select One</option>
                    {window.app.state.companies?.filter(a => this.state.type === 'title' ? (a.crm === 'acquisitions') : a).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.crm}</option>)}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Agreement Owner</label>
                <select className="form-control" autoComplete="none" value={this.state.owner} onChange={e => this.setState({ owner: e.target.value })} name="owner">
                    <option value="">Select One</option>
                    {window.app.state.users?.filter(a => window.isInternal(a.type)).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.email} - {a.type}</option>)}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Organization</label>
                <select className="form-control" autoComplete="none" value={this.state.organization} onChange={e => this.setState({ organization: e.target.value })} name="organization">
                    <option value="">Select One</option>
                    <option value="HOP">HOP</option>
                    <option value="OMM">OMM</option>
                    <option value="OMS">OMS</option>
                    <option value="DM">DM</option>
                    <option value="HOS">HOS</option>
                </select>
            </div>
            {this.state.type === 'title' && <>
                <div className="col-md-6">
                    <label className="col-form-label">Assignment Rights</label>
                    <YesNo name="assignmentRights" value={this.state.assignmentRights} change={e => this.setState({ assignmentRights: e })} />
                </div>
            </>}
            <div className="col-md-6">
                <label className="col-form-label">Agreement Notes</label>
                <NotesInput name="notes" placeholder="Agreement Notes" notes={this.state.notes || []} change={e => {
                    this.handlePending(e)
                    this.setState({ notes: this.state.notes ? [...this.state.notes, e] : [e] })
                }} remove={n => {
                    this.handlePending(n)
                    this.setState({ notes: this.state.notes ? [...this.state.notes].filter(a => a._id !== n) : [] })
                }} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Status</label>
                <select className="form-control" autoComplete="none" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} name="status">
                    <option value="">Select One</option>
                    {window.agreementStatuses?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Termination Date</label>
                <DateInput name={'terminationDate'} value={this.state.terminationDate} change={e => this.setState({ terminationDate: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">CMA Involved</label>
                <YesNo value={this.state.CMA} name="CMA" change={e => this.setState({ CMA: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Country of Law</label>
                <select className="form-control" autoComplete="none" value={this.state.countryOfLaw} onChange={e => this.setState({ countryOfLaw: e.target.value })} name="countryOfLaw">
                    <option value="">Select One</option>
                    {window.countries?.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                </select>
            </div>
            <div className="col-md-4">
                <label className="col-form-label">State of Law</label>
                <select className="form-control" autoComplete="none" value={this.state.stateOfLaw} onChange={e => this.setState({ stateOfLaw: e.target.value })} name="stateOfLaw">
                    <option value="">Select One</option>
                    {window.states?.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                </select>
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Effective Date</label>
                <DateInput name="effectiveDate" value={this.state.effectiveDate} change={e => this.setState({ effectiveDate: e })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Date of Signature</label>
                <DateInput name="dateOfSignature" value={this.state.dateOfSignature} change={e => this.setState({ dateOfSignature: e })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Renewal Date</label>
                <DateInput name="renewalDate" value={this.state.renewalDate} change={e => this.setState({ renewalDate: e })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Renewal Expiration</label>
                <DateInput name="renewalExpiration" value={this.state.renewalExpiration} change={e => this.setState({ renewalExpiration: e })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Distribution Term End Date</label>
                <DateInput name="distributionTermEndDate" value={this.state.distributionTermEndDate} change={e => this.setState({ distributionTermEndDate: e })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Sales Term End Date</label>
                <DateInput name="salesTermEndDate" value={this.state.salesTermEndDate} change={e => this.setState({ salesTermEndDate: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Delivery Due Date</label>
                <DateInput name="deliveryDueDate" value={this.state.deliveryDueDate} change={e => this.setState({ deliveryDueDate: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Perpetuity</label>
                <YesNo value={this.state.perpetuity} name="perpetuity" change={e => this.setState({ perpetuity: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Auto Renew</label>
                <YesNo value={this.state.autoRenew} name="autoRenew" change={e => this.setState({ autoRenew: e })} />
            </div>
            <div className="col-md-12">
                <label className="col-form-label">Auto Renew Notes</label>
                <NotesInput
                    name="autoRenewNotes"
                    placeholder="Auto Renew Notes"
                    notes={this.state.autoRenewNotes || []}
                    change={note => {
                        this.setState({
                            autoRenewNotes: this.state.autoRenewNotes ? [...this.state.autoRenewNotes, note] : [note]
                        })
                    }}
                    remove={_id => {
                        this.setState({ autoRenewNotes: this.state.autoRenewNotes ? [...this.state.autoRenewNotes].filter(u => u._id !== _id) : [] })
                    }} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Licensed Rights</label>
                <ArrayInput name="licensedRights" type="search" items={window.licensedRights || []} selected={this.state.licensedRights || []} change={(v, licensedRights) => this.setState({ licensedRights })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Reserved Rights</label>
                <ArrayInput type="search" name="reservedRights" selected={this.state.reservedRights || []} items={window.reservedRights || []} change={(v, reservedRights) => this.setState({ reservedRights })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Reserved Territories</label>
                <ArrayInput name="reservedTerritories" selected={this.state.reservedTerritories || []} items={window.territories || []} change={(v, reservedTerritories) => this.setState({ reservedTerritories })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Holdback(s)</label>
                <ArrayInput type="text" name="holdbacks" selected={this.state.holdbacks || []} change={(v, s) => this.setState({ holdbacks: s })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Holdback Date</label>
                <DateInput name="holdbackDate" value={this.state.holdbackDate} change={d => this.setState({ holdbackDate: d })} />
            </div>
            {this.state.type === 'title' && <>
                <div className="col-md-4">
                    <label className="col-form-label">Title Change Approval</label>
                    <YesNo value={this.state.titleChangeApproval} name="titleChangeApproval" change={e => this.setState({ titleChangeApproval: e })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Title HOP Has Final Say</label>
                    <YesNo value={this.state.titleChangeFinal} name="titleChangeFinal" change={e => this.setState({ titleChangeFinal: e })} />
                </div>
            </>}
            <div className="col-md-6">
                <label className="col-form-label">Gross Corridor Percentage</label>
                <div className="input-group">
                    <input className="form-control" autoComplete="none" placeholder="N/A" type="number" min={0} value={this.state.grossCorridorPercentage?.toString()} onChange={e => this.setState({ grossCorridorPercentage: e.target.value })} name="grossCorridorPercentage" />
                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                </div>
            </div>
            {/* <div className="col-md-6">
                <label className="col-form-label">Gross Corridor Platforms</label>
                <ArrayInput type="text" name="grossCorridorPlatforms" selected={this.state.grossCorridorPlatforms || []} change={(v, s) => this.setState({ grossCorridorPlatforms: s })} />
            </div> */}
            <div className="col-md-6">
                <label className="col-form-label">Gross Corridor Rights</label>
                <ArrayInput type="text" name="grossCorridorRights" selected={this.state.grossCorridorRights || []} change={(v, s) => this.setState({ grossCorridorRights: s })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Additional Bonuses</label>
                <ArrayInput type="text" name="additionalBonuses" selected={this.state.additionalBonuses || []} change={(v, s) => this.setState({ additionalBonuses: s })} />
            </div>
        </div>} />
        let accounting = () => <Accordian header="Accounting" content={<div className="form-group row">
            <div className="col-md-6">
                <label className="col-form-label">Accounting Contact</label>
                <input autoComplete="none" type="text" className="form-control" name="accountingContact" placeholder="Accounting Contact" value={this.state.accountingContact} onChange={e => this.setState({ accountingContact: e.target.value })} />
            </div>
            <div className="col-md-6">
                <label className="col-form-label">Accounting Cycle</label>
                <select className="form-control" value={this.state.accountingCycle} onChange={e => this.setState({ accountingCycle: e.target.value })} name="accountingCycle">
                    <option value="">Select One</option>
                    {['daily', 'weekly', 'monthly', 'quarterly', 'semi-annual', 'annual', 'bi-annual'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
                </select>
            </div>
            <div className="col-md-12">
                <label className="col-form-label">Accounting Notes</label>
                <NotesInput
                    name="accountingNotes"
                    placeholder="Accounting Notes"
                    notes={this.state?.accountingNotes}
                    change={note => {
                        this.handlePending(note)
                        this.setState({ accountingNotes: (this.state.accountingNotes ? ([...this.state.accountingNotes, note]) : []) })
                    }}
                    remove={_id => {
                        this.handlePending(_id)
                        this.setState({
                            accountingNotes: [...this.state.accountingNotes].filter(a => a._id !== _id)
                        })
                    }} />
            </div>
            <div className="col-md-12">
                <label className="col-form-label">Reports Due When</label>
                <select className="form-control" value={this.state.reportsDueWhen} onChange={e => this.setState({ reportsDueWhen: e.target.value })} name="reportsDueWhen">
                    <option value="">Select One</option>
                    {['daily', 'weekly', 'monthly', 'quarterly', 'semi-annual', 'annual', 'bi-annual'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
                </select>
            </div>
            <div className="col-md-12">
                <label className="col-form-label">Net Terms</label>
                <textarea autoComplete="none" className="form-control" rows={2} name="netTerms" placeholder="Net Terms" value={this.state.netTerms} onChange={e => this.setState({ netTerms: e.target.value })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">Flat Deal Only</label>
                <YesNo name="flatDealOnly" value={this.state.flatDealOnly} change={e => this.setState({ flatDealOnly: e })} />
            </div>
            <div className="col-md-4">
                <label className="col-form-label">QB Item Code</label>
                <input className="form-control" autoComplete="none" type="text" value={this.state.QBItemCode} name="QBItemCode" onChange={e => this.setState({ QBItemCode: e.target.value })} />
            </div>
            {this.state.type === 'title' && <>
                <div className="col-md-4">
                    <label className="col-form-label">IFTA Collections Submitted</label>
                    <YesNo name="IFTACollectionsSubmitted" value={this.state.IFTACollectionsSubmitted} change={e => this.setState({ IFTACollectionsSubmitted: e })}></YesNo>
                </div>
                <div className="col-md-4">
                    <label className="col-form-label" htmlFor="MGAmount">MG Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input autoComplete="off" name="MGAmount" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.MGAmount?.toString()} onChange={e => { this.setState({ MGAmount: window.parseNumber(e.target.value) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="expenseCapSales">Sales Expense Cap</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input autoComplete="off" name="expenseCapSales" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.expenseCapSales?.toString()} onChange={e => { this.setState({ expenseCapSales: Math.round(window.parseNumber(e.target.value)) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="expenseCapMarketing">Marketing Expense Cap</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input autoComplete="off" name="expenseCapMarketing" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.expenseCapMarketing?.toString()} onChange={e => { this.setState({ expenseCapMarketing: Math.round(window.parseNumber(e.target.value)) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="expenseCapDistribution">Distribution Expense Cap</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input autoComplete="off" name="expenseCapDistribution" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.expenseCapDistribution?.toString()} onChange={e => { this.setState({ expenseCapDistribution: Math.round(window.parseNumber(e.target.value)) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="col-md-12">
                        <label className="col-form-label" htmlFor="trailerCostCap">Trailer Cost Cap</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input autoComplete="off" name="trailerCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.trailerCostCap?.toString()} onChange={e => { this.setState({ trailerCostCap: Math.round(window.parseNumber(e.target.value)) }) }} />
                            <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label className="col-form-label" htmlFor="posterCostCap">Poster Cost Cap</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input autoComplete="off" name="posterCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.posterCostCap?.toString()} onChange={e => { this.setState({ posterCostCap: Math.round(window.parseNumber(e.target.value)) }) }} />
                            <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label className="col-form-label" htmlFor="pressCostCap">Press Cost Cap</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input autoComplete="off" name="pressCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.pressCostCap?.toString()} onChange={e => { this.setState({ pressCostCap: Math.round(window.parseNumber(e.target.value)) }) }} />
                            <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>
                    <label className="col-form-label" htmlFor="salesFeePercentage">Sales Fee %</label>
                    <div className="input-group">
                        <input autoComplete="off" name="salesFeePercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.salesFeePercentage?.toString()} onChange={e => { this.setState({ salesFeePercentage: window.parseNumber(e.target.value) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="distributionFeePercentage">Distribution Fee %</label>
                    <div className="input-group">
                        <input autoComplete="off" name="distributionFeePercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.distributionFeePercentage?.toString()} onChange={e => { this.setState({ distributionFeePercentage: window.parseNumber(e.target.value) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="incomeReservesPercentage">Income Reserves %</label>
                    <div className="input-group">
                        <input autoComplete="off" name="incomeReservesPercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.incomeReservesPercentage?.toString()} onChange={e => { this.setState({ incomeReservesPercentage: window.parseNumber(e.target.value) }) }} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="paymentsBecomeDue">Payments Become Due</label>
                    <DateInput name="paymentsBecomeDue" value={this.state.paymentsBecomeDue} change={d => this.setState({ paymentsBecomeDue: d })} />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="otherExpenseCaps">Other Expense Caps</label>
                    <ArrayInput name="otherExpenseCaps" items={this.state.otherExpenseCaps} change={(a, d) => this.setState({ otherExpenseCaps: d })} />
                </div>

                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="triggerDeadline">Trigger Deadling</label>
                    <DateInput name="triggerDeadline" value={this.state.triggerDeadline} change={d => this.setState({ triggerDeadline: d })} />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label" htmlFor="triggerAmount">Trigger Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input autoComplete="off" name="triggerAmount" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.triggerAmount?.toString()} onChange={e => { this.setState({ triggerAmount: window.parseNumber(e.target.value) }) }} />
                    </div>
                </div>
            </>}
        </div>} />
        let paymentInfo = () => <Accordian header="Payment Information" content={(<>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Billing Address</label>
                    <textarea autoComplete="none" className="form-control" rows={3} name="PI-billingAddress-street" placeholder="Billing Address" value={this.state.paymentInfo?.billingAddress?.street} onChange={e => this.setState({ paymentInfo: !this.state.paymentInfo ? { billingAddress: { street: e.target.value } } : this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo, billingAddress: { ...this.state.paymentInfo.billingAddress, street: e.target.value } } : { ...this.state.paymentInfo, billingAddress: { street: e.target.value } } })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Billing Region</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-billingAddress-region" placeholder="Billing Region" value={this.state.paymentInfo?.billingAddress?.region} onChange={e => this.setState({ paymentInfo: !this.state.paymentInfo ? { billingAddress: { region: e.target.value } } : this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo, billingAddress: { ...this.state.paymentInfo.billingAddress, region: e.target.value } } : { ...this.state.paymentInfo, billingAddress: { region: e.target.value } } })} />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-4">
                    <input autoComplete="none" type="text" value={this.state.paymentInfo?.billingAddress?.city} onChange={e => this.setState({ paymentInfo: !this.state.paymentInfo ? { billingAddress: { city: e.target.value } } : this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo, billingAddress: { ...this.state.billingAddress, city: e.target.value } } : { ...this.state.paymentInfo, billingAddress: { city: e.target.value } } })} className="form-control" placeholder="Billing City" name="PI-billingAddress-city" />
                </div>
                <div className="col-md-4">
                    <select value={this.state.paymentInfo?.billingAddress?.state} name="PI-billingAddress-state" className="form-control" onChange={e => {
                        let o = this.state.paymentInfo?.billingAddress ? { ...this.state.paymentInfo?.billingAddress } : {}
                        o.state = e.target.value
                        this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: o } : { billingAddress: o } }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }}>
                        <option value="">Select a State</option>
                        {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-md-4">
                    <input autoComplete="none" type="text" value={this.state.paymentInfo?.billingAddress?.postalCode} onChange={e => this.setState({ paymentInfo: !this.state.paymentInfo ? { billingAddress: { postalCode: e.target.value } } : this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo, billingAddress: { ...this.state.paymentInfo.billingAddress, postalCode: e.target.value } } : { ...this.state.paymentInfo, billingAddress: { postalCode: e.target.value } } })} className="form-control" placeholder="Billing Postal Code" name="PI-billingAddress-postal-code" />
                </div>
            </div><div className="form-group row">
                <div className="col-md-12">
                    <select name="PI-billingAddress-country" className="form-control" value={this.state.paymentInfo?.billingAddress?.country} onChange={e => {
                        let o = this.state.paymentInfo?.billingAddress ? { ...this.state.paymentInfo?.billingAddress } : {}
                        o.country = e.target.value
                        this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: o } : { billingAddress: o } })
                    }}>
                        <option value="">Select a Country</option>
                        {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="col-form-label">Bank Account Name</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-name" placeholder="Bank Account Name" value={this.state.paymentInfo?.name} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, name: e.target.value } : { name: e.target.value } })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Bank Name</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-bankName" placeholder="Bank Name" value={this.state.paymentInfo?.bankName} onChange={e => {
                        this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankName: e.target.value } : { bankName: e.target.value } })
                    }} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Bank Account Number</label>
                    <SensitiveInput name="PI-accountNumber" placeholder="Account Number" value={this.state.paymentInfo?.accountNumber} change={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, accountNumber: e } : { accountNumber: e } })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Bank Routing Number</label>
                    <SensitiveInput name="PI-routingNumber" placeholder="Routing Number" value={this.state.paymentInfo?.routingNumber} change={e => {
                        this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, routingNumber: e } : { routingNumber: e } })
                    }} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">IBAN</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-IBAN" placeholder="IBAN" value={this.state.paymentInfo?.IBAN} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, IBAN: e.target.value } : { IBAN: e.target.value } })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">SIC Code</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-SICCode" placeholder="SIC Code" value={this.state.paymentInfo?.SICCode} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, SICCode: e.target.value } : { SICCode: e.target.value } })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">SWIFT Code</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-SWIFTCode" placeholder="SWIFT Code" value={this.state.paymentInfo?.SWIFTCode} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, SWIFTCode: e.target.value } : { SWIFTCode: e.target.value } })} />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Sort Code</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-sortCode" placeholder="Sort Code" value={this.state.paymentInfo?.sortCode} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, sortCode: e.target.value } : { sortCode: e.target.value } })} />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Bank Street Address</label>
                    <textarea autoComplete="none" className="form-control" rows={3} name="PI-bankAddress-street" placeholder="Bank Street" value={this.state.paymentInfo?.bankAddress?.street} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo.bankAddress, street: e.target.value } : { street: e.target.value } } : { bankAddress: { street: e.target.value } } })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Bank Region/Province</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-region" placeholder="Bank Region" value={this.state.paymentInfo?.bankAddress?.region} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo.bankAddress, region: e.target.value } : { region: e.target.value } } : { bankAddress: { region: e.target.value } } })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Bank City</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-city" placeholder="Bank City" value={this.state.paymentInfo?.bankAddress?.city} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo.bankAddress, city: e.target.value } : { city: e.target.value } } : { bankAddress: { city: e.target.value } } })} />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Bank State</label>
                    <select className="form-control" name="PI-bankAddress-state" value={this.state.paymentInfo?.bankAddress?.state} onChange={e => {
                        let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
                        o.bankAddress = o.bankAddress ? { ...o.bankAddress, state: e.target.value } : { state: e.target.value }
                        this.setState({ paymentInfo: o }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }}>
                        <option value="">Select Bank State</option>
                        {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Bank Postal Code</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-postalCode" placeholder="Postal Code" value={this.state.paymentInfo?.bankAddress?.postalCode} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo.bankAddress, postalCode: e.target.value } : { postalCode: e.target.value } } : { bankAddress: { postalCode: e.target.value } } })} />
                </div>
                <div className="col-md-8">
                    <label className="col-form-label">Bank Country</label>
                    <select className="form-control" name="PI-bankAddress-country" value={this.state.paymentInfo?.billingAddress?.country} onChange={e => {
                        let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
                        o.bankAddress = o.bankAddress ? { ...o.bankAddress, country: e.target.value } : { country: e.target.value }
                        this.setState({ paymentInfo: o }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }}>
                        <option value="">Select Bank Country</option>
                        {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Tax ID Number</label>
                    <SensitiveInput name="PI-taxID" value={this.state.paymentInfo?.taxID} placeholder="Tax ID" />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">W9 or W8 BEN (.pdf)</label>
                    <div className="c2" style={{ maxWidth: '80%' }}>
                        <div className="b1">
                            <span>W9</span>
                            <input autoComplete="none" type="radio" checked={this.state.paymentInfo?.W9} onChange={e => {
                                this.setState({ paymentInfo: { ...this.state.paymentInfo, W9: true, W8BEN: false } })
                            }} />
                        </div>
                        <div className="b1">
                            <span>W8BEN</span>
                            <input autoComplete="none" type="radio" checked={this.state.paymentInfo?.W8BEN} onChange={e => {
                                this.setState({ paymentInfo: { ...this.state.paymentInfo, W8BEN: true, W9: false } })
                            }} />
                        </div>
                    </div>
                    {this.state.paymentInfo?.W9 && <input accept=".pdf" type="file" className="form-control" name="PI-W9" />}
                    {this.state.paymentInfo?.W8BEN && <input accept=".pdf" type="file" className="form-control" name="PI-W8BEN" />}
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">VAT</label>
                    <input autoComplete="none" type="text" className="form-control" name="PI-VAT" placeholder="VAT" value={this.state.paymentInfo?.VAT} onChange={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, VAT: e.target.value } : { VAT: e.target.value } })} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Currency Type</label>
                    <select className="form-control" name="PI-currencyType" value={this.state.paymentInfo?.currencyType} onChange={e => {
                        let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
                        o.currencyType = e.target.value
                        this.setState({ paymentInfo: o }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }}>
                        <option value="">Select a currency type</option>
                        {window.currencyTypes?.map((u, i) => (<option key={i} value={u}>{u}</option>))}
                    </select>
                </div>

            </div>
        </>)} />
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Agreement</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    {agreement(this.state)}
                                    {accounting(this.state)}
                                    {paymentInfo(typeof this.state.paymentInfo === 'object' ? this.state.paymentInfo : {})}
                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    <input type="hidden" name="owner" value={this.state.owner} />
                                    <input type="hidden" name="crm" value={this.props.crm} />
                                    <input type="hidden" name="_id" value={this.state._id} />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default AgreementForm
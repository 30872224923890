import React, { Component } from 'react'
import DateInput from '../helpers/inputs/dateinput'
import Accordian from '../helpers/display/accordian'
import Toggle from '../helpers/inputs/toggle'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class ActionForm extends Component {
    state = { titles: [], dealNotes: [] }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.reminders.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile })
        } else if (this.props.type) {
            this.setState({ type: this.props.type })
        }
        if (this.props.date) {
            this.setState({ date: this.props.date })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.reminders.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
            }
        } else if (!this.state._id && this.props.type !== this.state.type) {
            this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
        }
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({subbing: true})
        this.props.add(e.target).then(r => {
            this.pending = []
            this.setState({ ...r, subbing: false })
            window.flash('Reminder Saved')
        }).catch(e => {
            this.setState({subbing: false})
            window.flash(e)
        })
    }
    render() {
        window.dealform = this
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Reminder</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner/><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    <Accordian open={true} header="Reminder Info" content={<div className="form-group row">
                                        <div className="col-md-12">
                                            <label className="col-form-label">Content</label>
                                            <textarea name="content" autoComplete="none" className="form-control" placeholder={'Reminder Info'} value={this.state.content} onChange={e => this.setState({ content: e.target.value })}></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Reminder Type</label>
                                            <select className="form-control" value={this.state.reminderType} onChange={e => this.setState({ reminderType: e.target.value })} name="reminderType">
                                                <option value="">Select One</option>
                                                <option value="call">Call</option>
                                                <option value="email">Email</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label">Associate To</label>
                                            <select className="form-control" value={this.state.associated} onChange={e => this.setState({ associated: e.target.value })} name="associateType">
                                                <option value="">Select One</option>
                                                <option value="user">User</option>
                                                <option value="company">Company</option>
                                                <option value="deal">Deal</option>
                                            </select>
                                        </div>
                                        <div className="col-md-8">
                                            {this.state.associated && <>
                                                <label className="col-form-label">Which {window.capitalize(this.state.associated)}?</label>
                                                {this.state.associated && (() => {
                                                    switch (this.state.associated) {
                                                        case 'company': {
                                                            return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                                                <option value="">Select One</option>
                                                                {window.app.state.companies?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                                                            </select>)
                                                        }
                                                        case 'deal': {
                                                            return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                                                <option value="">Select One</option>
                                                                {window.app.state.deals?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                                                            </select>)
                                                        }
                                                        case 'title': {
                                                            return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                                                <option value="">Select One</option>
                                                                {window.app.state.titles?.map((u, i) => (<option key={i} value={u._id}>{u.title}</option>))}
                                                            </select>)
                                                        }
                                                        case 'user': {
                                                            return <select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                                                <option value="">Select One</option>
                                                                {window.app.state.users?.filter(u => !window.isInternal(u.type) && !['user', 'film'].includes(u.type)).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.type} - {u.crm || u.type}</option>))}
                                                            </select>
                                                        }
                                                        default: {
                                                            return (<select className="form-control" value={this.state.associate} onChange={e => this.setState({ associate: e.target.value })} name="associate">
                                                                <option value="">Select One</option>
                                                                {window.app.state.users?.filter(u => !window.isInternal(u.type) && !['user', 'film'].includes(u.type)).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.type} - {u.crm || u.type}</option>))}
                                                            </select>)
                                                        }
                                                    }
                                                })()}
                                            </>}

                                        </div>
                                        <div className="col-md-8">
                                            <label className="col-form-label">Date of Follow Up</label>
                                            <DateInput name="date" refe={this.date} value={this.state.date} onChange={e => this.setState({ date: e })}></DateInput>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="b2 wrap jfe">
                                                <Toggle name={'notification'} init={false} change={e => {
                                                    this.setState({ notification: e })
                                                    if (typeof this.props.change === 'function') this.props.change(e)
                                                }}></Toggle>
                                                <label className="col-form-label">
                                                    Notification?
                                                </label>
                                            </div>
                                            <div className="b2 wrap jfe" style={{marginTop: '5px'}}>
                                                <Toggle name={'email'} init={false} change={e => {
                                                    this.setState({ email: e })
                                                    if (typeof this.props.change === 'function') this.props.change(e)
                                                }}></Toggle>
                                                <label className="col-form-label">
                                                    Email?
                                                </label>
                                            </div>
                                        </div>
                                        {window.app.state.type === 'admin' && <div className="col-md-12">
                                            <label className="col-form-label">Assign To</label>
                                            <select className="form-control" value={this.state.user} onChange={e => this.setState({ user: e.target.value })} name="user">
                                                <option value="">N/A</option>
                                                {window.app.state.users?.filter(u => window.isInternal(u.type) && u._id !== window.app.state.userID).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.email} - {u.type}</option>))}
                                            </select>
                                        </div>}
                                    </div>} />
                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    {this.state.notification && <input type="hidden" name="type" value="notification" />}
                                    <input className="hidden" defaultChecked={true} name="reminder" type="checkbox" />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default ActionForm
import React from 'react'
export default function LogIn() {
  let screenCaps = (Math.min(Math.max(220, window.innerWidth / 8), 390)) + 'px'
  return (<>
    <video id="loginVideo" src={window.API+"/static/bg.mp4"} autoPlay={true} loop={true} muted={true}></video>
    <div id="loginCont">
      <div id="loginHold">
        <h3>Log In</h3>
        <form className="b1" onSubmit={e => {
          e.preventDefault()
          let form = e.target
          let username = form['username'].value
          let password = form['password'].value
          window.app.request('/login', 'post', { email: username, password }).then(result => {
            let { auth, userID, type, redirect } = result
            localStorage.setItem('auth', auth)
            localStorage.setItem('userID', userID)
            localStorage.setItem('type', type)
            //window.app.setState({ auth, userID, type }, () => {window.app.init(); })
            window.location = redirect
          }).catch(e => {
            window.flash(e)
          })
        }}>
          <img src={window.API+"/static/white-logo.png"} alt="" style={{ maxWidth: screenCaps, width: screenCaps }}></img>
          <div className="b1">
            <span className="fable jsfs">Email Address</span>
            <input className="fancyInput" type="text" style={{ maxWidth: screenCaps, width: screenCaps }} name="username" placeholder="Username"></input>
          </div>
          <div className="b1">
            <span className="fable jsfs">Password</span>
            <div className="rel">
              <input className="fancyInput" type="password" style={{ maxWidth: screenCaps, width: screenCaps }} name="password" placeholder="Password"></input>
              <i style={{ position: 'absolute', right: '0', top: '33%', color: 'var(--p)' }} className="feather-eye" onMouseEnter={e => e.target.parentElement.querySelector('input').type = 'text'} onMouseLeave={e => e.target.parentElement.querySelector('input').type = 'password'}></i>
            </div>
            <span onClick={e => {
              window.redirect('/reset-password')
            }} style={{ fontSize: '.9rem', color: 'var(--p)', textAlign: 'right', margin: '0', marginTop: '-5px', width: '100%', padding: '0', cursor: 'pointer' }}>Forgot Password</span>
          </div>
          <button>Log In</button>
        </form>
      </div>
    </div></>)
}
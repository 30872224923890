import React, {Component} from 'react'
class Mark extends Component {
    state = { cold: false }
    render() {
        return (<img className="mark" style={this.props.onClick ? { cursor: 'pointer' } : { cursor: 'default' }} onMouseEnter={e => {
            this.setState({ cold: true })
        }} onMouseLeave={e => {
            this.setState({ cold: false })
        }} onClick={this.props.onClick} src={((this.props.hover && this.state.cold) ? !this.props.x : this.props.x) ? window.API + '/static/xmark.png' : window.API + '/static/checkmark.png'}></img>)
    }
}
export default Mark
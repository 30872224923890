import React, { Component } from 'react'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import TabLink from '../helpers/tablink'
import CountDown from '../helpers/timer'
import NotFound from '../helpers/display/notfound'
import SidePop from '../helpers/display/sidepop'
class Action extends Component {
    state = { notes: [], noteData: [] }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.reminders.find(a => a._id === this.props._id)
            if (profile) {this.setState({ ...profile, notFound: false }, () => {

            })} else {this.setState({notFound: true})}
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.reminders.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, notFound: true })
            }
        }
    }
    reload = () => {
        let profile = window.app.state.reminders.find(a => a._id === this.props._id)
        if (profile) {
            this.setState({ ...profile, notFound: false })
        } else {
            this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, notFound: true })
        }
    }
    render() {
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        window.action = this
        return (<><div className="modal right fade show" style={{ display: 'block' }} onClick={this.props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
            <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close xs-close" onClick={this.props.close} data-dismiss="modal">×</button>
                        <div className="row w-100">
                            <div className="col-md-7 account">
                                <span className="modal-title users">{this.state.name} <a onClick={this.props.edit}><i style={{ margin: '3px' }} className="fa fa-pencil"></i></a><Link to={`/action?_id=${this.state._id}`}><i style={{ margin: '3px', color: 'var(--d)' }} className="fa fa-expand"></i></Link></span>&nbsp;
                            </div>
                            <div className="col-md-5 text-right">
                                <ul className="list-unstyled list-style-none">
                                    <li className="dropdown list-inline-item"><br />
                                        <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Actions </a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item">Do Something</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow p-2 due-dates">
                        <div className="row m-0">
                            <div className="col">
                                <span>Assigned To</span>
                                <p>{window.app.state.users?.find(a => a._id === this.state.user)?.name || this.state.user}</p>
                            </div>
                            <div className="col">
                                <span>Created</span>
                                <p>{window.formatDate(this.state.created)}</p>
                            </div>
                            <div className="col">
                                <span>Follow Up In</span>
                                <p><CountDown since={true} endText={'Today'} expires={this.state.date} /></p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <Accordian open={true} header="Details" content={<>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="border-0">Type</td>
                                        <td className="border-0">{this.state.reminderType}</td>
                                    </tr>
                                    {this.state.associate && <tr>
                                        <td className="border-0">Associated To</td>
                                        <td className="border-0">{this.state.associateType} - {(() => {
                                            switch (this.state.associateType) {
                                                case 'deal': {
                                                    return <TabLink to={`/deal?_id=${this.state.associate}`}><div className="squareButton">{window.app.state.deals?.find(a => a._id === this.state.associate)?.name || 'Go To Deal'}</div></TabLink>
                                                }
                                                case 'company': {
                                                    return <TabLink to={`/company?_id=${this.state.associate}`}><div className="squareButton">{window.app.state.companies?.find(a => a._id === this.state.associate)?.name || 'Go To Company'}</div></TabLink>
                                                }
                                                case 'title': {
                                                    return <TabLink to={`/title?_id=${this.state.associate}`}><div className="squareButton">{window.app.state.titles?.find(a => a._id === this.state.associate)?.title || 'Go To Title'}</div></TabLink>
                                                }
                                                case 'user': {
                                                    return <TabLink to={`/profile?_id=${this.state.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === this.state.associate)?.name || 'Go To Profile'}</div></TabLink>
                                                }
                                                default: {
                                                    return <TabLink to={`/profile?_id=${this.state.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === this.state.associate)?.name || 'Go To Profile'}</div></TabLink>
                                                }
                                            }
                                        })()}</td>
                                    </tr>}
                                    <tr>
                                        <td className="border-0">Note</td>
                                        <td className="border-0">{this.state.content}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>} />
                        <button onClick={this.props.remove}>Delete</button>
                        {/* <div className="task-infos">
                            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                <li className="nav-item"><a className="nav-link active" href="#task-details" data-toggle="tab">Details</a></li>
                                <li className="nav-item"><a className="nav-link" href="#task-related" data-toggle="tab">Related</a></li>
                                <li className="nav-item"><a className="nav-link" href="#task-activity" data-toggle="tab">Activity</a></li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane show active" id="task-details">
                                    <div className="crms-tasks">
                                        
                                    </div>
                                </div>
                                {related()}
                                {activity()}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default Action
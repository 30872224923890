import { Component } from 'react'
import './toggle.css'
class Toggle extends Component {
    render() {
        let {tog, name, change} = this.props
        let handleChange = e => {
            e.preventDefault()
            e.stopPropagation()
            change(!tog)
        }
        return (<>
            <input type="checkbox" name={name} className="hidden" checked={tog}></input>
            <div onClick={handleChange} className="togCont rel">
                <div className="tog togNeg">

                </div>
                <div className="tog togPos">

                </div>
                <div className="toggler" style={tog ? { top: 0, left: 0 } : { top: 0, right: 0 }}></div>
            </div></>)
    }
}
export default Toggle
import React, { Component } from 'react'
import DateInput from '../helpers/inputs/dateinput'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
class RegisterFilm extends Component {
    state = {
        title: '',
        marketingExpenseCap: 0
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    render() {
        let validTitle = title => {
            return title ? true : false
        }
        let validCap = amount => {
            if (amount) return true
            return false
        }
        return (<>
            <Helmet>
                <title>Register Film</title>
                <meta name="description" content="Register a new title" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> Register a Film</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/titles">Titles</Link></li>
                        <li className="breadcrumb-item active">Register a Film</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <form>
                    <div className="form-group">
                        {/*Metadata*/}
                        <div className="card-body">
                            <h2>Film Info</h2>
                            <div className="row" style={{ margin: '10px -15px' }}>
                                <div className="col">
                                    <label htmlFor="title">Title</label>
                                    <input name="title" className={"form-control " + (this.state.title ? (validTitle(this.state.title) ? 'is-valid' : 'is-invalid') : '')} type="text" value={this.state.title} onChange={e => { this.setState({ title: e.target.value.toUpperCase() }) }}></input>
                                    <div className="invalid-feedback">
                                        Please provide a valid title name.
                                    </div>
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <h4>Producer Info</h4>
                            <div className="row">
                                <div className="b1">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Address Line 1</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Address Line 2</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>State</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Country</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Postal Code</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>Metadata</h4>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="validationServer03">Genre</label>
                                    <select className={"form-control " + (this.state.value ? ('is-valid' || 'is-invalid') : '')} value={this.state.value} onChange={e => { }}>
                                        <option value="">Select a Genre</option>
                                        {window.genres?.map((u, i) => (<option key={i} value={u}>{u.toUpperCase()}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*Contract Info*/}
                        <div className="card-body">
                            <h2>Contract Info</h2>
                            <div className="col" style={{ marginTop: '15px' }}>
                                <h4>Expense Caps</h4>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="">Marketing</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input name="" type="number" className={"form-control " + (this.state.marketingExpenseCap ? (validCap(this.state.marketingExpenseCap) ? 'is-valid' : 'is-invalid') : '')} value={this.state.marketingExpenseCap?.toString()} onChange={e => { this.setState({ marketingExpenseCap: window.parseNumber(e.target.value) }) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                            <div className="invalid-feedback">
                                                Please provide a valid city.
                                            </div>
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="">Sales</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input name="" type="number" className={"form-control " + (this.state.marketingExpenseCap ? (validCap(this.state.marketingExpenseCap) ? 'is-valid' : 'is-invalid') : '')} value={this.state.marketingExpenseCap?.toString()} onChange={e => { this.setState({ marketingExpenseCap: window.parseNumber(e.target.value) }) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                            <div className="invalid-feedback">
                                                Please provide a valid city.
                                            </div>
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="">Distribution</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input name="" type="number" className={"form-control " + (this.state.marketingExpenseCap ? (validCap(this.state.marketingExpenseCap) ? 'is-valid' : 'is-invalid') : '')} value={this.state.marketingExpenseCap?.toString()} onChange={e => { this.setState({ marketingExpenseCap: window.parseNumber(e.target.value) }) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                            <div className="invalid-feedback">
                                                Please provide a valid city.
                                            </div>
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="">Other</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input name="" type="number" className={"form-control " + (this.state.marketingExpenseCap ? (validCap(this.state.marketingExpenseCap) ? 'is-valid' : 'is-invalid') : '')} value={this.state.marketingExpenseCap?.toString()} onChange={e => { this.setState({ marketingExpenseCap: window.parseNumber(e.target.value) }) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                            <div className="invalid-feedback">
                                                Please provide a valid city.
                                            </div>
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col" style={{ marginTop: '15px' }}>
                                <h4>Octane Multimedia Take</h4>
                                <div className="input-group">
                                    <input name="" type="number" className={"form-control " + (this.state.marketingExpenseCap ? (validCap(this.state.marketingExpenseCap) ? 'is-valid' : 'is-invalid') : '')} value={this.state.marketingExpenseCap?.toString()} onChange={e => { this.setState({ marketingExpenseCap: window.parseNumber(e.target.value) }) }} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </div>
                                    <div className="invalid-feedback">
                                        Please provide a valid city.
                                    </div>
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className="col" style={{ marginTop: '15px' }}>
                                <h4>Contract Term</h4>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="startDate">Start Date</label>
                                        <DateInput value={this.state.startDate} onChange={e => this.setState({ startDate: e.target.value })} name="startDate"></DateInput>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="endDate">End Date</label>
                                        <DateInput value={this.state.endDate} onChange={e => this.setState({ endDate: e.target.value })} name="endDate"></DateInput>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check">
                                <input name="" className={"form-check-input" + (this.state.value ? ('is-valid' || 'is-invalid') : '')} type="checkbox" value={this.state.value} onChange={e => { }} />
                                <label className="form-check-label" htmlFor="">
                                    Agree to terms and conditions
                                </label>
                                <div className="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary" type="submit">Register Film</button>
                </form>
            </div>
        </>)
    }
}
export default RegisterFilm
import React, { Component } from 'react'
class Censored extends Component {
    state = { show: false }
    render() {
        let { value, hidden } = this.props
        if (!value) return ''
        return (<div>
            <span>{this.state.show ? value : (() => {
                let a = Math.min((value?.length || 5), 8)
                let s = ''
                for (let i = 0; i < a; i++) s+= '*'
                return s
            })()}   </span>
            {!hidden && <i style={{ color: 'var(--p)' }} className="feather-eye" onMouseEnter={() => this.setState({ show: true })} onMouseLeave={() => this.setState({ show: false })}></i>}
        </div>)
    }
}
export default Censored
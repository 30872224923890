import React, { Component } from 'react'
class YesNo extends Component {
    render() {
        return (<div className="form-group row">
            <input className="hidden" name={this.props.name} type="checkbox" checked={this.props.value ? true : false} onChange={e => { }} />
            <div className="col">
                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.props.value ? true : false} onChange={e => { this.props.change(e.target.checked) }} /> Yes
                    </label>
                </div>
            </div>
            <div className="col">
                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.props.value ? false : true} onChange={e => { this.props.change(e.target.checked ? false : true) }} /> No
                    </label>
                </div>
            </div>
        </div>)
    }
}
export default YesNo
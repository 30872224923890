import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "../../helpers/display/table";
import 'antd/dist/antd.css'
import "../../helpers/display/antdstyle.css"
import { Link } from "react-router-dom";
import Agreement from '../../views/agreement-view'
import Filters from '../../helpers/filters'
class Agreements extends Component {
    state = {
        showFilters: false,
        currentFilter: [],
        view: '',
        edit: false,
        changeImage: false
    }
    restore = (_id) => {
        window.app.restoreAgreement(_id).then(() => {
            this.setState({ view: '' })
        }).catch(e => window.flash(e))
    }
    render() {
        const data = this.props.agreements
        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                render: (text, record) => (
                    <>
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.setState({ view: record._id })
                        }} >{text}</a></>
                ),
                sorter: (a, b) => window.nameCompare(a.name, b.name),
            },
            {
                title: "CRM",
                dataIndex: "crm",
                render: (text, record) => (
                  <>{text}</>
                ),
                sorter: (a, b) => window.nameCompare(a.crm, b.crm),
              },
            {
                title: "Type",
                dataIndex: "type",
                render: (text, record) => (
                    <>{window.capitalize(text)}</>
                ),
                sorter: (a, b) => window.nameCompare(a.type, b.type),
            },
            {
                title: "Owner",
                dataIndex: "owner",
                render: (text, record) => (
                    <>{window.app.state.users?.find(a => a._id === record.owner)?.name || text}</>
                ),
                sorter: (a, b) => window.nameCompare(a.owner, b.owner),
            },
            {
                title: "Actions",
                dataIndex: "status",
                render: (text, record) => (
                    <div className="dropdown dropdown-action">
                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.restore(record._id)}} href="#" >Restore Agreement</a>
                            <a className="dropdown-item" onClick={(e) => { e.preventDefault(); window.sure(`Delete ${record.name}`, () => window.app.permDeleteAgreement(record._id).catch(e => window.flash(e))) }} href="#" >Delete Agreement</a>
                        </div>
                    </div>
                ),

            },
        ];
        return (
            <>
                <Helmet>
                    <title>Agreements</title>
                    <meta name="description" content="Reactify Blank Page" />
                </Helmet>
                <div className="content container-fluid">
                    <div className="page-header pt-3 mb-0 ">
                        <div className="crms-title row bg-white">
                            <div className="col">
                                <h3 className="page-title m-0">
                                    <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                        <i className="feather-database" />
                                    </span> Agreements </h3>
                            </div>
                            <div className="actionButtonGroup">

                            </div>
                            <div className="col text-right">
                                <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Agreements</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{this.state.showFilters ? 'Hide' : 'Show'} Filters</span>
                            </div>
                            <div className="col text-right">
                                <ul className="list-inline-item pl-0">
                                    <li className="list-inline-item">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                        {this.state.showFilters && <Filters list={data} change={(list, filter) => this.setState({ currentFilter: filter })} />}

                        <Table
                            className="table"
                            style={{ overflowX: "auto" }}
                            columns={columns}
                            data={window.filter(data, this.state.currentFilter || [])}
                            mainKey={(record) => record._id}
                        />
                    </div>
                </div>
                {this.state.view ? <Agreement restore={() => this.restore(this.state.view)}  _id={this.state.view} close={() => this.setState({ view: '' })}></Agreement> : ''}
            </>
        );
    }
}

export default Agreements;

import React, { Component } from 'react'
import * as JsSearch from 'js-search';
class UniversalSearchBar extends Component {
    state = {
        list: [],
        searching: ''
    }
    componentDidMount() {
        this.setState({ key: this.props.mainKey || '_id' })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    componentDidUpdate() {
        if (this.props.mainKey !== this.state.key) this.setState({ key: this.props.mainKey })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    find = (i) => {
        let s = new JsSearch.Search(this.state.key);
        s.indexStrategy = new JsSearch.PrefixIndexStrategy()
        s.addIndex(i)
        return s
    }
    search(value) {
        if (!this.props.items || this.props.items.length < 1) return []
        let a = []
        for (let i = 0; i < this.props.items.length; i++) {
            let { key, indexes, items, template } = this.props.items[i]
            let b = []
            for (let z = 0; z < indexes.length; z++) {
                let s = this.find(indexes[z])
                s.addDocuments(items)
                b.push({ key: indexes[z], results: s.search(value).filter(u => !b.find(a => a.results?.find(a => a[this.state.key] === u[this.state.key]))) })
            }
            a.push({ key, results: b, template })
        }
        if (typeof this.props.onSearch === 'function') this.props.onSearch(value)
        return a
    }
    /* [{key: Model, results: [{key: Model[index], results}]}] */
    list = (u, i) => {
        let key = u.key
        let template = u.template
        if (!u.results?.length > 0 || !u.results.find(a => a.results?.length > 0)) return <></>
        let single = u.template || ((u, i) => (<div key={i} className="b2 jsb searchResult">
            <h3>{u[this.state.key]}</h3>
            <button id={u[this.state.key]} className="smallbut" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({full: false})
                if (this.props.callback && typeof this.props.callback === 'function') this.props.callback(e.target.id, key)
            }}>VIEW</button></div>))
        return <ul className="searchList">
            <li><h3 style={{ color: 'var(--l)', margin: '5px 0' }}>{u.key}</h3></li>
            {u.results.map((u, i) => u.results?.length > 0 && (<ul className="searchSection" key={i}>
                <li><strong>{u.key}</strong></li>
                {u.results.map((a, i) => <li key={i}>{typeof template === 'function' ? template(a, i, u.key) : single(a, i)}</li>)}
            </ul>))}
        </ul>
    }
    render() {
        if (this.state.full) {
            return (<div id="fullSearch" style={{ position: 'fixed', top: '0', left: '0', zIndex: 100000, backgroundColor: 'var(--d)', width: '100%', minHeight: '100vh', maxHeight: '100vh', overflowY: 'auto' }}>
                <div className="b1 jfs rel">
                    <div id="searchbar" style={{ position: 'webkit-sticky', zIndex: 1000000, top: 0, left: 0 }}>
                        <div className="rel" style={{ width: 'auto', padding: '10px' }}>
                            <input id="searchbarInput" style={{ width: '100%', margin: '0' }} placeholder={this.props.placeholder || "Search"} type="text" autoComplete="off" value={this.state.searching} onChange={e => this.setState({ list: this.search(e.target.value), searching: e.target.value })} />
                            <i className={'fa fa-close'} onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                this.setState({ full: false })
                            }} style={{ position: 'absolute', top: '20px', zIndex: 10000001, right: '20px', cursor: 'pointer' }}></i>
                        </div>
                    </div>
                    <div className="b1 jsa">Search Results</div>
                    {this.state.list.length < 1 ? (<h3 style={{ color: 'var(--l)' }}>No Results for {this.state.searching || 'Null'}</h3>) : this.state.list.map(this.list)}
                </div>
            </div>)
        }
        return (<div id="searchbar">
            <div className="rel" style={{ width: 'auto', padding: '10px' }}>
                <input id="searchbarInput" style={{ width: '100%', margin: '0' }} placeholder={this.props.placeholder || "Search"} type="text" autoComplete="off" value={this.state.searching} onChange={e => this.setState({ list: this.search(e.target.value), searching: e.target.value })} />
                <i className={!this.state.searching ? "feather-search" : 'fa fa-close'} onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ searching: '' })
                }} style={{ position: 'absolute', top: '20px', zIndex: 1, right: '20px', cursor: 'pointer' }}></i>
            </div>
            <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                <div className="b1 jsa">
                    <span>Search Results  <i style={{ color: 'var(--l)', cursor: 'pointer' }} className={!this.state.searching ? "feather-search" : 'fa fa-expand'} onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (this.state.searching) {
                            this.setState({ full: true })
                        }
                    }}></i></span>
                </div>
                {this.state.list.length < 1 ? (<h3 style={{ color: 'var(--l)' }}>No Results for {this.state.searching || 'Null'}</h3>) : this.state.list.map(this.list)}
            </div>
        </div>)
    }
}
export default UniversalSearchBar
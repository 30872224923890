import React, { Component } from 'react'
import Film from '../components/film'
import Screener from '../components/screener'
import Report from './report.js'
class Home extends Component {
    state = {
        type: '',
        reportMode: '',
        reportType: '',
        allDate: false,
        dateA: '',
        dateB: '',
        aPlats: false,
        selectedPlatforms: [],
        selectedBuyers: [],
        selectedCompanies: [],
        selectedTerritories: [],
        aComps: false,
        aTers: false,
        aDists: false,
        aBuys: false,
        selectBuyers: false,
        selectedDistributors: [],
        aTitles: false,
        selectedTitles: [],
        genres: [],
        salesBuyers: [],
        selectGenres: false,
        selectedGenres: [],
        filters: [],
        titles: [],
        agreed: false,
        received: false,
        deals: false,
        thirdParty: false,
        published: true
    }
    componentDidMount() {
        this.setState({
            titles: this.props.movies.map(u => u._id),
            genres: [...this.props.movies].reduce((a, b) => {
                if (b.genre) for (let i = 0; i < b.genre.length; i++) if (!a.includes(b.genre[i])) a.push(b.genre[i])
                return a
            }, window.primaryGenres).filter(u => u).sort(window.nameCompare),
            salesBuyers: this.props.sales.reduce((a, b) => {
                if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                return a
            }, [])
        })
        // if (window.app) {
        //     window.app.getPayments().catch(e => window.flash(e))
        //     window.app.getTitles().then(() => {
        //     }).catch(e => window.flash(e))
        //     window.app.getSales().then(() => {
        //         this.setState({
        //         })
        //     }).catch(e => window.flash(e))
        //     window.app.getCompanies().catch(e => window.flash(e))
        // }
    }
    setSelectedTitles = () => {
        let a = this.state.filters.find(u => u.name === 'genre')
        if (a) {
            let l = a.mode.value
            this.setState({
                selectedTitles: [...this.state.selectedTitles].filter(u => {
                    let m = this.props.movies.find(a => a._id == u)
                    if (m) {
                        if (a.mode.name === 'includes') {
                            let a = false
                            for (let i = 0; i < m.genre.length; i++) if (l.includes(m.genre[i])) a = true
                            return a
                        } else {
                            let a = true
                            for (let i = 0; i < m.genre.length; i++) if (l.includes(m.genre[i])) a = false
                            return a
                        }
                    }
                }),
                filters: [...this.state.filters].map(u => {
                    if (u.name === 'title') {
                        let mode = {
                            ...u.mode, value: [...u.mode.value].filter(z => {
                                let m = this.props.movies.find(a => a._id == z)
                                if (m) {
                                    if (a.mode.name === 'includes') {
                                        let a = false
                                        for (let i = 0; i < m.genre.length; i++) if (l.includes(m.genre[i])) a = true
                                        return a
                                    } else {
                                        let a = true
                                        for (let i = 0; i < m.genre.length; i++) if (l.includes(m.genre[i])) a = false
                                        return a
                                    }
                                }
                            })
                        }
                        if (mode.value.length < 1) return null
                        let z = ({
                            ...u, mode
                        })
                        return z
                    }
                    return u
                }).filter(u => u)
            })
        }
    }
    checkThirdParty(list) {
        if (this.state.thirdParty) return list.filter(u => !u.thirdParty)
        return list
    }
    checkPublished(list) {
        if (this.state.published) return list.filter(u => u.published)
        return list
    }
    movieFilter(movies) {
        if (this.state.filters.length < 1) return movies
        return movies.filter(u => {
            let c = 0
            for (let i = c; i < this.state.filters.length; i++) {
                let filter = this.state.filters[i]
                let { name, value } = filter.mode
                switch (filter.name) {
                    case 'title': {
                        if (name === 'includes') {

                            if (value.includes(u.title)) {
                                c++
                            }
                        } else {
                            if (!value.includes(u.title)) c++
                        }
                    }
                    case 'genre': {
                        if (name === 'includes') {
                            for (let i = 0; i < u.genre.length; i++) {
                                if (value.includes(u.genre[i])) {
                                    c++
                                    i = Infinity
                                }
                            }
                        } else {
                            c++
                            for (let i = 0; i < u.genre.length; i++) {
                                if (value.includes(u.genre[i])) {
                                    c--
                                    i = Infinity
                                }
                            }
                        }
                    }
                }
            }
            return c === this.state.filters.length
        })
    }
    filterPayments(list) {
        let a = list.filter(u => {
            let c = 0
            let t = this.props.movies.find(a => a._id == u.title)
            if (t) {
                for (let i = 0; i < this.state.filters.length; i++) {
                    let filter = this.state.filters[i]
                    let { name, value } = filter.mode
                    switch (filter.name) {
                        case 'title': {
                            if (name === 'includes') {
                                if (value.includes(t._id)) {
                                    c++
                                }
                            } else {
                                if (!value.includes(t._id)) c++
                            }
                        }
                        case 'genre': {
                            if (t.genre) {
                                if (name === 'includes') {
                                    for (let z = 0; z < t.genre.length; z++) {
                                        if (value.includes(t.genre[z])) {
                                            c++
                                            z = Infinity
                                        }
                                    }
                                } else {
                                    c++
                                    for (let z = 0; z < t.genre.length; z++) {
                                        if (value.includes(t.genre[z])) {
                                            c--
                                            z = Infinity
                                        }
                                    }
                                }
                            }
                        }
                        case 'buyer': {
                            if (name === 'includes') {
                                if (value.includes(u.buyer)) c++
                            } else {
                                if (!value.includes(u.buyer)) c++
                            }
                        }
                    }
                }
            }
            return c === this.state.filters.length
        })
        return a
    }
    runReport() {
        if (!this.state.reportType) return window.flash('Invalid report type')
        switch (this.state.reportType) {
            case 'film': {
                return window.redirect('/film-report')
            }
            case 'screener': {
                return window.redirect('/screener-report')
            }
            case 'platform': {
                return this.setState({ view: 'platform' })
            }
            case 'movie': {
                return this.setState({ view: 'movie' })
            }
            case 'subdistributor': {
                return this.setState({ view: 'subdistributor' })
            }
            case 'genre': {
                return this.setState({ view: 'genre' })
            }
            case 'salesgenre': {
                return this.setState({ view: 'sales-genre' })
            }
            case 'salesbuyer': {
                return this.setState({ view: 'sales-buyer' })
            }
            case 'salesmovie': {
                return this.setState({ view: 'sales-movie' })
            }
            case 'salesterritory': {
                return this.setState({ view: 'sales-territory' })
            }
            case 'customer': {
                return window.redirect('/customer-report')
            }
        }
    }
    render() {
        window.home = this
        let genreButton = () => <>{this.state.genres && <label className="fancyLabel" onClick={e => this.setState({ selectGenres: !this.state.selectGenres })}>{!this.state.selectGenres ? 'Select Genres' : 'Close Genres'}</label>}</>
        let titleButton = () => <>{this.state.titles && <label className="fancyLabel" onClick={e => this.setState({ selectTitles: !this.state.selectTitles })}>{!this.state.selectTitles ? 'Select Titles' : 'Close titles'}</label>}</>
        let selectGenres = () => {
            return (<>
                {this.state.selectGenres && <h3 style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectGenres: !this.state.selectGenres })}>Select Genres</h3>}
                {this.state.filters.find(a => a.name === 'genre')?.mode.value?.length > 0 && <>
                    <span>Selected Genres</span>
                    <div className="b2 wrap jfs afs">
                        {this.state.filters.find(a => a.name === 'genre')?.mode.value.toString().split('"').join('').split(',').map((u, i) => (<div className="selectedItem" key={i}>{u}</div>))}
                    </div>
                </>}
                {this.state.selectGenres && <div className="b1">
                    <div className="b2 jfs wrap">
                        <label className="homelabel">All Genres</label>
                        <input className="checkInput" type="checkbox" checked={this.state.filters.find(u => u.name === 'genre')?.mode.value.length === this.state.genres.length} onChange={e => {
                            if (e.target.checked) {
                                let a = this.state.filters.find(a => a.name === 'genre')
                                if (a) {
                                    let f = [...this.state.filters].map(u => {
                                        if (u.name === 'genre') {
                                            return { ...u, mode: { ...u.mode, value: [...this.state.genres] } }
                                        }
                                        return u
                                    })
                                    this.setState({
                                        filters: f
                                    }, () => this.setSelectedTitles())
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters, { name: 'genre', type: 'array', mode: { name: 'includes', value: this.state.genres } }]
                                    }, () => this.setSelectedTitles())
                                }
                            } else {
                                this.setState({ filters: [...this.state.filters].filter(u => u.name !== 'genre') })
                            }
                        }} name="all"></input>
                    </div>
                    {this.state.genres?.length > 0 && <div className="b1">{this.state.genres.map((u, i) => u && (<div className="b2 jfs wrap" key={i} name={u}>
                        <label>{u.toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={this.state.filters.find(a => a.name === 'genre' && a.mode.value?.includes(u)) ? true : false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = this.state.filters.find(a => a.name === 'genre')
                            if (a) {
                                if (a.mode.value?.includes(name)) {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'genre' && u.mode.value?.includes(name)) {
                                                let z = u.mode.value.filter(u => u !== name)
                                                if (z.length < 1) return null
                                                return { ...u, mode: { ...u.mode, value: z } }
                                            } else {
                                                return u
                                            }
                                        }).filter(u => u)
                                    }, () => this.setSelectedTitles())
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'genre') u.mode.value = [...u.mode.value, name]
                                            return u
                                        })
                                    }, () => this.setSelectedTitles())
                                }
                            } else {
                                this.setState({
                                    filters: [...this.state.filters, { name: 'genre', type: 'array', mode: { name: 'includes', value: [name] } }]
                                }, () => this.setSelectedTitles())
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div>}
            </>)
        }
        let selectSalesBuyers = () => {
            return (<>
                {this.state.selectBuyers && <h3 style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectBuyers: !this.state.selectBuyers })}>Select Buyers</h3>}
                {this.state.filters.find(a => a.name === 'buyer')?.mode.value?.length > 0 && <>
                    <span>Selected Buyers</span>
                    <div className="b2">
                        {this.state.filters.find(a => a.name === 'buyer')?.mode.value.toString().split('"').join('').split(',').map((u, i) => (<div className="selectedItem" key={i}>{window.getCompany(u)}</div>))}
                    </div>
                </>}
                {this.state.selectBuyers && <div className="b1">
                    <div className="b2 jfs wrap">
                        <label className="homelabel">All Buyers</label>
                        <input className="checkInput" type="checkbox" checked={this.state.filters.find(u => u.name === 'buyer')?.mode.value.length === this.state.salesBuyers.length} onChange={e => {
                            if (e.target.checked) {
                                let a = this.state.filters.find(a => a.name === 'buyer')
                                if (a) {
                                    let f = [...this.state.filters].map(u => {
                                        if (u.name === 'buyer') {
                                            return { ...u, mode: { ...u.mode, value: [...this.state.salesBuyers] } }
                                        }
                                        return u
                                    })
                                    this.setState({
                                        filters: f
                                    })
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters, { name: 'buyer', type: 'array', mode: { name: 'includes', value: this.state.salesBuyers } }]
                                    })
                                }
                            } else {
                                this.setState({ filters: [...this.state.filters].filter(u => u.name !== 'buyer') })
                            }
                        }} name="all"></input>
                    </div>
                    {this.state.salesBuyers?.length > 0 && <div className="b1">{this.state.salesBuyers.sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs wrap" key={i} name={u}>
                        <label>{window.getCompany(u).toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={(this.state.filters.find(a => a.name === 'buyer' && a.mode.value?.includes(u))) ? true : false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = this.state.filters.find(a => a.name === 'buyer')
                            if (a) {
                                if (a.mode.value?.includes(name)) {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'buyer' && u.mode.value?.includes(name)) {
                                                let z = u.mode.value.filter(u => u !== name)
                                                if (z.length < 1) return null
                                                return { ...u, mode: { ...u.mode, value: z } }
                                            } else {
                                                return u
                                            }
                                        }).filter(u => u)
                                    })
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'buyer') u.mode.value = [...u.mode.value, name]
                                            return u
                                        })
                                    })
                                }
                            } else {
                                this.setState({
                                    filters: [...this.state.filters, { name: 'buyer', type: 'array', mode: { name: 'includes', value: [name] } }]
                                })
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div>}
            </>)
        }
        let selectTitles = () => {
            return (<>
                {this.state.selectTitles && <h3 style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectTitles: !this.state.selectTitles })}>Select Titles</h3>}
                {this.state.filters.find(a => a.name === 'title')?.mode.value?.length > 0 && <>
                    <span>Selected Titles</span>
                    <div className="b2">
                        {this.state.filters.find(a => a.name === 'title')?.mode.value.toString().split('"').join('').split(',').map((u, i) => (<div className="selectedItem" key={i}>{this.props.movies.find(a => a._id == u)?.title}</div>))}
                    </div>
                </>}
                {this.state.selectTitles && <div className="b1">
                    <div className="b2 jfs wrap">
                        <label className="homelabel">All Titles</label>
                        <input className="checkInput" type="checkbox" checked={this.state.filters.find(u => u.name === 'title')?.mode.value.length === this.state.titles.length} onChange={e => {
                            if (e.target.checked) {
                                let a = this.state.filters.find(a => a.name === 'title')
                                if (a) {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'title') u.mode.value = this.state.titles
                                            return u
                                        })
                                    }, () => this.setSelectedTitles())
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters, { name: 'title', type: 'array', mode: { name: 'includes', value: this.state.titles } }]
                                    }, () => this.setSelectedTitles())
                                }
                            } else {
                                this.setState({ filters: [...this.state.filters].filter(u => u.name !== 'title') })
                            }
                        }} name="all"></input>
                    </div>
                    {this.state.titles?.length > 0 && <div className="b1">{((() => {
                        let a = this.state.filters.find(u => u.name === 'genre')
                        if (!a) return this.state.titles
                        let { value, name } = a.mode
                        return this.state.titles.filter(u => {
                            let m = this.props.movies.find(a => a._id == u)
                            if (m) {
                                if (name === 'includes') {
                                    let a = false
                                    for (let i = 0; i < m.genre.length; i++) if (value.includes(m.genre[i])) a = true
                                    return a
                                } else {
                                    let a = true
                                    for (let i = 0; i < m.genre.length; i++) if (value.includes(m.genre[i])) a = false
                                    return a
                                }
                            }
                        })
                    })()).sort((a, b) => {
                        let t = this.props.movies.find(z => z._id === a)
                        let r = this.props.movies.find(z => z._id === b)
                        return window.nameCompare(t ? t.title : t, r ? r.title : r)
                    }).map((u, i) => u && (<div className="b2 jfs wrap" key={i} name={u}>
                        <label>{(this.props.movies.find(a => a._id == u)?.title)?.toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={this.state.filters.find(a => a.name === 'title' && a.mode.value?.includes(u)) ? true : false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = this.state.filters.find(a => a.name === 'title')
                            if (a) {
                                if (a.mode.value?.includes(name)) {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'title' && u.mode.value?.includes(name)) {
                                                let z = u.mode.value.filter(u => u !== name)
                                                if (z.length < 1) return null
                                                return { ...u, mode: { ...u.mode, value: z } }
                                            } else {
                                                return u
                                            }
                                        }).filter(u => u)
                                    }, () => this.setSelectedTitles())
                                } else {
                                    this.setState({
                                        filters: [...this.state.filters].map(u => {
                                            if (u.name === 'title') u.mode.value = [...u.mode.value, name]
                                            return u
                                        })
                                    }, () => this.setSelectedTitles())
                                }
                            } else {
                                this.setState({
                                    filters: [...this.state.filters, { name: 'title', type: 'array', mode: { name: 'includes', value: [name] } }]
                                }, () => this.setSelectedTitles())
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div>}
            </>)
        }
        let salesFilter = () => {
            return <>
                <span><strong>Report Options: </strong></span>
                <div className="b2 jfs wrap">
                    <label>Received Amount</label>
                    <input type="checkbox" checked={this.state.received} onChange={e => this.setState({ received: e.target.checked })}></input>
                </div>
                <div className="b2 jfs wrap">
                    <label>Agreed Amount</label>
                    <input type="checkbox" checked={this.state.agreed} onChange={e => this.setState({ agreed: e.target.checked })}></input>
                </div>
                <div className="b2 jfs wrap">
                    <label>Deal Count</label>
                    <input type="checkbox" checked={this.state.deals} onChange={e => this.setState({ deals: e.target.checked })}></input>
                </div>
            </>
        }
        let thirdParty = () => (<div className="b2 wrap jfs">
            <label>Exclude 3rd Party Data</label>
            <input type="checkbox" checked={this.state.thirdParty} onChange={e => this.setState({ thirdParty: e.target.checked })}></input>
        </div>)
        let published = () => (<div className="b2 wrap jfs">
            <label>Only Published?</label>
            <input type="checkbox" checked={this.state.published} onChange={e => this.setState({ published: e.target.checked })}></input>
        </div>)
        let buyerButton = () => <><label className="fancyLabel" onClick={e => this.setState({ selectBuyers: !this.state.selectBuyers })}>{!this.state.selectBuyers ? 'Select Buyers' : 'Close Buyers'}</label></>
        let filters = () => {
            return (<div className="b1" style={{ borderBottom: 'var(--border)', borderTop: 'var(--border)', margin: '10px 0', padding: '10px 0', width: '100%', maxWidth: '100%' }}>
                <div className="c4">
                    <div className="b1">
                        {genreButton()}
                    </div>
                    <div className="b1">
                        {titleButton()}
                    </div>
                    <div className="b1">

                    </div>
                    {(this.state.reportMode === 'sales' && this.state.reportType === 'salesterritory') ? <>
                        <div className="b1">{buyerButton()}</div>
                        <div className="b1">{salesFilter()}</div>
                    </> : this.state.reportMode === 'sales' && <div className="b1">{salesFilter()}</div>}
                </div>
                {selectGenres()}
                {selectTitles()}
                {this.state.reportMode === 'sales' && this.state.reportType === 'salesterritory' && selectSalesBuyers()}
            </div>)
        }
        if (this.state.view === 'sales-movie') return (<div className="b1 rel" style={{ paddingTop: '25px' }}><button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
            <Report type={this.state.view} deals={this.state.deals} total={this.state.received} agreed={this.state.agreed} genres={this.state.filters.find(u => u.name === 'genre')} titles={(this.state.aTitles || this.state.selectedTitles.length < 1) ? this.props.movies.map(u => u._id) : this.state.selectedTitles} companies={this.props.companies} data={this.checkPublished(this.checkThirdParty(this.filterPayments(this.props.sales)))} movies={this.movieFilter(this.props.movies)} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null} ></Report>
        </div>)
        if (this.state.view === 'sales-territory') return (<div className="b1 rel" style={{ paddingTop: '25px' }}><button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
            <Report type={this.state.view} deals={this.state.deals} buyers={(() => {
                let a = this.state.filters.find(u => u.name === 'buyer')
                return a?.mode.value
            })()} total={this.state.received} agreed={this.state.agreed} movies={this.props.movies} titles={(() => {
                let a = this.state.filters.find(u => u.name === 'title')
                return a?.mode.value
            })()} genres={(() => {
                let a = this.state.filters.find(u => u.name === 'genre')
                return a?.mode.value
            })()} territories={(this.state.aTers || this.state.selectedTerritories.length < 1) ? this.props.sales.reduce((a, b) => {
                if (b.territory && !a.includes(b.territory)) a.push(b.territory)
                return a
            }, []) : this.state.selectedTerritories} data={this.checkPublished(this.checkThirdParty((!this.state.filters || this.state.filters.length < 1) ? this.props.sales : this.filterPayments(this.props.sales)))} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null} ></Report>
        </div>)
        if (this.state.view === 'sales-buyer') {
            return (<div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
                <Report type={this.state.view} deals={this.state.deals} total={this.state.received} agreed={this.state.agreed} movies={this.props.movies} titles={(() => {
                    let a = this.state.filters.find(u => u.name === 'title')
                    return a?.mode.value
                })()} genres={(() => {
                    let a = this.state.filters.find(u => u.name === 'genre')
                    return a?.mode.value
                })()} buyers={(this.state.aBuyers || this.state.selectedBuyers.length < 1) ? this.props.sales.reduce((a, b) => {
                    if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                    return a
                }, []) : this.state.selectedBuyers} data={this.checkPublished(this.checkThirdParty((!this.state.filters || this.state.filters.length < 1) ? this.props.sales : this.filterPayments(this.props.sales)))} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null}></Report></div>);
        }
        if (this.state.view === 'sales-genre') {
            return <div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
                <Report type={this.state.view} deals={this.state.deals} total={this.state.received} agreed={this.state.agreed} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null} movies={this.props.movies} data={this.checkPublished(this.checkThirdParty(this.props.sales))} genres={this.state.selectedGenres?.length < 1 || this.state.aGens ? this.state.genres : this.state.selectedGenres}></Report>
            </div>
        }
        if (this.state.view === 'genre') {
            return <div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
                <Report type={this.state.view} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null} movies={this.props.movies} data={this.checkPublished(this.checkThirdParty(this.props.payments))} genres={this.state.selectedGenres?.length < 1 || this.state.aGens ? this.state.genres : this.state.selectedGenres}></Report>
            </div>
        } else if (this.state.view === 'platform') {
            return (<div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button><Report type={this.state.view} movies={this.props.movies} titles={(() => {
                    let a = this.state.filters.find(u => u.name === 'title')
                    return a?.mode.value
                })()} genres={(() => {
                    let a = this.state.filters.find(u => u.name === 'genre')
                    return a?.mode.value
                })()} platforms={(this.state.aPlats || this.state.selectedPlatforms.length < 1) ? this.props.payments.reduce((a, b) => {
                    if (!a.includes(b.platform)) a.push(b.platform)
                    return a
                }, []) : this.state.selectedPlatforms} data={this.checkPublished(this.checkThirdParty((!this.state.filters || this.state.filters.length < 1) ? this.props.payments : this.filterPayments(this.props.payments)))} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null}></Report></div>);
        } else if (this.state.view === 'subdistributor') {
            return (<div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button><Report distributors={(this.state.aPlats || this.state.selectedDistributors.length < 1) ? this.props.payments.reduce((a, b) => {
                    if (!a.includes(b.subdistributor)) a.push(b.subdistributor)
                    return a
                }, []) : this.state.selectedDistributors} data={this.checkPublished(this.checkThirdParty((!this.state.filters || this.state.filters.length < 1) ? this.props.payments : this.filterPayments(this.props.payments)))} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null} movies={this.props.movies} titles={(() => {
                    let a = this.state.filters.find(u => u.name === 'title')
                    return a?.mode.value
                })()} genres={(() => {
                    let a = this.state.filters.find(u => u.name === 'genre')
                    return a?.mode.value
                })()} type={this.state.view}></Report></div>);
        } else if (this.state.view === 'movie') {
            return (<div className="b1 rel" style={{ paddingTop: '25px' }}>
                <button className="top-right-abs" onClick={e => this.setState({ view: '' })}>Close</button>
                <Report type={this.state.view} genres={this.state.filters.find(u => u.name === 'genre')} titles={(this.state.aTitles || this.state.selectedTitles.length < 1) ? this.props.movies.map(u => u._id) : this.state.selectedTitles} data={this.checkPublished(this.checkThirdParty(this.filterPayments(this.props.payments)))} movies={this.movieFilter(this.props.movies)} dateRange={(this.state.dateA || this.state.dateB) ? { a: this.state.dateA, b: this.state.dateB } : null}></Report>
            </div>)
        } else {

            return (<div className="formlet rel">
                <h1>Reporting</h1>
                <select className="fI selectInput" value={this.state.type} onChange={e => this.setState({ type: e.target.value, reportType: '', reportMode: '', filters: [] })}>
                    <option className="homeoption" value="">Choose Type</option>
                    <option className="homeoption" value="sales">Sales</option>
                    <option className="homeoption" value="film">Film</option>
                    <option className="homeoption" value="customer">Customer</option>
                </select>
                {(this.state.type === 'sales') && <select className="fI selectInput" value={this.state.reportMode} onChange={e => this.setState({ reportMode: e.target.value, reportType: '' })}>
                    <option className="homeoption" value="">Choose Type</option>
                    <option className="homeoption" value="sales">International Sales</option>
                    <option className="homeoption" value="distribution">Distribution</option>
                </select>}
                {this.state.type && ((this.state.type === 'sales' && this.state.reportMode) || this.state.type !== 'sales') && <select className="fI selectInput" value={this.state.reportType} onChange={e => this.setState({ reportType: e.target.value, filters: [], selectGenres: false, selectTitles: false })}>
                    <option className="homeoption" value="">Choose Report</option>
                    {this.state.type === 'sales' ? (this.state.reportMode === 'distribution' ? <>
                        <option className="homeoption" value="platform">Sales by Platform</option>
                        <option className="homeoption" value="movie">Sales by Movie</option>
                        <option className="homeoption" value="subdistributor">Sales by Sub-Distributor</option>
                        <option className="homeoption" value="genre">Sales by Genre</option>
                    </> : this.state.reportMode === 'sales' && <>
                        <option className="homeoption" value="salesbuyer">Sales by Buyer</option>
                        <option className="homeoption" value="salesmovie">Sales by Movie</option>
                        <option className="homeoption" value="salesterritory">Sales by Territory</option>
                        <option className="homeoption" value="salesgenre">Sales by Genre</option>
                    </>) : this.state.type === 'film' ? <>
                        <option className="homeoption" value="film">Film Report</option>
                        <option className="homeoption" value="screener">Screener Report</option>
                    </> : this.state.type === 'customer' ? <>

                    </> : ''}
                </select>}
                {this.state.reportType && this.state.reportMode === 'sales' && <div className="b2 wrap jfs">
                    {thirdParty()}
                    {published()}
                </div>}
                {this.state.type === 'sales' && this.state.reportType && <div className="c2 jfs">
                    {!this.state.allDate && <><div className="b1 jfs afs">
                        <label className="homelabel">Start Date</label>
                        <input type="date" name="start" value={this.state.dateA} onChange={e => this.setState({ dateA: e.target.value })} className="fI dateInput"></input>
                    </div>
                        <div></div>
                        <div className="b1 jfs afs">
                            <label className="homelabel">End Date</label>
                            <input type="date" value={this.state.dateB} onChange={e => this.setState({ dateB: e.target.value })} name="end" className="fI dateInput"></input>
                        </div></>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Dates</label>
                        <input className="checkInput" type="checkbox" checked={(!this.state.dateA && !this.state.dateB) || this.state.allDate} onChange={e => this.setState({ allDate: e.target.checked })} name="all"></input>
                    </div>
                </div>}
                {this.state.reportMode === 'distribution' ? (<>
                    {this.state.reportType === 'platform' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        {filters()}
                        <h3>Select Platforms</h3>
                        {!this.state.aPlats && <div className="b2 jfs afs wrap">{this.state.selectedPlatforms.sort((a, b) => {
                            return window.getCompany(a).toLowerCase() < window.getCompany(b).toLowerCase() ? -1 : window.getCompany(a).toLowerCase() === window.getCompany(b).toLowerCase() ? 0 : 1
                        }).map((u, i) => <label className="selectedLabel" key={i}>{u}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Platforms</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aPlats} onChange={e => this.setState({ aPlats: e.target.checked })} name="all"></input>
                        </div>
                        {!this.state.aPlats && <div className="b1">{this.props.payments.reduce((a, b) => {
                            if (!a.includes(b.platform)) a.push(b.platform)
                            return a
                        }, []).sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{window.getCompany(u).toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedPlatforms.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedPlatforms.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedPlatforms: [...this.state.selectedPlatforms].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedPlatforms: [...this.state.selectedPlatforms, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'genre' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        <h3>Select Genres</h3>
                        {!this.state.genres && <div className="b2 jfs afs wrap">
                            {this.state.selectedGenres.sort((a, b) => {
                                return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
                            }).map((u, i) => <label className="selectedLabel" key={i}>{u}</label>)}
                        </div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Genres</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aGens} onChange={e => this.setState({ aGens: e.target.checked })} name="all"></input>
                        </div>
                        {!this.state.aGens && <div className="b1">{this.state.genres.map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{u && u.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedGenres.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedGenres.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedGenres: [...this.state.selectedGenres].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedGenres: [...this.state.selectedGenres, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'subdistributor' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        {filters()}
                        <h3>Select Sub-Distributors</h3>
                        {!this.state.aDists && <div className="b2 jfs afs wrap">{this.state.selectedDistributors.filter(u => u).sort((a, b) => {
                            return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
                        }).map((u, i) => <label className="selectedLabel" key={i}>{u}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Sub-Distributors</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aDists} onChange={e => this.setState({ aDists: e.target.checked })} name="all"></input>
                        </div>
                        {!this.state.aDists && <div className="b1">{this.props.payments.reduce((a, b) => {
                            if (b?.subdistributor && !a.includes(b.subdistributor)) a.push(b.subdistributor)
                            return a
                        }, []).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{u.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedDistributors.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedDistributors.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedDistributors: [...this.state.selectedDistributors].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedDistributors: [...this.state.selectedDistributors, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'movie' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        <div className="b2 jfs" style={{ borderBottom: 'var(--border)', margin: '10px 0' }}>
                            {genreButton()}
                        </div>
                        {selectGenres()}
                        <h3>Select Movies</h3>
                        {!this.state.aTitles && <div className="b2 jfs afs wrap">{this.state.selectedTitles.sort((a, b) => {
                            return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
                        }).map((u, i) => <label className="selectedLabel" key={i}>{this.props.movies.find(a => a._id == u)?.title.toUpperCase()}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Titles</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aTitles} onChange={e => this.setState({ aTitles: e.target.checked })} name="all"></input>
                        </div>

                        {!this.state.aTitles && <div className="b1">{((() => {
                            let c = this.props.payments.reduce((a, b) => {
                                if (!a.includes(b.title)) a.push(b.title)
                                return a
                            }, [])

                            let a = this.state.filters.find(u => u.name === 'genre')
                            if (a) {
                                c = c.filter(u => {
                                    let z = this.props.movies.find(b => b._id == u)
                                    if (z) {
                                        if (a.mode.name === 'includes') {
                                            let r = false
                                            if (z.genre) for (let i = 0; i < z.genre.length; i++) if (a.mode.value.includes(z.genre[i])) r = true
                                            return r
                                        } else {
                                            return !a.mode.value.includes(z.genre)
                                        }
                                    }
                                })
                            }
                            c = c.sort((a, b) => {
                                let t = this.props.movies.find(z => z._id === a)
                                let r = this.props.movies.find(z => z._id === b)
                                return window.nameCompare(t ? t.title : t, r ? r.title : r)
                            })
                            return c
                        })()).map((u, i) => (<div className="b2 jfs" key={i} name={u}>
                            <label>{this.props.movies.find(a => a._id == u)?.title.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedTitles.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedTitles.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedTitles: [...this.state.selectedTitles].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedTitles: [...this.state.selectedTitles, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                </>) : this.state.reportMode === 'sales' ? <>
                    {this.state.reportType === 'salesbuyer' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        {filters()}
                        <h3>Select Buyers</h3>
                        {!this.state.aPlats && <div className="b2 jfs afs wrap">{this.state.selectedBuyers.sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && <label className="selectedLabel" key={i}>{window.getCompany(u).toUpperCase()}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Buyers</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aPlats} onChange={e => this.setState({ aPlats: e.target.checked })} name="all"></input>
                        </div>
                        {!this.state.aPlats && <div className="b1">{this.props.sales.reduce((a, b) => {
                            if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                            return a
                        }, []).sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{window.getCompany(u).toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedBuyers.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedBuyers.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedBuyers: [...this.state.selectedBuyers].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedBuyers: [...this.state.selectedBuyers, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'salesterritory' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        {filters()}
                        <h3>Select Territories</h3>
                        {!this.state.aTers && <div className="b2 jfs afs wrap">{this.state.selectedTerritories.sort(window.nameCompare).map((u, i) => <label className="selectedLabel" key={i}>{u.toUpperCase()}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Territories</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aTers} onChange={e => this.setState({ aTers: e.target.checked })} name="all"></input>
                        </div>
                        {!this.state.aTers && <div className="b1">{this.props.sales.reduce((a, b) => {
                            if (b.territory && !a.includes(b.territory)) a.push(b.territory)
                            return a
                        }, []).sort(window.nameCompare).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{u.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedTerritories.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedTerritories.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedTerritories: [...this.state.selectedTerritories].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedTerritories: [...this.state.selectedTerritories, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'salesgenre' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        <h3>Select Genres</h3>
                        {!this.state.genres && <div className="b2 jfs afs wrap">{this.state.selectedGenres.sort((a, b) => {
                            return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
                        }).map((u, i) => <label className="selectedLabel" key={i}>{u}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Genres</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aGens} onChange={e => this.setState({ aGens: e.target.checked })} name="all"></input>
                        </div>
                        <div className="b1">{salesFilter()}</div>
                        {!this.state.aGens && <div className="b1">{this.state.genres.map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                            <label>{u && u.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedGenres.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedGenres.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedGenres: [...this.state.selectedGenres].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedGenres: [...this.state.selectedGenres, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                    {this.state.reportType === 'salesmovie' && <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                        <div className="b1 afs jfs">{salesFilter()}</div>
                        <div className="b2 jfs" style={{ borderBottom: 'var(--border)', margin: '10px 0' }}>
                            {genreButton()}
                        </div>
                        {selectGenres()}
                        <h3>Select Movies</h3>
                        {!this.state.aTitles && <div className="b2 jfs afs wrap">{this.state.selectedTitles.sort((a, b) => {
                            return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
                        }).map((u, i) => <label className="selectedLabel" key={i}>{this.props.movies.find(a => a._id == u)?.title.toUpperCase()}</label>)}</div>}
                        <div className="b2 jfs">
                            <label className="homelabel">All Titles</label>
                            <input className="checkInput" type="checkbox" checked={this.state.aTitles} onChange={e => this.setState({ aTitles: e.target.checked })} name="all"></input>
                        </div>

                        {!this.state.aTitles && <div className="b1">{((() => {
                            let c = this.props.sales.reduce((a, b) => {
                                if (!a.includes(b.title)) a.push(b.title)
                                return a
                            }, [])

                            let a = this.state.filters.find(u => u.name === 'genre')
                            if (a) {
                                c = c.filter(u => {
                                    let z = this.props.movies.find(b => b._id == u)
                                    if (z) {
                                        if (a.mode.name === 'includes') {
                                            let r = false
                                            if (z.genre) for (let i = 0; i < z.genre.length; i++) if (a.mode.value.includes(z.genre[i])) r = true
                                            return r
                                        } else {
                                            return !a.mode.value.includes(z.genre)
                                        }
                                    }
                                })
                            }
                            c = c.sort((a, b) => {
                                let t = this.props.movies.find(z => z._id === a)
                                let r = this.props.movies.find(z => z._id === b)
                                return window.nameCompare(t ? t.title : t, r ? r.title : r)
                            })
                            return c
                        })()).map((u, i) => (<div className="b2 jfs" key={i} name={u}>
                            <label>{this.props.movies.find(a => a._id == u)?.title.toUpperCase()}</label>
                            <input className="checkInput" type="checkbox" checked={this.state.selectedTitles.find(a => a === u) || false} onChange={e => {
                                let name = e.target.getAttribute(['name'])
                                if (!name) return
                                let a = this.state.selectedTitles.find(u => u === name)
                                if (a) {
                                    this.setState({ selectedTitles: [...this.state.selectedTitles].filter(u => u !== name) })
                                } else {
                                    this.setState({ selectedTitles: [...this.state.selectedTitles, name] })
                                }
                            }} name={u}></input>
                        </div>))}
                        </div>}
                        <button style={{ marginTop: '7vh' }} onClick={() => this.runReport()}>Run Report</button>
                    </div>}
                </> : ""}
                {this.state.reportType === 'screener' && <Screener movies={this.props.movies} fields={this.props.fields} models={this.props.models}></Screener>}
                {this.state.reportType === 'film' && <Film movies={this.props.movies} fields={this.props.fields?.filter(u => !window.unselectable?.includes(u.name))} models={this.props.models}></Film>}
            </div>)
        }
    }
}
export default Home
window.languages = [
    {
        "name": "Abkhaz",
        "code": "ab",
        "local": "Аҧсуа"
    },
    {
        "name": "Afar",
        "code": "aa",
        "local": "Afaraf"
    },
    {
        "name": "Afrikaans",
        "code": "af",
        "local": "Afrikaans"
    },
    {
        "name": "Akan",
        "code": "ak",
        "local": "Akan"
    },
    {
        "name": "Albanian",
        "code": "sq",
        "local": "Shqip"
    },
    {
        "name": "Amharic",
        "code": "am",
        "local": "አማርኛ"
    },
    {
        "name": "Arabic",
        "code": "ar",
        "local": "العربية"
    },
    {
        "name": "Aragonese",
        "code": "an",
        "local": "Aragonés"
    },
    {
        "name": "Armenian",
        "code": "hy",
        "local": "Հայերեն"
    },
    {
        "name": "Assamese",
        "code": "as",
        "local": "অসমীয়া"
    },
    {
        "name": "Avaric",
        "code": "av",
        "local": "Авар"
    },
    {
        "name": "Avestan",
        "code": "ae",
        "local": "avesta"
    },
    {
        "name": "Aymara",
        "code": "ay",
        "local": "Aymar"
    },
    {
        "name": "Azerbaijani",
        "code": "az",
        "local": "Azərbaycanca"
    },
    {
        "name": "Bambara",
        "code": "bm",
        "local": "Bamanankan"
    },
    {
        "name": "Bashkir",
        "code": "ba",
        "local": "Башҡортса"
    },
    {
        "name": "Basque",
        "code": "eu",
        "local": "Euskara"
    },
    {
        "name": "Belarusian",
        "code": "be",
        "local": "Беларуская"
    },
    {
        "name": "Bengali",
        "code": "bn",
        "local": "বাংলা"
    },
    {
        "name": "Bihari",
        "code": "bh",
        "local": "भोजपुरी"
    },
    {
        "name": "Bislama",
        "code": "bi",
        "local": "Bislama"
    },
    {
        "name": "Bosnian",
        "code": "bs",
        "local": "Bosanski"
    },
    {
        "name": "Breton",
        "code": "br",
        "local": "Brezhoneg"
    },
    {
        "name": "Bulgarian",
        "code": "bg",
        "local": "Български"
    },
    {
        "name": "Burmese",
        "code": "my",
        "local": "မြန်မာဘာသာ"
    },
    {
        "name": "Catalan",
        "code": "ca",
        "local": "Català"
    },
    {
        "name": "Chamorro",
        "code": "ch",
        "local": "Chamoru"
    },
    {
        "name": "Chechen",
        "code": "ce",
        "local": "Нохчийн"
    },
    {
        "name": "Chichewa",
        "code": "ny",
        "local": "Chichewa"
    },
    {
        "name": "Chinese",
        "code": "zh",
        "local": "中文"
    },
    {
        "name": "Chuvash",
        "code": "cv",
        "local": "Чӑвашла"
    },
    {
        "name": "Cornish",
        "code": "kw",
        "local": "Kernewek"
    },
    {
        "name": "Corsican",
        "code": "co",
        "local": "Corsu"
    },
    {
        "name": "Cree",
        "code": "cr",
        "local": "ᓀᐦᐃᔭᐍᐏᐣ"
    },
    {
        "name": "Croatian",
        "code": "hr",
        "local": "Hrvatski"
    },
    {
        "name": "Czech",
        "code": "cs",
        "local": "Čeština"
    },
    {
        "name": "Danish",
        "code": "da",
        "local": "Dansk"
    },
    {
        "name": "Divehi",
        "code": "dv",
        "local": "Divehi"
    },
    {
        "name": "Dutch",
        "code": "nl",
        "local": "Nederlands"
    },
    {
        "name": "Dzongkha",
        "code": "dz",
        "local": "རྫོང་ཁ"
    },
    {
        "name": "English",
        "code": "en",
        "local": "English"
    },
    {
        "name": "Esperanto",
        "code": "eo",
        "local": "Esperanto"
    },
    {
        "name": "Estonian",
        "code": "et",
        "local": "Eesti"
    },
    {
        "name": "Ewe",
        "code": "ee",
        "local": "Eʋegbe"
    },
    {
        "name": "Faroese",
        "code": "fo",
        "local": "Føroyskt"
    },
    {
        "name": "Fijian",
        "code": "fj",
        "local": "Na Vosa Vaka-Viti"
    },
    {
        "name": "Finnish",
        "code": "fi",
        "local": "Suomi"
    },
    {
        "name": "French",
        "code": "fr",
        "local": "Français"
    },
    {
        "name": "Fula",
        "code": "ff",
        "local": "Fulfulde"
    },
    {
        "name": "Galician",
        "code": "gl",
        "local": "Galego"
    },
    {
        "name": "Georgian",
        "code": "ka",
        "local": "ქართული"
    },
    {
        "name": "German",
        "code": "de",
        "local": "Deutsch"
    },
    {
        "name": "Greek",
        "code": "el",
        "local": "Ελληνικά"
    },
    {
        "name": "Guaraní",
        "code": "gn",
        "local": "Avañe'ẽ"
    },
    {
        "name": "Gujarati",
        "code": "gu",
        "local": "ગુજરાતી"
    },
    {
        "name": "Haitian",
        "code": "ht",
        "local": "Kreyòl Ayisyen"
    },
    {
        "name": "Hausa",
        "code": "ha",
        "local": "هَوُسَ"
    },
    {
        "name": "Hebrew",
        "code": "he",
        "local": "עברית"
    },
    {
        "name": "Herero",
        "code": "hz",
        "local": "Otjiherero"
    },
    {
        "name": "Hindi",
        "code": "hi",
        "local": "हिन्दी"
    },
    {
        "name": "Hiri Motu",
        "code": "ho",
        "local": "Hiri Motu"
    },
    {
        "name": "Hungarian",
        "code": "hu",
        "local": "Magyar"
    },
    {
        "name": "Interlingua",
        "code": "ia",
        "local": "Interlingua"
    },
    {
        "name": "Indonesian",
        "code": "id",
        "local": "Bahasa Indonesia"
    },
    {
        "name": "Interlingue",
        "code": "ie",
        "local": "Interlingue"
    },
    {
        "name": "Irish",
        "code": "ga",
        "local": "Gaeilge"
    },
    {
        "name": "Igbo",
        "code": "ig",
        "local": "Igbo"
    },
    {
        "name": "Inupiaq",
        "code": "ik",
        "local": "Iñupiak"
    },
    {
        "name": "Ido",
        "code": "io",
        "local": "Ido"
    },
    {
        "name": "Icelandic",
        "code": "is",
        "local": "Íslenska"
    },
    {
        "name": "Italian",
        "code": "it",
        "local": "Italiano"
    },
    {
        "name": "Inuktitut",
        "code": "iu",
        "local": "ᐃᓄᒃᑎᑐᑦ"
    },
    {
        "name": "Japanese",
        "code": "ja",
        "local": "日本語"
    },
    {
        "name": "Javanese",
        "code": "jv",
        "local": "Basa Jawa"
    },
    {
        "name": "Kalaallisut",
        "code": "kl",
        "local": "Kalaallisut"
    },
    {
        "name": "Kannada",
        "code": "kn",
        "local": "ಕನ್ನಡ"
    },
    {
        "name": "Kanuri",
        "code": "kr",
        "local": "Kanuri"
    },
    {
        "name": "Kashmiri",
        "code": "ks",
        "local": "كشميري"
    },
    {
        "name": "Kazakh",
        "code": "kk",
        "local": "Қазақша"
    },
    {
        "name": "Khmer",
        "code": "km",
        "local": "ភាសាខ្មែរ"
    },
    {
        "name": "Kikuyu",
        "code": "ki",
        "local": "Gĩkũyũ"
    },
    {
        "name": "Kinyarwanda",
        "code": "rw",
        "local": "Kinyarwanda"
    },
    {
        "name": "Kyrgyz",
        "code": "ky",
        "local": "Кыргызча"
    },
    {
        "name": "Komi",
        "code": "kv",
        "local": "Коми"
    },
    {
        "name": "Kongo",
        "code": "kg",
        "local": "Kongo"
    },
    {
        "name": "Korean",
        "code": "ko",
        "local": "한국어"
    },
    {
        "name": "Kurdish",
        "code": "ku",
        "local": "Kurdî"
    },
    {
        "name": "Kwanyama",
        "code": "kj",
        "local": "Kuanyama"
    },
    {
        "name": "Latin",
        "code": "la",
        "local": "Latina"
    },
    {
        "name": "Luxembourgish",
        "code": "lb",
        "local": "Lëtzebuergesch"
    },
    {
        "name": "Ganda",
        "code": "lg",
        "local": "Luganda"
    },
    {
        "name": "Limburgish",
        "code": "li",
        "local": "Limburgs"
    },
    {
        "name": "Lingala",
        "code": "ln",
        "local": "Lingála"
    },
    {
        "name": "Lao",
        "code": "lo",
        "local": "ພາສາລາວ"
    },
    {
        "name": "Lithuanian",
        "code": "lt",
        "local": "Lietuvių"
    },
    {
        "name": "Luba-Katanga",
        "code": "lu",
        "local": "Tshiluba"
    },
    {
        "name": "Latvian",
        "code": "lv",
        "local": "Latviešu"
    },
    {
        "name": "Manx",
        "code": "gv",
        "local": "Gaelg"
    },
    {
        "name": "Macedonian",
        "code": "mk",
        "local": "Македонски"
    },
    {
        "name": "Malagasy",
        "code": "mg",
        "local": "Malagasy"
    },
    {
        "name": "Malay",
        "code": "ms",
        "local": "Bahasa Melayu"
    },
    {
        "name": "Malayalam",
        "code": "ml",
        "local": "മലയാളം"
    },
    {
        "name": "Maltese",
        "code": "mt",
        "local": "Malti"
    },
    {
        "name": "Māori",
        "code": "mi",
        "local": "Māori"
    },
    {
        "name": "Marathi",
        "code": "mr",
        "local": "मराठी"
    },
    {
        "name": "Marshallese",
        "code": "mh",
        "local": "Kajin M̧ajeļ"
    },
    {
        "name": "Mongolian",
        "code": "mn",
        "local": "Монгол"
    },
    {
        "name": "Nauru",
        "code": "na",
        "local": "Dorerin Naoero"
    },
    {
        "name": "Navajo",
        "code": "nv",
        "local": "Diné Bizaad"
    },
    {
        "name": "Northern Ndebele",
        "code": "nd",
        "local": "isiNdebele"
    },
    {
        "name": "Nepali",
        "code": "ne",
        "local": "नेपाली"
    },
    {
        "name": "Ndonga",
        "code": "ng",
        "local": "Owambo"
    },
    {
        "name": "Norwegian Bokmål",
        "code": "nb",
        "local": "Norsk (Bokmål)"
    },
    {
        "name": "Norwegian Nynorsk",
        "code": "nn",
        "local": "Norsk (Nynorsk)"
    },
    {
        "name": "Norwegian",
        "code": "no",
        "local": "Norsk"
    },
    {
        "name": "Nuosu",
        "code": "ii",
        "local": "ꆈꌠ꒿ Nuosuhxop"
    },
    {
        "name": "Southern Ndebele",
        "code": "nr",
        "local": "isiNdebele"
    },
    {
        "name": "Occitan",
        "code": "oc",
        "local": "Occitan"
    },
    {
        "name": "Ojibwe",
        "code": "oj",
        "local": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    },
    {
        "name": "Old Church Slavonic",
        "code": "cu",
        "local": "Словѣ́ньскъ"
    },
    {
        "name": "Oromo",
        "code": "om",
        "local": "Afaan Oromoo"
    },
    {
        "name": "Oriya",
        "code": "or",
        "local": "ଓଡି଼ଆ"
    },
    {
        "name": "Ossetian",
        "code": "os",
        "local": "Ирон æвзаг"
    },
    {
        "name": "Panjabi",
        "code": "pa",
        "local": "ਪੰਜਾਬੀ"
    },
    {
        "name": "Pāli",
        "code": "pi",
        "local": "पाऴि"
    },
    {
        "name": "Persian",
        "code": "fa",
        "local": "فارسی"
    },
    {
        "name": "Polish",
        "code": "pl",
        "local": "Polski"
    },
    {
        "name": "Pashto",
        "code": "ps",
        "local": "پښتو"
    },
    {
        "name": "Portuguese",
        "code": "pt",
        "local": "Português"
    },
    {
        "name": "Quechua",
        "code": "qu",
        "local": "Runa Simi"
    },
    {
        "name": "Romansh",
        "code": "rm",
        "local": "Rumantsch"
    },
    {
        "name": "Kirundi",
        "code": "rn",
        "local": "Kirundi"
    },
    {
        "name": "Romanian",
        "code": "ro",
        "local": "Română"
    },
    {
        "name": "Russian",
        "code": "ru",
        "local": "Русский"
    },
    {
        "name": "Sanskrit",
        "code": "sa",
        "local": "संस्कृतम्"
    },
    {
        "name": "Sardinian",
        "code": "sc",
        "local": "Sardu"
    },
    {
        "name": "Sindhi",
        "code": "sd",
        "local": "سنڌي‎"
    },
    {
        "name": "Northern Sami",
        "code": "se",
        "local": "Sámegiella"
    },
    {
        "name": "Samoan",
        "code": "sm",
        "local": "Gagana Sāmoa"
    },
    {
        "name": "Sango",
        "code": "sg",
        "local": "Sängö"
    },
    {
        "name": "Serbian",
        "code": "sr",
        "local": "Српски"
    },
    {
        "name": "Gaelic",
        "code": "gd",
        "local": "Gàidhlig"
    },
    {
        "name": "Shona",
        "code": "sn",
        "local": "ChiShona"
    },
    {
        "name": "Sinhala",
        "code": "si",
        "local": "සිංහල"
    },
    {
        "name": "Slovak",
        "code": "sk",
        "local": "Slovenčina"
    },
    {
        "name": "Slovene",
        "code": "sl",
        "local": "Slovenščina"
    },
    {
        "name": "Somali",
        "code": "so",
        "local": "Soomaaliga"
    },
    {
        "name": "Southern Sotho",
        "code": "st",
        "local": "Sesotho"
    },
    {
        "name": "Spanish",
        "code": "es",
        "local": "Español"
    },
    {
        "name": "Sundanese",
        "code": "su",
        "local": "Basa Sunda"
    },
    {
        "name": "Swahili",
        "code": "sw",
        "local": "Kiswahili"
    },
    {
        "name": "Swati",
        "code": "ss",
        "local": "SiSwati"
    },
    {
        "name": "Swedish",
        "code": "sv",
        "local": "Svenska"
    },
    {
        "name": "Tamil",
        "code": "ta",
        "local": "தமிழ்"
    },
    {
        "name": "Telugu",
        "code": "te",
        "local": "తెలుగు"
    },
    {
        "name": "Tajik",
        "code": "tg",
        "local": "Тоҷикӣ"
    },
    {
        "name": "Thai",
        "code": "th",
        "local": "ภาษาไทย"
    },
    {
        "name": "Tigrinya",
        "code": "ti",
        "local": "ትግርኛ"
    },
    {
        "name": "Tibetan Standard",
        "code": "bo",
        "local": "བོད་ཡིག"
    },
    {
        "name": "Turkmen",
        "code": "tk",
        "local": "Türkmençe"
    },
    {
        "name": "Tagalog",
        "code": "tl",
        "local": "Tagalog"
    },
    {
        "name": "Tswana",
        "code": "tn",
        "local": "Setswana"
    },
    {
        "name": "Tonga",
        "code": "to",
        "local": "faka Tonga"
    },
    {
        "name": "Turkish",
        "code": "tr",
        "local": "Türkçe"
    },
    {
        "name": "Tsonga",
        "code": "ts",
        "local": "Xitsonga"
    },
    {
        "name": "Tatar",
        "code": "tt",
        "local": "Татарча"
    },
    {
        "name": "Twi",
        "code": "tw",
        "local": "Twi"
    },
    {
        "name": "Tahitian",
        "code": "ty",
        "local": "Reo Mā’ohi"
    },
    {
        "name": "Uyghur",
        "code": "ug",
        "local": "ئۇيغۇرچه"
    },
    {
        "name": "Ukrainian",
        "code": "uk",
        "local": "Українська"
    },
    {
        "name": "Urdu",
        "code": "ur",
        "local": "اردو"
    },
    {
        "name": "Uzbek",
        "code": "uz",
        "local": "O‘zbek"
    },
    {
        "name": "Venda",
        "code": "ve",
        "local": "Tshivenḓa"
    },
    {
        "name": "Vietnamese",
        "code": "vi",
        "local": "Tiếng Việt"
    },
    {
        "name": "Volapük",
        "code": "vo",
        "local": "Volapük"
    },
    {
        "name": "Walloon",
        "code": "wa",
        "local": "Walon"
    },
    {
        "name": "Welsh",
        "code": "cy",
        "local": "Cymraeg"
    },
    {
        "name": "Wolof",
        "code": "wo",
        "local": "Wolof"
    },
    {
        "name": "Western Frisian",
        "code": "fy",
        "local": "Frysk"
    },
    {
        "name": "Xhosa",
        "code": "xh",
        "local": "isiXhosa"
    },
    {
        "name": "Yiddish",
        "code": "yi",
        "local": "ייִדיש"
    },
    {
        "name": "Yoruba",
        "code": "yo",
        "local": "Yorùbá"
    },
    {
        "name": "Zhuang",
        "code": "za",
        "local": "Cuengh"
    },
    {
        "name": "Zulu",
        "code": "zu",
        "local": "isiZulu"
    }
  ]
  
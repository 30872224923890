import React, { Component } from 'react'
import Pagination from '../helpers/pagination'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class CreatePayment extends Component {
    constructor(props) {
        super(props)
        this.input = React.createRef()
        this.form = React.createRef()
        this.state = { invalid: [], title: '', thirdParty: false, invalidPage: 0, published: false, income: 0, csv: null, type: '', date: '', platform: '', subdistributor: '', viewTitle: false, territory: '', agreement_amount: 0, received_amount: 0, buyer: '', amount: 0, page: 0, count: 50 }
    }
    componentDidMount() {
        if (this.props.type) this.setState({ type: this.props.type })
        if (this.props.title) this.setState({title: this.props.title})
    }
    request = (page, method, data) => {
        return new Promise((res, rej) => {
            let options = { method: method ? method.toUpperCase() : 'GET', headers: { 'Content-Type': 'application/json', authorization: window.app.state.auth, userid: window.app.state.userID } }
            if (data) options.body = JSON.stringify(data)
            fetch('https://crm.octanemultimedia.com' + page, options).then(r => r.json()).then(r => {
                if (r.error) return rej(r.message || JSON.stringify(r))
                res(r)
            }).catch(e => rej(e))
        })
    }
    uploadCSV = () => {
        if (this.getting) return
        this.setState({ getting: true })
        let data = new FormData()
        let end = (e) => {
            window.flash(e)
            this.setState({ getting: false })
        }
        data.append('csv', this.input.current.files[0])
        data.append('type', this.state.type === 'sale' ? 'sale' : 'payment')
        let that = new XMLHttpRequest()
        that.onerror = e => end(e)
        that.onabort = e => end(e)
        that.onreadystatechange = () => {
            if (that.readyState === 4) {
                try {
                    let d = JSON.parse(that.responseText)
                    this.setState({ previewImport: d.imported, validations: d.validations, csv: '', getting: false })
                    this.form.current?.reset()
                } catch (e) {
                    end(e)
                }
            }
        }
        that.open('POST', 'https://crm.octanemultimedia.com' + '/import-csv', true)
        that.setRequestHeader('authorization', window.app.state.auth)
        that.setRequestHeader('userid', window.app.state.userID)
        that.send(data)
    }
    validate(item) {
        return item.title && window.app.state.titles.find(a => a._id == item.title || a.title == item.title)
    }
    saveImport = (force) => {
        let list = this.state.previewImport
        let invalid = []
        for (let i = 0; i < list.length; i++) {
            let a = this.validate(list[i])
            if (!a) invalid.push({ item: list[i], index: i })
        }
        if (invalid.length > 0 && !force) {
            this.setState({ invalid, forceSend: true })
            return window.flash('SOME ITEMS WERE FOUND TO BE INVALID PLEASE CORRECT THOSE ITEMS AND TRY AGAIN')
        } else {
            for (let i = 0; i < invalid.length; i++) {
                let o = list.splice(invalid[i].index, 1)
            }
        }
        this.request(`${this.state.type === 'payment' ? '/payment' : '/sale'}`, 'post', { list, thirdParty: this.state.thirdParty }).then(() => {
            window.app.getPayments().then(() => {
                this.setState({ previewImport: null, invalid: [], forceSend: false })
            }).catch(e => { window.flash(e); this.setState({ forceSend: false }) })
        }).catch(e => { window.flash(e); this.setState({ forceSend: false }) })
    }
    save(data) {
        this.request(`${this.state.type === 'payment' || this.state.type === 'expense' ? '/payment' : '/sale'}`, 'POST', this.state.type === 'payment' || this.state.type === 'expense' ? { payment: data } : { sale: data }).then(r => {
            this.setState({ title: '', received_amount: 0, agreement_amount: 0, published: false, thirdParty: false, buyer: '', territory: '' }, () => window.flash(`${this.state.type === 'payment' ? 'Payment' : this.state.type === 'expense' ? 'Expense' : 'Sale'} Saved`))
        }).catch(e => window.flash(e))
    }
    render() {
        window.cp = this
        if (this.state.getting) {
            return (<div className="b1" style={{ paddingTop: '5vh' }}>
                <h3>Processing CSV<DotDot /></h3>
                <Spinner></Spinner>
                <span>This may take a couple minutes.</span>
            </div>)
        }
        if (this.state.validations) {
            return (<div className="b1 rel" style={{ paddingTop: '60px' }}>
                <button className="top-right-abs" onClick={() => this.setState({
                    validations: null, previewImport: [...this.state.previewImport].map(u => {
                        let a = this.props.movies.find(a => a._id === u.title || a.title === window.namefilter(u.title))
                        if (a) u.title = a._id
                        return u
                    })
                })}>Close Suggestions</button>
                <h3>Some of your items were not validated!</h3>
                <span>Please see choose from the suggestions below to validate your data to continue without changing these items</span>
                <div className="c3" style={{ width: 'auto', minWidth: '90%', padding: '5%' }}>
                    {this.state.validations.map((u, i) => {
                        let { title, suggestions } = u
                        return (<div key={i} className="b1 suggestionCont">
                            <h3 className="suggestionTitle">{title}</h3>
                            <span className="suggestionSpan">Suggestions:</span>
                            {suggestions?.data && suggestions.data.sort((a, b) => b.score - a.score).map((u, i) => (<div key={i} className="suggestion" data-title={title} onClick={e => {
                                let title = e.target.getAttribute('data-title')
                                if (title) {
                                    let value = e.target.innerHTML
                                    this.setState({
                                        previewImport: [...this.state.previewImport].map(u => {
                                            if (u.title === title) u.title = this.props.movies.find(a => a.title == value)._id
                                            return u
                                        }),
                                        validations: this.state.validations.filter(u => u.title != title)
                                    }, () => {
                                        if (this.state.validations.length < 1) this.setState({
                                            validations: null, previewImport: [...this.state.previewImport].map(u => {
                                                let a = this.props.movies.find(a => a._id === u.title || a.title == window.namefilter(u.title))
                                                if (a) u.title = a._id
                                                return u
                                            })
                                        })
                                    })
                                }
                            }}>{u.text}</div>))}
                        </div>)
                    })}
                </div>
            </div>)
        }
        if (this.state.forceSend) {
            return (<div className="b1" style={{ paddingTop: '5vh' }}>
                <h2>Invalid Items Found</h2>
                <div className="b1">
                    <span>Skip these items and import only validated items?</span>
                    <div className="b2">
                        <button onClick={e => {
                            this.saveImport(true)
                        }}>Yes</button>
                        <button onClick={() => this.setState({ previewImport: false, forceSend: false })}>No</button>
                    </div>
                </div>
                <div id="tablehold" style={{ width: '100%', maxWidth: '80%', margin: '20px 0' }}>
                    <table id="titleTable">
                        <thead>
                            <tr>
                                {this.state.type === 'payment' ? <>
                                    <th>Date</th>
                                    <th>Platform</th>
                                    <th>Title</th>
                                    <th>Amount</th>
                                    <th>Income</th>
                                </> : <>
                                    <th>Title</th>
                                    <th>Territory</th>
                                    <th>Buyer</th>
                                    <th>Agreement Amount</th>
                                    <th>Received Amount</th>
                                </>}
                            </tr>
                        </thead>
                        <tbody>
                            {window.index(this.state.invalid.map(u => u.item), this.state.invalidPage, 1000).map((u, i) => {
                                return (<tr className={"invalid-item"} key={i}>
                                    {this.state.type === 'payment' ? <>
                                        <td>{window.formatDate(u.date)}</td>
                                        <td>{u.platform || 'Null'}</td>
                                        <td>{this.props.movies.find(a => a._id === u.title)?.title || u.title}</td>
                                        <td>$ {u.amount ? window.currency(window.parseNumber(u.amount)) : 'NAN'}</td>
                                        <td>$ {u.income ? window.currency(window.parseNumber(u.income)) : 'NAN'}</td>
                                    </> : (<>
                                        <td>{this.props.movies.find(a => a._id === u.title)?.title || u.title}</td>
                                        <td>{u.territory || 'null'}</td>
                                        <td>{u.buyer || 'null'}</td>
                                        <td>$ {u.agreement_amount ? window.currency(window.parseNumber(u.agreement_amount)) : 'null'}</td>
                                        <td>$ {u.received_amount ? window.currency(window.parseNumber(u.received_amount)) : 'null'}</td>
                                    </>)}
                                </tr>)
                            })}
                        </tbody>
                    </table>
                    <Pagination total={this.state.invalid?.length || 0} page={this.state.invalidPage} count={1000} set={e => this.setState({ invalidPage: e })} />
                </div>
            </div>)
        }
        if (this.state.previewImport) {
            return (<div className="b1">
                <h3>Preview Import</h3>
                <div id="tablehold" style={{ width: '100%', maxWidth: '80%', margin: '20px 0' }}>
                    <table id="titleTable">
                        <thead>
                            <tr>
                                {this.state.type === 'payment' ? <>
                                    <th>Date</th>
                                    <th>Platform</th>
                                    <th>Title</th>
                                    <th>Amount</th>
                                    <th>Income</th>
                                </> : <>
                                    <th>Title</th>
                                    <th>Territory</th>
                                    <th>Buyer</th>
                                    <th>Agreement Amount</th>
                                    <th>Received Amount</th>
                                </>}
                            </tr>
                        </thead>
                        <tbody>
                            {window.index(this.state.previewImport, this.state.page, this.state.count).map((u, i) => {
                                return (<tr className={(!this.validate(u) ? "invalid-item" : '')} key={i}>
                                    {this.state.type === 'payment' ? <>
                                        <td>{window.formatDate(u.date)}</td>
                                        <td>{u.platform || 'Null'}</td>
                                        <td>{this.props.movies.find(a => a._id === u.title)?.title || u.title}</td>
                                        <td>$ {u.amount ? window.currency(window.parseNumber(u.amount)) : 'NAN'}</td>
                                        <td>$ {u.income ? window.currency(window.parseNumber(u.income)) : 'NAN'}</td>
                                    </> : (<>
                                        <td>{this.props.movies.find(a => a._id == u.title)?.title || u.title}</td>
                                        <td>{u.territory || 'null'}</td>
                                        <td>{u.buyer || 'null'}</td>
                                        <td>$ {u.agreement_amount ? window.currency(window.parseNumber(u.agreement_amount)) : 'null'}</td>
                                        <td>$ {u.received_amount ? window.currency(window.parseNumber(u.received_amount)) : 'null'}</td>
                                    </>)}
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination total={this.state.previewImport?.length || 0} page={this.state.page} count={this.state.count} set={e => this.setState({ page: e })} />
                <div style={{ marginTop: '100px' }}>
                    <button onClick={() => this.saveImport()}>Save</button>
                    <button onClick={() => this.setState({ previewImport: null })}>Cancel</button>
                </div>
            </div>)
        }
        return <>
            <div className="content container-fluid rel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {this.props.close && <div className="col-md-2">
                                <button onClick={this.props.close}>Back to Title</button>
                            </div>}
                            <div className="col-md-2">
                                {this.state.type && <a href={window.API+`/static/${this.state.type}-template.csv`} target="_blank" rel="noreferrer"><button>Download CSV Template</button></a>}
                            </div>
                            <div className="col-md-8">
                                <div className="b2 wrap" style={{ width: 'auto', maxWidth: '100%' }}>
                                    {this.state.type && <form ref={this.form} style={{ width: 'auto' }} onSubmit={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.uploadCSV()
                                    }}>
                                        <input ref={this.input} accept=".csv" className="hidden" onChange={e => { if (e.target.files.length > 0) { this.setState({ csv: e.target.files[0].name }) } else { this.setState({ csv: '' }) } }} type="file" />
                                        {this.state.csv && <span><strong>{this.state.csv}</strong></span>}
                                        <div className="c3">
                                            {this.input.current?.files?.length > 0 ? <div>
                                                <div>
                                                    <label>Third Party Data?</label>
                                                    <input type="checkbox" checked={this.state.thirdParty} onChange={e => this.setState({ thirdParty: e.target.checked })}></input>
                                                </div>
                                                <div>
                                                    <label>Published?</label>
                                                    <input type="checkbox" checked={this.state.published} onChange={e => this.setState({ published: e.target.checked })}></input>
                                                </div>
                                            </div> : <div></div>}
                                            <button onClick={e => {
                                                e.preventDefault()
                                                if (this.input.current?.files?.length > 0) return this.uploadCSV()
                                                this.input.current.click()
                                            }}>{this.input.current?.files?.length > 0 ? 'Upload CSV' : 'Select CSV'}</button>
                                            {this.input.current?.files?.length > 0 ? <button onClick={e => {
                                                e.preventDefault()
                                                this.form.current.reset()
                                                this.setState({ csv: '' })
                                            }}>Cancel</button> : <div></div>}
                                        </div>
                                    </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="fancyForm">
                            <h3>Create {this.state.type ? (this.state.type === 'payment' ? 'Income' : this.state.type === 'expense' ? 'Expense' : 'Sale') : 'Datapoint'}</h3>
                            <div className="fancySelects">
                                <label htmlFor="type">Type</label>
                                <select name="type" value={this.state.type} onChange={e => this.setState({ type: e.target.value })}>
                                    <option value="">Choose Type</option>
                                    <option value="sale">Sales</option>
                                    <option value="payment">Distribution - Income</option>
                                    <option value="expense">Distribution - Expense</option>
                                </select>
                            </div>
                            {this.state.type === 'sale' && <>
                                <div className="fancySelects">
                                    <label htmlFor="title">Title</label>
                                    <select name="title" disabled={this.props.title} value={this.props.title || this.state.title} onChange={e => this.setState({ title: e.target.value })}>
                                        <option value="">Select Title</option>
                                        {window.app.state.titles?.sort((a, b) => window.nameCompare(a.title, b.title)).map((u, i) => (<option value={u._id} key={i}>{u.title}</option>))}
                                    </select>
                                </div>
                                <div className="fancySelects">
                                    <label htmlFor="territory">Choose Territory</label>
                                    <select name="territory" value={this.state.territory} onChange={e => this.setState({ territory: e.target.value })}>
                                        <option value="">Select Territory</option>
                                        {window.app.state.sales?.reduce((a, b) => {
                                            if (b.territory && !a.includes(b.territory)) a.push(b.territory)
                                            return a
                                        }, []).sort(window.nameCompare).map((u, i) => (<option value={u} key={i}>{u}</option>))}
                                    </select>
                                </div>
                                <div className="fancySelects">
                                    <label htmlFor="buyer">Choose Buyer</label>
                                    <select name="buyer" value={this.state.buyer} onChange={e => this.setState({ buyer: e.target.value })}>
                                        <option value="">Select Buyer</option>
                                        {window.app.state.sales?.reduce((a, b) => {
                                            if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                                            return a
                                        }, []).sort(window.nameCompare).map((u, i) => (<option value={u} key={i}>{u}</option>))}
                                    </select>
                                </div>
                                <div className="fancySelects">
                                    <label>Agreement Amount ($)</label>
                                    <input type="number" value={this.state.agreement_amount.toString()} onChange={e => this.setState({ agreement_amount: window.parseNumber(e.target.value) })} />
                                </div>
                                <div className="fancySelects">
                                    <label>Received Amount ($)</label>
                                    <input type="number" value={this.state.received_amount.toString()} onChange={e => this.setState({ received_amount: window.parseNumber(e.target.value) })} />
                                </div>
                                <div className="fancySelects b2 jfs">
                                    <label>3rd Party Data</label>
                                    <input type="checkbox" checked={this.state.thirdParty} onChange={e => this.setState({ thirdParty: !this.state.thirdParty })} />
                                </div>
                                <div className="fancySelects b2 jfs">
                                    <label>Published</label>
                                    <input type="checkbox" checked={this.state.published} onChange={e => this.setState({ published: !this.state.published })} />
                                </div>
                            </>}
                            {(this.state.type === 'payment' || this.state.type === 'expense') && <>
                                <div className="fancySelects">
                                    <label htmlFor="title">Title</label>
                                    <select name="title" disabled={this.props.title} value={this.props.title || this.state.title} onChange={e => this.setState({ title: e.target.value })}>
                                        <option value="">Select Title</option>
                                        {window.app.state.titles?.sort((a, b) => window.nameCompare(a.title, b.title)).map((u, i) => (<option value={u._id} key={i}>{u.title}</option>))}
                                    </select>
                                </div>
                                <div className="b1">
                                    <div className="fancySelects">
                                        <label>Sub-Distributor</label>
                                        <select name="subdistributor" value={this.state.subdistributor} onChange={e => this.setState({ subdistributor: e.target.value })}>
                                            <option value="">Select Sub-Distributor</option>
                                            {window.app.state.payments?.reduce((a, b) => {
                                                if (!a.includes(b.subdistributor)) a.push(b.subdistributor)
                                                return a
                                            }, []).map((u, i) => (<option value={u} key={i}>{u || 'HOP'}</option>))}
                                        </select>
                                    </div>
                                    <div className="fancySelects">
                                        <label>Platform</label>
                                        <select name="platform" value={this.state.platform} onChange={e => this.setState({ platform: e.target.value })}>
                                            <option value="">Select Platform</option>
                                            {window.app.state.payments?.reduce((a, b) => {
                                                if (b.platform && !a.includes(b.platform)) a.push(b.platform)
                                                return a
                                            }, []).sort(window.nameCompare).map((u, i) => (<option key={i} value={u}>{u}</option>))}
                                        </select>
                                    </div>
                                    <div className="fancySelects">
                                        <label>Amount ($)</label>
                                        <input name="amount" value={this.state.amount.toString()} type="number" onChange={e => this.setState({ amount: parseFloat(e.target.value) })}></input>
                                    </div>
                                    <div className="fancySelects">
                                        <label>Income ($)</label>
                                        <input name="amount" value={(this.state.income*(this.state.type === 'expense' ? -1 : 1)).toString()} type="number" onChange={e => this.setState({ income: parseFloat(e.target.value)*(this.state.type === 'expense' ? -1 : 1) })}></input>
                                    </div>
                                    <div className="fancySelects">
                                        <label>Date</label>
                                        <input value={this.state.date} onChange={e => this.setState({ date: e.target.value })} type="date"></input>
                                    </div>
                                    <div className="fancySelects b2 jfs">
                                        <label>3rd Party Data</label>
                                        <input type="checkbox" checked={this.state.thirdParty} onChange={e => this.setState({ thirdParty: !this.state.thirdParty })} />
                                    </div>
                                    <div className="fancySelects b2 jfs">
                                        <label>Published</label>
                                        <input type="checkbox" checked={this.state.published} onChange={e => this.setState({ published: !this.state.published })} />
                                    </div>
                                </div>
                            </>}
                            {(this.state.type === 'payment' || this.state.type === 'expense') ? (this.state.title && <button onClick={e => this.save({ subdistributor: this.state.subdistributor, thirdParty: this.state.thirdParty, published: this.state.published, amount: this.state.amount, title: this.state.title, platform: this.state.platform, date: this.state.date })}>Save {this.state.type === 'payment' ? 'Payment' : 'Expense'}</button>) : (this.state.title && <button onClick={e => this.save({ published: this.state.published, buyer: this.state.buyer, thirdParty: this.state.thirdParty, title: this.state.title, agreement_amount: this.state.agreement_amount, received_amount: this.state.received_amount, territory: this.state.territory })}>Save Sale</button>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}
export default CreatePayment
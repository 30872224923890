import React, { Component } from 'react'
class DealNotesDisplay extends Component {
    state = {
        notes: []
    }
    // componentDidMount() {
    //     if (this.props.notes) this.getNotes(this.props.notes)
    // }
    // componentDidUpdate() {
    //     if (this.props.notes?.length > 0 && this.state.notes.length < 1) this.getNotes(this.props.notes)
    // }
    // getNotes = (notes) => {
    //     window.app.request('/notes', 'post', { notes }).then(r => {
    //         this.setState({ notes: r.notes })
    //     }).catch(e => window.flash(e))
    // }
    render() {
        if (this.props.notes?.length < 1) return <>  <tr>
        <td className="border-0">Last Follow Up</td>
        <td className="border-0"></td>
    </tr>
    <tr>
        <td className="border-0">Next Follow Up</td>
        <td className="border-0"></td>
    </tr>
    <tr>
        <td className="border-0"><strong>Note</strong></td>
        <td className="border-0"><strong>Created</strong></td>
        <td className="border-0"><strong>Follow Up</strong></td>
    </tr>
    </>
        return (<>
            <tr>
                <td className="border-0">Last Follow Up</td>
                <td className="border-0">{this.props.notes && window.formatDate(this.props.notes[this.props.notes.length - 1]?.date)}</td>
            </tr>
            <tr>
                <td className="border-0">Next Follow Up</td>
                <td className="border-0">{this.props.notes && window.formatDate(this.props.notes[this.props.notes.length - 1]?.followUp)}</td>
            </tr>
            <tr>
                <td className="border-0"><strong>Note</strong></td>
                <td className="border-0"><strong>Created</strong></td>
                <td className="border-0"><strong>Follow Up</strong></td>
            </tr>
            {this.props.notes.map((u, i) => (<tr key={i}>
                <td className="border-0">{u.note}</td>
                <td className="border-0">{u.date && window.formatDate(u.date)}</td>
                <td className="border-0">{u.followUp && window.formatDate(u.followUp)}</td>
            </tr>))}
        </>)
    }
}
export default DealNotesDisplay
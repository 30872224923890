import React, { Component } from 'react'
import Accordian from '../helpers/display/accordian'
import AddressDisplay from '../helpers/display/address'
import Circles from '../../assets/images/circle.png';
import NotesDisplay from '../helpers/display/notesdisplay'
import { Link } from 'react-router-dom'
import TitleDisplay from '../helpers/display/titledisplay'
import Table from '../helpers/display/table'
import TabLink from '../helpers/tablink'
import EmailCompose from '../forms/email-compose';
import EmailView from './email-view'
import NotFound from '../helpers/display/notfound'
import SidePop from '../helpers/display/sidepop'
class User extends Component {
    state = { view: 'details' }
    componentDidMount() {
        if (this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedUsers : window.app.state.users)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => { if (!this.props.restore) { this.getActions(); this.getEmails(this.state._id) } })
            } else {
                this.setState({ notFound: true })
            }
        } else {
            this.setState({ _id: this.props._id, type: this.props.type })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedUsers : window.app.state.users)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (!this.props.restore) {
                        if (!this.state.actions) this.getActions()
                        if (!this.state._emails) this.getEmails(this.state._id)
                    }
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, type: this.props.type, view: this.state.view, notFound: true })
            }
        }
    }
    getAgreements() {
        if (this.gettingAgreements) return
        this.gettingAgreements = true
        if (window.app.state.agreements) {
            let agreements = window.app.state.agreements?.filter(a => a.associate === this.state._id || a.contact === this.state._id || a.owner === this.state._id || a.signatory === this.state._id) || []
            this.gettingAgreements = false
            this.setState({ agreements })
        } else {
            window.app.request('/user-agreements', 'post', { _id: this.state._id }).then(r => {
                this.gettingAgreements = false
                this.setState({ agreements: r.agreements })
            }).catch(e => {
                this.gettingAgreements = false
                window.flash(e)
            })
        }
    }
    getEmails = _id => {
        if (this.getEmail) return false
        this.getEmail = true
        window.app.getUserEmails(_id).then(emails => {
            this.getEmail = false
            this.setState({ _emails: emails })
        }).catch(e => {
            this.getEmail = false
            window.flash(e)
        })
    }
    getActions() {
        if (this.gettingActions) return
        this.gettingActions = true
        window.app.request('/actions', 'post', { _id: this.state._id }).then(r => {
            this.gettingActions = false
            this.setState({ gotActions: true, actions: r.actions })
        }).catch(e => {
            this.gettingActions = false
            window.flash(e)
        })
    }
    reload = () => {
        let profile = (this.props.restore ? window.app.state.archivedUsers : window.app.state.users)?.find(a => a._id === this.props._id)
        if (profile) {
            this.setState({ ...profile }, () => {
                if (!this.props.restore) {
                    if (!this.actions) this.getActions()
                    if (!this._emails) this.getEmails()
                }
            })
        } else {
            this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view })
        }
    }
    render() {
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        let internal = window.isInternal(this.state.type)
        window.user = this
        let activity = () => {
            let categories = ['Notes', 'Actions', 'Emails'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Notes': {
                        items = this.state.notes || []
                        break
                    }
                    case 'Emails': {
                        items = this.state._emails || []
                        columns = [
                            {
                                title: "Date",
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                            },
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ currentEmail: record._id }) }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                        ]
                        break
                    }
                    case 'Drafts': {
                        items = this.state._id === window.app.state.userID ? window.app.state.draftEmails || [] : window.app.state.draftEmails?.filter(u => (u.associate === this.state._id)) || []
                        columns = [
                            {
                                title: "Subject",
                                dataIndex: "subject",
                                render: (text, record) => (
                                    <><span style={{ cursor: 'pointer' }} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({ currentDraft: record._id, emailMessage: record.message, emailSubject: record.subject, emailCc: record.cc, emailBcc: record.bcc, emailAttachments: record.attachments, emailSendAt: record.sendAt, emailFrom: record.from, emailAppendContent: record.appendContent, emailTo: record.to, emailThread: record.thread })
                                    }}>{text}</span></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.subject, b.subject)
                            },
                            {
                                title: "From",
                                dataIndex: "from",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.from, b.from)
                            },
                            {
                                title: "To",
                                dataIndex: "to",
                                render: (text, record) => (
                                    <>{(record.to || []).join(', ')}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.to?.join(','), b.to?.join(','))
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                window.app.request('/delete-draft-email', 'post', { _id: record._id }).then(d => {
                                                    if (d?.r?.nModified) {
                                                        window.app.setState({ draftEmails: [...window.app.state.draftEmails].filter(a => a._id !== record._id) })
                                                    } else {
                                                        window.flash('Failed to delete draft')
                                                    }
                                                }).catch(e => {
                                                    window.flash(e)
                                                })
                                            }}>Delete Draft</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                        break
                    }
                    case 'Actions': {
                        items = this.state.actions || []
                        columns = [
                            {
                                title: "Type",
                                dataIndex: "reminderType",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.reminderType, b.reminderType)
                            },
                            {
                                title: "Associated",
                                dataIndex: "associate",
                                render: (text, record) => (
                                    <>{(() => {
                                        switch (record.associateType) {
                                            case 'deal': {
                                                return <Link to={`/deal?_id=${record.associate}`}><div className="squareButton">{window.app.state.deals?.find(a => a._id === record.associate)?.name || 'Go to Deal'}</div></Link>
                                            }
                                            case 'company': {
                                                return <Link to={`/company?_id=${record.associate}`}><div className="squareButton">{window.app.state.companies?.find(a => a._id === record.associate)?.name || 'Go To Company'}</div></Link>
                                            }
                                            case 'title': {
                                                return <Link to={`/title?_id=${record.associate}`}><div className="squareButton">{window.app.state.titles?.find(a => a._id === record.associate)?.title || 'Go To Title'}</div></Link>
                                            }
                                            case 'user': {
                                                return <Link to={`/profile?_id=${record.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === record.associate)?.name || 'Go To User Profile'}</div></Link>
                                            }
                                            default: {
                                                return ''
                                            }
                                        }
                                    })()}</>
                                )
                            },
                            {
                                title: "User",
                                dataIndex: "user",
                                render: (text, record) => {
                                    let user = window.app.state.users?.find(a => a._id === record.user)?.name || record.user
                                    if (!user) return (<>{record.user}</>)
                                    return (
                                        <><Link to={`/profile?_id=${record.user}`}>{user}</Link></>
                                    )
                                },
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Created",
                                dateIndex: "created",
                                render: (text, record) => (<>{record.created && window.formatDate(record.created)}</>)
                            },
                            {
                                title: "Follow Up",
                                dateIndex: "date",
                                render: (text, record) => (<>{record.date && window.formatDate(record.date)}</>)
                            }
                        ]
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table columns={columns} data={items} mainKey={record => record._id} />} />
            return <div className="tab-pane show active" id="task-activity">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks p-2">
                        {categories.map((u, i) => (u.name === 'Notes' ? <Accordian header={'Notes'} count={u.items?.length || 0} content={<NotesDisplay type="table" notes={u.items} />} /> : accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let related = () => {
            let categories = ['Companies', 'Titles', 'Deals', 'Agreements', 'Users'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Users': {
                        items = window.app.state.users?.filter(a => a._id !== this.state._id && (a.owner === this.state._id || a.reportsTo === this.state._id || (a.company && this.state.company && a.company === this.state.company) || a.primaryContact === this.state._id)) || []
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/profile?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            }, {
                                title: 'Email',
                                dataIndex: 'email',
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.owner === this.state._id ? 'Owner' : a.reportTo === this.state._id ? 'Report To' : a.company && this.state.company && a.company === this.state.company ? 'Company' : a.primaryContact === this.state._id ? 'Primary Contact' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                            }

                        ]
                        break
                    }
                    case 'Agreements': {
                        let id = this.state._id
                        items = window.app.state.agreements?.filter(a => (a.contact === id || a.owner === id || a.signatory === id || a.associate === id)) || []
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/agreement?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.contact === this.state._id ? 'Contact' : a.owner === this.state._id ? 'Owner' : a.signatory === this.state._id ? 'Signatory' : 'Associate'}</>),
                                sorter: (a, b) => {

                                }
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                            }
                        ]
                        break
                    }
                    case 'Companies': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/company?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a.company === this.state.company ? 'Company' : a.primaryContact === this.state._id ? 'Primary Contact' : a.owner === this.state._id ? 'Onwer' : 'Company User'}</>),
                                sorter: (a, b) => {

                                }
                            }
                        ]
                        items = window.app.state.companies?.filter(u => {
                            if (u._id === this.state.company || u.primaryContact === this.state._id || u.owner === this.state._id || u.users?.find(a => a === this.state._id)) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Titles': {
                        columns = [
                            {
                                title: "Title",
                                dataIndex: "title",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.title, b.title)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, record) => (<>{this.state.titles?.includes(record._id) ? 'Title' : record.contact === this.state._id ? 'Contact' : record.hardDrive?.receiverID === this.state._id ? 'Hard Drive Receiver' : record.createdBy === this.state._id ? 'Creator' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        items = window.app.state.titles?.filter(u => {
                            if (this.state.titles?.includes(u._id) || u.contact === this.state._id || u.hardDrive?.receiverID === this.state._id || u.createdBy === this.state._id) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Deals': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/deal?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, record) => (<>{record.contact === this.state._id ? 'Contact' : record.creator === this.state._id ? 'Creator' : record.owner === this.state._id ? 'Owner' : 'unknown'}</>),
                                sorter: (a, b) => {

                                }
                            },
                        ]
                        items = window.app.state.deals?.filter(u => {
                            if (u.contact === this.state._id || u.creator === this.state._id || u.owner === this.state._id) return true
                            return false
                        }) || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table data={items} columns={columns} mainKey={record => record._id} />} />
            return <div className="tab-pane task-related  show active" id="task-related">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let nameOcc = () => (<Accordian open={true} header="Name & Occupation" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Record ID</td>
                    <td className="border-0">{this.state._id}</td>
                </tr>
                {internal && <tr>
                    <td className="border-0">App Id</td>
                    <td className="border-0">{this.state.appId}</td>
                </tr>}
                <tr>
                    <td className="border-0">Name</td>
                    <td className="border-0">{this.state.name || `${this.state.firstName} ${this.state.lastName}`}</td>
                </tr>
                {!internal && <tr>
                    <td className="border-0">Company</td>
                    <td className="border-0">{this.state.company && <TabLink to={`/company?_id=${this.state.company}`} content={(window.app.state.companies?.find(u => u._id === this.state.company)?.name || this.state.company)} />}</td>
                </tr>}
                {this.state.title && <tr>
                    <td className="border-0">Title</td>
                    <td className="border-0">{this.state.title}</td>
                </tr>}
                {!internal && <tr>
                    <td className="border-0">Titles</td>
                    <td className="border-0">{this.state.titles && <TitleDisplay items={this.state.titles || []} template={(u, i) => (<li key={i}>{window.app.state.titles?.find(a => a._id === u)?.title || u}</li>)} />}</td>
                </tr>}
                <tr>
                    <td className="border-0">Organization</td>
                    <td className="border-0">{this.state.organization}</td>
                </tr>
                {internal && <><tr>
                    <td className="border-0">Role</td>
                    <td className="border-0">{this.state.role}</td>
                </tr> <tr>
                        <td className="border-0">Reports to</td>
                        <td className="border-0">{this.state.reportsTo && <TabLink to={`/profile?_id=${this.state.reportsTo}`}>{window.app.state.users?.find(a => a._id === this.state.reportsTo)?.name}</TabLink>}</td>
                    </tr> <tr>
                        <td className="border-0">Relationship Type</td>
                        <td className="border-0">{this.state.relationshipType}</td>
                    </tr>
                </>}
            </tbody>
        </table>} />)
        let contactDet = () => (<Accordian header="Contact Details" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Email Address</td>
                    <td className="border-0">{this.state.email}</td>
                </tr>
                {this.state.otherEmail && <tr>
                    <td className="border-0">Email Address 2</td>
                    <td className="border-0">{this.state.otherEmail}</td>
                </tr>}
                {this.state.ccEmail && <tr>
                    <td className="border-0">CC Email Address</td>
                    <td className="border-0">{this.state.ccEmail}</td>
                </tr>}
                {this.state.ccEmail2 && <tr>
                    <td className="border-0">CC Email Address 2</td>
                    <td className="border-0">{this.state.ccEmail2}</td>
                </tr>}
                {/* <tr>
                    <td className="border-0">Phone</td>
                    <td className="border-0">{this.state.phone}</td>
                </tr> */}
                <tr>
                    <td className="border-0">Mobile Phone</td>
                    <td className="border-0">{this.state.mobilePhone}</td>
                </tr>
                <tr>
                    <td className="border-0">Work Phone</td>
                    <td className="border-0">{this.state.workPhone}</td>
                </tr>
                <tr>
                    <td className="border-0">Home Phone</td>
                    <td className="border-0">{this.state.homePhone}</td>
                </tr>
                <tr>
                    <td className="border-0">Fax</td>
                    <td className="border-0">{this.state.fax}</td>
                </tr>
                <tr>
                    <td className="border-0">Assistant Name</td>
                    <td className="border-0">{this.state.assistantName}</td>
                </tr>
                <tr>
                    <td className="border-0">Assistant Email</td>
                    <td className="border-0">{this.state.assistantEmail}</td>
                </tr>
                <tr>
                    <td className="border-0">Assistant Phone</td>
                    <td className="border-0">{this.state.assistantPhone}</td>
                </tr>
                <tr>
                    <td className="border-0">Website</td>
                    <td className="border-0">{this.state.website}</td>
                </tr>
                <tr>
                    <td className="border-0">Facebook</td>
                    <td className="border-0">{this.state.facebook}</td>
                </tr>
                <tr>
                    <td className="border-0">LinkedIn</td>
                    <td className="border-0">{this.state.linkedIn}</td>
                </tr>
                <tr>
                    <td className="border-0">Twitter</td>
                    <td className="border-0">{this.state.twitter}</td>
                </tr>
                <tr>
                    <td className="border-0">Instagram</td>
                    <td className="border-0">{this.state.instagram}</td>
                </tr>
                <tr>
                    <td className="border-0">Youtube</td>
                    <td className="border-0">{this.state.youtube}</td>
                </tr>
                <tr>
                    <td className="border-0">TikTok</td>
                    <td className="border-0">{this.state.tiktok}</td>
                </tr>
            </tbody>
        </table>} />)
        let addressInfo = () => (<Accordian header="Address Information" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Mailing Address</td>
                    <td className="border-0"><AddressDisplay address={this.state.mailingAddress} /></td>
                </tr>
                <tr>
                    <td className="border-0">Home Address</td>
                    <td className="border-0"><AddressDisplay address={this.state.homeAddress} /></td>
                </tr>
                <tr>
                    <td className="border-0">Other Address</td>
                    <td className="border-0"><AddressDisplay address={this.state.otherAddress} /></td>
                </tr>
            </tbody>
        </table>} />)
        let thingsToRem = () => (<Accordian header="Things to Remember" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Date of Birth</td>
                    <td className="border-0">{this.state.dateOfBirth && window.formatDate(this.state.dateOfBirth)}</td>
                </tr>
                <tr>
                    <td className="border-0">Anniversary</td>
                    <td className="border-0">{this.state.anniversary && window.formatDate(this.state.anniversary)}</td>
                </tr>
                <tr>
                    <td className="border-0">Drinks</td>
                    <td className="border-0">{typeof this.state.drinks === 'boolean' && (this.state.drinks ? 'True' : 'False')}</td>
                </tr>
                <tr>
                    <td className="border-0">Favorite Drink</td>
                    <td className="border-0">{this.state.favoriteDrink}</td>
                </tr>
                <tr>
                    <td className="border-0">Favorite Food</td>
                    <td className="border-0">{this.state.favoriteFood}</td>
                </tr>
                <tr>
                    <td className="border-0">Things they like</td>
                    <td className="border-0">{this.state.thingsLike}</td>
                </tr>
                <tr>
                    <td className="border-0">Special Notes</td>
                    <td className="border-0">{this.state.specialNotes}</td>
                </tr>
            </tbody>
        </table>} />)
        let buyInfo = () => (<Accordian header="Buyer Information" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Last Contacted</td>
                    <td className="border-0">{this.state.salesLastContact}</td>
                </tr>
                <tr>
                    <td className="border-0">Next Contact</td>
                    <td className="border-0">{this.state.salesNextContact}</td>
                </tr>
                <tr>
                    <td className="border-0">Films Passed</td>
                    <td className="border-0"><TitleDisplay items={this.state.filmsPassed || []} /></td>
                </tr>
                <tr>
                    <td className="border-0">Films Reviewing</td>
                    <td className="border-0"><TitleDisplay items={this.state.filmsReviewing || []} /></td>
                </tr>
            </tbody>
        </table>} />)
        let addInfo = () => (<Accordian header="Additional Information" content={<table className="table">
            <tbody>
                <tr>
                    <td className="border-0">Description</td>
                    <td className="border-0">{this.state.description}</td>
                </tr>
                {!internal && <>
                    <tr>
                        <td className="border-0">Trailer</td>
                        <td className="border-0">{this.state.trailer}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Partner Name</td>
                        <td className="border-0">{this.state.partnerName}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Partner Email</td>
                        <td className="border-0">{this.state.partnerEmail}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Partner Phone</td>
                        <td className="border-0">{this.state.partnerPhone}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Contact Source</td>
                        <td className="border-0">{this.state.source}</td>
                    </tr>
                    <tr>
                        <td className="border-0">Primary Employee In Charge of Account</td>
                        <td className="border-0">{this.state.owner && (window.app.state.users.find(a => a._id === this.state.owner)?.name || this.state.owner)}</td>
                    </tr>
                </>}
                <tr>
                    <td className="border-0">Notes</td>
                    <td className="border-0">{this.state.notes && <NotesDisplay notes={this.state.notes} />}</td>
                </tr>
                {/* <tr>
          <td className="border-0">Date of Last Activity</td>
          <td className="border-0">{}</td>
        </tr> */}
            </tbody>
        </table>} />)
        let email = this.state._emails?.find(a => a._id === this.state.currentEmail)
        if (this.state.currentDraft) return (<div><EmailCompose to={this.state.emailTo} from={this.state.emailFrom} attachments={this.state.emailAttachments} bcc={this.state.emailBcc} cc={this.state.emailCc} subject={this.state.emailSubject} message={this.state.emailMessage} appendContent={this.state.emailAppendContent} _id={this.state.currentDraft} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ currentDraft: '', emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>)
        if (this.state.compose) return (<div><EmailCompose to={this.state.emailTo || email?.to} from={this.state.emailFrom || email?.from} attachments={this.state.emailAttachments || email?.attachments} bcc={this.state.emailBcc || email?.bcc} cc={this.state.emailCc || email?.cc} subject={this.state.emailSubject || email?.subject} message={this.state.emailMessage || email?.message} appendContent={this.state.emailAppendContent} _id={this.state.currentEmail} thread={this.state.emailThread} sendAt={this.state.emailSendAt} close={() => this.setState({ compose: false, emailCc: undefined, emailBcc: undefined, emailSubject: undefined, emailAttachments: undefined, emailTo: undefined, emailFrom: undefined, emailSubject: undefined, emailMessage: undefined, emailThread: undefined })} /></div>)
        if (this.state.currentEmail) return <EmailView set={k => {
            this.setState({ _emails: this.state._emails.map(a => a._id === this.state.currentEmail ? { ...a, title: k } : a) })
        }} email={email} close={() => this.setState({ currentEmail: '' })} _id={this.state.currentEmail} />
        return (<>
            <div className="modal right fade show" style={{ display: 'block' }} onClick={this.props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
                <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                    <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close xs-close" onClick={this.props.close} data-dismiss="modal">×</button>
                            <div className="row w-100">
                                <div className="col-md-7 account">
                                    <div className="avatar"><img src={this.state.profilePic} alt="" className="avatar" /></div>
                                    <span className="modal-title users">
                                        {this.state.name || (this.state.firstName && this.state.lastName ? `${this.state.firstName} ${this.state.lastName[0]}.` : this.state.firstName ? this.state.firstName : this.state._id)}
                                        {this.props.restore && <a onClick={this.props.restore}>
                                            <i style={{ margin: '3px' }} className="fa fa-pencil"></i></a>}
                                        {this.props.edit && (!window.isInternal(this.state.type) || window.isAdmin(window.app.state.type)) && <a onClick={this.props.edit}>
                                            <i style={{ margin: '3px' }} className="fa fa-pencil"></i></a>}
                                        {this.props.edit && <Link to={`/profile?_id=${this.state._id}`}><i style={{ margin: '3px', color: 'var(--d)' }} className="fa fa-expand"></i></Link>}
                                    </span>&nbsp;
                                    {!this.props.restore && this.state.email && window.app.state.userID !== this.state._id && <button onClick={() => this.setState({ compose: true, emailTo: [this.state.email] })}>Send Email</button>}
                                </div>
                                <div className="col-md-5 text-right">
                                    <ul className="list-unstyled list-style-none">
                                        <li className="dropdown list-inline-item"><br />
                                            <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Actions </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">Do Something</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow p-2 due-dates">
                            <div className="row m-0">
                                <div className="col">
                                    <span>Title</span>
                                    <p>{this.state.title}</p>
                                </div>
                                <div className="col">
                                    <span>Company</span>
                                    <p>{this.state.company && <TabLink to={`/company?_id=${this.state.company}`} text={window.app.state.companies?.find(a => a._id === this.state.company)?.name || this.state.company} />}</p>
                                </div>
                                <div className="col">
                                    <span>Phone</span>
                                    <p>{this.state.phone ? window.formatPhone(this.state.phone) : ''}</p>
                                </div>
                                <div className="col">
                                    <span>Email</span>
                                    <p>{this.state.email}</p>
                                </div>
                                <div className="col">
                                    <span>Contact owner</span>
                                    <p>{(() => {
                                        let owner = window.app.state.users?.find(a => a._id === this.state.owner)
                                        if (owner) return <TabLink to={`/profile?_id=${this.state.owner}`} text={owner.name || (owner.firstName && owner.lastName ? `${owner.firstName} ${owner.lastName[0]}.` : this.state.owner)} />
                                        return ''
                                    })()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            {this.state.type !== 'contact' && this.state.type !== 'vendor' && !window.isInternal(this.state.type) && <div className="row" style={{ margin: '10px 0px' }}>
                                <div className="col-md-12">
                                    <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                        {window.userStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                            let status = e.target.getAttribute('data-status')
                                            if (!status) return window.flash('Status issue')
                                            window.app.handleUser({ fields: { _id: this.state._id, status } })
                                            this.setState({
                                                status
                                            })
                                        }}>
                                            <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                                <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                            </a>
                                        </li>))}
                                    </ul>
                                </div>
                            </div>}
                            <div className="task-infos">
                                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                    <li className="nav-item"><a className={this.state.view === 'details' ? "nav-link active" : 'nav-link'} href="#task-details" onClick={e => {
                                        e.preventDefault();
                                        this.setState({ view: 'details' })
                                    }}>Details</a></li>
                                    <li className="nav-item"><a className={this.state.view === 'related' ? "nav-link active" : 'nav-link'} href="#task-related" onClick={e => {
                                        e.preventDefault();
                                        this.setState({ view: 'related' })
                                    }}>Related</a></li>
                                    <li className="nav-item"><a className={this.state.view === 'activity' ? "nav-link active" : 'nav-link'} href="#task-activity" onClick={e => {
                                        e.preventDefault();
                                        this.setState({ view: 'activity' })
                                    }}>Activity</a></li>
                                </ul>
                                <div className="tab-content">
                                    {this.state.view === 'details' ? <div className="tab-pane show active" id="task-details">
                                        <div className="crms-tasks">
                                            {nameOcc()}
                                            {!internal && ((this.props.crm === 'film-sales' || this.props.crm === 'distribution')) && buyInfo()}
                                            {contactDet()}
                                            {addressInfo()}
                                            {!internal && thingsToRem()}
                                            {addInfo()}
                                        </div>
                                    </div> : this.state.view === 'related' ? related() : activity()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default User
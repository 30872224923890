import React, { Component } from 'react'
class Filters extends Component {
    state = {
        filters: [],
    }
    getListFields = list => {
        if (!list) return []
        if (!list instanceof Array) return [list]
        return Object.entries(list.map(u => Object.entries(u)).reduce((a, b) => {
            for (let i = 0; i < b.length; i++) {
                let key = b[i][0]
                let value = b[i][1]
                let type = typeof value
                type = ['title', '_id', 'metadata', 'id'].includes(key) ? 'string' : type === 'boolean' ? 'boolean' : value instanceof Array ? 'array' : type === 'number' ? 'number' : window.testDate(value) ? 'date' : type === 'object' ? 'object' : 'string'
                if (!a[key]) a[key] = { entries: [], types: {} }
                a[key].entries.push(value)
                if (!a[key].types[type]) {
                    a[key].types[type] = 1
                } else {
                    a[key].types[type]++
                }
            }
            return a
        }, {})).reduce((a, b) => {
            if (['__v', '_id', '__title', '_t', '_u', 'crm', 'id', 'accessLog', 'activities'].includes(b[0])) return a
            a.push({
                key: b[0], type: Object.entries(b[1].types).reduce((a, b) => {
                    if (a.count < b[1]) return { type: b[0], count: b[1] }
                    return a
                }, { type: '', count: 0 }).type
            })
            return a
        }, []).sort((a,b) => window.nameCompare(a.key, b.key))
    }
    filter(data) {
        let { filters: ffs } = this.state
        if (!data) return []
        var filterType = ''
        var filterField = ''
        var filterCount = Infinity
        var filterMode = null
        let filter = (u, i) => {
            let { name, value, compare } = filterMode
            if (compare && typeof value === 'object' && value instanceof Array) {
                let r = []
                let fm = filterMode, ft = filterType, ff = filterField, fc = filterCount;
                for (let z = 0; z < value.length; z++) {
                    let { name, type, mode, count } = value[i]
                    if (!name) continue
                    filterMode = mode
                    filterType = type
                    filterField = name
                    filterCount = count || Infinity
                    r.push(filter(u))
                }
                filterMode = fm
                filterType = ft
                filterField = ff
                filterCount = fc
                if (compare === 'not') {
                    return r.length > 0 && r.filter(u => u).length === 0
                } else if (compare === 'or') {
                    return r.filter(u => u).length > 0
                } else {
                    return r.filter(u => u).length === value.length
                }
            } else {
                if (!u || (!u[filterField] && u[filterField] === 0)) return false
                if (i > filterCount) return false
                const data = u[filterField]
                switch (filterType) {
                    case 'number': {
                        switch (name) {
                            case 'gt': {
                                return data > value
                            }
                            case 'lt': {
                                return data < value
                            }
                            case 'gte': {
                                return data >= value
                            }
                            case 'lte': {
                                return data <= value
                            }
                            case 'equal': {
                                return data == value
                            }
                            default: {
                                return data
                            }
                        }
                        break
                    }
                    case 'string': {
                        switch (name) {
                            case 'equal': {
                                return value == data
                            }
                            case 'matches': {
                                return data && value && data.toLowerCase().match(value.toLowerCase())
                            }
                            case 'contains': {
                                return data && value && data.toLowerCase().includes(value.toLowerCase())
                            }
                            case 'notcontain': {
                                return data && value && !data.toLowerCase().includes(value.toLowerCase())
                            }
                            case 'notequal': {
                                return value !== data
                            }
                            case 'lengthgte': {
                                return data && value <= data.length
                            }
                            case 'lengthlte': {
                                return data && value >= data.length
                            }
                            case 'lengtheq': {
                                return data && value === data.length
                            }
                            case 'lengthlt': {
                                return data && value > data.length
                            }
                            case 'lengthgt': {
                                return data && value < data.length
                            }
                            default: {
                                return data
                            }
                        }
                        break
                    }
                    case 'array': {
                        switch (name) {
                            case 'includes': {
                                if (!value || !data || data.length < 1) return false
                                let expr
                                try {
                                    expr = new RegExp(value, 'i')
                                } catch {
                                    return data.includes(value)
                                }
                                if (!expr) return false
                                for (let i = 0; i < data.length; i++) {
                                    if (data[i].match(expr)) return true
                                }
                                return false
                            }
                            case 'notincludes': {
                                if (!value || !data || data.length < 1) return true
                                let expr
                                try {
                                    expr = new RegExp(value, 'i')
                                } catch {
                                    return data.includes(value)
                                }
                                if (!expr) return true
                                for (let i = 0; i < data.length; i++) {
                                    if (data[i].match(expr)) return false
                                }
                                return true
                            }
                            case 'exists': {
                                return data && data.length > 0
                            }
                            default: {
                                return data
                            }
                        }
                        break
                    }
                    case 'object': {
                        switch (name) {
                            case 'has': {
                                return data && value && value in data
                            }
                            default: {
                                return data
                            }
                        }
                        break
                    }
                    case 'date': {
                        switch (name) {
                            case 'gt': {
                                return data && new Date(data).getTime() > new Date(value).getTime()
                            }
                            case 'lt': {
                                return data && new Date(data).getTime() < new Date(value).getTime()
                            }
                            case 'gte': {
                                return data && new Date(data).getTime() >= new Date(value).getTime()
                            }
                            case 'lte': {
                                return data && new Date(data).getTime() <= new Date(value).getTime()
                            }
                            case 'between': {
                                return data && filterMode['a'] && filterMode['b'] && new Date(data).getTime() - new Date(filterMode['a']).getTime() > 0 && new Date(data).getTime() - new Date(filterMode['b']).getTime() < 0
                            }
                            case 'exists': {
                                return !isNaN(Date.parse(data))
                            }
                            default: {
                                return data
                            }
                        }
                        break
                    }
                    case 'boolean': {
                        return name == 'false' ? !data : data
                    }
                    default: {
                        return data
                    }
                }
            }
        }
        ffs = ffs.filter(u => u.mode.value)
        for (let i = 0; i < ffs.length; i++) {
            if (!ffs[i]) continue
            let { name, type, mode, count } = ffs[i]
            if (!name) continue
            filterMode = mode
            filterType = type
            filterField = name
            filterCount = count || Infinity
            data = data.filter(filter)
        }
        return data
    }
    render() {
        let { height, statusList = [], change, list = [] } = this.props
        let fields = this.getListFields(list)
        let handleChange = () => {
            if (typeof change === 'function') change(this.filter(list), this.state.filters)
        }
        return (<div className="b1 scrollFlex" style={{ height }}>
            <ul className="kanfilt">
            {this.state.filters?.length > 0 && <li><strong>Selected Filters: </strong><ul>{this.state.filters?.length > 0 && this.state.filters.map((u,i) => (<li key={i}><strong>{u.name} ({u.type}){u.mode && <> {u.mode.name} - {u.mode.value}</>}</strong></li>))}</ul></li>}
                <li><strong>Select filters:</strong></li>
                {fields?.map((u, i) => (<li className="c2 filterItem" key={i}>
                    <div className="b1">
                        <input type="checkbox" checked={this.state.filters.find(a => a.name === u.key) ? true : false} className="form-control widthAuto" onChange={e => this.setState({ filters: this.state.filters.find(a => a.name === u.key) ? [...this.state.filters].filter(a => u.key !== a.name) : [...this.state.filters, { name: u.key, type: u.type, mode: { name: '', value: '' } }] }, handleChange)}></input>
                    </div>
                    <div className="b1 afs"><span><strong>{window.parseKey(u.key)}</strong></span></div>
                    {this.state.filters.find(a => a.name === u.key) && <div className="b2 wrap jfs filterWrap">
                        <select value={this.state.filters.find(a => a.name === u.key)?.mode?.name} onChange={e => {
                            this.setState({
                                filters: [...this.state.filters].map(z => {
                                    if (z.name === u.key) return ({ ...z, mode: { name: e.target.value, value: z.mode.name === e.target.value ? z.mode.value : e.target.value === 'exists' ? true : '' } })
                                    return z
                                })
                            }, handleChange)
                        }} className="form-control widthAuto" >
                            <option value="">Select One</option>
                            {(() => {
                                let { type, key, value } = u
                                let inners
                                switch (type) {
                                    case 'number': {
                                        inners = [
                                            (<option key={1} value={'equal'}>Equal To</option>),
                                            (<option key={2} value={'gt'}>Greater Than</option>),
                                            (<option key={3} value={'lt'}>Less Than</option>),
                                            (<option key={4} value={'gte'}>Greater Than/Equal To</option>),
                                            (<option key={5} value={'lte'}>Less Than/Equal To</option>),
                                            (<option key={6} value={'exists'}>Exists</option>),
                                        ]
                                        break
                                    }
                                    case 'string': {
                                        inners = [
                                            (<option key={1} value={'exists'}>Exists</option>),
                                            (<option key={2} value={'equal'}>Equal To</option>),
                                            (<option key={3} value={'contains'}>Contains</option>),
                                            (<option key={4} value={'matches'}>Matches</option>),
                                            (<option key={5} value={'notcontain'}>Does Not Contain</option>),
                                            (<option key={6} value={'notequal'}>Not Equal To</option>),
                                            (<option key={7} value={'lengthgt'}>Length Greater Than</option>),
                                            (<option key={8} value={'lengthlt'}>Length Less Than</option>),
                                            (<option key={9} value={'lengthgte'}>Length Greater Than/Equal To</option>),
                                            (<option key={10} value={'lengthlte'}>Length Less Than/Equal To</option>),
                                        ]
                                        break
                                    }
                                    case 'array': {
                                        inners = [
                                            (<option key={1} value={'includes'}>Includes</option>),
                                            (<option key={2} value={'notincludes'}>Does Not Include</option>),
                                            (<option key={3} value={'exists'}>Exists</option>),
                                        ]
                                        break
                                    }
                                    case 'object': {
                                        inners = [
                                            (<option key={1} value={'has'}>Has</option>),
                                            (<option key={2} value={'exists'}>Exists</option>),
                                        ]
                                        break
                                    }
                                    case 'boolean': {
                                        inners = [
                                            (<option key={1} value={'true'}>True</option>),
                                            (<option key={2} value={'false'}>False</option>),
                                        ]
                                        break
                                    }
                                    case 'date': {
                                        inners = [
                                            (<option key={1} value={'gt'}>After</option>),
                                            (<option key={2} value={'lt'}>Before</option>),
                                            (<option key={3} value={'gte'}>After or On</option>),
                                            (<option key={4} value={'lte'}>Before or On</option>),
                                            (<option key={5} value={'between'}>Between</option>),
                                            (<option key={6} value={'exists'}>Exists</option>),
                                        ]
                                        break
                                    }
                                    default: return ''
                                }
                                return inners
                            })()}
                        </select>
                        {(() => {
                            let j = this.state.filters.find(a => a.name === u.key)
                            let { name, value } = j.mode
                            let { type, key } = u
                            if (!name) return <></>
                            if (name === 'exists' || type === 'boolean') return <>{!value && <span onClick={() => {
                                if (!value) {
                                    this.setState({
                                        filters: [...this.state.filters].map((a) => {
                                            if (a.name === key) return ({ ...a, mode: { ...a.mode, value: true } })
                                            return a
                                        })
                                    }, handleChange)
                                }
                            }}>FITLER!</span>}</>
                            if (key === 'status' && !['', 'exists', 'lengthgte', 'lengthgt', 'lengthlte', 'lengthlt', 'contains', 'matches', 'contains'].includes(name)) {
                                return <select className="form-control"  value={value} onChange={e => {
                                    this.setState({
                                        filters: [...this.state.filters].map((a) => {
                                            if (a.name === key) return ({ ...a, mode: { ...a.mode, value: e.target.value } })
                                            return a
                                        })
                                    }, handleChange)
                                }}>
                                    <option value="">Choose One</option>
                                    {statusList.map((u, i) => (<option key={i} value={u}>{u}</option>))}
                                </select>
                            } else if (type === 'date') {
                                if (name === 'between') {
                                    return (<div className="b2 wrap">
                                        <input className="form-control"  style={{ maxWidth: '100%' }} type="date" value={(() => {
                                            if (!value) return ''
                                            let { start } = value
                                            let a = window.formatDate(start).split('-')
                                            let j = [a[2], a[0], a[1]].join('-')
                                            return j
                                        })()} onChange={e => {
                                            this.setState({
                                                filters: [...this.state.filters].map((a) => {
                                                    if (a.name === key) return ({ ...a, mode: { ...a.mode, value: (typeof a.mode.value === 'object' ? { ...a.mode.value, start: window.moveDate(e.target.value) } : { start: window.moveDate(e.target.value) }) } })
                                                    return a
                                                })
                                            }, handleChange)
                                        }} />
                                        <input className="form-control"  style={{ maxWidth: '100%' }} type="date" value={(() => {
                                            if (!value) return ''
                                            let { end } = value
                                            if (!end) return ''
                                            let a = window.formatDate(end).split('-')
                                            let j = [a[2], a[0], a[1]].join('-')
                                            return j
                                        })()} onChange={e => {
                                            this.setState({
                                                filters: [...this.state.filters].map((a) => {
                                                    if (a.name === key) return ({ ...a, mode: { ...a.mode, value: (typeof a.mode.value === 'object' ? { ...a.mode.value, end: window.moveDate(e.target.value) } : { end: window.moveDate(e.target.value) }) } })
                                                    return a
                                                })
                                            }, handleChange)
                                        }} />
                                    </div>)
                                } else {
                                    return <input className="form-control"  style={{ maxWidth: '100%' }} type="date" value={(() => {
                                        if (!value) return ''
                                        let a = window.formatDate(value).split('-')
                                        let j = [a[2], a[0], a[1]].join('-')
                                        return j
                                    })()} onChange={e => {
                                        this.setState({
                                            filters: [...this.state.filters].map((a) => {
                                                if (a.name === key) return ({ ...a, mode: { ...a.mode, value: window.moveDate(e.target.value) } })
                                                return a
                                            })
                                        }, handleChange)
                                    }} />
                                }
                            } else {
                                return <input style={{ width: 'auto', maxWidth: '100%' }} className="form-control" type="text" value={value} onChange={e => {
                                    this.setState({
                                        filters: [...this.state.filters].map((a) => {
                                            if (a.name === key) return ({ ...a, mode: { ...a.mode, value: e.target.value } })
                                            return a
                                        })
                                    }, handleChange)
                                }}></input>
                            }
                        })()}
                    </div>}
                </li>))}
            </ul>
        </div>)
    }
}
export default Filters
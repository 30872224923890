import React, { Component } from 'react'
import DateTime from '../helpers/inputs/datetime'
import MarkupInput from '../helpers/inputs/markupinput'
import SearchInput from '../helpers/inputs/searchinput'
import ArrayInput from '../helpers/inputs/arrayinput'
import Toggle from '../helpers/inputs/toggle2'
class CreateTemplate extends Component {
  constructor() {
    super()
    this.form = React.createRef()
  }
  state = { type: '', public: false, name: '', subject: '', message: undefined }
  componentDidMount() {
    if (this.props.current) {
      let template = window.app.state.emailTemplates?.find(a => a._id === this.props.current)
      if (template) this.setState({ ...template })
    }
  }
  componentDidUpdate(prv) {
    if (!prv.current && this.props.current) {
      let template = window.app.state.emailTemplates?.find(a => a._id === this.props.current)
      if (template) this.setState({ ...template })
    }
  }
  close = () => {
    if (this.state.subject && this.state.message && this.state.to && this.state.from) {
      if (!this.asked) {
        this.asked = true
        window.sure(`Want to save the template?`, () => {
          window.app.saveTemplate({ ...this.state, _id: this.props.current }).then(() => {
            if (typeof this.props.close === 'function') this.props.close()
          }).catch(e => window.flash(e))
        }, () => {
          if (typeof this.props.close === 'function') this.props.close()
        })
      } else {
        if (typeof this.props.close === 'function') this.props.close()
      }
    } else if (typeof this.props.close === 'function') { this.props.close() }
  }
  render() {
    console.log(this.props.current)
    return <>
      <div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
        <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
          <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Create Template</h4>
              <div className="b2 close xs-close" style={{ width: '40%' }}>
                {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={() => this.close()}>Back</button>}
                <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={() => this.close()}>×</button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <form autoComplete={'new-password'} ref={this.form}>
                    <div className="form-group-row">
                      <div className="col-md-4">
                        <label className="col-form-label" htmlFor="name">Name</label>
                        <input className="form-control" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} type="text" name="name" />
                      </div>
                      <div className="col-md-4">
                        <label className="col-form-label" htmlFor="name">Type</label>
                        <SearchInput items={[]} value={this.state.type} onChange={e => this.setState({ name: e.target.value })} name="name" />
                      </div>
                      <div className="col-md-4">
                        <label className="col-form-label" htmlFor="name">Public?</label>
                        <Toggle tog={this.state.public} change={e => this.setState({ public: !this.state.public })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">Subject</label>
                        <input className="form-control" placeholder="Subject" type="text" value={this.state.subject} name="subject" onChange={e => this.setState({ subject: e.target.value })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label">Message</label>
                        <MarkupInput name="message" value={this.state.message} change={(message) => {
                          this.setState({ message })
                        }}></MarkupInput>
                      </div>
                    </div>
                    <div className="text-center py-3">
                      <button className="border-0 btn btn-primary btn-gradient-primary btn-rounded" onClick={e => {
                        e.preventDefault();
                        window.app.saveTemplate({ ...this.state, _id: this.props.current }).then(() => {
                          if (typeof this.props.set === 'function') this.props.set(this.state)
                          if (typeof this.props.close === 'function') this.props.close()
                        }).catch(e => {
                          window.flash(e)
                        })
                      }}>Save</button>&nbsp;&nbsp;
                      <button type="button" className="btn btn-secondary btn-rounded" data-dismiss="modal" onClick={() => this.close()}>Cancel</button>
                    </div>
                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="modal-backdrop fade show"></div></>
  }
}
class EmailCompose extends Component {
  constructor() {
    super()
    this.form = React.createRef()
  }
  state = { to: [], from: '', subject: '', bcc: [], cc: [], message: undefined }
  componentDidMount() {
    if (this.props.to) this.setState({ to: this.props.to })
    if (this.props.from) this.setState({ from: this.props.from })
    if (this.props.cc) this.setState({ cc: this.props.cc })
    if (this.props.bcc) this.setState({ bcc: this.props.bcc })
    if (this.props.subject) this.setState({ subject: this.props.subject })
    if (this.props.message) this.setState({ message: this.props.message })
    if (this.props.attachments) this.setState({ attachments: this.props.attachments })
    if (this.props.sendAt) this.setState({ sendAt: this.props.sendAt })
  }
  componentDidUpdate(prv) {
    if (!prv.to && this.props.to) this.setState({ to: this.props.to })
    if (!prv.from && this.props.from) this.setState({ from: this.props.from })
    if (!prv.cc && this.props.cc) this.setState({ cc: this.props.cc })
    if (!prv.bcc && this.props.bcc) this.setState({ bcc: this.props.bcc })
    if (!prv.subject && this.props.subject) this.setState({ subject: this.props.subject })
    if (!prv.message && this.props.message) this.setState({ message: this.props.message })
    if (!prv.attachments && this.props.attachments) this.setState({ attachments: this.props.attachments })
    if (!prv.sendAt && this.props.sendAt) this.setState({ sendAt: this.props.sendAt })
  }
  close = () => {
    if (this.state.subject && this.state.message && this.state.to && this.state.from) {
      if (!this.asked && !this.state.currentTemplate) {
        this.asked = true
        window.sure(`Want to save the draft?`, () => {
          window.app.handleDraft({ ...this.state, thread: this.props.thread }).then(() => {
            if (typeof this.props.close === 'function') this.props.close()
          }).catch(e => window.flash(e))
        }, () => {
          if (typeof this.props.close === 'function') this.props.close()
        })
      } else {
        if (typeof this.props.close === 'function') this.props.close()
      }
    } else if (typeof this.props.close === 'function') { this.props.close() }
  }
  render() {
    window.emailForm = this
    if (this.state.createTemplate) {
      return <CreateTemplate current={this.state.currentTemplate} set={template => {
        this.setState({ message: template.message, subject: template.subject, attachments: template.attachments, createTemplate: false })
      }} close={() => this.setState({ createTemplate: false })} />
    }
    return <>
      <div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
        <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
          <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Compose Email</h4>
              <div className="b2 close xs-close" style={{ width: '40%' }}>
                {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={() => this.close()}>Back</button>}
                <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={() => this.close()}>×</button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="b1">
                    <div className="b2 jfe">
                      <span style={{ cursor: 'pointer' }} onClick={() => this.setState({ showTemplates: !this.state.showTemplates })}>{this.state.showTemplates ? 'Hide Templates' : 'Show templates'}</span>
                      {this.state.showTemplates && <button onClick={e => this.setState({ createTemplate: true, currentTemplate: '' })}>New Template</button>}
                    </div>
                    {this.state.showTemplates && <div className="c3">
                      {window.app.state.emailTemplates?.map((u, i) => (<div key={i}>
                        <h3 onClick={() => {
                          this.setState({ message: u.message, subject: u.subject, showTemplate: false })
                        }}>{u.name}</h3>
                        <button data-template={u._id} onClick={e => {
                          e.preventDefault()
                          this.setState({ currentTemplate: e.target.getAttribute(['data-template']), createTemplate: true })
                        }}>Edit</button>
                        <button data-template={u._id} onClick={e => {
                          e.preventDefault()
                          let id = e.target.getAttribute(['data-template'])
                          window.app.deleteTemplate(id)
                          this.setState({ currentTemplate: '' })
                        }}>Delete</button>
                      </div>))}
                    </div>}
                  </div>
                  <form autoComplete={'new-password'} ref={this.form}>
                    <div className="form-group-row">
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">From</label>
                        <SearchInput placeholder="From" name="from" value={this.state.from} change={e => this.setState({ from: e })} items={window.app.state.users?.map(u => u.email).filter(a => a)} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">To</label>
                        <ArrayInput placeholder={'To'} type="search" name="to" multiple={true} items={window.app.state.users?.map(u => u.email).filter(a => a && !this.state.cc.includes(a) && !this.state.bcc.includes(a)) || []} selected={this.state.to} change={(selected) => this.setState({ to: selected })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">Cc</label>
                        <ArrayInput placeholder={'Cc'} type="search" name="cc" multiple={true} items={window.app.state.users?.map(u => u.email).filter(a => a && !this.state.to.includes(a) && !this.state.bcc.includes(a)) || []} selected={this.state.cc} change={(selected) => this.setState({ cc: selected })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">Bcc</label>
                        <ArrayInput placeholder={'Bcc'} type="search" name="bcc" multiple={true} items={window.app.state.users?.map(u => u.email).filter(a => a && !this.state.cc.includes(a) && !this.state.to.includes(a)) || []} selected={this.state.bcc} change={(selected) => this.setState({ bcc: selected })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">Send At</label>
                        <DateTime name="sendAt" options={{ filterDate: date => new Date().getTime() < date.getTime() }} value={this.state.sendAt} change={sendAt => this.setState({ sendAt })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label" htmlFor="">Subject</label>
                        <input className="form-control" placeholder="Subject" type="text" value={this.state.subject} name="subject" onChange={e => this.setState({ subject: e.target.value })} />
                      </div>
                      <div className="col-md-12">
                        <label className="col-form-label">Message</label>
                        <MarkupInput name="message" value={this.state.message} change={(message) => {
                          this.setState({ message })
                        }}></MarkupInput>
                      </div>
                    </div>
                    <div className="text-center py-3">
                      <button className="border-0 btn btn-primary btn-gradient-primary btn-rounded" onClick={e => {
                        e.preventDefault();
                        window.app.handleDraft({ ...this.state, appendContent: this.props.appendContent, thread: this.props.thread, _id: this.props._id }).then(() => {
                          if (typeof this.props.close === 'function') this.props.close()
                        }).catch(e => {
                          window.flash(e)
                        })
                      }}>Save as Draft</button>&nbsp;&nbsp;
                      <button onClick={e => {
                        e.preventDefault();
                        window.app.sendEmail({ ...this.state, thread: this.props.thread, appendContent: this.props.appendContent, _id: this.props._id }).then(() => {
                          if (typeof this.props.close === 'function') this.props.close(true)
                        }).catch(e => {
                          window.flash(e)
                        })
                      }} className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Send</button>&nbsp;&nbsp;
                      <button type="button" className="btn btn-secondary btn-rounded" data-dismiss="modal" onClick={() => this.close()}>Cancel</button>
                    </div>
                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="modal-backdrop fade show"></div></>
  }
}
export default EmailCompose
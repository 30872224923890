import React, { Component } from 'react'
class IM extends Component {
    state = {
        message: ''
    }
    componentDidMount() {
        if (this.props.store) {
            let message = sessionStorage.getItem('IM')
            if (message && message != 'undefined') this.setState({ message })
        }
    }
    componentDidUpdate() {

    }
    render() {
        return (<div id="IM" className="b1 rel">
            <div id="HS">
                {this.props.history?.map((u, i) => (<div key={i} className={"message" + (i % 2 !== 0 ? '' : ' greybg') + (u.read ? '' : ' newborder')} onClick={e => {
                    if (!u.read && typeof this.props.viewed === 'function') this.props.viewed(u._id)
                }}><div className="smallCol"><img src={(() => {
                    return window.app.state.users?.find(a => a._id === u.from)?.profilePic
                })()} alt={""} className="avatar" /><span>{window.formatDate(u.date)}</span></div><div className="messageCol"><p>{u.content}</p></div></div>))}
            </div>
            <textarea value={this.state.message} onChange={e => this.setState({ message: e.target.value }, () => {
                if (this.props.store) {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(() => {
                        sessionStorage.setItem('IM', this.state.message)
                    }, 1500)
                }
                if (this.props.history.find(a => !a.viewed) && typeof this.props.viewed === 'function') this.props.viewed('*')
            })} id="TA"></textarea>
            <button id="SB" onClick={async () => {
                if (typeof this.props.send === 'function') {
                    try {
                        await this.props.send(this.state.message, this.props.recipient)
                    } catch (e) {
                        return window.flash(e)
                    }
                }
                this.setState({ message: '' }, () => {
                    if (this.props.store) sessionStorage.setItem('IM', '')
                })
            }}>Send</button>
        </div>)
    }
}
export default IM
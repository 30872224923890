import React from 'react'
class DisplayEmail extends HTMLElement {
    constructor() {
        super()
    }
    connectedCallback() {
        this.attachShadow({ mode: 'open' })
        this.shadowRoot.innerHTML = this.getAttribute(['data'])
    }
}
customElements.define('display-email', DisplayEmail)
function MarkdownView(props) {
    return (<display-email style={{maxWidth: '100%'}} data={(props.content || window.createHTML(props.data))}></display-email>)
}
export default MarkdownView
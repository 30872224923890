import React, { Component } from 'react'
import DateInput from '../helpers/inputs/dateinput'
import ArrayInput from '../helpers/inputs/arrayinput'
import NotesInput from '../helpers/inputs/notesinput'
import Accordian from '../helpers/display/accordian'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class CForm extends Component {
  state = { titles: [], notes: [] }
  componentDidMount() {
    if (this.props._id) {
      let profile = window.app.state.users?.find(a => a._id === this.props._id)
      if (profile) this.setState({ ...profile })
    } else if (this.props.type) {
      this.setState({ type: this.props.type })
    }
  }
  componentDidUpdate() {
    if (this.state._id !== this.props._id) {
      let profile = window.app.state.users?.find(a => a._id === this.props._id)
      if (profile) {
        this.setState({ ...profile })
      } else {
        this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
      }
    }
  }
  componentWillUnmount() {
    this.settlePending()
  }
  pending = []
  handlePending = _id => {
    if (this.pending.includes(_id)) {
      this.pending = [...this.pending].filter(u => u !== _id)
    } else {
      this.pending.push(_id)
    }
  }
  settlePending = () => {
    if (this.pending?.length < 1) return
    window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
      console.log(e)
    })
  }
  submit = e => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.subbing) return
    this.setState({ subbing: true })
    this.props.add(e.target).then(r => {
      this.pending = []
      this.setState({ ...r, subbing: false })
      window.flash('User Saved')
    }).catch(e => {
      this.setState({ subbing: false })
      window.flash(e)
    })
  }
  render() {
    window.cform = this
    let FC = window.isSuper(window.app.state.type)
    let internal = this.state.type ? window.isInternal(this.state.type) : (!this.props.type && !this.props.crm)
    let ownRecord = this.state._id === window.app.state.userID
    let general = () => <Accordian open={true} header={['prospect'].includes(this.state.type) ? 'Name and Occupation' : `${this.state.type ? window.capitalize(this.state.type) : 'User'} Information`}
      content={<>
        <div className="form-group row">
          <div className="col-md-12"><label className="col-form-label">Name</label></div>
          <div className="col-md-6">
            <input autoComplete="none" className="form-control" type="text" value={this.state.firstName} onChange={e => this.setState({ firstName: e.target.value })} placeholder="First name" name="firstName" />
          </div>
          <div className="col-md-6">
            <input autoComplete="none" className="form-control" type="text" value={this.state.lastName} onChange={e => this.setState({ lastName: e.target.value })} placeholder="Last name" name="lastName" />
          </div>
          {!internal && <div className="col-md-12">
            <label className="col-form-label">Company</label>
            <select name="company" className="form-control" onChange={e => {
              this.setState({ company: e.target.value })
            }} value={this.state.company}>
              <option value="">Select</option>
              {window.app.state.companies?.filter(a => this.props.crm ? a.crm === this.props.crm : a).sort((a, b) => window.nameCompare(a.name, b.name)).map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
            </select>
          </div>}
          {FC && internal && <>
            {!this.props.type && <div className="col-sm-6">
              <label className="col-form-label">User Type</label>
              <select name="type" className="form-control" onChange={e => {
                this.setState({ type: e.target.value })
              }} value={this.state.type}>
                <option value={""}>Select</option>
                {window.internalTypes?.sort((a, b) => window.nameCompare(a, b)).map((u, i) => (<option key={i} value={u}>{u}</option>))}
              </select>
            </div>}
            {!this.props.crm && ((!window.isSuper(this.state.type) && this.state.type === 'tech' && this.state.type === 'accountant') ? <div className="col-sm-6">
              <label className="col-form-label">CRM</label>
              <select name="crm" className="form-control" onChange={e => {
                this.setState({ crm: e.target.value })
              }} value={this.state.crm}>
                <option value={""}>Select One</option>
                {window.CRMS?.sort((a, b) => window.nameCompare(a, b)).map((u, i) => (<option key={i} value={u}>{u}</option>))}
              </select>
            </div> : <input type="hidden" name="crm" value={''} />)}
          </>}
          <div className="col-sm-6">
            <label className="col-form-label">Organization</label>
            <select name="organization" className="form-control" onChange={e => {
              this.setState({ organization: e.target.value })
            }} value={this.state.organization}>
              <option value={""}>Select</option>
              {window.organizations?.sort((a, b) => window.nameCompare(a, b)).map((u, i) => (<option key={i} value={u}>{u}</option>))}
            </select>
          </div>
          {internal && FC && <div className="col-sm-6">
            <label className="col-form-label">Role</label>
            <input className="form-control" type="text" name="role" value={this.state.role} onChange={e => this.setState({role: e.target.value})}/>
          </div>}
          {!internal && <div className="col-sm-6">
            <label className="col-form-label">Titles</label>
            <ArrayInput items={window.app.state.titles ? [...window.app.state.titles]?.sort((a, b) => window.nameCompare(a.title, b.title)) : []} selected={this.state.titles || []} template={(u, i) => (<option key={i} value={u._id}>{u.title}</option>)} listTemplate={(u) => window.app.state.titles?.find(a => a._id === u)?.title || 'NOT VALID'} name="titles" />
          </div>}
          {!internal && <div className="col-md-6">
            <label className="col-form-label">Relationship Type</label>
            <select className="form-control" name="relationshipType" value={this.state.relationshipType} onChange={e => {
              this.setState({ relationshipType: e.target.value })
            }}>
              <option value="">Select One</option>
              {window.relationshipsTypes.map((u, i) => (<option key={i} value={u}>{u.toUpperCase()}</option>))}
            </select>
          </div>}
          {internal && FC && <>
            <div className="col-md-6">
              <label className="col-form-label">AppId</label>
              <input type="text" name="appId" className="form-control" value={this.state.appId} onChange={e => this.setState({ appId: e.target.value })} />
            </div>
            <div className="col-md-6">
              <label className="col-form-label">Reports To</label>
              <select className="form-control" name="reportsTo" value={this.state.reportsTo} onChange={e => this.setState({ reportsTo: e.target.value })}>
                <option value="">Select One</option>
                {window.app.state.users?.filter(a => window.isAdmin(a.type))?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
              </select>
            </div>
          </>}
        </div>
      </>}></Accordian>
    let contact = () => <Accordian header="Contact Details" content={<>
      <div className="form-group row">
        <div className="col-md-6">
          <label className="col-form-label">Email Address</label>
          <input autoComplete="none" className="form-control" type="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} placeholder="Email Address" name="email" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Email 2</label>
          <input autoComplete="none" className="form-control" type="email" value={this.state.otherEmail} onChange={e => this.setState({ otherEmail: e.target.value })} placeholder="Other Email" name="otherEmail" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">CC Email 1</label>
          <input autoComplete="none" className="form-control" type="email" value={this.state.ccEmail} onChange={e => this.setState({ ccEmail: e.target.value })} placeholder="CC Email" name="ccEmail" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">CC Email 2</label>
          <input autoComplete="none" className="form-control" type="email" value={this.state.ccEmail2} onChange={e => this.setState({ ccEmail2: e.target.value })} placeholder="CC Email 2" name="ccEmail2" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Work Phone</label>
          <input autoComplete="none" className="form-control" type="tel" value={this.state.workPhone} onChange={e => this.setState({ workPhone: e.target.value })} placeholder="Work Phone" name="workPhone" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Home Phone</label>
          <input autoComplete="none" className="form-control" type="tel" value={this.state.homePhone} onChange={e => this.setState({ homePhone: e.target.value })} placeholder="Home Phone" name="homePhone" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Mobile Phone</label>
          <input autoComplete="none" className="form-control" type="tel" value={this.state.mobilePhone} onChange={e => this.setState({ mobilePhone: e.target.value })} placeholder="Mobile Phone" name="mobilePhone" />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Fax</label>
          <input autoComplete="none" className="form-control" type="tel" value={this.state.fax} onChange={e => this.setState({ fax: e.target.value })} placeholder="Fax" name="fax" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Assistant Name</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.assistantName} onChange={e => this.setState({ assistantName: e.target.value })} placeholder="Assistant Name" name="assistantName" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Assistant Email</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.assistantEmail} onChange={e => this.setState({ assistantEmail: e.target.value })} placeholder="Assistant Email" name="assistantEmail" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Assistant Phone</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.assistantPhone} onChange={e => this.setState({ assistantPhone: e.target.value })} placeholder="Assistant Phone" name="assistantPhone" />
        </div>
        <div className="col-sm-12">
          <label className="col-form-label">Website</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.website} onChange={e => this.setState({ website: e.target.value })} placeholder="Website" name="website" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Facebook</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.facebook} onChange={e => this.setState({ facebook: e.target.value })} placeholder="Facebook" name="facebook" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">LinkedIn</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.linkedIn} onChange={e => this.setState({ linkedIn: e.target.value })} placeholder="LinkedIn" name="linkedIn" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Twitter</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.twitter} onChange={e => this.setState({ twitter: e.target.value })} placeholder="Twitter" name="twitter" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Instagram</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.instagram} onChange={e => this.setState({ instagram: e.target.value })} placeholder="Instagram" name="instagram" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Youtube</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.youtube} onChange={e => this.setState({ youtube: e.target.value })} placeholder="Youtube" name="youtube" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">TikTok</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.tiktok} onChange={e => this.setState({ tiktok: e.target.value })} placeholder="Tiktok" name="tiktok" />
        </div>
      </div>
    </>} />
    let address = () => <Accordian header="Address Information" content={<>
      <div className="form-group row">
        <div className="col-sm-12">
          <label className="col-form-label">Mailing Address</label>
          <textarea autoComplete="none" className="form-control" rows={3} name="mailing-street" placeholder="Address" value={this.state.mailingAddress?.street} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <input type="text" className="form-control" placeholder="City" name="mailing-city" value={this.state.mailingAddress?.city} />
        </div>
        <div className="col-sm-6">
          <select name="mailing-state" className="form-control" value={this.state.mailingAddress?.state} onChange={e => {
            let o = this.state.mailingAddress ? { ...this.state.mailingAddress } : {}
            o.state = e.target.value
            this.setState({ mailingAddress: o })
          }}>
            <option value="">Select a State</option>
            {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-4">
          <input autoComplete="none" type="text" className="form-control" placeholder="Postal code" name="mailing-postal-code" value={this.state.mailingAddress?.postalCode} />
        </div>
        <div className="col-sm-4">
          <input autoComplete="none" type="text" className="form-control" placeholder="Region" name="mailing-region" value={this.state.mailingAddress?.region} />
        </div>
        <div className="col-sm-4">
          <select name="mailing-country" className="form-control" value={this.state.mailingAddress?.country} onChange={e => {
            let o = this.state.mailingAddress ? { ...this.state.mailingAddress } : {}
            o.country = e.target.value
            this.setState({ mailingAddress: o })
          }}>
            <option value="">Select a Country</option>
            {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-12">
          <label className="col-form-label">Home Address</label>
          <textarea autoComplete="none" className="form-control" rows={3} name="home-street" placeholder="Address" value={this.state.homeAddress?.street}  onChange={e => {
       let o = this.state.homeAddress ? { ...this.state.homeAddress } : {}
       o.street = e.target.value
       this.setState({ homeAddress: o })
          }} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <input autoComplete="none" type="text" className="form-control" placeholder="City" name="home-city" value={this.state.homeAddress?.city} onChange={e => {
       let o = this.state.homeAddress ? { ...this.state.homeAddress } : {}
       o.city = e.target.value
       this.setState({ homeAddress: o })
          }} />
        </div>
        <div className="col-sm-6">
          <select name="home-state" className="form-control" value={this.state.homeAddress?.state} onChange={e => {
            let o = this.state.homeAddress ? { ...this.state.homeAddress } : {}
            o.state = e.target.value
            this.setState({ homeAddress: o })
          }}>
            <option value="">Select a State</option>
            {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-4">
          <input type="text" className="form-control" placeholder="Postal code" name="home-postal-code" value={this.state.homeAddress?.postalCode} />
        </div>
        <div className="col-sm-4">
          <input type="text" className="form-control" placeholder="Region" name="home-region" value={this.state.homeAddress?.region} />
        </div>
        <div className="col-sm-4">
          <select name="home-country" className="form-control" value={this.state.homeAddress?.country} onChange={e => {
            let o = this.state.homeAddress ? { ...this.state.homeAddress } : {}
            o.country = e.target.value
            this.setState({ homeAddress: o })
          }}>
            <option value="">Select a Country</option>
            {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-12">
          <label className="col-form-label">Other Address</label>
          <textarea autoComplete="none" className="form-control" rows={3} name="other-street" placeholder="Address" value={this.state.otherAddress?.street} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <input autoComplete="none" type="text" className="form-control" placeholder="City" name="other-city" value={this.state.otherAddress?.city} />
        </div>
        <div className="col-sm-6">
          <select name="other-state" className="form-control" value={this.state.otherAddres?.state} onChange={e => {
            let o = this.state.otherAddress ? { ...this.state.otherAddress } : {}
            o.state = e.target.value
            this.setState({ otherAddress: o })
          }}>
            <option value="">Select a State</option>
            {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-4">
          <input autoComplete="none" type="text" className="form-control" placeholder="Postal code" name="other-postal-code" value={this.state.otherAddress?.postalCode} />
        </div>
        <div className="col-sm-4">
          <input autoComplete="none" type="text" className="form-control" placeholder="Region" name="other-region" value={this.state.otherAddress?.region} />
        </div>
        <div className="col-sm-4">
          <select name="other-country" className="form-control" value={this.state.otherAddress?.country} onChange={e => {
            let o = this.state.otherAddress ? { ...this.state.otherAddress } : {}
            o.country = e.target.value
            this.setState({ otherAddress: o })
          }}>
            <option value="">Select a Country</option>
            {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
    </>}
    ></Accordian>
    let thingsToRemember = () => <Accordian header="Things To Remember" content={<>
      <div className="form-group row">
        <div className="col-sm-6">
          <label className="col-form-label">Date of Birth</label>

          <DateInput value={this.state.dateOfBirth} name="dateOfBirth" onChange={e => {
            this.setState({ dateOfBirth: e })
          }} />
        </div>
        <div className="col-sm-6">
          <label className="col-form-label">Anniversary</label>
          <DateInput value={this.state.anniversary} name="anniversary" onChange={e => {
            this.setState({ anniversary: e })
          }} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-4">
          <label className="col-form-label">Drinks</label>
          <div className="checkbox">
            <label>
              <input autoComplete="none" type="checkbox" name="drinks" defaultChecked={this.state.drinks ? true : false} onChange={e => {
                this.setState({ drinks: e.target.checked })
              }} /> {this.state.drinks ? 'YES' : 'NO'}
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Favorite Drink</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.favoriteDrink} onChange={e => this.setState({ favoriteDrink: e.target.value })} placeholder="Favorite Drink" name="favoriteDrink" />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Favorite Food</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.favoriteFood} onChange={e => this.setState({ favoriteFood: e.target.value })} placeholder="Favorite Food" name="favoriteFood" />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-12">
          <label className="col-form-label">Things They Like</label>
          <ArrayInput type="text" selected={this.state.thingsLike} name="thingsLike" />
        </div>
        <div className="col-md-12">
          <label className="col-form-label">Special Notes</label>
          <textarea autoComplete="none" className="form-control" name="specialNotes" value={this.state.specialNotes} onChange={e => this.setState({ specialNotes: e.target.value })} ></textarea>
        </div>
      </div>
    </>} />
    let buyerInfo = () => <Accordian header="Buyer Info" content={<>
      <div className="form-group row">
        <div className="col-sm-6">
          <label className="col-form-label">Last Contacted</label>
          <DateInput value={this.state.salesLastContact} name="salesLastContact" onChange={e => {
            this.setState({ salesLastContact: e })
          }} />
        </div>
        <div className="col-sm-6">
          <label className="col-form-label">Next Contact</label>
          <DateInput value={this.state.salesNextContact} name="salesNextContact" onChange={e => {
            this.setState({ salesNextContact: e })
          }} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-6">
          <label className="col-form-label">Films Passed</label>
          <ArrayInput name="filmsPassed" items={window.app.state.titles} selected={this.state.filmsPassed} listTemplate={(u) => (<div>{window.app.state.titles?.find(a => a._id === u)?.title || u}</div>)} template={(u, i) => (<option key={i} value={u._id}>{u.title}</option>)} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Films Reviewing</label>
          <ArrayInput name="filmsReviewing" items={window.app.state.titles} selected={this.state.filmsReviewing || []} listTemplate={(u) => (<div>{window.app.state.titles?.find(a => a._id === u)?.title || u}</div>)} template={(u, i) => (<option key={i} value={u._id}>{u.title}</option>)} />
        </div>
      </div>

    </>} />
    let additionalInfo = () => <Accordian header="Additional Information" content={<div className="form-group row">

      <div className="col-sm-12">
        <label className="col-form-label">Description</label>
        <textarea autoComplete="none" type="text" className="form-control" name="description" onChange={e => this.setState({ description: e.target.value })} value={this.state.description} placeholder="Description of this user."></textarea>
      </div>
      {!internal && <>
        <div className="col-sm-6">
          <label className="col-form-label">Trailer</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.trailer} onChange={e => this.setState({ trailer: e.target.value })} placeholder="Trailer" name="trailer" />
        </div>
        <div className="col-sm-6">
          <label className="col-form-label">Partner Name</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.partnerName} onChange={e => this.setState({ partnerName: e.target.value })} placeholder="Partner Name" name="partnerName" />
        </div>
        <div className="col-sm-6">
          <label className="col-form-label">Partner Email</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.partnerEmail} onChange={e => this.setState({ partnerEmail: e.target.value })} placeholder="Partner Email" name="partnerEmail" />
        </div>
        <div className="col-sm-6">
          <label className="col-form-label">Partner Phone</label>
          <input autoComplete="none" className="form-control" type="text" value={this.state.partnerPhone} onChange={e => this.setState({ partnerPhone: e.target.value })} placeholder="Partner Phone" name="partnerPhone" />
        </div>
        <div className="col-sm-12">
          <label className="col-form-label">Contact Source</label>
          <select className="form-control" name="source" value={this.state.source} onChange={e => this.setState({ source: e.target.value })}>
            <option value="">Select One</option>
            {window.leadSources?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
          </select>
        </div>

      </>}
      {(internal ? (!ownRecord || FC) : true) && <div className="col-sm-12">
        <label className="col-form-label">Notes</label>
        <NotesInput followUp={true} associateType={'user'} _id={this.state._id} name="notes" notes={this.state.notes} change={note => {
          this.handlePending(note)
          this.setState({
            notes: [...(this.state.notes || []), note]
          })
        }} remove={_id => {
          this.handlePending(_id)
          this.setState({ notes: [...this.state.notes].filter(u => u !== _id) })
        }}></NotesInput>
      </div>}
      {!internal && <div className="col-sm-12">
        <label className="col-form-label">Primary Employee In Charge of Account</label>
        <select className="form-control" name="owner" value={this.state.owner} onChange={e => this.setState({ owner: e.target.value })}>
          <option value="">Select One</option>
          {window.app.state.users?.filter(a => window.internalType(a.type))?.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
        </select>
      </div>}

    </div>} />
    return <>
      <div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
        <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
          <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} {this.props.type ? window.capitalize(this.props.type) : 'Lead'} </h4>
              <div className="b2 close xs-close" style={{ width: '40%' }}>
                {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                    {general()}
                    {(this.props.crm === 'film-sales' || this.props.crm === 'distribution') && buyerInfo()}
                    {contact()}
                    {address()}
                    {!internal && thingsToRemember()}
                    {additionalInfo()}
                    <div className="text-center py-3">
                      <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                      <button type="button" className="btn btn-secondary btn-rounded" data-dismiss="modal" onClick={this.props.close}>Cancel</button>
                    </div>
                    {this.props.crm && <input type="hidden" name="crm" value={this.props.crm} />}
                    <input type="hidden" name="_id" value={this.state._id} />
                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                  </form>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="modal-backdrop fade show"></div></>
  }
}
export default CForm
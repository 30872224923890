import React, { Component } from 'react'
import SensitiveInput from './sensitiveinput2'
class CredentialInput extends Component {
    state = { email: '', delimiter: window.textdelim, password: '', type: '', directions: '', selected: [] }
    componentDidMount() {
        if (this.props.selected instanceof Array) {
            this.priorWatch = true
            this.setState({ selected: this.props.selected })
        }
    }
    componentDidUpdate(prev) {
        if (this.props.selected instanceof Array && !this.priorWatch) {
            this.priorWatch = true;
            this.setState({ selected: this.props.selected || [] })
        } else if (this.props.selected instanceof Array && this.state.selected?.length !== this.props.selected?.length) {
            this.setState({ selected: this.props.selected || [] })
        }
    }
    render() {
        let { name } = this.props
        let { selected } = this.state
        let change = () => {
            let { password } = this.state
            this.setState({ selected: [...this.state.selected, password], password: '' }, () => {
                if (typeof this.props.change === 'function') this.props.change(password, this.state.selected)
            })
        }
        return (<>
            <input type="text" value={(selected && selected instanceof Array && selected.length > 0 ? selected.join(this.state.delimiter) : 'null-input')} onChange={e => { }} className="hidden" name={name} />
            <ul>
                {selected && selected instanceof Array && selected.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{u}<i className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    this.setState({ selected: [...this.state.selected].filter(a => a !== u) }, () => {
                        if (typeof this.props.change === 'function') this.props.change(u, this.state.selected)
                    })
                }}></i></span></li>))}
            </ul>
            <div className="c2">
                <div className="b2 jfs">
                    <SensitiveInput placeholder="Title Password" value={this.state.password} change={e => this.setState({ password: e })} />
                </div>
                <div className="b1">
                    <button className="btn btn-primary" style={{ margin: 0, padding: '4px', cursor: 'pointer', gridArea: '4 / 1 / span 1 / span 2' }} onClick={e => { e.preventDefault(); change(); }}>Add Password</button>
                </div>
            </div>
        </>)
    }
}
export default CredentialInput
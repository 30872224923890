window.filter = (data, filters) => {
  if (!data) return []
  let ffs = filters
  var filterType = ''
  var filterField = ''
  var filterCount = Infinity
  var filterMode = null
  let filter = (u, i) => {
    let { name, value, compare } = filterMode
    if (compare && typeof value === 'object' && value instanceof Array) {
      let r = []
      let fm = filterMode, ft = filterType, ff = filterField, fc = filterCount;
      for (let z = 0; z < value.length; z++) {
        let { name, type, mode, count } = value[i]
        if (!name) continue
        filterMode = mode
        filterType = type
        filterField = name
        filterCount = count || Infinity
        r.push(filter(u))
      }
      filterMode = fm
      filterType = ft
      filterField = ff
      filterCount = fc
      if (compare === 'not') {
        return r.length > 0 && r.filter(u => u).length === 0
      } else if (compare === 'or') {
        return r.filter(u => u).length > 0
      } else {
        return r.filter(u => u).length === value.length
      }
    } else {
      if (!u || (!u[filterField] && u[filterField] === 0)) return false
      if (i > filterCount) return false
      const data = u[filterField]
      switch (filterType) {
        case 'number': {
          switch (name) {
            case 'gt': {
              return data > value
            }
            case 'lt': {
              return data < value
            }
            case 'gte': {
              return data >= value
            }
            case 'lte': {
              return data <= value
            }
            case 'equal': {
              return data == value
            }
            default: {
              return data
            }
          }
        }
        case 'string': {
          switch (name) {
            case 'equal': {
              return value == data
            }
            case 'matches': {
              return data && value && data.toLowerCase().match(value.toLowerCase())
            }
            case 'contains': {
              return data && value && data.toLowerCase().includes(value.toLowerCase())
            }
            case 'notcontain': {
              return data && value && !data.toLowerCase().includes(value.toLowerCase())
            }
            case 'notequal': {
              return value !== data
            }
            case 'lengthgte': {
              return data && value <= data.length
            }
            case 'lengthlte': {
              return data && value >= data.length
            }
            case 'lengtheq': {
              return data && value === data.length
            }
            case 'lengthlt': {
              return data && value > data.length
            }
            case 'lengthgt': {
              return data && value < data.length
            }
            default: {
              return data
            }
          }
        }
        case 'array': {
          switch (name) {
            case 'includes': {
              if (!value || !data || data.length < 1) return false
              let expr
              try {
                expr = new RegExp(value, 'i')
              } catch {
                return data.includes(value)
              }
              if (!expr) return false
              for (let i = 0; i < data.length; i++) {
                if (data[i].match(expr)) return true
              }
              return false
            }
            case 'notincludes': {
              if (!value || !data || data.length < 1) return true
              let expr
              try {
                expr = new RegExp(value, 'i')
              } catch {
                return data.includes(value)
              }
              if (!expr) return true
              for (let i = 0; i < data.length; i++) {
                if (data[i].match(expr)) return false
              }
              return true
            }
            case 'exists': {
              return data && data.length > 0
            }
            default: {
              return data
            }
          }
        }
        case 'object': {
          switch (name) {
            case 'has': {
              return value && data && value in data
            }
            default: {
              return data
            }
          }
        }
        case 'date': {
          switch (name) {
            case 'gt': {
              return data && new Date(data).getTime() > new Date(value).getTime()
            }
            case 'lt': {
              return data && new Date(data).getTime() < new Date(value).getTime()
            }
            case 'gte': {
              return data && new Date(data).getTime() >= new Date(value).getTime()
            }
            case 'lte': {
              return data && new Date(data).getTime() <= new Date(value).getTime()
            }
            case 'between': {
              return data && filterMode['a'] && filterMode['b'] && new Date(data).getTime() - new Date(filterMode['a']).getTime() > 0 && new Date(data).getTime() - new Date(filterMode['b']).getTime() < 0
            }
            case 'exists': {
              return !isNaN(Date.parse(data))
            }
            default: {
              return data
            }
          }
        }
        case 'boolean': {
          return name == 'false' ? !data : data
      }
        default: {
          return data
        }
      }
    }
  }
  ffs = ffs.filter(u => u.mode.value)
  for (let i = 0; i < ffs.length; i++) {
    if (!ffs[i]) continue
    let { name, type, mode, count } = ffs[i]
    if (!name) continue
    filterMode = mode
    filterType = type
    filterField = name
    filterCount = count || Infinity
    data = data.filter(filter)
  }
  return data
}
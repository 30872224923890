import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Accordian from '../helpers/display/accordian'
class Notifications extends Component {
    state = {}
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    render() {
        return (<>
            <Helmet>
                <title>Notifications</title>
                <meta name="description" content="OMM Titles" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="la la-film" />
                                </span> Notifications</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Notifications</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="task-infos">
                            <div style={{ margin: '10px 0' }}>
                                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                    <li className="nav-item"><a className="nav-link active" href="#task-details" data-toggle="tab">New</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#task-related" data-toggle="tab">All</a></li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane show active" id="task-details">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">New Notifications</h3>
                                            <div className="row">
                                                <div className="col">
                                                    {this.props.notifications?.filter(a => !a.viewed).length > 0 ? this.props.notifications.filter(a => !a.viewed)?.map((u, i) => (<Accordian key={i} header={`${u.subject} - ${window.timeSince(u.date)}`} content={<div className="notification">
                                                        <p>{u.content}
                                                            <span className="message-time">{window.formatDate(u.date)} @ {window.formatTime(u.date)}</span>
                                                        </p>
                                                        <div>
                                                            <button onClick={() => {
                                                                window.app.viewNotification(u._id)
                                                            }}>View</button>
                                                            <button onClick={() => {
                                                                window.app.removeNotification(u._id)
                                                            }}>Delete</button>
                                                        </div>
                                                    </div>} />)) : <p>No New Notifications</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="task-related">
                                    <div className="crms-tasks">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Old Notifications</h3>
                                                <div className="row">
                                                    <div className="col">
                                                        {this.props.notifications?.length > 0 ? this.props.notifications?.map((u, i) => (<Accordian key={i} header={`${u.subject} - ${window.timeSince(u.date)}`} content={<div className="notification">
                                                            <p>{u.content}
                                                                <span className="message-time">{window.formatDate(u.date)} @ {window.formatTime(u.date)}</span>
                                                            </p>
                                                            <button onClick={() => {
                                                                window.app.removeNotification(u._id)
                                                            }}>Delete</button>
                                                        </div>} />)) : <p>No Notifications</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Notifications
import React, { Component } from 'react'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import ArrayDisplay from '../helpers/display/arraydisplay'
import NotesDisplay from '../helpers/display/notesdisplay'
import NotFound from '../helpers/display/notfound'
import TR from '../helpers/display/table-row'
import SidePop from '../helpers/display/sidepop'
class Product extends Component {
    state = { notes: [], noteData: [], view: 'details' }
    componentDidMount() {
        if (this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedProducts : window.app.state.products)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false })
            } else { this.setState({ notFound: true }) }
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = (this.props.restore ? window.app.state.archivedProducts : window.app.state.products)?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        }
    }
    reload = () => {
        let profile = (this.props.restore ? window.app.state.archivedProducts : window.app.state.products)?.find(a => a._id === this.props._id)
        if (profile) {
            this.setState({ ...profile, notFound: false })
        } else {
            this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
        }
    }
    render() {
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        window.product = this
        let productInfo = () => (<>
            <Accordian open={true} header="Product Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Product Type', this.state.productType]} />
                        <TR content={['Price', `$${window.currency(this.state.price)}`]} />
                        <TR content={['Quantity', this.state.quantity]} />
                        <TR content={['On Sale', window.bool(this.state.onSale)]} />
                        {this.state.onSale && <>
                            <TR content={['Sale Price', `$${window.currency(this.state.salePrice)}`]} />
                            <TR content={['Sale Starts', window.formatDate(this.state.saleStarts)]} />
                            <TR content={['Sale Ends', window.formatDate(this.state.saleEnds)]} />
                        </>}
                        <TR content={['Shipping Rate', `$${window.currency(this.state.shippingRate)}`]} />
                        <TR content={['Blacklisted Countries', <ArrayDisplay items={this.state.blacklistedCountries} />]} />
                    </tbody>
                </table>
            </>} />
        </>)
        let serviceInfo = () => (<>
            <Accordian open={true} header="Service Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Service Type', this.state.serviceType]} />
                        {this.state.serviceType === 'subscription' ? <>
                            <TR content={['Frequency', this.state.frequency]} />
                            <TR content={['Amount', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Trial Period', this.state.trialPeriod]} />
                        </> : this.state.serviceType === 'hourly' ? <>
                            <TR content={['Hourly Rate', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Number of Hours', this.state.quantity]} />
                        </> : this.state.serviceType === 'flat fee' ? <>
                            <TR content={['Fee Amount', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Installments', this.state.quantity]} />
                        </> : ''}
                    </tbody>
                </table>
            </>} />
        </>)
        let offeringInfo = () => (<>
            <Accordian open={true} header="Offering Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Offering Type', this.state.offeringType]} />
                    </tbody>
                </table>
            </>} />
        </>)
        return (<><div className="modal right fade show" style={{ display: 'block' }} onClick={this.props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
            <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close xs-close" onClick={this.props.close} data-dismiss="modal">×</button>
                        <div className="row w-100">
                            <div className="col-md-7 account">
                                <span className="modal-title users">{this.state.name} {this.props.restore && <a onClick={this.props.restore}><i style={{ margin: '3px' }} className="fa fa-sync"></i></a>}{this.props.edit && <a onClick={this.props.edit}><i style={{ margin: '3px' }} className="fa fa-pencil"></i></a>}{this.props.edit && <Link to={`/deal?_id=${this.state._id}`}><i style={{ margin: '3px', color: 'var(--d)' }} className="fa fa-expand"></i></Link>}</span>&nbsp;
                            </div>
                            <div className="col-md-5 text-right">
                                <ul className="list-unstyled list-style-none">
                                    <li className="dropdown list-inline-item"><br />
                                        <a className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"> Actions </a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item">Do Something</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow p-2 due-dates">
                        <div className="row m-0">
                            <div className="col">
                                <span>Company</span>
                                <p>{this.state.company && (window.app.state.companies?.find(a => a._id === this.state.company)?.name || this.state.company)}</p>
                            </div>
                            <div className="col">
                                <span>Contact</span>
                                <p>{this.state.contact && (window.app.state.users?.find(a => a._id === this.state.contact)?.name || this.state.contact)}</p>
                            </div>
                            <div className="col">
                                <span>Owner</span>
                                <p>{this.state.owner && (window.app.state.users?.find(a => a._id === this.state.owner)?.name || this.state.owner)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-md-12">
                                <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                    {window.productStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                        let status = e.target.getAttribute('data-status')
                                        if (!status) return window.flash('Status issue')
                                        window.app.addProduct({ fields: { _id: this.state._id, status } })
                                        this.setState({
                                            status, closed: (() => {
                                                if (window.closedStates.includes(status)) {
                                                    if (window.closedStates.includes(this.state.status)) return this.state.closed
                                                    return new Date()
                                                }
                                                return undefined
                                            })()
                                        })
                                    }}>
                                        <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                            <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                        </a>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                        <div className="task-infos">
                            <div className="tab-content">
                                <div className="tab-pane show active" id="task-details">
                                    <div className="crms-tasks">
                                        <Accordian open={true} header="General Information" content={<>
                                            <table className="table">
                                                <tbody>
                                                    <TR content={['Name', this.state.name]} />
                                                    <TR content={['Type', this.state.type]} />
                                                    <TR content={['Created', window.formatDate(this.state.created)]} />
                                                    {this.state.updated && <TR content={['Updated', window.formatDate(this.state.updated)]} />}
                                                    <TR content={['Description', this.state.description]} />
                                                    <TR content={['Available', window.bool(this.state.available)]} />
                                                    {!this.state.available && <TR content={['Available After', window.formatDate(this.state.availableAfter)]} />}
                                                    <TR content={['Categories', <ArrayDisplay items={this.state.categories} />]} />
                                                    <TR content={['Tags', <ArrayDisplay items={this.state.tags} />]} />
                                                    <TR content={['Notes', <NotesDisplay notes={this.state.notes} />]} />
                                                </tbody>
                                            </table>
                                        </>} />
                                        {this.state.type === 'product' ? productInfo() : this.state.type === 'service' ? serviceInfo() : this.state.type === 'offering' ? offeringInfo() : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default Product
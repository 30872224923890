import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import ArrayDisplay from '../helpers/display/arraydisplay'
import Circles from '../../assets/images/circle.png';
import CountDown from '../helpers/timer'
import TabLink from '../helpers/tablink'
import PitchForm from '../forms/pitchform'
import NotesDisplay from '../helpers/display/notesdisplay'
import Table from '../helpers/display/table'
import Error from './404'
import LoadingData from '../helpers/display/loadingdata'
class Pitch extends Component {
    state = {
        edit: false,
        changeImage: false,
        view: 'details',
        notes: [],
        noteData: [],
        notFound: true
    }
    componentDidMount() {
        this.setState({ _id: this.props._id }, this.init)
    }
    componentDidUpdate() {
        if (this.props._id !== this.state._id) this.setState({ _id: this.props._id }, this.init)
    }
    init = () => {
        let { _id } = this.state
        if (!_id) return this.setState({notFound: true})
        let t = window.app.state.pitches?.find(a => a._id === _id)
        if (!t) return this.setState({notFound: true})
        let m = Object.keys(this.state || {}).reduce((a, v) => ({ ...a, [v]: undefined }), {})
        let j = { ...m, ...t, edit: this.state.edit, _id: this.props._id, view: this.state.view, notFound: false }
        this.setState(j, () => {
            this.getActions()
        })
    }
    getActions() {
        if (this.gettingActions) return
        this.gettingActions = true
        window.app.request('/actions', 'post', { _id: this.state._id }).then(r => {
            this.gettingActions = false
            this.setState({ gotActions: true, actions: r.actions })
        }).catch(e => {
            this.gettingActions = false
            window.flash(e)
        })
    }
    render() {
        if (this.state.notFound) return <Error />
        window.pitch = this
        let activity = () => {
            let categories = ['Notes'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Notes': {
                        items = this.state.notes || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table columns={columns} data={items} mainKey={record => record._id} />} />
            return <div className="tab-pane show active" id="task-activity">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (u.name === 'Notes' ? <Accordian header={'Notes'} type="table" count={u.items?.length || 0} content={<NotesDisplay notes={u.items} />} /> : accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        let related = () => {
            let categories = ['Companies', 'Users', 'Titles'].map(u => {
                let items = []
                let columns = []
                switch (u) {
                    case 'Companies': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/company?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a._id === this.state.company ? 'Company' :  'Unknown'}</>),
                                sorter: (a, b) => {
      
                                }
                            }
                        ]
                        items = window.app.state.companies?.filter(u => {
                            if (u._id === this.state.company) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Users': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/profile?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{a._id === this.state.owner ? 'Owner' : a._id === this.state.contact ? 'Contact' : a._id === this.state.creator ? 'Created by' : 'uknown'}</>),
                                sorter: (a, b) => {
      
                                }
                            }
                        ]
                        items = window.app.state.users?.filter(u => {
                            if (this.state.owner === u._id || this.state.contact === u._id || this.state.creator === u._id) return true
                            return false
                        }) || []
                        break
                    }
                    case 'Titles': {
                        columns = [
                            {
                                title: "Name",
                                dataIndex: "name",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record._id}`} text={text} /></>
                                ),
                                sorter: (a, b) => window.nameCompare(a.name, b.name)
                            },
                            {
                                title: "Related By",
                                dataIndex: "_id",
                                render: (text, a) => (<>{this.state.titles?.includes(a._id) ? 'Title' : this.state.titleAllocations?.find(b => b.title === a._id)  ? 'Title Allocations' : 'unknown'}</>),
                                sorter: (a, b) => {
      
                                }
                            }
                        ]
                        items = window.app.state.titles?.filter(u => {
                            if (this.state.titles?.includes(u._id) || this.state.titleAllocations?.find(a => a.title === u._id)) return true
                            return false
                        }) || []
                        break
                    }
                    default: {
                        break
                    }
                }
                return ({ name: u, items, count: items.length, columns })
            })
            let head = (name, count, colors) => <div className="col-md-4">
                <div className={`card bg-gradient-${colors || 'danger'} card-img-holder text-white h-100`}>
                    <div className="card-body">
                        <img src={Circles} className="card-img-absolute" alt="" />
                        <h4 className="font-weight-normal mb-3">{name}</h4>
                        <span>{count}</span>
                    </div>
                </div>
            </div>
            let accordian = (name, count, items, columns) => <Accordian header={name} count={count} content={<Table data={items} columns={columns} mainKey={record => record._id} />} />
            return <div className="tab-pane task-related  show active" id="task-related">
                <div className="row pt-3">
                    {categories.map((u, i) => (head(u.name, u.count)))}
                </div>
                <div className="row">
                    <div className="crms-tasks  p-2">
                        {categories.map((u, i) => (accordian(u.name, u.count, u.items, u.columns)))}
                    </div>
                </div>
            </div>
        }
        return (<>
            {this.state.edit && <PitchForm _id={this.state._id} crm={this.state.crm || window.app.state.crm} add={form => {
                return new Promise((res, rej) => {
                    if (!form) return rej('Missing form')
                    window.app.pitchForm(form).then(deal => {
                        this.init()
                        res(deal)
                    }).catch(e => rej(e))
                })
            }} close={() => this.setState({ edit: false })} />}
            <Helmet>
                <title>{this.state.name || 'Pitch'}</title>
                <meta name="description" content="An Octane Multimedia film" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> {this.state.name || 'Pitch'}</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/deals">Deals</Link></li>
                        <li className="breadcrumb-item active">{this.state.name || 'Pitch'}</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">
                                <span>Name</span>
                                <p>{this.state.name} <a onClick={() => this.setState({ edit: true })}><i className="fa fa-pencil"></i></a></p>
                            </div>
                            <div className="col-md-2">
                                <span>Value</span>
                                <p>${window.currency(this.state.value)}</p>
                            </div>
                            <div className="col-md-8">
                                <span>Term</span>
                                <p>{this.state.endDate ? <CountDown countDown={false} start={this.state.startDate} expires={new Date(this.state.endDate)} /> : 'Perpetual'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-md-12">
                                <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                    {window.pitchStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                        let status = e.target.getAttribute('data-status')
                                        if (!status) return window.flash('Status issue')
                                        window.app.addPitch({ fields: { _id: this.state._id, status } })
                                        this.setState({
                                            status, closed: (() => {
                                                if (window.closedStates.includes(status)) {
                                                    if (window.closedStates.includes(this.state.status)) return this.state.closed
                                                    return new Date()
                                                }
                                                return undefined
                                            })()
                                        })
                                    }}>
                                        <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                            <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                        </a>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="task-infos">
                        <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                <li className="nav-item"><a className={this.state.view === 'details' ? "nav-link active" : 'nav-link'} href="#task-details" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'details' })
                                }}>Details</a></li>
                                <li className="nav-item"><a className={this.state.view === 'related' ? "nav-link active" : 'nav-link'} href="#task-related" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'related' })
                                }}>Related</a></li>
                                <li className="nav-item"><a className={this.state.view === 'activity' ? "nav-link active" : 'nav-link'} href="#task-activity" onClick={e => {
                                    e.preventDefault();
                                    this.setState({ view: 'activity' })
                                }}>Activity</a></li>
                            </ul>
                            <div className="tab-content">
                                {this.state.view === 'details' ? <div className="tab-pane show active" id="task-details">
                                <Accordian open={true} header="General Information" content={<>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td className="border-0">Name</td>
                                                    <td className="border-0">{this.state.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Company</td>
                                                    <td className="border-0">{this.state.company && (window.app.state.companies?.find(a => a._id === this.state.company)?.name || this.state.company)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Contacts</td>
                                                    <td className="border-0"><ArrayDisplay items={this.state.contacts || []} template={(u,i) => (<li key={i}>{window.app.state.users?.find(a => a._id === u)?.name || u}</li>)} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Titles</td>
                                                    <td className="border-0"><ArrayDisplay items={this.state.titles || []} template={(u,i) => (<li key={i}>{window.app.state.titles?.find(a => a._id === u)?.title || u}</li>)} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Status</td>
                                                    <td className="border-0">{this.state.status}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Type</td>
                                                    <td className="border-0">{this.state.type}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>} />
                                    <Accordian open={true} header="Details" content={<>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td className="border-0">Pitch Deck</td>
                                                    <td className="border-0">{this.state.pitchDeck}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Products</td>
                                                    <td className="border-0"><ArrayDisplay items={this.state.products || []} template={(u,i) => (<li key={i}>{window.app.state.products?.find(a => a._id === u)?.name || u}</li>)} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Territories</td>
                                                    <td className="border-0"><ArrayDisplay items={this.state.territories || []} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Key Points</td>
                                                    <td className="border-0"><ArrayDisplay items={this.state.keyPoints || []} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Last Meeting</td>
                                                    <td className="border-0">{this.state.lastMeeting && window.formatDate(this.state.lastMeeting)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Next Meeting</td>
                                                    <td className="border-0">{this.state.nextMeeting && window.formatDate(this.state.nextMeeting)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Notes</td>
                                                    <td className="border-0"><NotesDisplay notes={this.state.notes}/></td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Owner</td>
                                                    <td className="border-0">{this.state.owner && (window.app.state.users?.find(a => a._id === this.state.owner)?.name || this.state.owner)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>} />
                                
                                </div> : this.state.view === 'related' ? related() : activity()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Pitch




import React, { Component } from 'react'
class Sorter extends Component {
    state = {
        sortMode: [],
        sort: ''
    }
    componentDidMount() {

    }
    componentDidUpdate() {

    }
    getListFields = list => {
        if (!list) return []
        if (!list instanceof Array) return [list]
        return Object.entries(list.map(u => Object.entries(u)).reduce((a, b) => {
            for (let i = 0; i < b.length; i++) {
                let key = b[i][0]
                let value = b[i][1]
                let type = typeof value
                type = ['title', '_id', 'metadata', 'id'].includes(key) ? 'string' : type === 'boolean' ? 'boolean' : value instanceof Array ? 'array' : type === 'number' ? 'number' : window.testDate(value) ? 'date' : type === 'object' ? 'object' : 'string'
                if (!a[key]) a[key] = { entries: [], types: {} }
                a[key].entries.push(value)
                if (!a[key].types[type]) {
                    a[key].types[type] = 1
                } else {
                    a[key].types[type]++
                }
            }
            return a
        }, {})).reduce((a, b) => {
            a.push({
                key: b[0], type: Object.entries(b[1].types).reduce((a, b) => {
                    if (a.count < b[1]) return { type: b[0], count: b[1] }
                    return a
                }, { type: '', count: 0 }).type
            })
            return a
        }, [])
    }
    sort = (data, fields) => {
        let sfs = fields
        if (!data) return []
        var sortType = ''
        var sortField = ''
        var sortDirection = false
        let sort = (a, b) => {
            if (!a[sortField] && !b[sortField]) {
                if (!a && !b) return 0
                if (a && !b) return 1
                if (b && !a) return -1
                switch (sortType) {
                    case 'date': {
                        return new Date(a).getTime() - new Date(b).getTime()
                    }
                    case 'array' || 'object': {
                        if (a instanceof Array || b instanceof Array) {
                            return a.length - b.length
                        } else {
                            return Object.keys(a)?.length - Object.keys(b?.length)
                        }
                    }
                    case 'number': {
                        return a - b
                    }
                    case 'string': {
                        return (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) < (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? -1 : (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) === (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? 0 : 1
                    }
                    default: {
                        return a < b ? -1 : a === b ? 0 : 1
                    }
                }
            } else {
                if (a[sortField] && !b[sortField]) return 1
                if (b[sortField] && !a[sortField]) return -1
                switch (sortType) {
                    case 'date': {
                        if (!isNaN(Date.parse(a[sortField])) && isNaN(Date.parse(b[sortField]))) return 1
                        if (isNaN(Date.parse(a[sortField])) && !isNaN(Date.parse(b[sortField]))) return -1
                        return new Date(a[sortField]).getTime() - new Date(b[sortField]).getTime()
                    }
                    case 'array' || 'object': {
                        if (a[sortField] instanceof Array || b[sortField] instanceof Array) {
                            return a[sortField].length - b[sortField].length
                        } else {
                            return Object.keys(a[sortField])?.length - Object.keys(b[sortField]?.length)
                        }
                    }
                    case 'number': {
                        return a[sortField] - b[sortField]
                    }
                    case 'string': {
                        return a[sortField].toLowerCase() < b[sortField].toLowerCase() ? -1 : a[sortField].toLowerCase() === b[sortField].toLowerCase() ? 0 : 1
                    }
                    default: {
                        return a[sortField] < b[sortField] ? -1 : a[sortField] === b[sortField] ? 0 : 1
                    }
                }
            }
        }
        for (let i = sfs.length - 1; i >= 0; i--) {
            let sf = sfs[i]
            if (!sf) continue
            sortDirection = sf.direction
            sortField = sf.name
            sortType = sf.type
            if (sortType === 'array') {
                let c = false
                let s = sortType
                for (let z = 0; z < data.length; z++) if (data[z][sortField]) {
                    c = true
                    sortType = typeof data[z][sortField]
                    z = data.length
                }
                if (c) {
                    data = data.map(u => {
                        u[sortField] = u[sortField].sort(sort)
                        return u
                    })
                    sortType = s
                }
            }
            data = sortDirection ? data.sort(sort).reverse() : data.sort(sort)
        }
        return data
    }
    render() {
        let { change, list } = this.props
        let fields = this.getListFields(list)
        let handleChange = () => {
            if (typeof change === 'function') change(this.sort(list, this.state.sortMode))
        }
        return (<div className="b2" style={{ overflow: 'visible' }}>
            <div className="dropdown">
                <a className="dropdown-toggle recently-viewed" href="#" role="button" data-toggle="dropdown" aria-expanded="false">Sort By: {this.state.sort && window.parseKey(this.state.sort)}</a>
                <div className="dropdown-menu" >
                    {fields?.map((u, i) => <div key={i} onClick={e => this.setState({ sort: u.key, sortMode: [{ ...this.state.sortMode[0], name: u.key, type: u.type }] }, handleChange)}
                        className="dropdown-item" value={u.key}>{window.parseKey(u.key)}</div>)}
                </div>
            </div>
            {this.state.sortMode && <div style={{ marginLeft: '12px' }} className="dropdown">
                <a className="dropdown-toggle recently-viewed" href="#" role="button" data-toggle="dropdown" aria-expanded="false">{this.state.sortMode[0]?.direction ? 'Descending' : 'Ascending'}</a>
                <div className="dropdown-menu" >
                    {['true', 'false'].map((u, i) => <div key={i} onClick={e => this.setState({ sortMode: this.state.sortMode ? [{ ...this.state.sortMode[0], direction: e.target.getAttribute('value') === 'true' ? true : false }] : [{ direction: e.target.value === 'true' ? true : false }] }, handleChange)} className="dropdown-item" value={u}>{u === 'true' ? 'Descending' : 'Ascending'}</div>)}
                </div>
            </div>}
        </div>)
    }
}
export default Sorter
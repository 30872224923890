import React, { Component } from 'react'
import Mark from './helpers/mark'
import ModelView from '../main/model-view'
import Model from '../main/model'
import Title from './title'
import Table from '../../helpers/display/table'
class Screeners extends Component {
    state = {
        movies: [], all: false, selected: [],
        model: new Model(),
        models: [],
        fields: [],
        selectedFields: [],
        tall: false,
        aFields: false,
        selectedRowKeys: [],
    }
    addField(name, type) {
        if (!name || !type) return window.flash('Missing name or type')
        if (this.props.fields.find(u => u.name === name)) return window.flash('Field already exists in model')
        this.setState({ fields: [...this.props.fields, { name, type }] })
    }
    componentDidMount() {
        window.app.getModels()
    }
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selected];
        let value = record
        if (selectedRowKeys.indexOf(value) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(value), 1);
        } else {
            selectedRowKeys.push(value);
        }
        this.setState({ selected: selectedRowKeys });
    }
    onSelectedRowKeysChange = (selected) => {
        this.setState({ selected });
    }
    setModel = (id) => {
        this.setState({ model: new Model({ ...this.props.models.find(u => u._id === id), items: ((this.state.tall || this.state.all) ? [...this.props.movies].map(u => u._id) : this.state.selected) }), viewModel: true })
    }
    removeTitle(id) {
        window.app.request('/delete-title', 'post', { id }).then(u => {
            window.app.getTitles()
            this.setState({ selected: [...this.state.selected].filter(u => u !== id) })
        }).catch(e => window.flash(e))
    }
    render() {
        const { selected } = this.state;
        const rowSelection = {
            selectedRowKeys: selected,
            onChange: this.onSelectedRowKeysChange,
        };
        window.screeners = this
        if (this.state.viewModel) return <>
            <button className="top-right-abs" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ viewModel: false })
            }}>Close Report</button>
            <div className="b1 rel">
                <ModelView fields={this.props.fields} model={this.state.model} titles={(this.state.all || this.state.tall) ? this.props.movies : [...this.props.movies].filter(u => this.state.selected.includes(u._id))} mode={this.state.editModel}></ModelView>
            </div>
        </>
        if (this.state.viewTitle) return (<div className="b1 rel"><Title fields={this.props.fields} keys={this.props.fields.map(u => u.name)} title={this.state.viewTitle}></Title><button className="bottom-right-float" onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ viewTitle: false })
        }}>Close Title</button></div>)
        return (<div className="b1">
            {this.state.chooseReport && <div id="reportFields" onClick={e => {
                if (e.target.id === 'reportFields') this.setState({ chooseReport: false, tall: false })
            }}>
                <div id="reportholder" className="b1 jfs rel">
                    <div><h3>Keys</h3>
                        <button onClick={() => this.setState({ aFields: !this.state.aFields })}>All Fields</button>
                        <div className="c3">{this.props.fields?.length > 0 && this.props.fields.map((u, i) => {
                            return (<div key={i} className="b2 jfs"><Mark hover={false} x={(this.state.aFields || this.state.selectedFields.find(a => a.name === u.name)) ? false : true} /><button name={u.name} onClick={e => {
                                let key = e.target.getAttribute(['name'])
                                if (this.state.aFields) {
                                    this.setState({ aFields: false, selectedFields: [...this.props.fields].filter(u => u.name !== key) })
                                } else {
                                    let f = this.state.selectedFields.find(u => u.name === key)
                                    if (f) {
                                        this.setState({ selectedFields: [...this.state.selectedFields].filter(u => u.name !== key) })
                                    } else {
                                        let z = this.props.fields.find(u => u.name === key)
                                        if (z) this.setState({ selectedFields: [...this.state.selectedFields, z] })
                                    }
                                }
                            }} className={"reportButton" + ((this.state.aFields || this.state.selectedFields.find(a => a.name === u.name)) ? ' selectedButton' : '')}>{u.name.toUpperCase().split('_').join(' ')}</button></div>)
                        })}</div>{(this.state.selectedFields?.length > 0 || this.state.aFields) && <button className="bigbut" onClick={() => {
                            this.setState({ model: new Model({ fields: (this.state.aFields ? this.props.fields : this.state.selectedFields), items: (this.state.all || this.state.tall) ? [...this.props.movies].map(u => u._id) : this.state.selected }), viewModel: true })
                        }}>RUN REPORT</button>}</div>
                    <div><h3>Templates</h3>{this.props.models.map((u, i) => {
                        return (<button className="reportButton" key={i} id={u._id} onClick={e => {
                            this.setModel(e.target.id)
                        }}>{u.modelName || 'New Template'}</button>)
                    })}</div>
                    <button onClick={u => {
                        this.setState({ model: new Model({ fields: this.state.aFields ? this.props.fields : this.state.selectedFields, items: (this.state.all || this.state.tall) ? [...this.props.movies].map(u => u._id) : this.state.selected }), viewModel: true, editModel: true })
                    }}>New Template</button>
                    {/* <button className="bottom-right-abs" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ chooseReport: false, tall: false })
                    }}>Cancel</button> */}
                </div>
            </div>}
            {/* <div id="buttons">
                <button className="specbutton" onClick={() => {
                    this.setState({ viewTitle: true })
                }}>Add Title</button>
            </div> */}
            <div className="b1" style={{ margin: '10px 0' }}>
                <h3>Select Titles</h3>
                <Table className="table"
                    style={{ overflowX: "auto" }}
                    columns={[
                        {
                            title: "Title",
                            dataIndex: "title",
                            render: (text, record) => (
                                <>{record.keyArt ? <a href="#" onClick={e => {
                                    e.preventDefault()
                                    this.selectRow(record)
                                }} className="avatar"><img alt="" src={record.keyArt} /></a> : ''}
                                    <a onClick={e => {
                                        e.preventDefault()
                                        this.selectRow(record)
                                    }} href="#" data-toggle="modal" data-target="#company-details">{text}</a></>
                            ),
                            sorter: (a, b) => window.nameCompare(a.title, b.title)
                        },
                    ]}
                    data={this.props.movies?.length > 0 ? ([...this.props.movies].sort((a, b) => {
                        let c = a.title && a.title.value ? a.title.value.toLowerCase() : a.title ? a.title.toLowerCase() : ''
                        let d = b.title && b.title.value ? b.title.value.toLowerCase() : b.title ? b.title.toLowerCase() : ''
                        return c < d ? -1 : c === d ? 0 : 1
                    })) : []}
                    rowSelection={rowSelection} />
            </div>
            {/*   <div id="tablehold" style={{ width: '100%', maxWidth: '80%', margin: '20px 0' }}>
                <table id="titleTable">
                    <thead>
                        <tr>
                            {!this.props.edit && <th>
                                <input style={{ cursor: 'pointer', maxWidth: '100%', height: '20px', width: '100%' }} type="checkbox" checked={this.state.all || this.state.tall} onChange={e => {
                                    this.setState({ all: e.target.checked })
                                }}></input>
                            </th>}
                            <th><strong>ID</strong></th>
                            <th><strong>Title</strong></th>
                            <th><strong></strong></th>
                            <th><strong></strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.movies?.length > 0 && [...this.props.movies].sort((a, b) => {
                            let c = a.title && a.title.value ? a.title.value.toLowerCase() : a.title ? a.title.toLowerCase() : ''
                            let d = b.title && b.title.value ? b.title.value.toLowerCase() : b.title ? b.title.toLowerCase() : ''
                            return c < d ? -1 : c === d ? 0 : 1
                        }).map((u, i) => {
                            return (<tr key={i} id={u._id}>
                                {!this.props.edit && <td>
                                    <input type="checkbox" checked={this.state.all ? true : this.state.selected.includes(u._id) ? true : false} onChange={e => {
                                        if (this.state.all) return
                                        let c = e.target.checked
                                        let id = e.target.parentElement.parentElement.id
                                        let j = this.state.selected.find(u => u === id)
                                        if (c && !j) {
                                            this.setState({ selected: [...this.state.selected, id] })
                                        } else if (!c && j) {
                                            this.setState({ selected: [...this.state.selected].filter(u => u !== id) })
                                        }
                                    }}></input>
                                </td>}
                                <td>{u._id}</td>
                                <td><strong>{u.title}</strong></td>
                                <td>
                                    <button className="tablebutton" data={u._id} onClick={e => {
                                        let id = e.target.getAttribute(['data'])
                                        this.setState({ viewTitle: this.props.movies.find(u => u._id == id) })
                                    }}>Edit</button>
                                </td>
                                <td>
                                    <button className="tablebutton" data={u._id} onClick={e => {
                                        let id = e.target.getAttribute(['data'])
                                        this.removeTitle(id)
                                    }}>Remove</button>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div> */}
            {!this.props.edit && <button className="bottom-right-float" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                let s = { chooseReport: true }
                if (!this.state.selected?.length > 0) s.tall = true
                this.setState(s)
            }}>Run Report</button>}
        </div>)
    }
}
export default Screeners
import React, { Component } from 'react'
import ArrayInput from '../helpers/inputs/arrayinput'
import YesNo from '../helpers/inputs/yesno'
import TitleAllocations from '../helpers/inputs/titleallocations'
import DealNotes from '../helpers/inputs/notesinput'
import DateInput from '../helpers/inputs/dateinput'
import Accordian from '../helpers/display/accordian'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class DealForm extends Component {
    state = { titles: [], dealNotes: [] }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.deals?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile })
        } else if (this.props.type) {
            this.setState({ type: this.props.type })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.deals?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
            }
        } else if (!this.state._id && this.props.type !== this.state.type) {
            this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({ subbing: true })
        window.app.dealForm(e.target).then(r => {
            this.pending = []
            this.setState({ ...r, subbing: false })
            window.flash('Deal Saved')
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        window.dealform = this
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Deal</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    {<Accordian open={true} header="General Information" content={<div className="form-group row">
                                        <div className="col-md-12">
                                            <label className="col-form-label">Name</label>
                                            <input className="form-control" autoComplete="none" type="text" placeholder="Deal Name" defaultValue={this.state.name} name="name" />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Contact</label>
                                            <select className="form-control" value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} name="contact">
                                                <option value="">Select One</option>
                                                {window.app.state.users?.filter(u => u.crm).map((u, i) => (<option key={i} value={u._id}>{u.name} - {u.crm || 'Internal'}</option>))}
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Company</label>
                                            <select className="form-control" value={this.state.company} onChange={e => this.setState({ company: e.target.value })} name="company">
                                                <option value="">Choose a Company</option>
                                                {window.app.state.companies?.filter(a => this.props.crm || a.crm === 'film-sales').sort((a,b) => window.nameCompare(a.name, b.name)).map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Territory(ies)</label>
                                            <ArrayInput selected={this.state.territories || []} items={window.territories} name={'territories'} change={e => { }} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Probability</label>
                                            <select className="form-control" value={this.state.probability} onChange={e => this.setState({ probability: e.target.value })} name="probability">
                                                <option value="">Select One</option>
                                                {[10, 25, 50, 75, 90, 100].map(u => u.toString() + '%').map((u, i) => (<option key={i} value={u.split('%')[0]}>{u}</option>))}
                                            </select>
                                        </div>
                                    </div>} />}
                                    {<Accordian header="Deal Values" content={<div className="form-group row">
                                        <div className="col-md-12">
                                            <label className="col-form-label" htmlFor="value">Total Value</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input autoComplete="off" name="value" type="number" placeholder="N/A" className={"form-control"} value={this.state.value?.toString()} onChange={e => { this.setState({ value: window.parseNumber(e.target.value) }) }} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">.00</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label" htmlFor="royalties">Royalties</label>
                                            <YesNo value={this.state.royalties} change={e => this.setState({ royalties: e })} name="royalties" />
                                        </div>
                                        <div className="col-md-8">
                                            <label className="col-form-label" htmlFor="royalties">Royalty Splits</label>
                                            <ArrayInput type="text" name="royaltySplits" selected={this.state.royaltySplits} placeholder="Enter a split" change={e => {

                                            }} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label" htmlFor="royalties">Title Allocations</label>
                                            <TitleAllocations total={this.state.value || 0} name="titleAllocations" selected={this.state.titleAllocations} change={e => { }} />
                                        </div>
                                    </div>} />}
                                    {<Accordian header="Terms And Conditions" content={<div className="form-group row">
                                        <div className="col-md-4">
                                            <label className="col-form-label">Term</label>
                                            <select className="form-control" value={this.state.term || (this.state.endDate ? 'Fixed Term' : 'Until Further Notice')} onChange={e => this.setState({ term: e.target.value })}>
                                                <option value="">Select One</option>
                                                {window.dealTerms?.map((u, i) => (<option key={i} value={u}>{u}</option>))}
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label">Start Date</label>
                                            <DateInput name="startDate" value={this.state.startDate || new Date()} onChange={e => {
                                                this.setState({ startDate: e })
                                            }} />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label">End Date</label>
                                            <DateInput name="endDate" value={this.state.endDate} onChange={e => {
                                                this.setState({ endDate: e })
                                            }} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Rights</label>
                                            <ArrayInput name="rights" items={window.dealRights} selected={this.state.rights} onChange={e => { }} />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label">Exclusivity</label>
                                            <select name="exclusivity" value={this.state.exclusivity} onChange={e => {
                                                this.setState({ exclusivity: e.target.value })
                                            }}>
                                                <option value="Non-Exclusive">Non-Exclusive</option>
                                                <option value="Exclusive">Exclusive</option>
                                                <option value="Both">Both</option>
                                            </select>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <label className="col-form-label">Exclusive</label>
                                            <YesNo name="exclusive" value={this.state.exclusive} change={e => this.setState({ exclusive: e })} />
                                        </div> */}
                                    </div>} />}
                                    {<Accordian header="Communication" content={<div className="form-group row">
                                        <div className="col-md-12">
                                            <label className="col-form-label">Notes</label>
                                            <DealNotes followUp={true} name="notes" _id={this.state._id} notes={this.state.notes} change={async note => {
                                                this.handlePending(note)
                                                let notes = [...this.state.notes, note]
                                                this.setState({ notes })
                                            }} remove={_id => {
                                                this.handlePending(_id)
                                                let notes = [...this.state.notes].filter(u => u._id !== _id)
                                                this.setState({ notes })
                                            }} />
                                        </div>
                                    </div>} />}

                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    <input type="hidden" name="owner" value={this.state.owner} />
                                    <input type="hidden" name="crm" value={this.props.crm} />
                                    <input type="hidden" name="_id" value={this.state._id} />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default DealForm
import React, { Component } from 'react'
import DateInput from '../inputs/dateinput2'
class Calendar extends Component {
    constructor() {
        super()
        this.date = React.createRef()
        this.start = new Date()
        this.state = {
            year: this.start.getFullYear(),
            month: this.start.getMonth(),
            day: this.start.getDate(),
            hour: this.start.getHours(),
            minute: this.start.getMinutes()
        }
    }
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
    componentDidMount() {

    }
    nextMonth() {
        if (this.state.month >= 11) {
            this.setState({ month: 0, year: this.state.year + 1, day: 1 }, () => this.setCalendar())
        } else  {
            this.setState({ month: this.state.month + 1, day:1 }, () => this.setCalendar())
        }
    }
    prevMonth() {
        if (this.state.month <= 0) {
            this.setState({ month: 11, year: this.state.year - 1, day: 1 }, () => this.setCalendar())
        } else {
            this.setState({ month: this.state.month - 1, day: 1 }, () => this.setCalendar())
        }
    }
    addDay(number) {
        return (<td><input className="C344" type="button" value={number}></input></td>)
    }
    setCalendar(n) {
        let currentDate = new Date(this.state.year, this.state.month, n || this.state.day)
        if (typeof this.props.set === 'function') this.props.set(currentDate)
        return currentDate
    }
    render() {
        let firstDay = new Date(this.state.year, this.state.month, 1);
        let lastDay = new Date(this.state.year, this.state.month + 1, 0);
        let offset = firstDay.getDay();
        let dayCount = 1
        let myMonth = (this.state.month === this.start.getMonth())
        let date = new Date(this.state.year, this.state.month, this.state.day)
        return (<div className="b1">
            <div className="b2 spa"><button className="C331" id="C300" onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                this.prevMonth()
            }}>{'<'}</button>
                <div style={{maxWidth: '100%', width: 'auto'}}>{!isNaN(date.getTime()) && <DateInput options={{showMonthYearPicker: true, showFullMonthYearPicker: true, showMonthPicker:true, showYearDropdown: true,  dateFormat: 'MMMM yyyy', icons:{
				up: "fa fa-angle-up",
				down: "fa fa-angle-down",
				next: 'fa fa-angle-right',
				previous: 'fa fa-angle-left'
			}}} value={date} change={date => {this.setState({ year: date.getFullYear(), month: date.getMonth(), day: 1 })}}/>}</div>
                <button className="C331" id="C301" onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.nextMonth()
                }}>{'>'}</button></div>
            <div className="table-responsive">
                <table className="calendarTable">
                    <thead><tr>{[...this.days].map((u, i) => (<td key={i}>{u}</td>))}</tr></thead>
                    <tbody>{[1, 2, 3, 4, 5, 6].map((u, i) => {
                        if (u === 6 && dayCount >= lastDay.getDate()) return;
                        return (<tr key={i}>{[1, 2, 3, 4, 5, 6, 7].map((u, i) => {
                            let d = dayCount
                            if (offset === 0) {
                                if (dayCount > lastDay.getDate()) return (<td key={i}></td>)
                                dayCount++
                                let td = new Date(this.state.year, this.state.month, dayCount)
                                let today = new Date()
                                let past = td.getTime() - today.getTime() < 0
                                return (<td data-status={d} onDrop={this.props.onDrop}
                                    onDragExit={this.props.onDragExit}
                                    onDragLeave={this.props.onDragLeave}
                                    onDragOver={this.props.onDragOver} key={i} className={'rel' + ((past) ? ' blackedOut' : '') + (this.state.day === d ? ' selectedMonthCalendar' : myMonth && d === this.start.getDate() && this.state.year === new Date().getFullYear() ? ' todaysDateCalendar' : '')} onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (past) return
                                        this.setState({ day: parseInt(e.currentTarget.getAttribute('date')) })
                                        this.setCalendar(parseInt(e.currentTarget.getAttribute('date')))
                                    }} date={d}>
                                    <span className="absdate">{d}</span>
                                    <div className="calendarItems">{this.props.items?.filter(u => window.compareDates(u.date, new Date(`${this.state.month + 1}-${d}-${this.state.year}`))).map(typeof this.props.template === 'function' ? this.props.template : (u, i) => (<div key={i} id={u._id} >{JSON.stringify(u)}</div>))}</div>
                                </td>)
                            } else {
                                offset--
                                return (<td key={i}></td>)
                            }
                        })}</tr>)
                    })}</tbody>
                </table>
            </div>
        </div>)
    }
}
export default Calendar
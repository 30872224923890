class Model {
    constructor(model) {
        this.fields = []
        this.items = []
        this.sortFields = []
        this.filterFields = []
        if (model) {
            let keys = Object.keys(model)
            for (let i = 0; i < keys.length; i++) this[keys[i]] = model[keys[i]]
        }
    }
    addField(name, type) {
        if (this.fields.find(u => u.name === name)) return window.alert('Field already exists in model')
        this.fields.push({ name, type })
    }
    removeField(name) {
        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].name === name) {
                return this.fields.splice(i, 1)[0]
            }
        }
        return null
    }
    saveModel() {
        window.app.request('/model', 'post', { model: this }).then(u => {
            window.flash('SAVED MODEL!')
            window.app.getModels()
        }).catch(e => {
            window.flash(e)
        })
    }
    filter(data) {
        if (!data) return []
        let ffs = this.filterFields
        var filterType = ''
        var filterField = ''
        var filterCount = Infinity
        var filterMode = null
        let filter = (u, i) => {
            let { name, value, compare } = filterMode
            if (compare && typeof value === 'object' && value instanceof Array) {
                let r = []
                let fm = filterMode, ft = filterType, ff = filterField, fc = filterCount;
                for (let z = 0; z < value.length; z++) {
                    let { name, type, mode, count } = value[i]
                    if (!name) continue
                    filterMode = mode
                    filterType = type
                    filterField = name
                    filterCount = count || Infinity
                    r.push(filter(u))
                }
                filterMode = fm
                filterType = ft
                filterField = ff
                filterCount = fc
                if (compare === 'not') {
                    return r.length > 0 && r.filter(u => u).length === 0
                } else if (compare === 'or') {
                    return r.filter(u => u).length > 0
                } else {
                    return r.filter(u => u).length === value.length
                }
            } else {
                if (!u || (!u[filterField] && u[filterField] === 0)) return false
                if (i > filterCount) return false
                const data = u[filterField]
                switch (filterType) {
                    case 'number': {
                        switch (name) {
                            case 'gt': {
                                return data > value
                            }
                            case 'lt': {
                                return data < value
                            }
                            case 'gte': {
                                return data >= value
                            }
                            case 'lte': {
                                return data <= value
                            }
                            case 'equal': {
                                return data === value
                            }
                            default: {
                                return data
                            }
                        }
                    }
                    case 'string': {
                        switch (name) {
                            case 'equal': {
                                return value === data
                            }
                            case 'matches': {
                                return data && value && data.toLowerCase().match(value.toLowerCase())
                            }
                            case 'contains': {
                                return data && value && data.toLowerCase().includes(value.toLowerCase())
                            }
                            case 'notcontain': {
                                return data && value && !data.toLowerCase().includes(value.toLowerCase())
                            }
                            case 'notequal': {
                                return value !== data
                            }
                            case 'lengthgte': {
                                return data && value <= data.length
                            }
                            case 'lengthlte': {
                                return data && value >= data.length
                            }
                            case 'lengtheq': {
                                return data && value === data.length
                            }
                            case 'lengthlt': {
                                return data && value > data.length
                            }
                            case 'lengthgt': {
                                return data && value < data.length
                            }
                            default: {
                                return data
                            }
                        }
                    }
                    case 'array': {
                        switch (name) {
                            case 'includes': {
                                return data.includes(value)
                            }
                            case 'notincludes': {
                                return !data.includes(value)
                            }
                            default: {
                                return data
                            }
                        }
                    }
                    case 'object': {
                        switch (name) {
                            case 'has': {
                                return value in data
                            }
                            default: {
                                return data
                            }
                        }
                    }
                    case 'date': {
                        switch (name) {
                            case 'gt': {
                                return data && new Date(data).getTime() > new Date(value).getTime()
                            }
                            case 'lt': {
                                return data && new Date(data).getTime() < new Date(value).getTime()
                            }
                            case 'gte': {
                                return data && new Date(data).getTime() >= new Date(value).getTime()
                            }
                            case 'lte': {
                                return data && new Date(data).getTime() <= new Date(value).getTime()
                            }
                            case 'between': {
                                return data && filterMode['a'] && filterMode['b'] && new Date(data).getTime() - new Date(filterMode['a']).getTime() > 0 && new Date(data).getTime() - new Date(filterMode['b']).getTime() < 0
                            }
                            case 'exists': {
                                return !isNaN(Date.parse(data))
                            }
                            default: {
                                return data
                            }
                        }
                    }
                    default: {
                        return data
                    }
                }
            }
        }
        for (let i = 0; i < ffs.length; i++) {
            if (!ffs[i]) continue
            let { name, type, mode, count } = ffs[i]
            // console.log(i, name, type, mode, count)
            if (!name) continue
            filterMode = mode
            filterType = type
            filterField = name
            filterCount = count || Infinity
            data = data.filter(filter)
        }
        return data
    }
    sorter(data) {
        let sfs = this.sortFields
        if (!data) return []
        var sortType = ''
        var sortField = ''
        var sortDirection = false
        let sort = (a, b) => {
            if (!a[sortField] && !b[sortField]) {
                if (!a && !b) return 0
                if (a && !b) return 1
                if (b && !a) return -1
                switch (sortType) {
                    case 'date': {
                        return new Date(a).getTime() - new Date(b).getTime()
                    }
                    case 'array' || 'object': {
                        if (a instanceof Array || b instanceof Array) {
                            return a.length - b.length
                        } else {
                            return Object.keys(a)?.length - Object.keys(b?.length)
                        }
                    }
                    case 'number': {
                        return a - b
                    }
                    case 'string': {
                        return (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) < (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? -1 : (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) === (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? 0 : 1
                    }
                    default: {
                        return a < b ? -1 : a === b ? 0 : 1
                    }
                }
            } else {
                if (a[sortField] && !b[sortField]) return 1
                if (b[sortField] && !a[sortField]) return -1
                switch (sortType) {
                    case 'date': {
                        if (!isNaN(Date.parse(a[sortField])) && isNaN(Date.parse(b[sortField]))) return 1
                        if (isNaN(Date.parse(a[sortField])) && !isNaN(Date.parse(b[sortField]))) return -1
                        return new Date(a[sortField]).getTime() - new Date(b[sortField]).getTime()
                    }
                    case 'array' || 'object': {
                        if (a[sortField] instanceof Array || b[sortField] instanceof Array) {
                            return a[sortField].length - b[sortField].length
                        } else {
                            return Object.keys(a[sortField])?.length - Object.keys(b[sortField]?.length)
                        }
                    }
                    case 'number': {
                        return a[sortField] - b[sortField]
                    }
                    case 'string': {
                        return window.nameCompare(a[sortField], b[sortField])
                    }
                    default: {
                        return a[sortField] < b[sortField] ? -1 : a[sortField] === b[sortField] ? 0 : 1
                    }
                }
            }
        }
        for (let i = sfs.length - 1; i >= 0; i--) {
            let sf = sfs[i]
            if (!sf) continue
            sortDirection = sf.direction
            sortField = sf.name
            sortType = sf.type
            if (sortType === 'array') {
                let c = false
                let s = sortType
                for (let z = 0; z < data.length; z++) if (data[z][sortField]) {
                    c = true
                    sortType = typeof data[z][sortField]
                    z = data.length
                }
                if (c) {
                    data = data.map(u => {
                        u[sortField] = u[sortField].sort(sort)
                        return u
                    })
                    sortType = s
                }
            }
            data = sortDirection ? data.sort(sort).reverse() : data.sort(sort)
        }
        return data
    }
    exportModel(items) {
        let d = []
        for (let i = 0; i < items.length; i++) {
            if (!this.items.includes(items[i]._id)) continue
            let item = items[i]
            let point = {}
            let a = [{ name: 'title', type: 'string' }, ...this.fields, { name: '_u', type: 'date' }]
            for (let z = 0; z < a.length; z++) point[a[z].name] = item[a[z].name]
            d.push(point)
        }
        window.app.request('/run-report', 'post', { data: this.filter(this.sorter(d)) }).then(r => {
            if (!r.link) window.flash('Failed to load resource')
            let a = document.createElement('a')
            a.href = r.link
            a.target = '_blank'
            document.querySelector('body').append(a)
            a.click()
            a.remove()
        }).catch(e => window.flash(e))
    }
}
export default Model
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "../helpers/display/table";
import 'antd/dist/antd.css'
import "../helpers/display/antdstyle.css"
import { Link } from "react-router-dom";
import ProductForm from '../forms/productform'
import Product from '../views/product-view'
import ChangeImage from '../forms/changeimage'
import ImportCSV from '../forms/importcsv';
import Filters from '../helpers/filters'
class Products extends Component {
    state = {
        view: '',
        edit: false,
        changeImage: false,
        showFilters: false,
        currentFilter: []
    }
    render() {
        window.companies = this
        const { data, add } = this.props;
        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                render: (text, record) => (
                    <><a href="#" onClick={e => {
                        e.preventDefault()
                        this.setState({ view: record._id })
                    }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.setState({ view: record._id })
                        }} >{text}</a></>
                ),
                sorter: (a, b) => window.nameCompare(a.name, b.name),
            },
            {
                title: "Type",
                dataIndex: "type",
                render: (text, record) => (
                    <><a href="#" onClick={e => {
                        e.preventDefault()
                        this.setState({ view: record._id })
                    }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.setState({ view: record._id })
                        }} >{text}</a></>
                ),
                sorter: (a, b) => window.nameCompare(a.name, b.name),
            },
            {
                title: "Price",
                dataIndex: "name",
                render: (text, record) => (
                    <><a href="#" onClick={e => {
                        e.preventDefault()
                        this.setState({ view: record._id })
                    }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.setState({ view: record._id })
                        }} >{text}</a></>
                ),
                sorter: (a, b) => window.nameCompare(a.name, b.name),
            },
            {
                title: "Actions",
                dataIndex: "status",
                render: (text, record) => (
                    <div className="dropdown dropdown-action">
                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {/* <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }} href="#" >Change Image</a> */}
                            <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.setState({ view: record._id, edit: true }) }} href="#" >Edit Product</a>
                            <a className="dropdown-item" onClick={(e) => { window.sure(`Delete ${record.name}`, () => window.app.deleteProduct(record._id)) }} href="#" >Delete Product</a>
                        </div>
                    </div>
                ),
            },
        ];

        return (
            <>
                <Helmet>
                    <title>Products</title>
                    <meta name="description" content="Reactify Blank Page" />
                </Helmet>
                <div className="content container-fluid">
                    <div className="page-header pt-3 mb-0 ">
                        <div className="crms-title row bg-white">
                            <div className="col">
                                <h3 className="page-title m-0">
                                    <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                        <i className="feather-database" />
                                    </span> Products </h3>
                            </div>
                            <div className="actionButtonGroup">

                            </div>
                            <div className="col text-right">
                                <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Products</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="pointer filterToggle" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{this.state.showFilters ? 'Hide' : 'Show'} Filters</span>
                            </div>
                            <div className="col text-right">
                                <ul className="list-inline-item pl-0">
                                    <li className="list-inline-item">

                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={e => { this.setState({ edit: true }) }}>New Product</button>
                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                        {this.state.showFilters && <Filters list={data} change={(currentList, currentFilter) => this.setState({ currentFilter })} />}

                        <Table
                            className="table"
                            style={{ overflowX: "auto" }}
                            columns={columns}
                            data={window.filter(data, this.state.currentFilter || []).sort((a,b) => window.nameCompare(a.name, b.name))}
                            mainKey={(record) => record._id}
                        />
                    </div>
                </div>
                {/* {this.state._id && this.state.changeImage && <ChangeImage product={true} close={e => this.setState({ changeImage: false, _id: '' })} _id={this.state._id} />} */}
                {this.state.import && <ImportCSV templateName={'CompanyTemplate'} template={['name', 'type', 'price', 'quantity']} dataType="Products" import={data => {
                    window.app.addProduct({ fields: { ...data, crm: this.props.crm } }).catch(e => window.flash(e))
                }} close={() => this.setState({ import: false })} />}
                {this.state.edit ? <ProductForm crm={this.props.crm} _id={this.state.view} add={add} close={() => this.setState({ edit: false })} /> : this.state.view ? <Product edit={() => this.setState({ edit: true })} crm={this.props.crm} _id={this.state.view} close={() => this.setState({ view: '' })}></Product> : ''}
            </>
        );
    }
}

export default Products;

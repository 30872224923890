import React, { Component } from 'react'
import TabLink from '../tablink'
import Table from './table'
class NotesDisplay extends Component {
    state = {
        notes: []
    }
    componentDidMount() {
        if (this.props.notes) this.getNotes(this.props.notes)
    }
    componentDidUpdate() {
        if (this.props.notes?.length > 0 && this.state.notes.length < 1 && !this.state.gotNotes) this.getNotes(this.props.notes)
    }
    getNotes = (notes) => {
        if (this.gettingNotes || notes?.length < 1) return
        this.gettingNotes = true
        window.app.request('/notes', 'post', { notes }).then(r => {
            this.gettingNotes = false
            this.setState({ notes: r.notes, gotNotes: true })
        }).catch(e => { window.flash(e); this.setState({ gotNotes: true }); this.gettingNotes = false })
    }
    render() {
        if (this.state.notes?.length < 1) return <></>
        if (this.props.type === 'table') {
            const columns = [
                {
                    title: "Date",
                    dataIndex: "date",
                    render: (text, record) => (
                        <>{window.formatDate(record.date)}</>
                    ),
                    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                },
                {
                    title: "User",
                    dataIndex: "name",
                    render: (text, record) => (
                        <><TabLink to={`/profile?_id=${record.user}`} text={window.app.state.users?.find(a => a._id === record.user)?.name || 'Unknown'} /></>
                    ),
                    sorter: (a, b) => window.nameCompare(a.name, b.name),
                },
                {
                    title: "Note",
                    dataIndex: "note",
                    render: (text, record) => (
                        <>{text}</>
                    ),
                    sorter: (a, b) => window.nameCompare(a.text, b.text),
                },
                {
                    title: "Follow Up",
                    dataIndex: "followUp",
                    render: (text, record) => (<>{window.formatDate(record.followUp)}</>),
                    sorter: (a, b) => new Date(a.followUp).getTime() - new Date(b.followUp).getTime()
                },
                {
                    title: "Reminder",
                    dataIndex: "reminder",
                    render: (text, record) => (<>{record.reminder ? 'True' : 'False'}</>)
                }
            ];
            return <Table className="table"
                style={{ overflowX: "auto" }}
                columns={columns}
                data={this.state.notes}
                mainKey={(record) => record._id} />
        }
        return (<ul>
            {this.state.notes?.map((u, i) => (<li key={i} className="b1 jfs" style={{alignItems: 'flex-start', marginBottom: '10px'}} id={u._id}>
                <span>{u.note}</span>
                <span>{<TabLink to={`/profile?_id=${u.user}`} text={window.app.state.users?.find(a => a._id === u.user)?.name || 'Unknown'} />} on <strong>{window.formatDate(u.date)} @ {window.formatTime(u.date)}</strong></span>
                {u.followUp ? <><br></br><strong>{`Follow Up: ${window.formatDate(u.followUp)}`}</strong></> : ''}<br></br>
            </li>))}
        </ul>)
    }
}
export default NotesDisplay
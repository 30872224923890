import { Component } from 'react'
import PropTypes from 'prop-types';
import { EditorState, convertFromRaw, convertToRaw, Modifier, AtomicBlockUtils, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './markup.css'
class CustomOption1 extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    };

    addStar = () => {
        const { editorState, onChange } = this.props;
        var selectionState = editorState.getSelection();
        var anchorKey = selectionState.getAnchorKey();
        var currentContent = editorState.getCurrentContent();
        var currentContentBlock = currentContent.getBlockForKey(anchorKey);
        var start = selectionState.getStartOffset();
        var end = selectionState.getEndOffset();
        var selectedText = currentContentBlock.getText().slice(start, end);
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            selectedText.toUpperCase(),
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };
    render() {
        return (
            <div className="rdw-option-wrapper" title="Change Case" onClick={this.addStar}><span style={{ color: 'var(--d)' }}>TT</span></div>
        );
    }
}
class CustomOption2 extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    };

    addStar = () => {
        const { editorState, onChange } = this.props;
        var selectionState = editorState.getSelection();
        var anchorKey = selectionState.getAnchorKey();
        var currentContent = editorState.getCurrentContent();
        var currentContentBlock = currentContent.getBlockForKey(anchorKey);
        var start = selectionState.getStartOffset();
        var end = selectionState.getEndOffset();
        var selectedText = currentContentBlock.getText().slice(start, end);
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            selectedText.toLowerCase(),
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };
    render() {
        return (
            <div className="rdw-option-wrapper" title="Lowercase" onClick={this.addStar}><span style={{ color: 'var(--d)', textTransform: 'lowercase' }}>tt</span></div>
        );
    }
}
class CustomOption3 extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    };

    addStar = (e) => {
        this.props.change(e)
    };
    render() {
        return (
            <div className={"rdw-option-wrapper " + (typeof this.props.change === 'function' ? '' : 'hidden')} title="Add Attachment" onClick={this.addStar}><i className="fa fa-paperclip" style={{ color: 'var(--d)' }}></i></div>
        );
    }
}
class MarkupEditor extends Component {
    init() {
        let { value } = this.props
        if (typeof value === 'string') {
            let v
            try {
                v = JSON.parse(this.props.value)
            } catch (e) { }
            return v ? EditorState.createWithContent(convertFromRaw(v)) : EditorState.createEmpty()
        } else if (typeof value === 'object') {
            return EditorState.createWithContent(convertFromRaw(value))
        } else {
            return EditorState.createEmpty()
        }
    }
    state = {
        menu: true
    }
    paste = event => {
        if (!this.state.preview) {
            var items = (event.clipboardData || event.originalEvent.clipboardData).items
            for (var index in items) {
                var item = items[index]
                if (item.kind === 'file') {
                    var blob = item.getAsFile()
                    var reader = new FileReader()
                    reader.onload = event => {
                        let image = event.target.result
                        this.insertImage(image)
                    }
                    reader.readAsDataURL(blob)
                }
            }
        }
    }
    componentDidMount() {
        document.addEventListener('paste', this.paste)
        this.setState({editor: this.init()})
    }
    componentDidUpdate(j){
        if (this.props.value && !j.value) this.setState({editor: this.init()})
    }
    componentWillUnmount() {
        document.removeEventListener('paste', this.paste)
    }
    insertImage = (image) => {
        const contentState = this.state.editor.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: image })
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.state.editor, { currentContent: contentStateWithEntity })
        this.editorStateChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "))
    };
    editorStateChange = (es) => {
        let raw = convertToRaw(es.getCurrentContent())
        this.setState({ editor: es }, () => {
            if (es && typeof this.props.change === 'function') this.props.change(JSON.stringify(raw))
        })
    }
    render() {
        return (<div style={{ height: this.props.height }}>
            <input className="hidden" value={this.state.html} name={this.props.name} />
            {this.state.editor && <Editor spellCheck={true} handlePastedText={(text, html, editorState) => {
                if (html) {
                    const blocks = convertFromHTML(html.replace(/<b>/g, '').replace(/<\/b>/, ''))
                    this.editorStateChange(EditorState.set(this.state.editor, {
                        currentContent: Modifier.replaceWithFragment(
                            this.state.editor.getCurrentContent(),
                            this.state.editor.getSelection(),
                            ContentState.createFromBlockArray(
                                blocks.contentBlocks,
                                blocks.entityMap,
                            ).getBlockMap(),
                        )
                    }))
                }
                return false
            }} toolbarCustomButtons={[<CustomOption1 />, <CustomOption2 />, <CustomOption3 change={this.props.addAttachment} />]} toolbar={{ image: { uploadEnabled: false, uploadCallback: window.app.uploadImage } }} toolbarClassName="flex sticky top-0 !justify-center mx-auto" editorClassName="mt-6 bg-white shadow-lg max-w-5xl mx-auto mb-12 border p-10" editorState={this.state.editor} onEditorStateChange={this.editorStateChange}></Editor>}
        </div>)
    }
}
export default MarkupEditor
import React, { Component } from 'react'
import MarkdownView from '../helpers/display/markdown-view.js'
import Spinner from '../helpers/display/spinner'
import EmailCompose from '../forms/email-compose'
import NotFound from '../helpers/display/notfound'
import SidePop from '../helpers/display/sidepop'
class Email extends Component {
    state = { to: [], from: '', subject: '', message: '',  attachments: [], bcc: [], cc: [], date: null, reply: false, forward: false, replyAll: false, title: '' }
    componentDidMount() {
        if (this.props._id) {
            let profile = this.props.email || window.app.state.emails?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    this.getEmail(this.state._id)
                })
            } else { this.setState({ notFound: true }) }
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = this.props.email || window.app.state.emails.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...profile, notFound: false }, () => {
                    if (!this.state.message) this.getEmail(this.state._id)
                })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id, view: this.state.view, notFound: true })
            }
        }
    }
    setTitle = (title) => {
        if (this.settingTitle) return
        this.settingTitle = true
        window.app.request('/set-email-title', 'post', { _id: this.state._id, title: title }).then(() => {
            this.settingTitle = false
            if (typeof this.props.set === 'function') this.props.set(title)
        }).catch(e => {
            this.settingTitle = false
            window.flash(e)
        })
    }
    getEmail = _id => {
        if (this.getting) return
        this.getting = true
        window.app.getEmail(_id).then(email => {
            this.getting = false
            let { message } = email
            if (!message) message = 'No Content'
            this.setState({ message })
        }).catch(e => {
            this.getting = false
            this.setState({ failed: true })
        })
    }
    findUser = email => {
        if (!email) return ''
        let user = window.app.state.users?.find(a => a.email === email)
        if (!user) return email
        return `${user.name} - ${user.email}`
    }
    render() {
        if (this.state.notFound) return <SidePop close={this.props.close} content={<NotFound />} />
        if (this.state.reply) {
            let from = window.app.state.users?.find(a => a._id === window.app.state?.userID)?.email
            return <>
                <EmailCompose title={this.state.title} thread={this.state.thread || this.props.thread} appendContent={window.createDraft(this.state.message)} to={this.state.from !== from ? [this.state.from] : this.state.to ? [this.state.to[0]] : []} from={from} subject={/^RE: /i.test(this.state.subject) ? this.state.subject : `RE: ${this.state.subject}`} close={() => this.setState({ reply: false })} />
            </>
        } else if (this.state.replyAll) {
            let from = window.app.state.users?.find(a => a._id === window.app.state?.userID)?.email
            return <>
                <EmailCompose title={this.state.title} thread={this.state.thread || this.props.thread} appendContent={window.createDraft(this.state.message)} to={this.state.from !== from ? [this.state.from, ...this.state.to.filter(a => a !== from)] : this.state.to} from={from} subject={/^RE: /i.test(this.state.subject) ? this.state.subject : `RE: ${this.state.subject}`} cc={this.state.cc} bcc={this.state.bcc} close={() => this.setState({ replyAll: false })} />
            </>
        } else if (this.state.forward) {
            let from = window.app.state.users?.find(a => a._id === window.app.state?.userID)?.email
            return <>
                <EmailCompose title={this.state.title} subject={`FWD: ${this.state.subject}`} message={window.createDraft(this.state.message)} from={from} close={() => this.setState({ forward: false })} />
            </>
        }
        return (<><div className="modal right fade show" style={{ display: 'block' }} onClick={this.props.close} id="system-user" tabIndex={-1} role="dialog" aria-modal="true">
            <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">View Email</h4>
                        {!this.state.setTitle && <button onClick={e => this.setState({ setTitle: true })}>{this.state.title ? window.app.state.titles?.find(a => a._id === this.state.title)?.title || this.state.title : 'Set Title'}</button>}
                        <div className="b2">
                            <button onClick={e => { console.log('forawrd'); this.setState({ forward: true }) }}>Forward</button>
                            <button onClick={e => this.setState({ reply: true })}>Reply</button>
                            <button onClick={e => this.setState({ replyAll: true })}>Reply All</button>
                        </div>
                        <div className="b2 close xs-close">
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    {this.state.setTitle && <div className="card shadow">
                        <div className="row m-0">
                            <div className="col">
                                <span>Title</span>
                                <select className="form-control" value={this.state.titleValue || this.state.title} onChange={e => {
                                    this.setState({ titleValue: e.target.value })
                                }}>
                                    <option value="">Select Title</option>
                                    {window.app.state.titles?.map(a => <option key={a._id} value={a._id}>{a.title}</option>)}
                                </select>
                                <div className="b2">
                                    <button onClick={() => this.setTitle(this.state.titleValue)}>Confirm</button>
                                    <button onClick={() => this.setState({ setTitle: false, titleValue: '' })}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="card shadow p-2 due-dates">
                        <div className="row m-0">
                            <div className="col">
                                <span>Date</span>
                                <p>{this.state.date && window.formatDate(this.state.date)}</p>
                            </div>
                            <div className="col">
                                <span>From</span>
                                <p>{this.findUser(this.state.from)}</p>
                            </div>
                            <div className="col">
                                <span>Subject</span>
                                <p>{this.state.subject}</p>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col">
                                <span>To</span>
                                <p>{this.state.to.map(u => this.findUser(u)).join('; ')}</p>
                            </div>
                            <div className="col">
                                <span>Cc</span>
                                <p>{this.state.cc.map(u => this.findUser(u)).join('; ')}</p>
                            </div>
                            <div className="col">
                                <span>Bcc</span>
                                <p>{this.state.bcc.map(u => this.findUser(u)).join('; ')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        {this.state.message ? <MarkdownView content={this.state.message} ></MarkdownView> : !this.state.failed ? <div className="b1"><Spinner></Spinner><span>GETTING EMAIL</span></div> : <div className="b1"><span>Failed to load message</span></div>}
                    </div>
                </div>
            </div>
        </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default Email
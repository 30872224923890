import React, { Component } from 'react/cjs/react.production.min'
import Mark from '../components/helpers/mark'
class FilterField extends Component{
    render(){
        let {remove, change, name, type, mode, index} = this.props
        return (<div className="b1 inners" style={{width: 'auto'}} name={name}>
            <div className="b2"><span><strong>{name.toUpperCase().split('_').join(' ')}</strong></span><div name={name} onClick={e => {
                let name = e.target.getAttribute(['name'])
                remove(name, this.props.index)
            }}><Mark x={false} hover={true} onClick={window.parentClick}></Mark></div></div>
            <label>{(() => {
                switch(type) {
                        case 'number': {
                            switch(mode.name) {
                                case 'lte': {
                                    return 'Less Than/Equal To'
                                }
                                case 'gte': {
                                    return 'Greater Than/Equal To'
                                }
                                case 'lt': {
                                    return 'Less Than'
                                }
                                case 'gt': {
                                    return 'Greater Than'
                                }
                                case 'exists': {
                                    return 'Exists'
                                }
                                default: {
                                    return null
                                }
                            }
                        }
                        case 'string': {
                            switch(mode.name) {
                                case 'lengthlte': {
                                    return 'Length Less Than/Equal To'
                                }
                                case 'lengthgte': {
                                    return 'Length Greater Than/Equal To'
                                }
                                case 'lengthlt': {
                                    return 'Length Less Than'
                                }
                                case 'lengthgt': {
                                    return 'Length Greater Than'
                                }
                                case 'contains': {
                                    return 'Contains'
                                }
                                case 'matches': {
                                    return 'Matches'
                                }
                                case 'equal': {
                                    return 'Equal To'
                                }
                                case 'notequal': {
                                    return 'Not Equal To'
                                }
                                case 'notcontain': {
                                    return 'Does Not Contain'
                                }
                                case 'exists': {
                                    return 'Exists'
                                }
                                default: return null
                            }
                        }
                        case 'array': {
                            switch(mode.name) {
                                case 'includes': {
                                    return 'Includes'
                                }
                                case 'notincludes': {
                                    return 'Does Not Include'
                                }
                                case 'exists': {
                                    return 'Exists'
                                }
                                default: {
                                    return null
                                }
                            }
                        }
                        case 'object': {
                            switch(mode.name) {
                                case 'has': {
                                    return 'Has'
                                }
                                case 'exists': {
                                    return 'Exists'
                                }
                                default: {
                                    return null
                                }
                            }
                        }
                        case 'date': {
                            switch(mode.name) {
                                case 'lte': {
                                    return 'Before or On'
                                }
                                case 'gte': {
                                    return 'After or On'
                                }
                                case 'lt': {
                                    return 'Before'
                                }
                                case 'gt': {
                                    return 'After'
                                }
                                case 'between': {
                                    return 'Between'
                                }
                                case 'exists': {
                                    return 'Exists'
                                }
                                default: {
                                    return null
                                }
                            }
                        }
                        default: return null
                    }
            })()}</label>
            {(() => {
                if (mode.name === 'exists') return (<span>True</span>)
                switch(type){
                    case 'number': {
                        return <input type="number" name={name} value={`${mode.value}`} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            change(name, parseInt(e.target.value), this.props.index)
                        }}></input>
                    }
                    case 'string': {
                        return <input name={name} type="text" value={mode.value} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            change(name, e.target.value, this.props.index)
                        }}></input>
                    }
                    case 'object': {
                        return <input name={name} type="text" value={mode.value} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            change(name, e.target.value, this.props.index)
                        }}></input>
                    }
                    case 'array': {
                        return <input name={name} type="text" value={mode.value} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            change(name, e.target.value, this.props.index)
                        }}></input>
                    }
                    case 'date': {
                        if (mode.name === 'between') {
                            return <div className="b2">
                                <input name={name} type="date" value={mode && mode['a']} onChange={e => {
                                    let name = e.target.getAttribute(['name'])
                                    change(name, e.target.value, this.props.index, 'a')
                                }}></input>
                                <input  name={name} type="date" value={mode && mode['b']} onChange={e => {
                                    let name = e.target.getAttribute(['name'])
                                    change(name, e.target.value, this.props.index, 'b')
                                }}></input>
                            </div>
                        }
                        return <input name={name} type="date" value={mode.value} onChange={e => {
                            change(name, e.target.value, this.props.index)
                        }}></input>
                    }
                    default: {
                        return null
                    }
                }
            })()}
        </div>)
    }
}
export default FilterField
window.sort = (data, fields) => {
  let sfs = fields
  if (!data) return []
  var sortType = ''
  var sortField = ''
  var sortDirection = false
  let sort = (a, b) => {
    if (!a[sortField] && !b[sortField]) {
      if (!a && !b) return 0
      if (a && !b) return 1
      if (b && !a) return -1
      switch (sortType) {
        case 'date': {
          return new Date(a).getTime() - new Date(b).getTime()
        }
        case 'array' || 'object': {
          if (a instanceof Array || b instanceof Array) {
            return a.length - b.length
          } else {
            return Object.keys(a)?.length - Object.keys(b?.length)
          }
        }
        case 'number': {
          return a - b
        }
        case 'string': {
          return (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) < (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? -1 : (typeof a.toLowerCase === 'function' ? a.toLowerCase() : a) === (typeof b.toLowerCase === 'function' ? b.toLowerCase() : b) ? 0 : 1
        }
        default: {
          return a < b ? -1 : a === b ? 0 : 1
        }
      }
    } else {
      if (a[sortField] && !b[sortField]) return 1
      if (b[sortField] && !a[sortField]) return -1
      switch (sortType) {
        case 'date': {
          if (!isNaN(Date.parse(a[sortField])) && isNaN(Date.parse(b[sortField]))) return 1
          if (isNaN(Date.parse(a[sortField])) && !isNaN(Date.parse(b[sortField]))) return -1
          return new Date(a[sortField]).getTime() - new Date(b[sortField]).getTime()
        }
        case 'array' || 'object': {
          if (a[sortField] instanceof Array || b[sortField] instanceof Array) {
            return a[sortField].length - b[sortField].length
          } else {
            return Object.keys(a[sortField])?.length - Object.keys(b[sortField]?.length)
          }
        }
        case 'number': {
          return a[sortField] - b[sortField]
        }
        case 'string': {
          return a[sortField].toLowerCase() < b[sortField].toLowerCase() ? -1 : a[sortField].toLowerCase() === b[sortField].toLowerCase() ? 0 : 1
        }
        default: {
          return a[sortField] < b[sortField] ? -1 : a[sortField] === b[sortField] ? 0 : 1
        }
      }
    }
  }
  for (let i = sfs.length - 1; i >= 0; i--) {
    let sf = sfs[i]
    if (!sf) continue
    sortDirection = sf.direction
    sortField = sf.name
    sortType = sf.type
    if (sortType === 'array') {
      let c = false
      let s = sortType
      for (let z = 0; z < data.length; z++) if (data[z][sortField]) {
        c = true
        sortType = typeof data[z][sortField]
        z = data.length
      }
      if (c) {
        data = data.map(u => {
          u[sortField] = u[sortField].sort(sort)
          return u
        })
        sortType = s
      }
    }
    data = sortDirection ? data.sort(sort).reverse() : data.sort(sort)
  }
  return data
}
import React, { Component } from 'react'
import ArrayInput from '../helpers/inputs/arrayinput'
import FileInput from '../helpers/inputs/fileinput'
import CredentialInput from '../helpers/inputs/credential-input'
import NotesInput from '../helpers/inputs/notesinput'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
import SelectInput from '../helpers/inputs/select-input'
import NumberInput from '../helpers/inputs/numberinput'
class CompanyForm extends Component {
    state = {
        titles: []
    }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.companies?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), ...profile })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.companies?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), _id: this.props._id })
            }
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({ subbing: true })
        this.props.add(e.target).then(r => {
            this.pending = []
            console.log(`Result:`, r)
            this.setState({ ...Object.keys(this.state).reduce((a, v) => ({ ...a, [v]: undefined }), {}), ...r, subbing: false })
            window.flash('Company Saved')
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        window.companyForm = this
        let generalInfo = () => <Accordian open={true} header="General Information" content={<>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Name <span className="text-danger">*</span></label>
                    <input className="form-control" autoComplete="none" type="text" placeholder="Company Name" value={this.state.name} onChange={e => this.setState({name: e.target.value})} name="name" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Phone</label>
                    <input className="form-control" autoComplete="none" value={this.state.phone} onChange={e => this.setState({phone: e.target.value})} type="tel" name="phone" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Email</label>
                    <input className="form-control" autoComplete="none" value={this.state.email} onChange={e => this.setState({email: e.target.value})} type="email" name="email" />
                </div>
                <div className="col">
                    <label className="col-form-label">Notes</label>
                    <NotesInput followUp={true} name="notes" associateType={'company'} _id={this.state._id} placeholder="Enter Notes Here" notes={this.state.notes}
                        change={note => {
                            this.handlePending(note)
                            this.setState({ notes: this.state.notes ? [...this.state.notes, note] : [note] }, () => {
                                if (this.state._id) window.app.request('/company', 'post', { company: { _id: this.state._id, notes: this.state.notes } }).then(r => {
                                    window.app.setState({
                                        companies: [...window.app.state.companies].map(u => {
                                            if (u._id === r.company._id) return r.company
                                            return u
                                        })
                                    })
                                }).catch(e => window.flash(e))
                            })
                        }}
                        remove={_id => {
                            this.handlePending(_id)
                            this.setState({ notes: [...this.state.notes].filter(u => u !== _id) }, () => {
                                if (this.state._id) window.app.request('/company', 'post', { company: { _id: this.state._id, notes: this.state.notes } }).then(r => {
                                    window.app.setState({
                                        companies: [...window.app.state.companies].map(u => {
                                            if (u._id === r.company._id) return r.company
                                            return u
                                        })
                                    })
                                }).catch(e => window.flash(e))
                            })
                        }}
                    />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Parent Company</label>
                    <select className="form-control" value={this.state.parentCompany} onChange={e => this.setState({ parentCompany: e.target.value }, () => {
                        if (typeof this.props.change === 'function') this.props.change(e)
                    })} name="parentCompany">
                        <option value="">Choose Parent Company</option>
                        {window.app.state.companies?.sort((a,b) => window.nameCompare(a.name, b.name)).map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Website</label>
                    <input autoComplete="none" className="form-control" type="text" value={this.state.website} onChange={e => this.setState({website: e.target.value})}  name="website" />
                </div>
            </div>
        </>} />
        let socialMedia = () => <Accordian header="Social Media" content={(<>
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="col-form-label">Cinando</label>
                    <input autoComplete="none" type="text" className="form-control" name="cinando" value={this.state.cinando}  onChange={e => this.setState({cinando: e.target.value})}  placeholder="Cinando" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Instagram</label>
                    <input autoComplete="none" type="text" className="form-control" name="instagram" value={this.state.instagram}  onChange={e => this.setState({instagram: e.target.value})}  placeholder="Instagram" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Facebook</label>
                    <input autoComplete="none" type="text" className="form-control" name="facebook" value={this.state.facebook}  onChange={e => this.setState({facebook: e.target.value})}  placeholder="Facebook" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">IMDB</label>
                    <input autoComplete="none" type="text" className="form-control" name="imdb" value={this.state.imdb}  onChange={e => this.setState({imdb: e.target.value})}  placeholder="IMDB" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Linkedin</label>
                    <input autoComplete="none" type="text" className="form-control" name="linkedIn" value={this.state.linkedIn}  onChange={e => this.setState({linkedIn: e.target.value})}  placeholder="Linkedin" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Twitter</label>
                    <input autoComplete="none" type="text" className="form-control" name="twitter" value={this.state.twitter}  onChange={e => this.setState({twitter: e.target.value})}  placeholder="Twitter" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">Youtube</label>
                    <input autoComplete="none" type="text" className="form-control" name="youtube" value={this.state.youtube}  onChange={e => this.setState({youtube: e.target.value})}  placeholder="Youtube" />
                </div>
                <div className="col-sm-6">
                    <label className="col-form-label">TikTok</label>
                    <input autoComplete="none" type="text" className="form-control" name="tiktok" value={this.state.tiktok}  onChange={e => this.setState({tiktok: e.target.value})}  placeholder="Tiktok" />
                </div>
            </div>
        </>)} />
        let buyingInfo = () => <Accordian header="Buying Information" content={(<>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="col-form-label">Country of Acquisition</label>
                    <SelectInput name="countryOfAcquisition" value={this.state.countryOfAcquisition} change={e => this.setState({countryOfAcquisition: e})} options={window.countries.map((u,i) => (<option key={i} value={u.name}>{u.name}</option>))}/>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Rights of Interest</label>
                    <ArrayInput type="search" selected={this.state.rightsOfInterest || []} items={window.rights} name={'rightsOfInterest'} change={e => { console.log(e) }} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Genres of Interest</label>
                    <ArrayInput selected={this.state.genresOfInterest || []} items={[...window.primaryGenres, ...window.secondaryGenres]} name={'genresOfInterest'} change={e => { }} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Exclusivity Needed</label>
                    <select className="form-control" name="exclusivityNeeded" value={this.state.exclusivityNeeded} onChange={e => this.setState({ exclusivityNeeded: e.target.value })}>
                        <option value="">Select One</option>
                        <option value="exclusive">Exclusive</option>
                        <option value="nonexclusive">Non-Exclusive</option>
                        <option value="both">Both</option>
                    </select>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Source Code</label>
                    <input autoComplete="none" className="form-control" name="sourceCode" type="text" placeholder="Source Code" value={this.state.sourceCode}  onChange={e => this.setState({sourceCode: e.target.value})}  />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Territory(ies)</label>
                    <ArrayInput selected={this.state.territories || []} items={window.territories} name={'territories'} change={e => { }} />
                </div>
            </div>
        </>)} />
        let deliveryInfo = () => <Accordian header="Delivery Information" content={(<>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Delivery Contact Name</label>
                    <input autoComplete="none" className="form-control" name="deliveryContactName" type="text" placeholder="Delivery Contact Name" value={this.state.deliveryContactName}  onChange={e => this.setState({deliveryContactName: e.target.value})}  />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Delivery Contact Email</label>
                    <input autoComplete="none" className="form-control" name="deliveryContactEmail" type="email" placeholder="Delivery Contact Email" value={this.state.deliveryContactEmail}  onChange={e => this.setState({deliveryContactEmail: e.target.value})}  />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Delivery Specs</label>
                    <textarea autoComplete="none" className="form-control" name="deliverySpecs" placeholder="Delivery Specs" value={this.state.deliverySpecs}  onChange={e => this.setState({deliverySpecs: e.target.value})}  />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Delivery Specs Sheets</label>
                    {this.state.deliverySpecsSheets ? <>{this.state.deliverySpecsSheets?.map((u, i) => u && <span key={i}><Link to={u} /></span>)}</> : ''}
                    <FileInput multiple={true} name="deliverySpecsSheets" />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">File Transfer Dashboard Login</label>
                    <CredentialInput selected={this.state.fileTransferLogin} name="credentials-fileTransferLogin" type="fileTransferLogin" />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Servicing Dashboard Login</label>
                    <CredentialInput selected={this.state.servicingLogin} name="credentials-servicingLogin" type="servicingLogin" />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Reporting Dashboard Login</label>
                    <CredentialInput selected={this.state.reportingLogin} name="credentials-reportingLogin" type="reportingLogin" />
                </div>
            </div>
        </>)} />
        let shippingInfo = () => <Accordian header="Shipping Information" content={(<>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="col-form-label">Shipping Company Name</label>
                    <input autoComplete="none" type="text" className="form-control" name="shippingCompany" placeholder="Shipping Company" value={this.state.shippingCompany}  onChange={e => this.setState({shippingCompany: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Contact</label>
                    <input autoComplete="none" type="text" className="form-control" name="shippingContact" placeholder="Shipping Contact" value={this.state.shippingContact}  onChange={e => this.setState({shippingContact: e.target.value})}  />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Shipping Address</label>
                    <textarea autoComplete="none" className="form-control" rows={3} name="shipping-street" placeholder="Shipping Address" value={this.state.shippingAddress?.street}  onChange={e => this.setState({shippingAddress: this.state.shippingAddress ? {...this.state.shippingAddress, street: e.target.value} : {street: e.target.value}})}  />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-4">
                    <input autoComplete="none" type="text" className="form-control" placeholder="Shipping City" name="shipping-city" value={this.state.shippingAddress?.city} onChange={e => this.setState({shippingAddress: this.state.shippingAddress ? {...this.state.shippingAddress, city: e.target.value} : {city: e.target.value}})} />
                </div>
                <div className="col-md-4">
                    <select value={this.state.shippingAddress?.state || ''} onChange={e => {
                        let o = this.state.shippingAddress ? { ...this.state.shippingAddress } : {}
                        o.state = e.target.value
                        this.setState({ shippingAddress: o }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }} name="shipping-state" className="form-control">
                        <option value="">Select a State</option>
                        {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-md-4">
                    <input type="text" className="form-control" value={this.state.shippingAddress?.postalCode}  onChange={e => this.setState({shippingAddress: this.state.shippingAddress ? {...this.state.shippingAddress, postalCode: e.target.value} : {postalCode: e.target.value}})}  placeholder="Shipping Postal Code" name="shipping-postal-code" />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <select className="form-control" value={this.state.shippingAddress?.country} onChange={e => {
                        let o = this.state.shippingAddress ? { ...this.state.shippingAddress } : {}
                        o.country = e.target.value
                        this.setState({ shippingAddress: o }, () => {
                            if (typeof this.props.change === 'function') this.props.change(e)
                        })
                    }} name="shipping-country">
                        <option value="">Select a Country</option>
                        {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
                    </select>
                </div>
                <div className="col-md-6">
                    <input autoComplete="none" type="text" className="form-control" name="shipping-region" placeholder="Shipping Region" onChange={e => this.setState({ shippingAddress: ({ ...this.state.shippingAddress, region: e.target.value }) })} value={this.state.shippingAddress?.region} />
                </div>
            </div>
        </>)} />
        let distributorInfo = () => <Accordian header="Distributor Information" content={(<>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="col-form-label">Type</label>
                    <ArrayInput name="distributorTypes" type="search" items={window.distributorTypes} selected={this.state.distributorTypes || []} change={(u, l) => this.setState({ distributorTypes: l })} />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Pitch Requirements</label>
                    {this.state.pitchRequirements ? <>{this.state.pitchRequirements?.map((u, i) => u && <span key={i}><Link to={u}>{u}</Link></span>)}</> : ''}
                    <FileInput multiple={true} name="pitchRequirements" />
                </div>
                <div className="col-md-4">
                    <label className="col-form-label">Submissions Due By</label>
                    <input type="text" className="form-control" name="submissionsDueBy" value={this.state.submissionsDueBy} onChange={e => this.setState({ submissionsDueBy: e.target.value })} />
                </div>
                <div className="col-md-8">
                    <label className="col-form-label">Territories of Interest</label>
                    <ArrayInput name="territoriesOfInterest" items={window.territories} selected={this.state.territoriesOfInterest || []}></ArrayInput>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Fees & Percentages</label>
                    <ArrayInput name="feesAndPercentages" type="text" selected={this.state.feesAndPercentages || []}></ArrayInput>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Months Out to Go Live</label>
                        <NumberInput name="monthsOut" min={0} value={this.state.monthsOut} change={e => this.setState({monthsOut: e})} append={'Months'} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Placement Costs</label>
                    <ArrayInput name="placementCosts" type="text" selected={this.state.placementCosts || []} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Max Age for Title</label>
                    <NumberInput name="maxAgeForTitle" min={0} value={this.state.maxAgeForTitle} change={e => this.setState({maxAgeForTitle: e})} append={'Months'} />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Ad Buy Options</label><br></br>
                    {this.state.adBuyOptions && <span>{this.state.adBuyOptions}</span>}
                    <FileInput mode="yesno" yes={this.state.adBuyOptions ? true : false} name="adBuyOptions" />
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Previously Released Titles</label>
                    <ArrayInput name="previouslyReleasedTitles" listTemplate={(u) => window.app.state.titles?.find(a => a._id === u)?.title || u} selected={this.state.previouslyReleasedTitles || []} items={window.app.state.titles?.map(u => ({ name: u.title, value: u._id })) || []} template={((u, i) => (<option key={i} value={u.value}>{u.name}</option>))} />
                </div>
            </div>
        </>)} />
        return (<>
            <div onClick={this.props.close} className="modal right fade show" style={{ display: 'block' }} id="add_company" tabIndex={-1} role="dialog" aria-modal="true">
                <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                    <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Company</h4>
                            <div className="b2 close xs-close" style={{ width: '40%' }}>
                                {this.props._id && <button data-dismiss="modal" className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                                <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {this.state.subbing ? <div className="b1"><Spinner/><h3>SUBMITTING<DotDot /></h3></div>  : <form autoComplete="none" onSubmit={this.submit}>
                                        <input type="hidden" defaultValue={'hello'} className="hidden" autoComplete="on" readOnly={true} />
                                        {this.props.crm === 'film-sales' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {shippingInfo()}
                                            {/* {accountingInfo()} */}
                                        </> : this.props.crm === 'acquisitions' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {shippingInfo()}
                                            {/* {paymentInfo()}
                                        {accountingInfo()} */}
                                        </> : this.props.crm === 'film-distribution' ? <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {distributorInfo()}
                                            {shippingInfo()}
                                            {/* {paymentInfo()}
                                        {accountingInfo()} */}
                                        </> : <>
                                            {generalInfo()}
                                            {socialMedia()}
                                            {distributorInfo()}
                                            {buyingInfo()}
                                            {deliveryInfo()}
                                            {shippingInfo()}
                                            {/* {paymentInfo()}
                                        {accountingInfo()} */}
                                        </>}
                                        <div className="text-center py-3">
                                            <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                            <button data-dismiss="modal" onClick={this.props.close} type="button" className="btn btn-secondary btn-rounded" >Cancel</button>
                                        </div>
                                        <input type="hidden" name="crm" value={this.props.crm} />
                                        <input type="hidden" name="_id" value={this.state._id} />
                                    </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div className="modal-backdrop fade show"></div></>)
    }
}
export default CompanyForm
import React, { Component } from 'react'
import InputMask from 'react-input-mask';
import DateInput from './dateinput'
class InternationalDates extends Component {
    constructor(props) {
        super(props)
        this.warn = React.createRef()
    }
    state = { selected: [], currentDate: '', currentCountry: '', currentReset: true }
    satSelected = false
    componentDidMount() {
        if (this.props.selected?.length > 0) this.setState({ selected: this.props.selected || [] }, () => {
            this.satSelected = true
        })
    }
    componentDidUpdate() {
        if (this.props.selected?.length > 0 && !this.satSelected) {
            this.satSelected = true
            this.setState({ selected: this.props.selected || [] })
        }
    }
    warning = e => {
        this.warn.current.innerHTML = e
        setTimeout(() => {
            this.warn.current.innerHTML = ''
        }, 3000)
    }
    render() {
        let { name } = this.props
        let { selected } = this.state
        let change = () => {
            if (!this.state.currentCountry) return this.warning('Invalid country')
            if (!this.state.currentDate || isNaN(new Date(this.state.currentDate).getTime())) return this.warning('Please enter a valid date')
            let state = this.state.selected.find(a => a.country === this.state.currentCountry) ? {
                selected: [...this.state.selected].map(u => {
                    if (u.country === this.state.currentCountry) return { country: u.country, date: this.state.currentDate }
                    return u
                })
            } : { selected: [...this.state.selected, { country: this.state.currentCountry, date: this.state.currentDate }], currentDate: '', currentCountry: '', currentReset: true }
            this.setState(state, () => {
                if (typeof this.props.change === 'function') this.props.change(this.state.selected)
            })
        }
        let remove = (country) => {this.setState({ selected: [...this.state.selected].filter(u => u.country !== country) })}
        return (<div>
            <span ref={this.warn} className="warn"></span>
            <input type="text" value={JSON.stringify(selected)} onChange={e => { }} className="hidden" name={name || 'internationalReleaseDates'} />
            {selected && selected?.length > 0 && <ul>
                {selected?.map((u, i) => (<li key={i} className="arrayInput-item hide-x">{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : <span  style={{ display: 'inline-flex' }}><strong style={{marginRight: '.3em'}}>{u.country}: </strong> {window.formatDate(u.date)} <i className="hidden-x la la-xing"  onClick={e => {
                    e.preventDefault()
                    remove(u.country)
                }}></i></span>}</li>))}
            </ul>}
            {!this.state.currentReset && <DateInput value={this.state.currentDate} change={e => this.setState({ currentDate: e })} />}
            <select className="form-control" value={this.state.currentCountry} onChange={e => this.setState({ currentCountry: e.target.value, currentReset: false })}>
                <option value="">Select One</option>
                {window.countries?.filter(a => !selected?.find(b => b.country === a.name)).map(typeof this.props.template === 'function' ? this.props.template : (u, i) => (<option key={i} value={u.name}>{u.name}</option>))}
            </select>
            <button onClick={e => { e.preventDefault(); change() }}>Add</button>
        </div>)
    }
}
export default InternationalDates
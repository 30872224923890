import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CreatePayment from '../../forms/createpayment'
import PForm from './paymentform'
import TabLink from '../../helpers/tablink';
import Filters from '../../helpers/filters'
import Table from '../../helpers/display/table'
import ImportCSV from './importpayments';
import Spinner from '../../helpers/display/spinner'
import DotDot from '../../helpers/display/dotdot'
class Payments extends Component {
    state = { create: false, page: 0, count: 25, viewing: '', view: 'payment', currentFilter: [], showFilters: false }
    componentDidMount() {
        if (this.props.type) this.setState({ view: this.props.type })
    }
    componentDidUpdate() {
        if (this.props.type !== this.state.view) this.setState({ view: this.props.type })
    }
    delete(id) {
        if (this.props.type === 'sale') { window.app.deleteSale(id) } else { window.app.deletePayment(id) }
    }
    save(data) {
        if (this.props.type === 'sale') { window.app.saveSale(data) } else { window.app.savePayment(data) }
    }
    render() {
        window.payments = this
        //Create a form
        let helmet = <Helmet>
            <title>{this.props.type ? window.capitalize(this.props.type + 's') : 'Distribution Revenue'}</title>
            <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        //Change to look more like the other sections with Sales and Create Sale at the top
        /*         if (this.state.viewing) {
                    let u = this.state.view === 'payment' ? this.props.payments.find(u => u._id === this.state.viewing) : this.props.sales.find(u => u._id === this.state.viewing)
                    return (<div className="b1 rel">
                        <button className="top-right-abs" onClick={() => this.setState({ viewing: '' })}>Close</button>
                        <div className="paycont">
                            {u && Object.keys(u).map((a, i) => (<div key={i} className="b2 jfs">
                                <label>{a}</label>
                                <span id={a} suppressContentEditableWarning={true} contentEditable={true} onInput={e => {
                                    e.preventDefault()
                                    let a = this.state.view === 'payment' ? this.props.payments.findIndex(u => u._id === this.state.viewing) : this.props.sales.findIndex(u => u._id === this.state.viewing)
                                    let payments = [...this.props.payments].map((u, i) => {
                                        if (i === a) u[e.target.id] = e.target.innerHTML
                                        return u
                                    })
                                    window.app.setState({ payments })
                                }}>{u[a] && u[a].value ? u[a].value : u[a]}</span>
                            </div>))}
                            <div className="b2" style={{ width: 'auto' }}>
                                <button onClick={e => {
                                    this.save(this.props.payments?.find(u => u._id === this.state.viewing))
                                }}>Save</button>
                                <button onClick={e => this.delete({})}>Delete</button>
                            </div>
                        </div>
                    </div>)
                } */
        let data = (this.state.view === 'sale' ? this.props.sales : this.props.payments) || []
        let { type = 'payment' } = this.props
        if (!this.props.gotPayment) return <div className="b1">
            <Spinner />
            <h3>Getting {window.capitalize(this.props.type)}s<DotDot /></h3>
        </div>
        return <>
            {helmet}
            <div className="content container-fluid">
                <div className="page-header pt-3 mb-0 ">
                    <div className="crms-title row bg-white">
                        <div className="col ">
                            <h3 className="page-title m-0">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="feather-smartphone" />
                                </span> {type ? (type === 'sale' ? 'Sales Revenue' : type === 'expense' ? 'Title Expenses' : 'Distribution Revenue') : ''} </h3>
                        </div>
                        <div className="col text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">{type ? (type === 'sale' ? 'Sales Revenue' : type === 'expense' ? 'Title Expenses' : 'Distribution Revenue') : ''}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className="pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{!this.state.showFilters ? 'Show' : 'Hide'} Filters</span>
                        </div>
                        <div className="col text-right">
                            <ul className="list-inline-item pl-0">
                                <li className="list-inline-item">
                                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ edit: true })}>Create {this.props.type === 'sale' ? 'Sale' : this.props.type === 'expense' ? 'Expense' : 'Revenue'}</button>
                                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                    {this.state.showFilters && <Filters height={'70vh'} list={data} change={(list, filters) => { this.setState({ currentFilter: filters }) }} />}
                    <Table
                        style={{ overflowX: "auto" }}
                        columns={(this.state.view === 'sale' ? [
                            {
                                title: `Date Closed`,
                                dataIndex: "dateClosed",
                                render: (text, record) => (
                                    <>{record.dateClosed && window.formatDate(record.dateClosed)}</>
                                ),
                                sorter: (a, b) => (a.dateClosed && !b.dateClosed) ? 1 : (b.dateClosed && !a.dateClosed) ? -1 : (!a.dateClosed && !b.dateClosed) ? 0 : new Date(a.dateClosed).getTime() - new Date(b.dateClosed).getTime(),
                            }, {
                                title: `Date Received`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{record.date && window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => (a.date && !b.date) ? 1 : (b.date && !a.date) ? -1 : (!a.date && !b.date) ? 0 : new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            {
                                title: `Buyer`,
                                dataIndex: "buyer",
                                render: (text, record) => (
                                    <><TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink></>
                                ),
                                sorter: (a, b) => window.nameCompare(window.getCompany(a.buyer), window.getCompany(b.buyer)),
                            },
                            {
                                title: `Territory`,
                                dataIndex: "territory",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.territory, b.territory),
                            },
                            {
                                title: `Title`,
                                dataIndex: "title",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record.title}`} text={window.app.state.titles?.find(a => a._id === record.title)?.title || record.title}></TabLink></>
                                ),
                                sorter: (a, b) => window.nameCompare(window.app.state.titles?.find(z => a.title === z._id)?.title || '', window.app.state.titles?.find(z => b.title === z._id)?.title || ''),
                            },
                            {
                                title: `Received Amount`,
                                dataIndex: "received_amount",
                                render: (text, record) => (
                                    <>${window.currency(record.received_amount)}</>
                                ),
                                sorter: (a, b) => a.received_amount || 0 - b.received_amount || 0,
                            },
                            {
                                title: `Agreement Amount`,
                                dataIndex: "agreement_amount",
                                render: (text, record) => (
                                    <>${window.currency(record.agreement_amount)}</>
                                ),
                                sorter: (a, b) => a.agreement_amount || 0 - b.agreement_amount || 0,
                            },
                            {
                                title: `Published`,
                                dataIndex: "published",
                                render: (text, record) => (
                                    <>{window.bool(record.published)}</>
                                ),
                                sorter: (a, b) => a.published && !b.published ? 1 : b.published && !a.published ? -1 : 0,
                            },
                            {
                                title: `Third Party`,
                                dataIndex: "thirdParty",
                                render: (text, record) => (
                                    <>{window.bool(record.thirdParty)}</>
                                ),
                                sorter: (a, b) => a.thirdParty && !b.thirdParty ? 1 : b.thirdParty && !a.thirdParty ? -1 : 0,
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ viewing: record._id, edit: true }) }}>Edit {window.capitalize(this.state.type === 'sale')}</a>
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.delete(record._id) }}>Delete {window.capitalize(this.state.type)}</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ] : this.props.type === 'expense' ? [
                            {
                                title: `Date Entered`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => (a.date && !b.date) ? 1 : (b.date && !a.date) ? -1 : (!a.date && !b.date) ? 0 : new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            {
                                title: `WO No.`,
                                dataIndex: "workorder",
                                render: (text, record) => (
                                    <>{record.workorder && <TabLink to={`/work-order?_id=${record.workorder}`}>{window.app.state.workOrders?.find(a => a._id === record.workorder)?.name || record.workorder}</TabLink>}</>
                                ),
                                sorter: (a, b) => window.nameCompare(b.workorder, a.workorder),
                            },
                            {
                                title: `Invoice No.`,
                                dataIndex: "invoiceNumber",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(b.invoiceNumber, a.invoiceNumber),
                            },
                            {
                                title: `Vendor`,
                                dataIndex: "vendor",
                                render: (text, record) => (
                                    <>{text && <TabLink to={`/profile?_id=${text}`}>{window.getUser(text)}</TabLink>}</>
                                ),
                                sorter: (a, b) => window.nameCompare(window.getUser(a.vendor), window.getUser(b.vendor)),
                            },
                            {
                                title: `Platform`,
                                dataIndex: "platform",
                                render: (text, record) => (
                                    <>{text && <TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink>}</>
                                ),
                                sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)),
                            },
                            {
                                title: `Title`,
                                dataIndex: "title",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record.title}`} text={window.app.state.titles?.find(a => a._id === record.title)?.title || record.title}></TabLink></>
                                ),
                                sorter: (a, b) => window.nameCompare(window.app.state.titles?.find(z => a.title === z._id)?.title || '', window.app.state.titles?.find(z => b.title === z._id)?.title || ''),
                            },
                            {
                                title: `Amount`,
                                dataIndex: "income",
                                render: (text, record) => (
                                    <>-${window.currency(record.income * -1)}</>
                                ),
                                sorter: (a, b) => a.income || 0 - b.income || 0,
                            },
                            {
                                title: `Published`,
                                dataIndex: "published",
                                render: (text, record) => (
                                    <>{window.bool(record.published)}</>
                                ),
                                sorter: (a, b) => a.published && !b.published ? 1 : b.published && !a.published ? -1 : 0,
                            },
                            {
                                title: `Approved`,
                                dataIndex: "approved",
                                render: (text, record) => (
                                    <>{window.bool(record.approved)}</>
                                ),
                                sorter: (a, b) => a.approved && !b.approved ? 1 : b.approved && !a.approved ? -1 : 0,
                            },
                            {
                                title: `Third Party`,
                                dataIndex: "thirdParty",
                                render: (text, record) => (
                                    <>{window.bool(record.thirdParty)}</>
                                ),
                                sorter: (a, b) => a.thirdParty && !b.thirdParty ? 1 : b.thirdParty && !a.thirdParty ? -1 : 0,
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ viewing: record._id, edit: true }) }}>Edit {window.capitalize(this.state.type)}</a>
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.delete(record._id) }}>Delete {window.capitalize(this.state.type)}</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ] : [
                            {
                                title: `Date Entered`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => (a.date && !b.date) ? 1 : (b.date && !a.date) ? -1 : (!a.date && !b.date) ? 0 : new Date(a.date).getTime() - new Date(b.date).getTime()
                            },
                            {
                                title: `Category`,
                                dataIndex: "category",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.category, b.category),
                            },
                            {
                                title: `Platform`,
                                dataIndex: "platform",
                                render: (text, record) => (
                                    <>{text && <TabLink to={`/company?_id=${text}`}>{window.getCompany(text)}</TabLink>}</>
                                ),
                                sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)),
                            },
                            {
                                title: `Title`,
                                dataIndex: "title",
                                render: (text, record) => (
                                    <><TabLink to={`/title?_id=${record.title}`} text={window.app.state.titles?.find(a => a._id === record.title)?.title || record.title}></TabLink></>
                                ),
                                sorter: (a, b) => window.nameCompare(window.app.state.titles?.find(z => a.title === z._id)?.title || '', window.app.state.titles?.find(z => b.title === z._id)?.title || ''),
                            },
                            {
                                title: `Amount`,
                                dataIndex: "income",
                                render: (text, record) => (
                                    <>${window.currency(record.income)}</>
                                ),
                                sorter: (a, b) => a.income || 0 - b.income || 0,
                            },
                            {
                                title: `Published`,
                                dataIndex: "published",
                                render: (text, record) => (
                                    <>{window.bool(record.published)}</>
                                ),
                                sorter: (a, b) => a.published && !b.published ? 1 : b.published && !a.published ? -1 : 0,
                            },
                            {
                                title: `Third Party`,
                                dataIndex: "thirdParty",
                                render: (text, record) => (
                                    <>{window.bool(record.thirdParty)}</>
                                ),
                                sorter: (a, b) => a.thirdParty && !b.thirdParty ? 1 : b.thirdParty && !a.thirdParty ? -1 : 0,
                            },
                            {
                                title: "Action",
                                dataIndex: "status",
                                render: (text, record) => (
                                    <div className="dropdown dropdown-action">
                                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.setState({ viewing: record._id, edit: true }) }}>Edit {window.capitalize(this.state.type)}</a>
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); e.stopPropagation(); this.delete(record._id) }}>Delete {window.capitalize(this.state.type)}</a>
                                        </div>
                                    </div>
                                ),
                            },
                        ])}
                        data={window.filter(data, this.state.currentFilter)}
                        mainKey={'_id'}
                        rowClassName={'pointer'}
                        onRow={(record) => ({ onClick: () => this.setState({ viewing: record._id, edit: true, ptype: window.app.state.sales?.find(a => a._id === record._id) ? 'sale' : window.app.state.payments?.find(a => a._id === record._id) ? (window.app.state.payments?.find(a => a._id === record._id).amount >= 0 ? 'payment' : 'expense') : '' }) })}
                    />
                </div>
            </div>
            {this.state.import && <ImportCSV templateName={this.props.type} template={this.props.type === 'sale' ? ['dateClosed', 'date', 'buyer', 'territory', 'title', 'received_amount', 'agreement_amount'] : this.props.type === 'expense' ? ['date', 'title', 'platform', 'vendor', 'invoiceNumber', 'subdistributor', 'title', 'amount', 'income', 'workorder'] : ['date', 'dateReceived', 'title', 'platform', 'subdistributor', 'amount', 'income']} dataType={type} close={() => this.setState({ import: false })} />}
            {this.state.edit ? <PForm _id={this.state.viewing} type={type} close={() => this.setState({ edit: false, viewing: '' })} /> : ''}
        </>
    }
}
export default Payments
//old

import React, { Component } from 'react'
import ArrayInput from '../../helpers/inputs/arrayinput'
import YesNo from '../../helpers/inputs/yesno'
import TitleAllocations from '../../helpers/inputs/titleallocations'
import NotesInput from '../../helpers/inputs/notesinput'
import DateInput from '../../helpers/inputs/dateinput'
import Label from '../../helpers/inputs/label'
import TextInput from '../../helpers/inputs/textinput'
import TextArea from '../../helpers/inputs/textarea'
import NumberInput from '../../helpers/inputs/numberinput'
import SelectInput from '../../helpers/inputs/select-input'
import Toggle from '../../helpers/inputs/toggle2'
import Accordian from '../../helpers/display/accordian'
import Spinner from '../../helpers/display/spinner'
import DotDot from '../../helpers/display/dotdot'
class PForm extends Component {
    state = { type: '', onSale: false, available: true, thirdParty: false }
    componentDidMount() {
        if (this.props._id) {
            let d = this.props.type === 'sale' ? window.app.state.sales : window.app.state.payments
            let profile = d?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile })
        } else {
            this.setState({ _id: this.props._id })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let d = this.props.type === 'sale' ? window.app.state.sales : window.app.state.payments
            let profile = d?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id })
            }
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({ subbing: true })
        let item = this.props.type === 'sale' ? { dateClosed: this.state.dateClosed, date: this.state.date, territory: this.state.territory, buyer: this.state.buyer, agreement_amount: this.state.agreement_amount, received_amount: this.state.received_amount } : this.props.type === 'expense' ? { approved: this.state.approved, category: this.state.category, platform: this.state.platform, invoiceNumber: this.state.invoiceNumber, amount: this.state.amount, income: this.state.income, date: this.state.date, workorder: this.state.workorder, subdistributor: this.state.subdistributor } : { dateReceived: this.state.dateReceived, date: this.state.date, amount: this.state.amount, income: this.state.income, platform: this.state.platform, subdistributor: this.state.subdistributor }
        item = { ...item, title: this.state.title, published: this.state.published, thirdParty: this.state.thirdParty, _id: this.state._id }
        let func = this.props.type === 'sale' ? window.app.saveSale : window.app.savePayment
        func(item).then(r => {
            this.pending = []
            let result = this.props.type === 'sale' ? r['sale'] : r['payment']
            let s = {}
            let data = this.props.type === 'sale' ? window.app.state.sales : window.app.state.payments
            data = !data ? [result] : data.find(a => a._id === result._id) ? [...data].map(u => {
                if (u._id === result._id) return result
                return u
            }) : [...data, result]
            if (this.props.type === 'sale') {
                s.sales = data
            } else {
                s.payments = data
            }
            window.app.setState(s)
            this.setState({ ...result, subbing: false }, () => {
                window.flash(`${window.capitalize(this.props.type)} Saved`)
                if (typeof this.props.close === 'function') this.props.close()
            })
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        window.paymentform = this
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} {window.capitalize(this.props.type)}</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    <Accordian open={true} header={`${window.capitalize(this.props.type)} Information`} content={<div className="form-group row">
                                        {/* <div className="col-md-12">
                                            <label className="col-form-label" htmlFor="type">Type</label>
                                            <select className="form-control" name="type" value={this.state.type} onChange={e => this.setState({ type: e.target.value })}>
                                                <option value="">Choose Type</option>
                                                <option value="sale">Sales</option>
                                                <option value="payment">Distribution</option>
                                            </select>
                                        </div> */}
                                        <div className="col-md-12">
                                            <label className="col-form-label" htmlFor="title">Choose Title</label>
                                            {this.props.title ? <><input type="text" className="form-control" disabled={true} value={window.app.state.titles?.find(a => a._id === this.props.title)?.title || this.props.title} onChange={() => { }} /><input type="hidden" name="title" className="form-control" value={this.props.title} onChange={() => { }} /></> : <select className="form-control" name="title" value={this.state.title} onChange={e => this.setState({ title: e.target.value })}>
                                                <option value="">Select Title</option>
                                                {window.app.state.movies?.sort((a, b) => window.nameCompare(a.title, b.title)).map((u, i) => (<option value={u._id} key={i}>{u.title}</option>))}
                                            </select>}
                                        </div>
                                        {this.props.type === 'sale' ? <>
                                            <div className="col-md-12">
                                                <label className="col-form-label" htmlFor="territory">Choose Territory</label>
                                                <SelectInput placeholder="Select a Territory" name="territory" value={this.state.territory} change={e => this.setState({ territory: e })} options={window.territories?.sort(window.nameCompare).map((u, i) => (<option value={u} key={i}>{u}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label" htmlFor="buyer">Choose Buyer</label>
                                                <SelectInput placeholder="Select a Buyer" name="buyer" value={this.state.buyer} change={e => this.setState({ buyer: e })} options={window.app.state.companies?.filter(a => a.crm === 'film-sales').map(u => u._id).sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => (<option value={u} key={i}>{window.getCompany(u)}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Agreement Amount ($)</label>
                                                <NumberInput prepend={'$'} value={this.state.agreement_amount} change={e => this.setState({ agreement_amount: e })} name="agreement_amount" /></div>

                                            <div className="col-md-12">
                                                <label className="col-form-label">Received Amount ($)</label>
                                                <NumberInput prepend={'$'} value={this.state.received_amount} change={e => this.setState({ received_amount: e })} name="received_amount" />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Date Received</label>
                                                <DateInput name="date" value={this.state.date} change={e => this.setState({ date: e })} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Date Closed</label>
                                                <DateInput name="date" value={this.state.dateClosed} change={e => this.setState({ dateClosed: e })} />
                                            </div>
                                        </> : this.props.type === 'expense' ? <>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Sub-Distributor</label>
                                                <SelectInput name="subdistributor" placeholder="Select Sub-Distributor" value={this.state.subdistributor} change={e => this.setState({ subdistributor: e })} options={window.app.state.payments.reduce((a, b) => {
                                                    if (b.subdistributor && !a.includes(b.subdistributor)) a.push(b.subdistributor)
                                                    return a
                                                }, []).map((u, i) => (<option value={u} key={i}>{u}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Category</label>
                                                <SelectInput value={this.state.category} change={e => this.setState({ category: e })} placeholder="Select Category" name="category" options={window.expenseCategories?.sort(window.nameCompare).map((u, i) => (<option key={i} value={u}>{u}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Platform</label>
                                                <SelectInput value={this.state.platform} change={e => this.setState({ platform: e })} placeholder="Select Platform" name="platform" options={window.app.state.companies?.filter(a => a.crm === 'film-distribution').map(a => a._id).sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => (<option key={i} value={u}>{window.getCompany(u)}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Vendor</label>
                                                <SelectInput value={this.state.vendor} change={e => this.setState({ vendor: e })} placeholder="Select a Vendor" name="vendor" options={window.app.state.users && window.app.state.users?.filter(a => a.type === 'vendor').map(u => u._id).sort((a, b) => window.nameCompare(window.getUser(a), window.getUser(b))).map((u, i) => (<option key={i} value={u}>{window.getUser(u)}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Invoice Number</label>
                                                <input className="form-control" placeholder="Invoice Number" type="text" value={this.state.invoiceNumber || ''} onChange={e => this.setState({ invoiceNumber: e.target.value })} name="invoiceNumber" />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Expense</label>
                                                <NumberInput prepend={'$'} value={(this.state.income || 0) * -1} change={e => this.setState({ income: e * -1 })} name="income" />
                                            </div>
                                            <div className="col-md-12 hidden">
                                                <label className="col-form-label">Amount</label>
                                                <NumberInput prepend={'$'} value={(this.state.income || 0) * -1} change={e => this.setState({ income: e * -1 })} name="amount" />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Date</label>
                                                <DateInput name="date" value={this.state.date} change={e => this.setState({ date: e })} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Work Order</label>
                                                <SelectInput name="workorder" value={this.state.workorder} change={e => this.setState({ workorder: e })} placeholder="Select Work Order" options={window.app.state.workOrders?.map((u, i) => (<option key={i} value={u._id}>{u.name}{u.type && ` - ${window.capitalize(u.type)}`}{u.status && ` - ${window.capitalize(u.status)}`}</option>))} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label">Approved Expense</label>
                                                <Toggle tog={this.state.approved} change={(e) => { if (window.canApproveExpense(window.app.state.type)) this.setState({ approved: e }) }} />
                                            </div>
                                            <div className="col-md-6"></div>
                                        </> : <>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Sub-Distributor</label>
                                                <SelectInput name="subdistributor" placeholder="Select Sub-Distributor" value={this.state.subdistributor} change={e => this.setState({ subdistributor: e })} options={window.app.state.payments.reduce((a, b) => {
                                                    if (b.subdistributor && !a.includes(b.subdistributor)) a.push(b.subdistributor)
                                                    return a
                                                }, []).map((u, i) => (<option value={u} key={i}>{u || 'HOP'}</option>))} />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Platform</label>
                                                <SelectInput value={this.state.platform} change={e => this.setState({ platform: e })} placeholder="Select Platform" name="platform" options={window.app.state.companies?.filter(a => a.crm === 'film-distribution').map(a => a._id).sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => (<option key={i} value={u}>{window.getCompany(u)}</option>))} />
                                            </div>
                                            <div className="col-md-12 hidden">
                                                <label className="col-form-label">Amount</label>
                                                <NumberInput prepend={'$'} value={this.state.income} change={e => { }} name="amount" />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Income</label>
                                                <NumberInput prepend={'$'} value={this.state.income} change={e => this.setState({ income: e })} name="income" />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Date</label>
                                                <DateInput name="date" value={this.state.date} change={e => this.setState({ date: e })} />

                                            </div>
                                            <div className="col-md-12">
                                                <label className="col-form-label">Date Received</label>
                                                <DateInput name="date" value={this.state.dateReceived} change={e => this.setState({ dateReceived: e })} />
                                            </div>
                                        </>}
                                        <div className="col-md-6">
                                            <label className="col-form-label">3rd Party Data</label>
                                            <Toggle tog={this.state.thirdParty} change={(e) => this.setState({ thirdParty: e })} />
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Published</label>
                                            <Toggle tog={this.state.published} change={(e) => this.setState({ published: e })} />
                                        </div>
                                    </div>} />
                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    <input type="hidden" name="_id" value={this.state._id} />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default PForm
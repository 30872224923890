import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchBar from './components/helpers/universalsearch'
import ArrayDisplay from "./components/helpers/display/arraydisplay";
class Header extends Component {
  componentDidMount() {
    let body = document.querySelector('body');
    const url = window.app.state.location;
    if (url.includes("login") || url.includes("register") || url.includes("forgotpassword")
      || url.includes("otp") || url.includes("lockscreen")) {
      body.classList.add('account-page');
    } else if (url.includes("error-404") || url.includes("error-500")) {
      body.classList.add('error-page');
    }
    if (sessionStorage.getItem("mini-sidebar") != undefined) {
      body.classList.add('mini-sidebar')
      document.addEventListener('mouseover', this.toggleSidebar)
    }
  }
  toggleSidebar = e => {
    let body = document.querySelector('body')
    if (body.classList.contains('mini-sidebar')) {
      if (e.target.closest('#sidebar')) {
        body.classList.add('expand-menu')
      } else {
        body.classList.remove('expand-menu')
      }
    }
  }
  render() {
    return (
      <div className="header" id="heading">
        <a id="mobile_btn" className="mobile_btn" href="#sidebar" onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          document.querySelector('.main-wrapper').classList.toggle('slide-nav');
          document.querySelector('.sidebar-overlay').classList.toggle('opened');
          document.querySelector('html').classList.add('menu-opened');
          document.querySelector('#task_window').classList.remove('opened');
        }} ><i className="fa fa-bars" /></a>
        {/* Logo */}
        <div className="header-left">
          <Link to={'/'}>
            <div className="logo">
              <big-logo class="sidebar-logo"></big-logo>
              <small-logo class="mini-sidebar-logo"></small-logo>
            </div>
          </Link>
        </div>
        {/* /Logo */}
        <a id="toggle_btn" onClick={e => {
          e.preventDefault()
          let body = document.querySelector('body')
          let hasSide = body.classList.contains('mini-sidebar')
          if (hasSide) {
            body.classList.remove('mini-sidebar')
            document.removeEventListener('mouseover', this.toggleSidebar)
            sessionStorage.removeItem('mini-sidebar')
          } else {
            body.classList.add('mini-sidebar')
            document.addEventListener('mouseover', this.toggleSidebar)
            sessionStorage.setItem('mini-sidebar', true)
          }
        }}>
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </a>
        {/* Header Title */}
        <div className="page-title-box" style={{ width: '50%' }}>
          <div className="top-nav-search">
            <a className="responsive-search">
              <i className="fa fa-search" />
            </a>
            <SearchBar bar={bar => this.bar = bar} callback={(_id, key) => {
              window.redirect(`/${key === 'Titles' ? 'title' : key === 'Companies' ? 'company' : key === 'Deals' ? 'deal' : 'profile'}?_id=${_id}`)
              this.bar?.setState({ list: [], searching: '', full: false })
            }} placeholder="Search Titles, Leads, Companies and more" mainKey={'_id'} items={[
              {
                key: 'Users', indexes: ['name', 'email', 'phone', 'website', 'facebook', 'twitter', 'youtube', 'instagram', 'tiktok', 'linkedIn', 'title', 'otherEmail', 'assistantName', 'assistantEmail', 'assistantPhone', 'partnerName', 'partnerEmail', 'partnerPhone'], items: window.app.state.users, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}{u.email && ` - ${u.email}`}{u.type && ` - ${u.type}`}{u.crm && ` - ${u.crm}`}{key !== 'email' && key !== 'name' && key !== 'crm' && key !== 'type' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/profile?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '', full: false })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Titles', indexes: ['title', 'titleAKA', 'producers', 'director', 'cast', 'writers'], items: window.app.state.titles, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u.title}{u.titleAKA ? `- ${u.titleAKA}` : ''}{key !== 'titleAKA' && key !== 'title' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/title?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '', full: false })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Deals', indexes: ['name'], items: window.app.state.deals, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/deal?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '', full: false })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Companies', indexes: ['name', 'email', 'phone', 'facebook', 'twitter', 'instagram', 'cinando', 'website', 'deliveryContactName', 'deliveryContactEmail', 'dashboardURL', 'imdb', 'fax'], items: window.app.state.companies, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}{key !== 'name' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/company?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '', full: false })
                    }}>VIEW</button></div>)
                }
              },
              {
                key: 'Agreements', indexes: ['name', 'accountingContact'], items: window.app.state.companies, template: (u, i, key) => {
                  return (<div key={i} className="b2 jsb searchResult">
                    <h3>{u['name']}{key !== 'name' ? (u[key] instanceof Array ? <ArrayDisplay items={u[key]}></ArrayDisplay> : ` - ${u[key]}`) : ''}</h3>
                    <button id={u['_id']} className="smallbut" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.redirect(`/agreement?_id=${e.target.id}`)
                      this.bar?.setState({ list: [], searching: '', full: false })
                    }}>VIEW</button></div>)
                }
              }
            ]} indexes={['name']} ></SearchBar>
          </div>
        </div>
        {/* /Header Title */}

        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item">
          </li>
          {/* /Search */}
          {/* Flag */}

          {/* /Flag */}
          {/* Notifications */}
          <li className="nav-item dropdown">
            <a className="dropdown-toggle nav-link" onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.app.refresh()
            }}><i className="hoverActive fa fa-refresh" /></a>
          </li>
          <li className="nav-item dropdown">
            <a className="dropdown-toggle nav-link" data-toggle="dropdown">
              <i className="fa fa-bell-o" /> <span className="badge badge-pill">{this.props.notifications?.length || 0}</span>
            </a>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <a className="clear-noti" onClick={e => {
                  e.preventDefault();
                  window.app.state.notifications?.forEach(notification => {
                    window.app.viewNotification(notification._id)
                  })
                }}> Clear All </a>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {this.props.notifications?.map((u, i) => (<li key={i} className="notification-message">
                  <Link to={`/notifications?_id=${u._id}`}>
                    <div className="media">
                      <span className="avatar">
                        <img alt="" src={window.notificationImage(u.type)} />
                      </span>
                      <div className="media-body"><span>{u.subject}</span>
                        <p className="noti-details">{u.content.substr(0, 32)}</p>
                        <p className="noti-time"><span className="notification-time">{window.timeSince(u.date)}</span></p>
                      </div>
                      <div onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        window.app.viewNotification(u._id)
                      }}>X</div>
                    </div>
                  </Link>
                </li>))}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="/notifications">View all Notifications</Link>
            </div>
          </div>
        </li>
        {/* /Notifications */}
        {/* Message Notifications */}
        <li className="nav-item dropdown">
          <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
            <i className="fa fa-comment-o" /> <span className="badge badge-pill">{this.props.messages?.length || 0}</span>
          </a>
          <div className="dropdown-menu notifications">
            <div className="topnav-dropdown-header">
              <span className="notification-title">Messages</span>
              {/* <a className="clear-noti" onClick={e => {
                  e.preventDefault();
                 this.props.messages?.forEach(notification => {
                    window.app.viewNotification(notification._id)
                  })
                }}>Clear All</a> */}
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {this.props.messages.map((u, i) => {
                  let user = window.app.state.users?.find(a => a._id === u.from)
                  return (<li key={i} className="notification-message">
                    <Link to={`/messages?_id=${u._id}`}>
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img alt="" src={user?.profilePic} />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">{user?.name || 'Deleted User'}</span>
                          <span className="message-time">{window.timeSince(u.date)}</span>
                          <div className="clearfix" />
                          <span className="message-content">{u.content.substr(0, 32)}...</span>
                        </div>
                      </div>
                    </Link>
                  </li>)
                })}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to={'/messages'}>View all Messages</Link>
            </div>
          </div>
        </li>
        {/* /Message Notifications */}
        <li className="nav-item dropdown has-arrow main-drop">
          <a className="dropdown-toggle nav-link" data-toggle="dropdown">
            <span className="avatar rel"><img class="avatar" src={this.props.profilePic} alt="" />
              <span className="status online" /></span>
            <span>{this.props.name}</span>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/profile?_id=${window.app.state.userID}`}>My Profile</Link>
            <Link className="dropdown-item" to="/settings">Settings</Link>
            <a className="dropdown-item" onClick={e => { e.preventDefault(); window.logOut() }}>Logout</a>
          </div>
        </li>
      </ul>
        {/* /Header Menu */ }
    {/* Mobile Menu */ }
    <div className="dropdown mobile-user-menu">
      <a
        href="#"
        className="nav-link dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v"></i>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        <Link className="dropdown-item" to={`/profile?_id=${window.app.state.userID}`}>My Profile</Link>
        <Link className="dropdown-item" to="/settings">Settings</Link>
        <a onClick={e => { e.preventDefault(); window.logOut() }}>Logout</a>
      </div>
    </div>
    {/* /Mobile Menu */ }
      </div >
    );
  }
}

export default Header;
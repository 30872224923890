import React, { Component } from 'react'
import ArrayDisplay from '../helpers/display/arraydisplay';
import Table from '../helpers/display/table'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
class ImportCSV extends Component {
    constructor(props) {
        super(props)
        this.file = React.createRef()
        this.check = React.createRef()
    }
    state = {
        imported: [],
        suggestions: [],
        suggest: false,
        requesting: false
    }
    import() {
        let that = new XMLHttpRequest()
        that.onerror = (e) => { this.setState({ requesting: false }); window.flash(e) }
        that.onabort = e => { this.setState({ requesting: false }); window.flash(e) }
        that.onreadystatechange = e => {
            if (that.readyState === 4) {
                this.setState({ requesting: false })
                let data
                try {
                    data = JSON.parse(that.responseText)
                } catch (e) {
                    console.log(that.responseText)
                    return window.flash(e)
                }
                if (!data || data.error) return window.flash(data.message || JSON.stringify(data))
                this.setState({ file: null, imported: data.imported, suggestions: data.validations }, () => {
                    let input = this.file.current
                    if (input) input.files = null
                })
            }
        }
        let fd = new FormData()
        fd.append('csv', this.file.current.files[0])
        if (this.state.suggest) fd.append('validate', 'true')
        this.setState({ requesting: true })
        that.open('POST', window.API + '/import-csv', true)
        that.setRequestHeader('authorization', window.app.state.auth)
        that.send(fd)
    }
    getCSV = fields => {
        window.app.request('/gen-csv', 'post', {fields, name: this.props.name}).then(result => {
            let a = document.createElement('a')
            a.href = result.link
            a.target = '_blank'
            document.querySelector('body').append(a)
            a.click()
            a.remove()
        }).catch(e => window.flash(e))
    }
    render() {
        let keyset = (() => this.state.imported.reduce((a, b) => {
            let k = Object.keys(b)
            for (let i = 0; i < k.length; i++) if (!a.includes(k[i])) a.push(k[i])
            return a
        }, []))()
        let instance = data => {
            let o = {}
            for (let i = 0; i < keyset.length; i++) o[keyset[i]] = data[keyset[i]] || null
            return Object.values(o)
        }
        let spin = () => (<div id="importCSV" className="b1">
            <Spinner></Spinner>
            <span>Loading Suggestions<DotDot></DotDot></span>
        </div>)
        return (<>
            <div id="importData" onClick={this.props.close} className="modal fade show" style={{ display: 'block' }}>
                <div id="importChild" onClick={e => e.stopPropagation()} className="modal-dialog">
                    <div className="modal-content" style={{ overflowY: 'auto' }}>
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title">Import {this.props.dataType || 'Data'}</h4>
                            <button type="button" id="exit-pictures" className="close" onClick={this.props.close}>×</button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body c1">
                            <>
                                {!this.state.requesting && !this.state.imported?.length > 0 && <div className="c1">
                                    <div className="b1">
                                        <input className="form-control" accept="text/csv" ref={this.file} type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                                    </div>
                                    {this.props.validation && <div className="b2 jfs">
                                        <div className="checkbox">
                                            <input className="form-control" ref={this.check} type="checkbox" checked={this.state.suggest} onChange={e => this.setState({ suggest: e.target.checked })} /> Validate Titles?
                                        </div>
                                    </div>}
                                </div>}
                                <div className="b2 wrap">
                                    {this.props.template && !this.state.file && <button onClick={() => {
                                        this.getCSV(this.props.template)
                                    }}>Download Template</button>}{this.state.file && <button onClick={() => {
                                        this.import()
                                    }}>Import</button>}{this.state.imported?.length > 0 && <button onClick={() => {
                                        for (let i = 0; i < this.state.imported.length; i++) if (this.state.imported[i]) this.props.import(this.state.imported[i])
                                        this.setState({ imported: [], suggestions: [] })
                                    }}>Save</button>}<button onClick={this.props.close}>Cancel</button>
                                </div>
                                {this.state.requesting ? spin() : <div id="importCSV" className="b1">
                                    {this.state.suggestions?.length > 0 ? (<div className="b1 rel" style={{ paddingTop: '60px' }}>
                                        <button className="top-right-abs" onClick={() => this.setState({
                                            suggestions: null, imported: [...this.state.imported].map(u => {
                                                let a = window.app.state.titles?.find(a => a._id === u.title || a.title === window.namefilter(u.title))
                                                if (a) u.title = a._id
                                                return u
                                            })
                                        })}>Close Suggestions</button>
                                        <h3>Some of your items were not validated!</h3>
                                        <span>Please see choose from the suggestions below to validate your data to continue without changing these items</span>
                                        <div className="c3" style={{ width: 'auto', minWidth: '90%', padding: '5%' }}>
                                            {this.state.suggestions.map((u, i) => {
                                                let { title, suggestions } = u
                                                return (<div key={i} className="b1 suggestionCont">
                                                    <h3 className="suggestionTitle">{title}</h3>
                                                    <span className="suggestionSpan">Suggestions:</span>
                                                    {suggestions?.data && suggestions.data.sort((a, b) => b.score - a.score).map((u, i) => (<div key={i} className="suggestion" data-title={title} onClick={e => {

                                                        let title = e.target.getAttribute('data-title')
                                                        if (title) {
                                                            let value = e.target.innerHTML
                                                            this.setState({
                                                                imported: [...this.state.imported].map(u => {
                                                                    if (u.title === title) u.title = window.app.state.titles?.find(a => a.title == value)?._id || title
                                                                    return u
                                                                }),
                                                                suggestions: this.state.suggestions.filter(u => u.title != title)
                                                            }, () => {
                                                                if (this.state.suggestions.length < 1) this.setState({
                                                                    suggestions: null, imported: [...this.state.imported].map(u => {
                                                                        let a = window.app.state.titles?.find(a => a._id === u.title || a.title == window.namefilter(u.title))
                                                                        if (a) u.title = a._id
                                                                        return u
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    }}>{u.text}</div>))}
                                                </div>)
                                            })}
                                        </div>
                                    </div>) : this.state.imported?.length > 0 ? <Table data={this.state.imported} columns={keyset.map(u => ({ title: u, dataIndex: u }))} /> : false ? (<div className="table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {keyset.map((u, i) => (<th key={i}>{u}</th>))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.imported.map((u, i) => (<tr key={i}>{instance(u).map((value, i) => value ? (<td key={i}>{value instanceof Array ? <ArrayDisplay items={value} /> : JSON.stringify(value)}</td>) : <td key={i}></td>)}</tr>))}
                                            </tbody>
                                        </table>
                                    </div>) : ''}


                                </div>}
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div></>)
    }
}
export default ImportCSV
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import WorkForm from '../forms/workform'
import WorkOrder from '../views/work-order'
import Table from '../helpers/display/table'
import TabLink from '../helpers/tablink'
import Filters from '../helpers/filters'
import Sorter from '../helpers/sorter'
class WorkOrders extends Component {
    height = () => `${Math.floor(window.innerHeight * .75)}px`
    handleHeight = () => this.setState({ height: this.height() })
    state = {
        edit: false,
        view: '',
        currentList: [],
        currentFilter: [],
        currentSort: [],
        showFilters: false,
        fields: [],
        filters: [],
        sortMode: [],
        sort: sessionStorage.getItem('workOrderSort'),
        height: this.height(),
        mode: sessionStorage.getItem('workordermode') || 'kanban'
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleHeight)
    }
    componentDidUpdate() {
        if (this.state.sort && !this.state.sortMode.length > 0 && !this.sorted) {
            this.sorted = true
            let { sort } = this.state
            let fields = window.getListFields(this.props.deals)
            let type = fields?.find(a => a.key === sort)?.type || 'string'
            this.setState({ sortMode: [{ name: sort, type, direction: sessionStorage.getItem('workOrderSortDir') === 'true' ? true : false }] })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleHeight)
    }
    drag = false
    changeWorkOrderStatus = (_id, status) => {
        window.app.addWorkOrder({ fields: { _id, status } })
    }
    drop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.remove('kanbanOver')
        let data = e.dataTransfer.getData('text')
        if (!data) return window.flash('Bad Drop')
        let deal = this.props.data.find(a => a._id === data)
        if (!deal) return window.flash('Bad Drop')
        let status = e.currentTarget.getAttribute('data-status')
        if (!status) return window.flash('Bad drop')
        if (status === deal.status) return
        this.changeWorkOrderStatus(data, status)
    }
    exit = e => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.remove('kanbanOver')
    }
    over = e => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.add('kanbanOver')
    }
    render() {
        window.workOrders = this
        if (typeof window.$ === 'function') window.$(function () {
            window.$('[data-toggle="tooltip"]').tooltip()
        })
        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => window.nameCompare(a.name, b.name),
                render: (text, record) => <>
                    <span className="linkable" onClick={() => this.setState({ view: record._id })}>{text}</span>
                </>
            },
            {
                title: 'Estimated Expense',
                dataIndex: 'amount',
                sorter: (a, b) => b.amount - a.amount,
                render: (text, record) => <>${window.currency(text)}</>
            },
            {
                title: 'Company',
                dataIndex: 'company',
                sorter: (a, b) => window.nameCompare(a.company, b.company),
                render: (text, record) => <>
                    <span className="linkable" onClick={() => window.redirect(`/company?_id=${record.company}`)}>{window.app.state.companies?.find(a => a._id === record.company)?.name || text}</span>
                </>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: (a, b) => window.nameCompare(a.status, b.status),
                render: (text, record) => <>
                    <span>{window.capitalize(text)}</span>
                </>
            },
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: (a, b) => window.nameCompare(a.type, b.type),
                render: (text, record) => <>
                    <span>{window.capitalize(text)}</span>
                </>
            },
            {
                title: 'Assigned To',
                dataIndex: 'assignedTo',
                sorter: (a, b) => window.nameCompare(a.assignedTo, b.assignedTo),
                render: (text, record) => <>
                    <span className="linkable" onClick={() => window.redirect(`/profile?_id=${record.assignedTo}`)}>{window.app.state.users?.find(a => a?._id === record.assignedTo)?.name || text}</span>
                </>
            },
            {
                title: "Action",
                dataIndex: "status",
                render: (text, record) => (
                    <div className="dropdown dropdown-action">
                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#" onClick={e => { this.setState({ view: record._id, edit: true }) }}>Edit Work Order</a>
                            <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete Work Order: ${record.name} - ${record._id}`, () => {window.app.deleteWorkOrder(record._id)}) }}>Delete Work Order</a>
                        </div>
                    </div>
                ),
            },
        ]
        return (<>
            <Helmet>
                <title>Work Orders</title>
                <meta name="description" content="Work Orders" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="feather-list" />
                                </span> Work Orders</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Work Orders</li>
                            </ul>
                        </div>
                    </div>
                    <div className="b2 wrap jsb" style={{ overflow: 'visible' }}>
                        <div className="col" style={{ maxWidth: '25%' }}>
                            <div className="dropdown">
                                <a className="dropdown-toggle recently-viewed" href="#" role="button" data-toggle="dropdown" aria-expanded="false"> {window.capitalize(this.state.mode)}</a>
                                <div className="dropdown-menu" >
                                    <div onClick={e => this.setState({ mode: 'kanban' }, () => {
                                        sessionStorage.setItem('workordermode', this.state.mode)
                                    })} className="dropdown-item" value="kanban">KanBan</div>
                                    <div onClick={e => this.setState({ mode: 'table' }, () => {
                                        sessionStorage.setItem('workordermode', this.state.mode)
                                    })} className="dropdown-item" value="table">Table</div>
                                </div>
                            </div>
                            <div className="col" style={{ marginTop: '10px' }}>
                                {this.state.mode === 'kanban' && <span className="pointer filterToggle" onClick={e => this.setState({ showFilters: !this.state.showFilters })} style={{ cursor: 'pointer' }}>{this.state.showFilters ? 'Hide Filters' : 'Show Filters'}</span>}
                                {this.state.mode === 'kanban' && this.state.filters?.length > 0 && <div className="b2 wrap">
                                    <span><strong>Filters:</strong></span>
                                    {this.state.filters.map((u, i) => u.mode && u.mode.name && u.mode.value && (<span key={i} style={{ margin: '3px' }}>({window.parseKey(u.name)} - {u.mode?.name} {u.mode?.value ? (u.mode.value instanceof Date ? window.formatDate(u.mode.value) : JSON.stringify(u.mode.value)) : JSON.stringify(u.mode?.value)})</span>))}
                                </div>}
                            </div>
                        </div>
                        <div className="b2 jfe" style={{ maxWidth: '70%', width: 'auto', overflow: 'visible' }}>
                            {this.state.mode === 'kanban' && <Sorter list={this.props.data} change={(list, currentSort) => {
                                this.setState({ currentSort })
                            }} />}
                            <div className="col text-right">
                                <ul className="list-inline-item pl-0">
                                    <li className="list-inline-item b2">
                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ edit: true })}>New Work Order</button>
                                        {/* <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.mode === 'kanban' ? <div id="dealCont" style={{ gridTemplateColumns: this.state.showFilters ? (window.innerWidth > 1080 ? '1fr 5fr' : '1fr 2fr') : '1fr' }}>
                    {this.state.showFilters && <Filters list={this.props.data} change={(list, filters) => {
                        this.setState({ currentFilter: filters })
                    }} height={this.state.height} statusList={window.dealStatuses} />}
                    <div className="b1">
                        <div id="kanban" className="d1" style={{ height: this.state.height }}>
                            {this.props.data && Object.entries(window.sort(window.filter(this.props.data, this.state.currentFilter || []), this.state.currentSort || [])?.reduce((a, b) => {
                                if (!a[b.status]) a[b.status] = []
                                a[b.status].push(b)
                                return a
                            }, window.workOrderStatuses?.reduce((a, b) => {
                                a[b] = []
                                return a
                            }, {})) || []).map(u => {
                                if (u[1]) {
                                    return ([u[0], window.sort(u[1], this.state.sortMode)])
                                } else {
                                    return u
                                }
                            }).map((u, i) => {
                                if (!u) return null
                                return (<ul key={i} className="b1 kanList rel" data-status={u[0]} onDrop={e => this.drop(e)}
                                    onDragExit={e => this.exit(e)}
                                    onDragLeave={e => this.exit(e)}
                                    onDragOver={e => this.over(e)}>
                                    <div className="kanBanner">
                                        <h3 style={{ color: 'inherit' }}>{window.capitalize(u[0].toString())}</h3>
                                        <span>${u[1] instanceof Array && window.currency(window.sum(u[1].map(u => u.amount)))} - {u[1]?.length || 0} Work Orders</span>
                                    </div>
                                    <div className="b1" style={{ height: '100%', overflow: 'visible', justifyContent: 'flex-start' }} >
                                        {u[1] instanceof Array && u[1]?.length > 0 ? u[1].map((a, i) => (<div className="kanItem" onDragStart={e => {
                                            e.dataTransfer.setData('text', e.target.id);
                                            this.drag = true
                                            e.target.classList.add('kanDrag')
                                        }} onDragEnd={e => {
                                            this.drag = false
                                            e.target.classList.remove('kanDrag')
                                        }} draggable={true} id={a._id} key={i}>
                                            <span onClick={() => this.setState({ view: a._id })} className="linkable" style={{ color: 'inherit', cursor: 'pointer' }} key={i}><strong>{a.name}</strong></span>
                                            {a.assignedTo && <TabLink to={`/profile?_id=${a.assignedTo}`} content={<span data-toggle="tooltip" data-placement="right" title="Assigned To" className="linkable">{window.app.state.users?.find(z => z._id === a.assignedTo)?.name}</span>} />}
                                            {a.company && <TabLink to={`/company?_id=${a.company}`} content={<span data-toggle="tooltip" data-placement="right" title="Company" className="linkable">{window.app.state.companies?.find(z => z._id === a.company)?.name}</span>} />}
                                            {a.contacts && a.contacts.map(({contact}, i) => <TabLink key={i} to={`/profile?_id=${contact}`} content={<span data-toggle="tooltip" data-placement="right" title="Work Order Contact" className="linkable">{window.app.state.users?.find(z => z._id === contact)?.name}</span>} />)}
                                            {a.notes && a.notes[a.notes.length - 1]?.date && <a style={{ cursor: 'default' }}><span data-toggle="tooltip" data-placement="right" title="Last Activity">{window.formatDate(a.notes[a.notes.length - 1]?.date)}</span></a>}
                                        </div>)) : (<div
                                            data-status={u[0]}
                                            onDrop={e => this.drop(e)}
                                            onDragExit={e => this.exit(e)}
                                            onDragLeave={e => this.exit(e)}
                                            onDragOver={e => this.over(e)}
                                            className="b1 emptykan">
                                            <p>No Work Orders</p>
                                        </div>)}
                                    </div>
                                </ul>)
                            })}
                        </div>
                    </div>
                </div> : this.state.mode === 'table' ? (<Table data={window.filter(this.props.data, this.state.filters || []).sort((a,b) => window.nameCompare(a.name, b.name))} key={'_id'} columns={columns}></Table>) : ''}
            </div>
            {this.state.edit ? <WorkForm _id={this.state.view} close={() => this.setState({ edit: false })} add={this.props.add}></WorkForm> : this.state.view ? (<WorkOrder _id={this.state.view} add={this.props.add} edit={() => this.setState({ edit: true })} close={() => this.setState({ view: '' })} />) : ''}
        </>)
    }
}
export default WorkOrders
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "../helpers/display/table";
import 'antd/dist/antd.css'
import "../helpers/display/antdstyle.css"
import { Link } from "react-router-dom";
import CompanyForm from '../forms/companyform'
import Company from '../views/company'
import ChangeImage from '../forms/changeimage'
import ImportCSV from '../forms/importcsv';
import Filters from '../helpers/filters'
class Companies extends Component {
    state = {
        view: '',
        edit: false,
        changeImage: false,
        showFilters: false,
        currentFilter: [],
        selectedItems: []
    }
    clearSelection = (id) => {
        this.setState({ selectedItems: [...this.state.selectedItems].filter(i => i !== id) })
    }
    delete = (id) => {
        window.app.deleteCompany(id)
        this.clearSelection(id)
    }

    render() {
        window.companies = this
        const { data, add } = this.props;
        const rowSelection = {
            selectedRowKeys: this.state.selectedItems,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedItems: selectedRows.map(u => u._id) })
            },
            getCheckboxProps: (record) => ({
                disabled: false
            }),
        };
        const columns = [
            {
                title: "Company Name",
                dataIndex: "name",
                render: (text, record) => (
                    <><a href="#" onClick={e => {
                        e.preventDefault()
                        this.setState({ view: record._id })
                    }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.setState({ view: record._id })
                        }} >{text}</a></>
                ),
                sorter: (a, b) => window.nameCompare(a.name, b.name),
            },
        ];
        if (this.state.selectedItems?.length < 1) columns.push({
            title: "Actions",
            dataIndex: "status",
            render: (text, record) => (
                <div className="dropdown dropdown-action">
                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }} href="#" >Change Image</a>
                        <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.setState({ view: record._id, edit: true }) }} href="#" >Edit Company</a>
                        <a className="dropdown-item" onClick={(e) => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }} href="#" >Delete Company</a>
                    </div>
                </div>
            ),
        })

        return (
            <>
                <Helmet>
                    <title>Companies</title>
                    <meta name="description" content="Reactify Blank Page" />
                </Helmet>
                <div className="content container-fluid">
                    <div className="page-header pt-3 mb-0 ">
                        <div className="crms-title row bg-white">
                            <div className="col">
                                <h3 className="page-title m-0">
                                    <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                        <i className="feather-database" />
                                    </span> Companies </h3>
                            </div>
                            <div className="actionButtonGroup">

                            </div>
                            <div className="col text-right">
                                <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Companies</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="pointer filterToggle" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{this.state.showFilters ? 'Hide' : 'Show'} Filters</span>
                            </div>
                            <div className="col text-right">
                                <ul className="list-inline-item pl-0">
                                    <li className="list-inline-item">

                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={e => { this.setState({ edit: true }) }}>New Company</button>
                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                        {this.state.showFilters && <Filters list={data} change={(currentList, currentFilter) => this.setState({ currentFilter })} />}
                        <div className="b1">
                            {this.state.selectedItems?.length > 0 && <div className="b2">
                                <button onClick={e => {
                                    window.sure(`Delete ${this.state.selectedItems.length} Items`, () => {
                                        let list = [...this.state.selectedItems]
                                        for (let i = 0; i < list.length; i++) {
                                            this.delete(list[i])
                                        }
                                    })
                                }}>Delete Selected</button>
                            </div>}
                            <Table
                                className="table"
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                data={window.filter(data, this.state.currentFilter || []).sort((a, b) => window.nameCompare(a.name, b.name))}
                                mainKey={'_id'}
                                rowSelection={rowSelection}
                            />
                        </div>
                    </div>
                </div>
                {this.state._id && this.state.changeImage && <ChangeImage company={true} close={e => this.setState({ changeImage: false, _id: '' })} _id={this.state._id} />}
                {this.state.import && <ImportCSV templateName={'CompanyTemplate'} template={['name', 'phone', 'website', 'email', 'fax']} dataType="Companies" import={data => {
                    window.app.addCompany({ fields: { ...data, crm: this.props.crm } }).catch(e => window.flash(e))
                }} close={() => this.setState({ import: false })} />}
                {this.state.edit ? <CompanyForm crm={this.props.crm} _id={this.state.view} add={add} close={() => this.setState({ edit: false })} /> : this.state.view ? <Company edit={() => this.setState({ edit: true })} crm={this.props.crm} _id={this.state.view} close={() => this.setState({ view: '' })}></Company> : ''}
            </>
        );
    }
}

export default Companies;

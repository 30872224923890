import React, { Component } from 'react'
import Table from '../helpers/display/table'
import 'antd/dist/antd.css'
import "../helpers/display/antdstyle.css"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import FForm from '../forms/film-form'
import ChangeImage from '../forms/changeimage'
import TitleView from '../views/titleview'
import ImportCSV from '../forms/importcsv';
import Filters from '../helpers/filters'
class Titles extends Component {
    state = {
        showFilters: false,
        currentFilter: [],
        selectedItems: []
    }
    clearSelection = (id) => {
        this.setState({ selectedItems: [...this.state.selectedItems].filter(i => i !== id) })
    }
    delete = id => {
        window.app.deleteTitle(id)
        this.clearSelection(id)
    }
    render() {
        window.titles = this
        const rowSelection = {
            selectedRowKeys: this.state.selectedItems,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedItems: selectedRows.map(u => u._id) })
            },
            getCheckboxProps: (record) => ({
                disabled: false
            }),
        };
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                sorter: (a, b) => window.nameCompare(a.title, b.title),
                render: (text, record) => <>
                    <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar">
                        <img className="avatar" alt="" src={record.keyArt} />
                    </a>
                    <span style={{ cursor: 'pointer', fontWeight: 800 }} onClick={() => this.setState({ view: record._id })}>{text}</span>
                </>
            },
        ]
        if (this.state.selectedItems?.length < 1) columns.push({
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
                <div className="dropdown dropdown-action">
                    <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ changeImage: true, _id: record._id }) }}>Change Image</a>
                        <a className="dropdown-item" href="#" onClick={e => { this.setState({ view: record._id, edit: true }) }}>Edit Title</a>
                        <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.title}`, () => this.delete(record._id)) }}>Delete Title</a>
                    </div>
                </div>
            ),
        })
        return (<>
            <Helmet>
                <title>Titles</title>
                <meta name="description" content="OMM Titles" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="la la-film" />
                                </span> Titles</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>

                                <li className="breadcrumb-item active">Titles</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className="filterToggle pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{this.state.showFilters ? 'Hide' : 'Show'} Filters</span>
                        </div>
                        <div className="col text-right">
                            <ul className="list-inline-item pl-0">
                                {/* <li className="nav-item dropdown list-inline-item add-lists">
                                    <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                                        <div className="nav-profile-text">
                                            <i className="fa fa-th" aria-hidden="true" />
                                        </div>
                                    </a>
                                    <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#add-new-list">Add New List View</a>
                                    </div>
                                </li> */}
                                <li className="list-inline-item">
                                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ edit: true })}>Register a Film</button>
                                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                    {this.state.showFilters && <Filters list={this.props.titles} change={(list, filter) => this.setState({ currentFilter: filter })} />}
                    <div className="b1">
                        {this.state.selectedItems?.length > 0 && <div className="b2">
                            <button onClick={() => {
                                window.sure(`Delete ${this.state.selectedItems.length} Items`, () => {
                                let list = [...this.state.selectedItems]
                                for (let i = 0; i < list.length; i++) {
                                    this.delete(list[i])
                                }
                            })
                            }}>Delete Selected</button>
                        </div>}
                        <Table rowSelection={rowSelection} data={window.filter(this.props.titles, this.state.currentFilter || []).sort((a, b) => window.nameCompare((a.title || ''), (b.title || '')))} mainKey={'_id'} columns={columns}></Table>
                    </div>
                </div>
                {/* <button className="bottom-right-float" onClick={e => window.redirect('/register-a-film')}>REGISTER FILM</button> */}
            </div>
            {this.state.import && <ImportCSV templateName={'TitleTemplate'} template={['name', 'primaryGenre', 'contractStartDate', 'contractEndDate', 'titleAKA']} dataType="title" import={data => {
                window.app.addTitle({ fields: data }).catch(e => window.flash(e))
            }} close={() => this.setState({ import: false })} />}
            {this.state.edit ? <FForm _id={this.state.view} add={this.props.add} close={() => this.setState({ edit: false })} /> : this.state.view ? <TitleView edit={() => this.setState({ edit: true })} _id={this.state.view} close={() => {
                this.setState({ view: '' })
            }} /> : ''}
            {this.state._id && this.state.changeImage && <ChangeImage _id={this.state._id} title={true} close={e => this.setState({ changeImage: false, _id: '' })} />}
        </>)
    }
}
export default Titles

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
class Sure extends Component {
    render() {
        let close = () => ReactDOM.unmountComponentAtNode(document.querySelector("#sure-pop"))
        let no = this.props.no
        return (<div onClick={close} className="modal fade show" style={{ display: 'block' }} id="change-picture">
            <div onClick={e => e.stopPropagation()} className="modal-dialog">
                <div className="modal-content">
                    {/* Modal Header */}
                    <div className="modal-header">
                        <h4 className="modal-title">Are You Sure?</h4>
                        <button type="button" id="exit-pictures" className="close" onClick={() => {                 
                            if (typeof no === 'function') no();
                            close();
                            }}>×</button>
                    </div>
                    {/* Modal body */}
                    <div className="modal-body">
                        <h3>{this.props.text}</h3>
                        <div className="c2">
                            <div className="b1">
                                <button onClick={() => {
                                    if (typeof this.props.callback === 'function') this.props.callback()
                                    close()
                                }}>Yes</button>
                            </div>
                            <div className="b1">
                                <button onClick={() => {
                                    if (typeof no === 'function') no()
                                    close()
                                }}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default Sure
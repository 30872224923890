import React, { Component } from 'react'
import SensitiveInput from './sensitiveinput2'
import SearchInput from './searchinput'
class CredentialInput extends Component {
    state = { email: '', delimiter: window.textdelim, password: '', type: '', directions: '', selected: [] }
    componentDidMount() {
        if (this.props.noLink && this.props.noDirections && this.props.noType) {
            if (typeof this.props.selected === 'object') {
                this.priorWatch = true
                this.setState({ selected: this.props.selected })
            }
        } else {
            if (typeof this.props.selected === 'object' && !(this.props.selected instanceof Array)) {
                this.priorWatch = true
                this.setState({ selected: [], password: this.props.selected.password, email: this.props.selected.email, link: this.props.selected.link, type: this.props.type, directions: this.props.selected.directions })
            } else if (this.props.selected?.length > 0) {
                this.priorWatch = true
                this.setState({ selected: this.props.selected || [], type: this.props.multiple ? '' : this.props.type })
            }
        }
    }
    componentDidUpdate() {
        if (this.props.noLink && this.props.noDirections && this.props.noType) {
            if (this.props.selected && !this.priorWatch) {
                this.priorWatch = true;
                if (typeof this.props.selected === 'object') {
                    this.setState({ selected: this.props.selected })
                } else {
                    this.setState({ selected: this.props.selected || {} })
                }
            }
        } else {
            if (this.props.selected && !this.priorWatch) {
                this.priorWatch = true;
                if (typeof this.props.selected === 'object' && !(this.props.selected instanceof Array)) {
                    this.setState({ password: this.props.selected.password, email: this.props.selected.email, link: this.props.selected.link, type: this.props.selected.type, directions: this.props.selected.directions })
                } else {
                    this.setState({ selected: this.props.selected || [], delimiter: this.props.type === 'text' ? window.textdelim : ',' })
                }
            }
        }
    }
    render() {
        let { name, multiple, noLink, noDirections, noType } = this.props
        let { selected } = this.state
        let change = () => {
            let { email, password, type, directions, link } = this.state
            if (this.props.noLink && this.props.noDirections && this.props.noType) {
                this.setState({ selected: { ...this.state.selected, [email]: password } })
            } else {
                if (!email) return window.flash('Please select a title')
                if (!type) return window.flash('Please describe the title\'s allocation')
                if (selected.find(a => a.type === type)) return window.flash('That type already exists in the selection. Please choose another type or remove the existing one before addign the new one.')
                this.setState({ selected: [...this.state.selected, { email, password, type, directions, link }], email: '', password: '', link: '', name: '', type: '', directions: '' })
            }
        }
        return (<>
            <input type="text" value={(noLink && noDirections && noType ? JSON.stringify(selected || {}) : selected && selected instanceof Array && selected.length > 0 ? JSON.stringify(selected) : (this.state.email || this.state.password || (this.state.type && !this.props.type) || this.state.directions) ? JSON.stringify({ email: this.state.email, password: this.state.password, type: this.state.type, directions: this.state.directions, link: this.state.link }) : 'null-input')} onChange={e => { }} className="hidden" name={name ? `credentials-${name}` : name} />
            {selected && selected instanceof Array ? <ul>
                {selected.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : JSON.stringify(u)}<i id={u.id} className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    this.setState({ selected: [...this.state.selected].filter(a => a.id !== e.target.id) })
                }}></i></span></li>))}
            </ul> : selected && typeof selected === 'object' ? <ul>
                {Object.entries(selected).map(([username, password], i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}><span><strong>Username:</strong> {username} <strong>Password: </strong> {password}</span><i className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    this.setState({ selected: Object.entries({ ...this.state.selected }).reduce((a, [key, value]) => key !== username ? { ...a, [key]: value } : a, {}) })
                }}></i>
                </span>
                </li>))}
            </ul> : ''}
            <div className="c2">
                {noType && noDirections && noLink ? <>
                    <div className="b2 jfs">
                        <input className="form-control" type="text" placeholder="Username" value={this.state.email} onChange={e => { this.setState({ email: e.target.value?.toLowerCase() }) }}></input>
                    </div>
                    <div className="b2 jfs">
                        <SensitiveInput placeholder="Password" value={this.state.password} change={e => this.setState({ password: e })} />
                    </div>
                </> : <>
                    <div className="b2 jfs">
                        <input className="form-control" type="text" placeholder="Email/Username" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }}></input>
                    </div>
                    <div className="b2 jfs">
                        <SensitiveInput placeholder="Password" value={this.state.password} change={e => this.setState({ password: e })} />
                    </div>
                    {!this.props.type && <div className="b2 jfs">
                        <SearchInput items={['servicing dashboard', 'reporting dashboard', 'file transfer dashboard']} change={e => {
                            this.setState({ type: e })
                        }} placeholder="Type" value={this.state.type} />
                    </div>}
                    <div className="b2 jfs">
                        <input className="form-control" type="text" placeholder="Directions" value={this.state.directions} onChange={e => { this.setState({ directions: e.target.value }) }} />
                    </div>
                    <div className="b1">
                        <input type="text" className="form-control" placeholder="Link" value={this.state.link} onChange={e => this.setState({ link: e.target.value })}></input>
                    </div>
                </>}
                {multiple && <button className="btn btn-primary" style={{ margin: 0, padding: '4px', cursor: 'pointer', gridArea: '4 / 1 / span 1 / span 2' }} onClick={e => { e.preventDefault(); change(); }}>Add Credentials</button>}
            </div>
        </>)
    }
}
export default CredentialInput
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import IM from '../helpers/im'
class Messages extends Component {
    state = { conversations: [], recipients: [], id: false }
    componentDidMount() {
        let {_id} = window.app.state.queries
        if (_id) {
            let message = this.props.messages.find(a => a._id === _id)
            if (message) {
                this.setState({conversations: [...this.state.conversations, {recipient: message.from !== window.app.state.userID ? message.from : message.to, message: '', _id: this.state.conversations.length}]})
            }
            this.setState({_id})
        }
    }
    componentDidUpdate() {
        let {_id} = window.app.state.queries
        if (_id !== this.state._id) {
            this.setState({_id})
            if (_id) {
                let message = this.props.messages.find(a => a._id === _id)
                if (message) {
                    this.setState({conversations: [...this.state.conversations, {recipient: message.from !== window.app.state.userID ? message.from : message.to, message: '', _id: this.state.conversations.length}]})
                }
            }
        }

    }
    send = (message, recipient) => {
        if (!message || !recipient) throw 'Missing recipient || message'
        window.ws.sendData({ type: 'message', data: { to: recipient, from: window.app.state.userID, message } })
    }
    startConverstation = user => {
        if (!user) return window.flash('Missing User')
        if (this.state.conversations?.find(a => a.recipient === user)) return
        this.setState({ conversations: [...this.state.conversations, { recipient: user, message: '', _id: this.state.conversations.length }] })
    }
    render() {
        return (<>
            <Helmet>
                <title>Messages</title>
                <meta name="description" content="OMM Titles" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="la la-film" />
                                </span> Messages</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Messages</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ margin: '10px 0' }}>
                    <div className="col-md-2" style={{ border: 'var(--border)', backgroundColor: 'var(--l)', minHeight: '40vh', maxHeight: '40vh' }}>
                        <h3>Recipients</h3>
                        <div className="c1" style={{ maxHeight: '35vh', overflowY: 'auto' }}>
                            {this.props.recipients?.map((u, i) => (<div key={i} user={u._id} onClick={e => {
                                this.startConverstation(e.target.getAttribute('user'))
                            }} className="recipient">
                                <img onClick={window.parentClick} className="avatar" alt="" src={u.profilePic}></img>
                                <p onClick={window.parentClick}>{u.name}</p>
                            </div>))}
                        </div>
                    </div>
                    <div className="col-md-10" style={{ minHeight: '40vh' }}>
                        <h3>Current Conversation / Multiple Conversations</h3>
                        <div id="conversations" className="row">
                            {this.state.conversations?.map((u, i) => <div key={i} className="col conversation">
                                {(() => {
                                    let recipient = this.props.recipients?.find(a => a._id === u.recipient)
                                    if (!recipient) return null
                                    return <div onClick={e => e.preventDefault()} className="recipient rel">
                                        <img onClick={window.parentClick} className="avatar" src={recipient.profilePic} alt="" />
                                        <p onClick={window.parentClick}>{recipient.name}</p>
                                        <button onClick={() => this.setState({ conversations: [...this.state.conversations].filter(a => a.recipient !== u.recipient) })} style={{ position: 'absolute', top: 0, right: 0 }}>X</button>
                                    </div>
                                })()}
                                <IM viewed={id => {
                                    if (id === '*') {
                                        if (window.ws) {
                                            let messages = this.props.messages.filter(a => (a.from === u.recipient) && !a.read)
                                            for (let i = 0; i < messages.length; i++) window.ws.sendData({type: 'message', data: {_id: messages[i]._id}})
                                        }
                                    } else if (id) {
                                        if (window.ws) {
                                            let message = this.props.messages?.find(a => a._id === id)
                                            if (message.from === u.recipient && !message.read) window.ws.sendData({type: 'message', data: {_id: message._id}})
                                        }
                                    }
                                }} history={u.history || this.props.messages?.filter(a => a.to === u.recipient || a.from === u.recipient)} recipient={u.recipient} message={u.message} send={this.send}></IM>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Messages
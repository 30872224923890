import React, { Component } from 'react'
import YesNo from './yesno'
class FileInput extends Component {
    constructor(props) {
        super(props)
        this.input = React.createRef()
    }
    state = { ye: false, files: [], selected: [], delimiter: '---!---' }
    componentDidMount() {
        if (this.props.mode === 'yesno') this.setState({ ye: this.props.yes || false })
    }
    componentDidUpdate(prv){
        if (this.props.mode === 'yesno' && this.props.yes !== prv.yes) this.setState({ye: this.props.yes})
    }
    onDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        let input = this.input.current
        input.files = ev.dataTransfer.files;
        let f = []
        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    f.push(file)
                }
            }
        }
        this.setState({ files: f, enter: false })
    }
    dragOver = (e) => {
        e.stopPropagation();
        e.preventDefault()
        this.setState({ enter: true })
    }
    dragStop(e) {
        e.stopPropagation();
        e.preventDefault()
        this.setState({ enter: false })
    }
    change = () => {
        if (!this.state.ye) {
            this.setState({files: []})
        } else {
            let { files } = this.input.current
            this.setState({ files })
            let { max, types, change } = this.props
            if (max) {
    
            }
            if (types) {
    
            }
            if (typeof change === 'function') change(files)
        }
    }
    selectedFiles = () => {
        return this.state.files.map(u => {

        })
    }
    render() {
        let { mode, name, multiple, folder, currentItems } = this.props
        let {selected, delimiter} = this.state
        if (mode === 'itemized') {
            return (<div>
                <input type="text" value={selected && selected instanceof Array && selected?.join(delimiter || ',') || 'null-input'} onChange={e => { }} className="hidden" name={name} />
                {selected && selected?.length > 0 && selected instanceof Array && <ul>
                    {selected?.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : u}<i className="hidden-x la la-xing" onClick={e => {
                        e.preventDefault()
                        this.change(u, i)
                    }}></i></span></li>))}
                </ul>}
                <input ref={this.input} className="form-control" type="file" name={name} webkitdirectory={folder ? true : false} multiple={multiple || false} onChange={this.change} />
            </div>)
        } else if (mode === 'drag') {
            return <div>
                {this.state.files?.length > 0 && this.state.files.map((u, i) => (<div key={i} className="selectedFile">
                    {u.name} - {window.fileSize(u.size)}
                </div>))}
                <div className={"b1 " + (this.state.enter ? 'dragOver' : '')} id="drop_zone" onDrop={e => this.onDrop(e)} onDragExit={e => this.dragStop(e)} onMouseEnter={e => {
                    this.setState({ enter: true })
                }} onMouseLeave={e => {
                    this.setState({ enter: false })
                }} onDragOver={e => this.dragOver(e)} onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.input.current.click()
                }}>
                    <span style={{ cursor: 'pointer' }}>{(this.state?.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) ? `Selected ${this.state.acceptedFiles.length + this.state.acceptedFolders.length} Files` : `Drag Files Here or Click to Select Files`}</span>
                    <i className="fas fa-file-upload"></i>
                </div>
                <div className="b1">
                    {/* {<button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.input.current.click()
                    }}><i className="fas fa-file"></i> &nbsp;Select Files</button>} */}
                    {folder && <button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.input.current.click()
                    }}><i className="fas fa-folder"></i> &nbsp;Select Folders</button>}</div>
                <input ref={this.input} name={name} className="hidden" type="file" webkitdirectory={folder ? true : false} multiple={multiple || false} onChange={this.change} ></input>
            </div>
        } else if (mode === 'yesno') {
            return (<div>
                <YesNo value={this.state.ye} change={e => this.setState({ ye: e }, this.change)} />
                {this.state.ye ? <input className="form-control" ref={this.input} type="file" name={name} webkitdirectory={folder ? true : false} multiple={multiple || false} onChange={this.change} /> : <input name={name} className="hidden" type="checkbox" onChange={e => {}} checked={this.state.ye}/>}
            </div>)
        } else {
            return (<div>
                <input ref={this.input} className="form-control" type="file" name={name} webkitdirectory={folder ? true : false} multiple={multiple || false} onChange={this.change} />
            </div>)
        }
    }
}
export default FileInput
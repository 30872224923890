import React, { Component } from 'react'
import Table from '../../helpers/display/table'
import 'antd/dist/antd.css'
import "../../helpers/display/antdstyle.css"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import TitleView from '../../views/titleview'
import Filters from '../../helpers/filters'
class Titles extends Component {
    state = {
        showFilters: false,
        currentFilter: []
    }
    restore = (_id) => {
        window.app.restoreTitle(_id).then(() => {
            this.setState({ view: '' })
        }).catch(e => window.flash(e))
    }
    render() {
        window.titles = this
        return (<>
            <Helmet>
                <title>Titles</title>
                <meta name="description" content="OMM Titles" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="la la-film" />
                                </span> Titles</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>

                                <li className="breadcrumb-item active">Titles</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className="pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{this.state.showFilters ? 'Hide' : 'Show'} Filters</span>
                        </div>
                        <div className="col text-right">
                            <ul className="list-inline-item pl-0">
                                <li className="list-inline-item">

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
                    {this.state.showFilters && <Filters list={this.props.titles} change={(list, filter) => this.setState({ currentFilter: filter })} />}
                    <Table data={window.filter(this.props.titles, this.state.currentFilter || [])} key={'_id'} columns={[
                        {
                            title: 'Title',
                            dataIndex: 'title',
                            sorter: (a, b) => window.nameCompare(a.title, b.title),
                            render: (text, record) => <>
                                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar">
                                    <img className="avatar" alt="" src={record.keyArt} />
                                </a>
                                <span style={{ cursor: 'pointer', fontWeight: 800 }} onClick={() => this.setState({ view: record._id })}>{text}</span>
                            </>
                        },
                        {
                            title: "Action",
                            dataIndex: "status",
                            render: (text, record) => (
                                <div className="dropdown dropdown-action">
                                    <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.restore(record._id) }}>Restore Title</a>
                                        <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); window.sure(`Delete ${record.title}`, () => window.app.permDeleteTitle(record._id)) }}>Delete Title</a>
                                    </div>
                                </div>
                            ),
                        },
                    ]}></Table>
                </div>
            </div>
            {this.state.view ? <TitleView restore={() => this.restore(this.state.view)} _id={this.state.view} close={() => {
                this.setState({ view: '' })
            }} /> : ''}
        </>)
    }
}
export default Titles

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Action from '../views/action'
import ActionForm from '../forms/actionform'
import CountDown from '../helpers/timer'
import Table from '../helpers/display/table'
import Calendar from '../helpers/display/calendar'
import TabLink from '../helpers/tablink'
import Sorter from '../helpers/sorter'
import Filters from '../helpers/filters'
class Actions extends Component {
    height = () => `${Math.floor(window.innerHeight * .75)}px`
    handleHeight = () => this.setState({ height: this.height() })
    state = { date: null, edit: false, currentList: [], showFilters: false, mode: 'calendar', view: '', fields: [], filters: [], sortMode: [{ name: sessionStorage.getItem('actionSort') || 'date', type: sessionStorage.getItem('actionSortType') || 'date', direction: sessionStorage.getItem('actionSortDir') || false }], sort: sessionStorage.getItem('actionSort') || 'date', height: this.height(), mode: sessionStorage.getItem('actionmode') || 'calendar' }
    componentDidMount() {
        let mode = sessionStorage.getItem('actionmode')
        if (mode && mode !== 'undefined') this.setState({ mode })
        window.addEventListener('resize', this.handleHeight)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleHeight)
    }
    changeActionDate = (_id, date) => {
        window.app.addReminder({ fields: { _id, date } })
    }
    drop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.remove('kanbanOver')
        let data = this.props.actions.find(a => a._id === e.dataTransfer.getData('text'))
        if (!data) return window.flash('Bad Drop')
        let now = new Date(data.date)
        let date = e.currentTarget.getAttribute('data-status')
        if (!date) return window.flash('Bad drop')
        if (date === now.getDate()) return
        this.changeActionDate(data._id, new Date(`${now.getMonth() + 1}-${date}-${now.getFullYear()}`))
    }
    exit = e => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.remove('kanbanOver')
    }
    over = e => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget?.classList.add('kanbanOver')
    }
    render() {
        window.actions = this
        return (<>
            <Helmet>
                <title>Actions</title>
                <meta name="description" content="Actions" />
            </Helmet>
            <div className="content container-fluid rel">
                <div className="page-header pt-3 mb-0 nobot">
                    <div className="crms-title row bg-white mb-4">
                        <div className="col  p-0">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                    <i className="feather-check-circle" />
                                </span> Actions</h3>
                        </div>
                        <div className="col p-0 text-right">
                            <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Actions</li>
                            </ul>
                        </div>
                    </div>
                    <div className="b2 wrap jsb" style={{ overflow: 'visible' }}>
                        <div className="col" style={{ maxWidth: '25%' }}>
                            <div className="dropdown">
                                <a className="dropdown-toggle recently-viewed" href="#" role="button" data-toggle="dropdown" aria-expanded="false"> {window.capitalize(this.state.mode)}</a>
                                <div className="dropdown-menu" >
                                    <div onClick={e => this.setState({ mode: 'list' }, () => {
                                        sessionStorage.setItem('actionmode', this.state.mode)
                                    })} className="dropdown-item" value="list">List</div>
                                    <div onClick={e => this.setState({ mode: 'calendar' }, () => {
                                        sessionStorage.setItem('actionmode', this.state.mode)
                                    })} className="dropdown-item" value="calendar">Calendar</div>
                                </div>
                            </div>
                            <div className="col" style={{ marginTop: '10px' }}>
                                <span className="pointer filterToggle" onClick={e => this.setState({ showFilters: !this.state.showFilters })} style={{ cursor: 'pointer' }}>{this.state.showFilters ? 'Hide Filters' : 'Show Filters'}</span>
                            </div>
                        </div>
                        <div className="b2 jfe" style={{ maxWidth: '70%', width: 'auto', overflow: 'visible' }}>
                            <Sorter list={this.props.actions} change={(list, currentSort) => this.setState({ currentSort })} />
                            <div className="col text-right">
                                <ul className="list-inline-item pl-0">
                                    <li className="list-inline-item">
                                        <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ edit: true })}>New Reminder</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dealCont" style={{ gridTemplateColumns: this.state.showFilters ? (window.innerWidth > 1080 ? '1fr 5fr' : '1fr 2fr') : '1fr' }}>
                    {this.state.showFilters && <><Filters list={this.props.actions} change={(list, currentFilter )=> this.setState({ currentFilter })} height={this.state.height} statusList={[]} /></>}
                    <div className="b1">
                        {this.state.mode === 'list' ? <>
                            {this.props.actions?.length > 0 ? window.sort(window.filter(this.props.actions, this.state.currentFilter || []), this.state.currentSort || []).map((u, i) => <div key={i} className="reminder">
                                <div className="primaryInfo">
                                    <span><strong>Assigned To:</strong> <TabLink to={`/profile?_id=${u.user}`} content={<>{u.user && window.app.state.users?.find(a => a._id === u.user)?.name || u.user}</>} /></span>
                                    <span><strong>Created:</strong> {u.created && window.formatDate(u.created)}</span>
                                    {u.createdBy && u.createdBy !== u.user && <span><strong>Created By:</strong> <TabLink to={`/profile?_id=${u.createdBy}`} content={<>{window.app.state.users?.find(a => a._id === u.createdBy)?.name || u.createdBy}</>} /></span>}
                                    {u.date && <span><strong>Follow Up:</strong> {window.formatDate(u.date)}{u.reminderType && ` by ${u.reminderType}`}</span>}
                                    {u.date && <span><strong>Follow Up In:</strong><span style={{ color: 'var(--p)' }}><strong><CountDown since={true} endText={'Today'} expires={u.date} /></strong></span></span>}
                                </div>
                                {u.associate && <div className="associated">{(() => {
                                    switch (u.associateType) {
                                        case 'deal': {
                                            return <Link to={`/deal?_id=${u.associate}`}><div className="squareButton">{window.app.state.deals?.find(a => a._id === u.associate)?.name || 'Go To Deal'}</div></Link>
                                        }
                                        case 'company': {
                                            return <Link to={`/company?_id=${u.associate}`}><div className="squareButton">{window.app.state.companies?.find(a => a._id === u.associate)?.name || 'Go To Company'}</div></Link>
                                        }
                                        case 'title': {
                                            return <Link to={`/title?_id=${u.associate}`}><div className="squareButton">{window.app.state.titles?.find(a => a._id === u.associate)?.title || 'Go To Title'}</div></Link>
                                        }
                                        case 'user': {
                                            return <Link to={`/profile?_id=${u.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === u.associate)?.name || 'Go To Profile'}</div></Link>
                                        }
                                        default: {
                                            return <Link to={`/profile?_id=${u.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === u.associate)?.name || 'Go To Profile'}</div></Link>
                                        }
                                    }
                                })()}</div>}
                                <div className="reminderContent" style={{ cursor: 'pointer' }} onClick={e => {
                                    this.setState({ view: u._id })
                                }}>
                                    <span>{u.content}</span>
                                </div>
                                <button style={{ margin: '5px', padding: '3px', marginRight: '10px' }} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    window.app.deleteReminder(u._id)
                                }}>Delete</button>
                            </div>) : <h3>No Actions Yet</h3>}
                        </> : <>
                            <Calendar onDrop={e => this.drop(e)}
                                onDragExit={e => this.exit(e)}
                                onDragLeave={e => this.exit(e)}
                                onDragOver={e => this.over(e)}
                                set={e => this.setState({ date: e })}
                                items={window.sort(window.filter(this.props.actions, this.state.currentFilter || []), this.state.currentSort || [])}
                                template={(u, i) => (<div onDragStart={e => {
                                    e.dataTransfer.setData('text', u._id);
                                    this.drag = true
                                    e.target.classList.add('kanDrag')
                                }} onDragEnd={e => {
                                    this.drag = false
                                    e.target.classList.remove('kanDrag')
                                }} draggable={true} key={i} className="calReminder" id={u._id} onClick={e => {
                                    this.setState({ view: e.currentTarget.id })
                                }}>
                                    <div className="calItemInfo">
                                        <span onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            window.redirect(`/profile?_id=${u.user}`)
                                        }}><strong>Assigned To:</strong> <TabLink to={`/profile?_id=${u.user}`} content={<>{u.user && window.app.state.users?.find(a => a._id === u.user)?.name || u.user}</>} /></span>
                                        <span><strong>Created:</strong> {u.created && window.formatDate(u.created)}</span>
                                        {u.createdBy && u.createdBy !== u.user && <span onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            window.redirect(`/profile?_id=${u.createdBy}`)
                                        }}><strong>Created By:</strong> <TabLink to={`/profile?_id=${u.createdBy}`} content={<>{window.app.state.users?.find(a => a._id === u.createdBy)?.name || u.createdBy}</>} /></span>}
                                        {u.date && <span><strong>Follow Up:</strong> {window.formatDate(u.date)}{u.reminderType && ` by ${u.reminderType}`}</span>}
                                        {u.date && <span><strong>Follow Up In:</strong><span style={{ color: 'var(--p)' }}><strong><CountDown since={true} endText={'Today'} expires={u.date} /></strong></span></span>}
                                    </div>
                                    {u.associate && <div className="associated">{(() => {
                                        switch (u.associateType) {
                                            case 'deal': {
                                                return <Link to={`/deal?_id=${u.associate}`}><div className="squareButton">{window.app.state.deals?.find(a => a._id === u.associate)?.name || 'Go To Deal'}</div></Link>
                                            }
                                            case 'company': {
                                                return <Link to={`/company?_id=${u.associate}`}><div className="squareButton">{window.app.state.companies?.find(a => a._id === u.associate)?.name || 'Go To Company'}</div></Link>
                                            }
                                            case 'title': {
                                                return <Link to={`/title?_id=${u.associate}`}><div className="squareButton">{window.app.state.titles?.find(a => a._id === u.associate)?.title || 'Go To Title'}</div></Link>
                                            }
                                            case 'user': {
                                                return <Link to={`/profile?_id=${u.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === u.associate)?.name || 'Go To Profile'}</div></Link>
                                            }
                                            default: {
                                                return <Link to={`/profile?_id=${u.associate}`}><div className="squareButton">{window.app.state.users?.find(a => a._id === u.associate)?.name || 'Go To Profile'}</div></Link>
                                            }
                                        }
                                    })()}</div>}
                                    <div className={"calItemContent"}>
                                        <span>{u.content}</span>
                                    </div>
                                    <button style={{ position: 'absolute', top: '3px', right: '3px', fontSize: '.7rem', margin: 0, padding: '2px' }} onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        window.app.deleteReminder(u._id)
                                    }}>X</button>
                                </div>)} />
                        </>}
                    </div>
                </div>
            </div>
            {this.state.edit ? <ActionForm _id={this.state.view} date={this.state.date} close={() => this.setState({ edit: false })} add={this.props.add} /> : this.state.view ? (<Action _id={this.state.view} add={this.props.add} remove={e => {
                window.app.deleteReminder(this.state.view)
            }} edit={() => this.setState({ edit: true })} close={() => this.setState({ view: '' })} />) : ''}
        </>)
    }
}
export default Actions
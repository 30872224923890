import React, { Component } from 'react'
import DateInput from './dateinput'
import Toggle2 from './toggle2'
class NotesInput extends Component {
    constructor(props) {
        super(props)
        this.date = React.createRef()
        this.reminder = React.createRef()
    }
    state = {
        notes: [],
        note: ''
    }
    componentDidMount() {
        if (this.props.notes?.length > 0) this.getNotes(this.props.notes)
    }
    componentDidUpdate() {
        if (this.props.notes?.length > 0 && !this.gotNotes) this.getNotes(this.props.notes)
    }
    getNotes = (notes) => {
        if (this.gettingNotes) return
        this.gettingNotes = true
        window.app.request('/notes', 'post', { notes }).then(r => {
            this.gettingNotes = false
            this.gotNotes = true
            this.setState({ notes: r.notes })
        }).catch(e => {
            this.gettingNotes = false
            this.gotNotes = true
            window.flash(e)
        })
    }
    addNote = () => {
        let followUp = this.state.followUp
        let reminder = this.state.reminder
        let { note } = this.state
        if (!note) return window.flash('Please enter a note')
        let data = { note: this.state.note, reminder, followUp, associate: this.props._id, associateType: this.props.associateType || 'user' }
        window.app.request('/note', 'post', data).then(r => {
            this.setState({ notes: [...this.state.notes, r.note], note: '', followUp: null, reminder: false, setFollowup: false }, () => {
                if (typeof this.props.change === 'function') this.props.change(r.note)
            })
        }).catch(e => {
            window.flash(`Issue creating note: ${e}`)
        })
    }
    removeNote = (_id) => {
        window.app.request('/delete-note', 'post', { _id }).then(r => {
            this.setState({ notes: [...this.state.notes].filter(u => u._id !== _id) }, () => {
                if (typeof this.props.remove === 'function') this.props.remove(_id)
            })
        }).catch(e => window.flash(e))
    }
    render() {
        return (<div>
            <input name={this.props.name} type="hidden" value={this.state.notes.map((u) => u._id).join(',')} onChange={e => { }} />
            {this.state.notes?.length > 0 && <ul>
                {this.state.notes.map((u, i) => (<li className="b1 jfs" style={{alignItems: 'flex-start', marginBottom: '10px'}} key={i} id={u._id}>
                    <span>{u.note}</span>
                    <span>{window.app.state.users?.find(a => a._id === u.user)?.name || 'Unknown'} - <strong>{window.formatDate(u.date)} @ {window.formatTime(u.date)}</strong><span style={{ margin: '5px', cursor: 'pointer' }} id={u._id} onClick={e => {
                        this.removeNote(e.target.id)
                    }}>Remove Note</span></span>
                    <br></br>
                </li>))}
            </ul>}
            <textarea onKeyUp={e => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    e.preventDefault()
                    e.stopPropagation()
                    if (this.state.note) this.addNote()
                }
            }} autoComplete="none" className="form-control" placeholder={this.props.placeholder || 'New Note'} value={this.state.note} onChange={e => this.setState({ note: e.target.value })}></textarea>
            {this.props.followUp && <>
                <div className="checkbox">
                    <label>
                        <Toggle2 tog={this.state.setFollowup} change={e => this.setState({ setFollowup: !this.state.setFollowup })} /> Follow Up
                    </label>
                </div>
                {this.state.setFollowup && <>
                <label className="col-form-label">Date of Follow Up</label>
                <div className="row">
                    <div className="col-md-8"><DateInput value={this.state.followUp} change={e => this.setState({ followUp: e })}></DateInput></div>
                    <div className="col-md-4">
                        <div className="checkbox">
                            <label>
                                <input checked={this.state.reminder} onChange={e => this.setState({ reminder: !this.state.reminder })} type="checkbox" /> Reminder
                            </label>
                        </div>
                    </div>
                </div>
                
                </>}
            </>}
            <button className="btn btn-primary" style={{ margin: '5px 0', padding: '5px' }} onClick={(e) => { e.preventDefault(); this.addNote() }}>Add Note To List</button>
        </div>)
    }
}
export default NotesInput
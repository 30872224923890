import React, { Component } from 'react'
class SensitiveInput extends Component {
    state={ show: false}
    render() {
        let { name, placeholder, value, change } = this.props
        return (<div className="rel">
            <input autoComplete="new-password" className="form-control" value={value} onChange={e => {
                change(e.target.value)
            }} type={this.state.show ? 'text' : "password"} name={name} placeholder={placeholder || ''}></input>
            <i style={{ position: 'absolute', right: '8px', top: '33%', color: 'var(--p)' }} className="feather-eye" onMouseEnter={() => this.setState({show: true})} onMouseLeave={() => this.setState({show: false})}></i>
        </div>)
    }
}
export default SensitiveInput
class Dot extends HTMLElement {
    constructor() {
        super()
        this.interval = 0
    }
    render() {
        this.innerHTML = `${(() => {
            let s = ''
            for (let i = 0; i <= this.interval; i++) s += '.'
            return s
        })()}`
    }
    connectedCallback() {
        this.render()
        setInterval(() => {
            this.interval++
            if (this.interval >= 3) this.interval = 0
            this.render()
        }, 1000)
    }
}
customElements.define('dot-dot', Dot)
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import ArrayDisplay from '../helpers/display/arraydisplay'
import ProductForm from '../forms/productform'
import NotesDisplay from '../helpers/display/notesdisplay'
import Error from './404'
import LoadingData from '../helpers/display/loadingdata'
import TR from '../helpers/display/table-row'
class Product extends Component {
    state = {
        edit: false,
        changeImage: false,
        view: 'details',
        notes: [],
        noteData: []
    }
    componentDidMount() {
        this.setState({ _id: this.props._id }, this.init)
    }
    componentDidUpdate() {
        if (this.props._id !== this.state._id) this.setState({ _id: this.props._id }, this.init)
    }
    init = () => {
        let { _id } = this.state
        if (!_id) return
        this.setState({ loaded: true })
        let t = window.app.state.products?.find(a => a._id === _id)
        if (!t) return this.setState({ notFound: true })
        let m = Object.keys(this.state || {}).reduce((a, v) => ({ ...a, [v]: undefined }), {})
        let j = { ...m, ...t, edit: this.state.edit, _id: this.props._id, view: this.state.view, notFound: false }
        this.setState(j)
    }
    render() {
        if (this.state.notFound) return <Error />
        if (!this.state.loaded) return <LoadingData />
        window.product = this
        let productInfo = () => (<>
            <Accordian open={true} header="Product Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Product Type', this.state.productType]} />
                        <TR content={['Price', `$${window.currency(this.state.price)}`]} />
                        <TR content={['Quantity', this.state.quantity]} />
                        <TR content={['On Sale', window.bool(this.state.onSale)]} />
                        {this.state.onSale && <>
                            <TR content={['Sale Price', `$${window.currency(this.state.salePrice)}`]} />
                            <TR content={['Sale Starts', window.formatDate(this.state.saleStart)]} />
                            <TR content={['Sale Ends', window.formatDate(this.state.saleEnd)]} />
                        </>}
                        <TR content={['Shipping Rate', `$${window.currency(this.state.shippingRate)}`]} />
                        <TR content={['Blacklisted Countries', <ArrayDisplay items={this.state.blacklistedCountries} />]} />
                    </tbody>
                </table>
            </>} />
        </>)
        let serviceInfo = () => (<>
            <Accordian open={true} header="Service Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Service Type', this.state.serviceType]} />
                        {this.state.serviceType === 'subscription' ? <>
                            <TR content={['Frequency', this.state.frequency]} />
                            <TR content={['Amount', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Trial Period', this.state.trialPeriod]} />
                        </> : this.state.serviceType === 'hourly' ? <>
                            <TR content={['Hourly Rate', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Number of Hours', this.state.quantity]} />
                        </> : this.state.serviceType === 'flat fee' ? <>
                            <TR content={['Fee Amount', `$${window.currency(this.state.price)}`]} />
                            <TR content={['Installments', this.state.quantity]} />
                        </> : ''}
                    </tbody>
                </table>
            </>} />
        </>)
        let offeringInfo = () => (<>
            <Accordian open={true} header="Offering Information" content={<>
                <table className="table">
                    <tbody>
                        <TR content={['Offering Type', this.state.offeringType]} />
                    </tbody>
                </table>
            </>} />
        </>)
        return (<>
            {this.state.edit && <ProductForm _id={this.state._id} crm={this.state.crm || window.app.state.crm} add={form => {
                return new Promise((res, rej) => {
                    if (!form) return rej('Missing form')
                    window.app.productForm(form).then(deal => {
                        this.init()
                        res(deal)
                    }).catch(e => rej(e))
                })
            }} close={() => this.setState({ edit: false })} />}
            <Helmet>
                <title>{this.state.name || 'Product'}</title>
                <meta name="description" content="An Octane Multimedia film" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> {this.state.name || 'Product'}</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/products">Products</Link></li>
                        <li className="breadcrumb-item active">{this.state.name || 'Product'}</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">
                                <span>Name</span>
                                <p>{this.state.name} <a onClick={() => this.setState({ edit: true })}><i className="fa fa-pencil"></i></a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-md-12">
                                <ul className="cd-breadcrumb triangle nav nav-tabs w-100 crms-steps" role="tablist">
                                    {window.productStatuses?.map((u, i) => (<li key={i} data-status={u} onClick={e => {
                                        let status = e.target.getAttribute('data-status')
                                        if (!status) return window.flash('Status issue')
                                        window.app.addProduct({ fields: { _id: this.state._id, status } })
                                        this.setState({
                                            status, closed: (() => {
                                                if (window.closedStates.includes(status)) {
                                                    if (window.closedStates.includes(this.state.status)) return this.state.closed
                                                    return new Date()
                                                }
                                                return undefined
                                            })()
                                        })
                                    }}>
                                        <a onClick={window.parentClick} href="#" className={this.state.status === u ? 'active' : ''}>
                                            <span onClick={window.parentClick} className="octicon octicon-light-bulb"></span>{window.capitalize(u)}
                                        </a>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="task-infos">
                            <div className="tab-content">
                                <div className="tab-pane show active" id="task-details">
                                    <Accordian open={true} header="General Information" content={<>
                                        <table className="table">
                                            <tbody>
                                                <TR content={['Name', this.state.name]} />
                                                <TR content={['Type', this.state.type]} />
                                                <TR content={['Created', window.formatDate(this.state.created)]} />
                                                {this.state.updated && <TR content={['Updated', window.formatDate(this.state.updated)]} />}
                                                <TR content={['Description', this.state.description]} />
                                                <TR content={['Available', window.bool(this.state.available)]} />
                                                {!this.state.available && <TR content={['Available After', window.formatDate(this.state.availableAfter)]} />}
                                                <TR content={['Categories', <ArrayDisplay items={this.state.categories} />]} />
                                                <TR content={['Tags', <ArrayDisplay items={this.state.tags} />]} />
                                                <TR content={['Notes', <NotesDisplay notes={this.state.notes} />]} />
                                            </tbody>
                                        </table>
                                    </>} />
                                    {this.state.type === 'product' ? productInfo() : this.state.type === 'service' ? serviceInfo() : this.state.type === 'offering' ? offeringInfo() : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Product




import React, { Component } from 'react'
import AddField from './add-field'
import SearchInput from './helpers/searchinput'
class Title extends Component {
    state = { mode: 'add', item: {} }
    componentDidMount() {
        if (this.props.title && typeof this.props.title !== 'boolean') this.setState({ mode: 'edit', item: { ...this.props.title } })
    }
    addTitle() {
        let item = { ...this.state.item }
        let k = Object.keys(item)
        for (let i = 0; i < k.length; i++) if (k[i] !== 'id') item[k[i]] = { value: item[k[i]], type: this.props.fields.find(u => u.name === k[i])?.type || typeof item[k[i]] }
        window.app.request('/title', 'post', { title: item }).then(r => {
            window.flash('Saved Title!')
            let { result } = r
            this.setState({ item: window.destructureObject(result) })
            window.app.getTitles()
        })
    }
    date = (date) => {
        let a = new Date(date)
        if (!a || typeof a.getDate !== 'function') return null
        let fix = (o) => o < 10 ? `0${o}` : o
        let month = fix(a.getMonth())
        let ans = `${a.getFullYear()}-${month}-${fix(a.getDate())}`
        return ans
    }
    render() {
        window.title = this
        return (<div className="b1 rel" id="titleform">
            <div className="b2 jfs">
                <h2>{this.state.mode === 'edit' ? 'Editing: ' : 'Adding: '}{this.state.item?.title || 'New Title'}</h2>
                <button className="tablebutton" onClick={() => {
                    this.addTitle()
                }}>Save Title</button>
            </div>
            <div className="c2 jfs">
                {this.props.keys?.length > 0 && this.props.keys.filter(u => ![...window.filterList, 'id'].includes(u)).map((fieldName, i) => (<div className="b1 jfs tcont" key={i}>
                    {this.props.fields && (() => {
                        let field = this.props.fields.find(a => a.name === fieldName)
                        let template = (item, fieldName, type, index, parentField) => {
                            if (fieldName === 'genre') {
                                let val = item
                                return (<div key={index} className="b1">
                                    <div className="b1 jfs">
                                        {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                    </div>
                                    <div className="fieldList" parent={parentField} name={fieldName}>
                                        {val && typeof val === 'object' && <div key={i} className="b1 innerTemplate">{val.map((a, i) => <div key={i}>
                                                <span>{a}</span>
                                                <button data={a} field={'genre'} onClick={e => {
                                                    let t = { ...this.state.item }
                                                    t.genre = t.genre.filter(u => u !== e.target.getAttribute(['data']))
                                                    this.setState({ item: t })
                                                }}>X</button>
                                            </div>)}
                                        </div>}
                                    </div>
                                    <div>
                                        <SearchInput placeholder="Select a Genre" mainKey={'genre'} indexes={['genre']} items={(val ? window.primaryGenres.filter(u => !val.includes(u)) : window.primaryGenres).map(u => ({ genre: u }))} callback={value => {
                                            let t = { ...this.state.item }
                                            if (!t.genre.includes(value)) t['genre'].push(value)
                                            this.setState({ item: t })
                                        }} template={(u, i) => {
                                            return (<div key={i} onClick={(e) => {
                                                let genre = e.target.innerHTML
                                                let callback = value => {
                                                    let t = { ...this.state.item }
                                                    if (!t.genre.includes(value)) t['genre'].push(value)
                                                    this.setState({ item: t })
                                                }
                                                callback(genre)
                                            }}>{u.genre}</div>)
                                        }} />
                                    </div>
                                </div>)
                            } else if (fieldName === '') {

                            } else {
                                switch (type) {
                                    case 'date': {
                                        return (<div key={index} className="b1">
                                            <div className="b1 jfs">
                                                {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                            </div>
                                            <div className="b1">
                                                <input value={this.date(item)} type={'date'} name={fieldName} parent={parentField} onChange={e => {
                                                    let t = { ...this.state.item }
                                                    t[e.target.getAttribute(['name'])] = new Date(new Date(e.target.value).getTime() + 1000 * 60 * 60 * 24)
                                                    this.setState({ item: t })
                                                }}></input>
                                            </div>
                                        </div>)
                                    }
                                    case 'number': {
                                        return (<div key={index} className="b1">
                                            <div className="b1 jfs">
                                                {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                            </div>
                                            <div className="b1">
                                                <div className="b2 wrap">
                                                    <input value={`${item}`} type={'number'} parent={parentField} name={fieldName} onChange={e => {
                                                        let t = { ...this.state.item }
                                                        let x = parseInt(e.target.value)
                                                        t[e.target.getAttribute(['name'])] = (isNaN(x) ? 0 : x)
                                                        this.setState({ item: t })
                                                    }}></input>
                                                    <div className="b1" style={{ width: 'auto' }}>
                                                        <button onClick={e => {
                                                            let t = { ...this.state.item }
                                                            let x = parseInt(e.target.value)
                                                            t[e.target.getAttribute(['name'])] = (isNaN(x) ? 0 : x) + 1
                                                            this.setState({ item: t })
                                                        }} value={`${item}`} type={'number'} parent={parentField} name={fieldName} >+</button>
                                                        <button onClick={e => {
                                                            let t = { ...this.state.item }
                                                            let x = parseInt(e.target.value)
                                                            t[e.target.getAttribute(['name'])] = (isNaN(x) ? 0 : x) - 1
                                                            this.setState({ item: t })
                                                        }} value={`${item}`} type={'number'} name={fieldName} parent={parentField} >-</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                    case 'object': {
                                        return (<div key={index} className="b1">
                                            <div className="b1 jfs">
                                                {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                            </div>
                                            <ul>
                                                {item && Object.keys(item).map((u, i) => {
                                                    //if (typeof item[u] === 'object') return <li name={fieldName} parent={parentField}  key={i}>{template(item[u], u, item[u] instanceof Array ? 'array' : 'object'), null, parentField}</li>
                                                    return (<li key={i} parent={parentField} name={fieldName}>
                                                        {template(item[u], u, typeof item[u], null, parentField)}
                                                        <button onClick={e => {
                                                            let n = e.target.getAttribute(['name'])
                                                            let p = e.target.getAttribute(['parentField'])
                                                            if (p && n) {
                                                                let t = { ...this.state.item }
                                                                let s = t[p]
                                                                let k = Object.keys(s)
                                                                let o = {}
                                                                for (let i = 0; i < k.length; i++) if (k[i] !== n) o[k[i]] = s[k[i]]
                                                                t[p] = o
                                                                this.setState({ item: t })
                                                            }
                                                        }}>Remove Key</button></li>)
                                                })}
                                            </ul>
                                            <input type="text" name="key" placeholder="key"></input><input name="value" type="text" placeholder="value"></input>
                                            <button name={fieldName} parent={parentField} onClick={e => {
                                                let n = e.target.getAttribute(['name'])
                                                let p = e.target.getAttribute(['parentField'])
                                                if (p && n) {
                                                    let t = { ...this.state.item }
                                                    t[p][n] = e.target.value
                                                    this.setState({ item: t })
                                                }
                                            }}>Add Key</button>
                                        </div>)
                                    }
                                    case 'array': {
                                        let val = item
                                        return (<div key={index} className="b1">
                                            <div className="b1 jfs">
                                                {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                            </div>
                                            <div className="fieldList" parent={parentField} name={fieldName}>
                                                {val instanceof Array && val.map((u, i) => {
                                                    let type
                                                    if (typeof u === 'object') {
                                                        let c = false
                                                        for (let z = 0; z < u.length; z++) {
                                                            if (u[z]) {
                                                                if (u[z] instanceof Array) c = true
                                                                type = typeof u[z]
                                                                z = u.length
                                                            }
                                                        }
                                                        if (type) {
                                                            if (c) {
                                                                return <div key={i} className="b1 innerTemplate">{template(u, null, 'array', null, parentField)}</div>
                                                            } else {
                                                                return <div key={i} className="b1 innerTemplate">{template(u, null, type, null, parentField)}</div>
                                                            }
                                                        }
                                                    } else {
                                                        type = typeof u
                                                        return <div key={i} className="b1 innerTemplate">{template(u, null, type, null, parentField)}</div>
                                                    }
                                                })}</div>
                                            <div>
                                                <input type="text"></input>
                                                <button name={fieldName} onClick={e => {
                                                    let n = e.target.getAttribute(['name'])
                                                    let input = e.target.parentElement.querySelector('input')
                                                    let { value } = input
                                                    input.value = ''
                                                    if (n) {
                                                        let t = { ...this.state.item }
                                                        t[n].push(value)
                                                        this.setState({ item: t })
                                                    }
                                                }}>Insert Value</button>
                                            </div>
                                        </div>)
                                    }
                                    default: {
                                        return (<div key={index} className="b1">
                                            <div className="b1 jfs">
                                                {fieldName && <label>{fieldName.toUpperCase().split('_').join(' ')}</label>}
                                            </div>
                                            <div className="b1">
                                                <textarea placeholder="Enter text here" value={item} parent={parentField} name={fieldName} onChange={e => {
                                                    let t = { ...this.state.item }
                                                    t[e.target.getAttribute(['name'])] = e.target.value
                                                    this.setState({ item: t })
                                                }}></textarea>
                                            </div>
                                        </div>)
                                    }
                                }
                            }
                        }
                        if (field) return template(this.state.item[fieldName], fieldName, field.type, null, fieldName)
                        return null
                    })()}
                </div>))}
            </div>
            {this.state.addField && <AddField addField={window.app.addField} close={e => {
                this.setState({ addField: false })
            }}></AddField>}
            {!this.state.addField && <button className="top-right-abs" onClick={() => {
                this.setState({ addField: true })
            }}>Add a Field</button>}
        </div>)
    }
}
export default Title
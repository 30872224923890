import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.min.css'
import DatePicker, {CalendarContainer} from 'react-datepicker'
class DateTime extends Component {
	state = {
		date: new Date()
	}
	componentDidMount() {
		if (this.props.date) this.setState({ date: this.props.date })
	}
	componentDidUpdate(prv) {
		if (this.props.date && !prv.date) this.setState({ date: new Date(this.props.date) })
	}
	change = e => {
		this.setState({ date: e })
	}
	render() {
		let { options } = this.props
		const CalContainer = ({ className, children }) => {
			return (
				<div className="calWrapper">
					<CalendarContainer className={className}>
						<div className="rel">{children}</div>
					</CalendarContainer>
				</div>
			);
		};
		return 				<DatePicker
		selected={this.state.date}
		onChange={(date) => this.change(date)}
		calendarContainer={CalContainer}
		className={'form-control'}
		name={this.props.name}
		showTimeSelect
		timeFormat="HH:mm"
		timeIntervals={15}
		timeCaption="Time"
		dateFormat="MMMM d, yyyy h:mm aa"
		icons={{
			up: "fa fa-angle-up",
			down: "fa fa-angle-down",
			next: 'fa fa-angle-right',
			previous: 'fa fa-angle-left'
		}}
		{...options}
	/>
	}
}
export default DateTime
import './secure-transfers'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ArrayDisplay from './components/helpers/display/arraydisplay'
import App from './app'
import Sure from './components/helpers/sure'
import './index.css'
import './ws'
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './components/helpers/logos';
import './namefilter'
import './states-countries'
import './languages'
import './sort'
import './filter'
import './genres'
import './reduce-form';
window.CRMS = ['film-sales', 'film-distribution', 'acquisitions', 'corporate-sales']
window.adminTypes = ['admin', 'dev', 'manager', 'accountant']
window.internalTypes = ['admin', 'sales', 'tech', 'dev', 'manager', 'accountant']
window.canApproveExpense = (type) => type === 'accountant'
window.isAdmin = type => window.adminTypes.includes(type)
window.internalType = type => {
  return window.internalTypes.includes(type)
}
window.isSuper = type => ['admin', 'dev'].includes(type)
window.fok = (obj, keys = []) => Object.entries(obj).reduce((a, b) => {
  if (!keys.includes(b[0])) a[b[0]] = b[1]
  return a
}, {})
window.createHTML = raw => draftToHtml(typeof raw === 'string' ? JSON.parse(raw) : raw);
window.createDraft = html => {
  let { contentBlocks, entityMap } = htmlToDraft(html);
  return JSON.stringify(convertToRaw(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)).getCurrentContent()))
}
window.reduceVisible = (array) => array.reduce((a, b) => {
  if (b.visible) {
    a.visible.push(b)
  } else {
    a.archived.push(b)
  }
  return a
}, { visible: [], archived: [] })
window.DEV = false
window.API = window.DEV ? 'http://localhost:4232' : 'https://crm.octanemultimedia.com'
window.WSURI = window.DEV ? 'ws://localhost:2801' : 'wss://ws.crm.octanemultimedia.com'
window.testDate = (string) => {
  let a = Date.parse(string)
  let b = new Date(string).valueOf()
  let r = !isNaN(a) && !isNaN(b) && b > 0
  return r
}
let permissions = {
  DataTypes: [
    { name: 'User', types: [], crms: [] },
    { name: 'Company', types: [], crms: [] },
    { name: 'Title', types: [], crms: [] },
    { name: 'Agreement', types: [], crms: [] },
    { name: 'Deal', types: [], crms: [] },
    { name: 'Product', types: [], crms: [] },
    { name: 'Sale', types: [], crms: [] },
    { name: 'Pitch', types: [], crms: [] },
    { name: 'Payment', types: [], crms: [] },
    { name: 'Email', types: [], crms: [] },
    { name: 'EmailTemplate', types: [], crms: [] },
    { name: 'Directory', types: [], crms: [] },
    { name: 'File', types: [], crms: [] },
    { name: 'Note', types: [], crms: [] },
    { name: 'Work Order', types: [], crms: [] },
    { name: 'Hard Drive', types: [], crms: [] },
    { name: 'Policy', types: [], crms: [] },
    { name: 'Reminder', types: [], crms: [] }
  ],
  Actions: ['Create', 'Edit', 'Delete', 'View', 'Any']
}
let permissionExpression = (Data, Type, Action) => {
  try {
    return new RegExp(`\(${Data}${Type && Type !== 'Any' ? `-${Type}` : '*'}:${Action && Action !== 'Any' ? Action : '*'}\)`, 'm')
  } catch (e) {
    throw new Error(`Invalid permission expression: ${Data}${Type && Type !== 'Any' ? `-${Type}` : '*'}:${Action && Action !== 'Any' ? Action : '*'}`)
  }
}
let addPermission = (permissions = '', Data, Type, Action) => `${permissions}
${Data}${Type ? `-${Type}` : ''}:${Action}`
let removePermission = (permissions, Data, Type, Action) => permissions.replace(permissionExpression(Data, Type, Action), '')
let hasPermission = (permissions, Data, Type, Action) => permissionExpression(Data, Type, Action).test(permissions)

window.isLink = string => {
  if (!string) return false
  return /^(http|https):\/\//.test(string)
}
window.compareDates = (a, b) => {
  if ((!a && b) || (!b && a)) return false
  let c = new Date(a)
  let d = new Date(b)
  let comps = [[c.getDate(), d.getDate()], [c.getMonth(), d.getMonth()], [c.getFullYear(), d.getFullYear()]]
  for (let i = 0; i < comps.length; i++) if (comps[i][0] !== comps[i][1]) return false
  return true
}
window.swap = (ar, a, b) => {
  let r = [...ar]
  r[a] = ar[b]
  r[b] = ar[a]
  return r
}
window.sure = (text, callback, no) => {
  ReactDOM.render(<Sure text={text} no={no} callback={callback} />, document.querySelector('#sure-pop'))
}
window.leadSources = ['word of mouth', 'web', 'television', 'employee']
window.exclusivities = ['Exclusive', 'Non-Exclusive', 'Both']
window.dealStatuses = ['hold', 'legal', 'lost', 'presale', 'presented', 'discussing', 'shopping', 'countering', 'accepted', 'crafting agreement', 'awaiting redline', 'signature pending', 'executed', 'invoice sent', 'first payment received', 'delivery started', 'delivery completed', 'final payment owed', 'won']
window.closedStates = ['closed', 'paused', 'hold']
window.dealTerms = ['Fixed Term', 'Until Further Notice']
window.dealRights = {
  'Cinematic Rights': ['Theatrical', 'Non-Theatrical', 'Public Video'],
  'Pay Per View': ['Pay Per View', 'Residential', 'Non-Residential'],
  'Pay TV Rights': ['Terrestrial', 'Cable', 'Satellite', 'Catch-Up'],
  'Free TV Rights': ['Terrestrial', 'Cable', 'Satellite', 'Catch-Up'],
  'Video Rights': ['Rental', 'Sell Thru'],
  'VOD Rights': ['AdVOD', 'FVOD', 'SVOD', 'TVOD', 'Internet Streaming'],
  'EST Rights': ['Single-Use', 'Limited-Use', 'Extended-Use', 'Internet Downloading'],
  'Ancillary': ['Hotel', 'Motel', 'Airline', 'Cruise']
}
window.rights = Object.entries(window.dealRights).reduce((a, b) => {
  for (let i = 0; i < b[1].length; i++) {
    a.push(b[0] + ': ' + b[1][i])
  }
  return a
}, [])
window.adBuys = []
window.placementCosts = []
window.productCategories = ['Physical', 'Digital', 'Obligatory']
window.currencyTypes = ['USD', 'EUR', 'CAD', 'JPY', 'AUD', 'GBP', 'CNY', 'INR', 'RUB', 'KPW', 'MXN']
window.pitchRequirements = []
window.distributorTypes = ['AVOD', 'TVOD', 'SVOD']
window.reservedRights = []
window.feesAndPercentages = []
window.titleClassifications = []
window.adultRatings = ["adult", "ma", "tv-ma", "r", "nc-17", "m", "ao"]
window.nonAdultRatings = ["nonadult", "non-adult", "tv-y", "tv-y7", "tv-y7-fv", "tv-g", "tv-pg", "tv-14", "g", "pg", "pg-13", "rp", "c", "e", "e10+", "t"]
window.filmRatings = [...window.adultRatings, ...window.nonAdultRatings].sort(window.nameCompare)
window.capitalize = (string) => {
  if (typeof string !== 'string') return string
  let a = string.split('')
  if (a && a[0]) a[0] = a[0].toUpperCase()
  return a.join('')
}
window.bool = value => value ? 'Yes' : 'No'
window.isInternal = type => window.internalType(type)
window.insert = (ar, a, b) => {
  let r = []
  let s = ar.splice(a, 1)
  let c = false
  for (let i = 0; i < ar.length + 1; i++) {
    if (!c) {
      if (i === b) {
        r.push(s ? s[0] : null)
        c = true
      } else {
        r.push(ar[i])
      }
    } else {
      r.push(ar[i - 1])
    }
  }
  return r
}
window.reduceToKeys = data => data.reduce((a, b) => {
  let k = Object.keys(b)
  for (let i = 0; i < k.length; i++) if (!a.includes(k[i]) && k[i] !== '_id') a.push(k[i])
  return a
}, [])
window.parseKey = (string) => {
  let a = string?.split('') || []
  let f = []
  let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  let uppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  let delimiter = ['-', '_']
  let preps = ['the', 'a', 'an', 'of', 'in']
  for (let i = 0; i < a.length; i++) {
    if (i === 0 && a[i] === '$') continue
    if (i === 0 && !delimiter.includes(a[i])) {
      f.push(a[i].toUpperCase())
    } else {
      if (delimiter.includes(a[i])) {
        f.push(' ')
      } else if (numbers.includes(a[i]) && !numbers.includes(a[i - 1])) {
        f.push(' ', a[i])
      } else if (uppercase.includes(a[i])) {
        f.push(' ', a[i])
      } else {
        f.push(a[i])
      }
    }
  }
  return f.join('').split(' ').map((u, i) => {
    if (preps.includes(u.toLowerCase()) && i > 0) {
      return u.toLowerCase()
    } else {
      return u
    }
  }).join(' ').trim()
}
window.parseValue = value => {
  let type = value instanceof Array ? 'array' : value instanceof Date ? 'date' : typeof value
  switch (type) {
    case 'number': return window.parseNumber(value)
    case 'array': return <ArrayDisplay items={value} />
    case 'object': return JSON.stringify(value)
    case 'string': return value
    case 'boolean': return window.bool(value)
    case 'date': return window.formatDate(value)
    case 'undefined': return ''
    default: return JSON.stringify(value)
  }
}
window.flash = (message, time = 5000 ) => {
  document.querySelector('.flasher')?.remove()
  let div = document.createElement('div')
  div.innerHTML = message
  div.className = 'flasher'
  document.querySelector('body').prepend(div)
  setTimeout(() => div.remove(), time)
}
window.redirect = link => window.app.props.history.push(link)
window.nameCompare = (a, b) => {
  if (!a && b) return -1
  if (a && !b) return 1
  if (!a && !b) return 0
  if (typeof a !== 'string' && typeof b !== 'string') return 0
  if (typeof a === 'string' && typeof b !== 'string') return 1
  if (typeof a !== 'string' && typeof b === 'string') return -1
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1
  } else if (a.toLowerCase() === b.toLowerCase()) {
    return 0
  } else {
    return 1
  }
}
window.index = (ar, page, s) => {
  let a = []
  if (ar.length <= s) return ar
  if (ar.length > s) {
    if (ar.length - s * page >= 0) {
      for (let i = s * page; i < s * (page + 1); i++) if (ar[i]) a.push(ar[i])
    } else {
      for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++) if (ar[z]) a.push(ar[z])
    }
  }
  return a
}
window.timeSince = date => {
  let t = new Date() - new Date(date)
  return t < 1000 ? `${t}ms ago` : t < 1000 * 60 ? `${Math.floor(t / (1000))} seconds ago` : t < 1000 * 60 * 60 ? `${Math.floor(t / (1000 * 60))} minutes ago` : t < 1000 * 60 * 60 * 24 ? `${Math.floor(t / (1000 * 60 * 60))} hours ago` : t < 1000 * 60 * 60 * 24 * 30 ? `${Math.floor(t / (1000 * 60 * 60 * 24))} days ago` : t < 1000 * 60 * 60 * 24 * 365 ? `${Math.floor(t / (1000 * 60 * 60 * 24 * 30))} months ago` : t < 1000 * 60 * 60 * 24 * 365 * 5 ? `${Math.floor(t / (1000 * 60 * 60 * 24 * 365))} Years ago` : 'Long Ago..'
}
window.notificationImage = type => {
  switch (type) {
    case '': return ''
    default: return ''
  }
}
window.destructureObject = u => {
  if (!u) return u
  let k = Object.keys(u)
  let o = {}
  for (let i = 0; i < k.length; i++) {
    if (typeof u[k[i]] !== 'object') {
      o[k[i]] = u[k[i]]
    } else if (u[k[i]]) {
      let { type, value } = u[k[i]]
      let v
      switch (type) {
        case 'object': {
          v = typeof value === 'object' ? value : typeof value === 'string' ? (() => {
            try {
              return JSON.parse(value)
            } catch (e) {
              return new Object(value)
            }
          })() : {}
          break
        }
        case 'array': {
          v = Array.from(value)
          break
        }
        case 'date': {
          v = new Date(value)
          break
        }
        case 'number': {
          v = window.parseNumber(value)
          break
        }
        case 'string': {
          v = typeof value === 'string' ? value : JSON.stringify(value)
          break
        }
        default: {
          v = value
        }
      }
      o[k[i]] = v
    } else {
      o[k[i]] = undefined
    }
  }
  return o
}
window.sum = array => {
  let a = 0;
  if (!array || array.length < 1) return a
  for (let i = 0; i < array.length; i++) if (typeof array[i] === 'number') a += array[i]
  return a
}
window.currency = num => typeof num !== 'number' ? '0.00' : num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
window.parseNumber = v => {
  let a = parseFloat(v)
  if (!isNaN(a)) return a
  return 0
}
window.territories = [
  'Africa',
  'Australia / NZ',
  'Baltics',
  'Benelux',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Cambodia',
  'Central Asia',
  'China',
  'CIS',
  'Czech Rep',
  'East Europe',
  'Yugoslavia',
  'France',
  'Germany',
  'Greece',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Israel',
  'Italy',
  'Japan',
  'Latin America',
  'Malaysia',
  'Middle East',
  'Mexico',
  'Mongolia',
  'North America',
  'Pakistan',
  'Pan Asia',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Scandinavia',
  'Singapore',
  'South Africa',
  'South Korea',
  'Southeast Asia',
  'Spain',
  'Taiwan',
  'Thailand',
  'Turkey',
  'Ukraine',
  'UAE',
  'UK',
  'USA',
  'Vietnam'
]
window.pitchStatuses = [
  'PLACED',
  'PENDING PLACEMENT',
  'PASSED',
]
window.pitchTypes = [
  'TVOD',
  'AVOD',
  'TV',
  'SVOD',
  'THEATRICAL',
  'PHYSICAL',
  'OTHER',
]
window.workOrderStatuses = [
  'pending',
  'in progress',
  'review/finalizing',
  'completed',
  'on hold',
  'cancelled'
]
window.workOrderTypes = [
  'Distribution Expenses', 'Sales Expenses', 'Delivery & Processing Expenses', 'Marketing Expenses', 'Market Expenses and Pre-Approved Expenses'
]
window.expenseCategories = [
  'Distribution Expenses', 'Sales Expenses', 'Delivery & Processing Expenses', 'Marketing Expenses', 'Market Expenses and Pre-Approved Expenses'
]
window.organizations = [
  'Octane Multimedia',
  'High Octane Pictures',
  'Delivery Minds'
]
window.contentTypes = [
  'Features',
  'TV',
  'Shorts',
  'Music',
  'Sports',
  'Social',
  'other'
]
window.relationshipsTypes = [
  'cold',
  'warm',
  'hot',
  'preferred'
]
window.userStatuses = ['contacted', 'waiting', 'signed', 'shopping', 'not interested', 'appointment set']
window.titleStatuses = ['registered', 'expired']
window.index = (ar, page, s) => {
  let a = []
  if (ar.length <= s) return ar
  if (ar.length > s) {
    if (ar.length - s * page >= 0) {
      for (let i = s * page; i < s * (page + 1); i++) if (ar[i]) a.push(ar[i])
    } else {
      for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++) if (ar[z]) a.push(ar[z])
    }
  }
  return a
}
window.filterList = ['__title', '__model', '_t', '_u', '_v', 'metadata', '_id', 'title']
window.logOut = () => {
  localStorage.clear();
  window.app.init()
  window.location = '/logout-cookie'
}
window.formatDate = (date) => {
  let d = date && typeof date.getTime === 'function' ? date : new Date(date)
  let month = d.getMonth() + 1
  let day = d.getDate()
  return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${d.getFullYear()}`
}
window.formatTime = date => {
  let d = date && typeof date.getTime === 'function' ? date : new Date(date)
  let hours = d.getHours()
  let h = hours >= 12 ? 'PM' : 'AM'
  let m = d.getMinutes()
  return `${hours > 12 ? hours % 12 : hours}:${m < 10 ? `0${m}` : m}${h}`
}
window.formatPhone = string => {
  return string
}
window.parentClick = (e) => {
  e.preventDefault()
  e.stopPropagation()
  e.target.parentElement?.click()
}
window.getCompany = b => b && typeof b === 'string' ? window.app.state.companyList?.find(a => a._id === b)?.name || b : ''
window.getUser = b => b && typeof b === 'string' ? window.app.state.users?.find(a => a._id === b)?.name || b : ''
window.moveDate = date => new Date(new Date(date).getTime() + 1000 * 60 * 60 * 24)
window.shiftDate = date => new Date(new Date(date).getTime() + 1000 * 60 * 60 * 12)
window.slideDate = date => new Date(new Date(date).getTime() - 1000 * 60 * 60 * 24)
window.getListFields = list => {
  if (!list) return []
  if (!list instanceof Array) return [list]
  return Object.entries(list.map(u => Object.entries(u)).reduce((a, b) => {
    for (let i = 0; i < b.length; i++) {
      let key = b[i][0]
      let value = b[i][1]
      let type = typeof value
      type = ['title', '_id', 'metadata', 'id'].includes(key) ? 'string' : type === 'boolean' ? 'boolean' : value instanceof Array ? 'array' : type === 'number' ? 'number' : window.testDate(value) ? 'date' : type === 'object' ? 'object' : 'string'
      if (!a[key]) a[key] = { entries: [], types: {} }
      a[key].entries.push(value)
      if (!a[key].types[type]) {
        a[key].types[type] = 1
      } else {
        a[key].types[type]++
      }
    }
    return a
  }, {})).reduce((a, b) => {
    a.push({
      key: b[0], type: Object.entries(b[1].types).reduce((a, b) => {
        if (a.count < b[1]) return { type: b[0], count: b[1] }
        return a
      }, { type: '', count: 0 }).type
    })
    return a
  }, [])
}
document.querySelector('.sidebar-overlay').addEventListener('click', (e) => {
  e.currentTarget.classList.remove('opened');
  document.querySelector('.main-wrapper')?.classList.remove('slide-nav');
  document.querySelector('html')?.classList.remove('menu-opened');
  document.querySelector('#task_window')?.classList.add('opened');
})
ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
import React, { Component } from 'react'
import FilterField from './filter-field'
class FilterSelect extends Component {
    state = { name: '', type: '', filter: '', value: null, index: null }
    componentDidMount() {
        let { name, type, index } = this.props
        this.setState({ name, type, index })
    }
    componentDidUpdate() {
        let { name, type, index } = this.props
        if (this.state.name !== name || this.state.type !== type || this.state.index !== index) this.setState({ name, type, index })
    }
    change(value) {
        this.setState({ filter: value })
    }
    render() {
        var inners
        let { name, type, index } = this.props
        switch (type) {
            case 'number': {
                inners = [
                    (<option value={'equal'}>Equal To</option>),
                    (<option value={'gt'}>Greater Than</option>),
                    (<option value={'lt'}>Less Than</option>),
                    (<option value={'gte'}>Greater Than/Equal To</option>),
                    (<option value={'lte'}>Less Than/Equal To</option>),
                    (<option value={'exists'}>Exists</option>),
                ]
                break
            }
            case 'string': {
                inners = [
                    (<option value={'exists'}>Exists</option>),
                    (<option value={'equal'}>Equal To</option>),
                    (<option value={'contains'}>Contains</option>),
                    (<option value={'matches'}>Matches</option>),
                    (<option value={'notcontain'}>Does Not Contain</option>),
                    (<option value={'notequal'}>Not Equal To</option>),
                    (<option value={'lengthgt'}>Length Greater Than</option>),
                    (<option value={'lengthlt'}>Length Less Than</option>),
                    (<option value={'lengthgte'}>Length Greater Than/Equal To</option>),
                    (<option value={'lengthlte'}>Length Less Than/Equal To</option>),
                ]
                break
            }
            case 'array': {
                inners = [
                    (<option value={'includes'}>Includes</option>),
                    (<option value={'notincludes'}>Does Not Include</option>),
                    (<option value={'exists'}>Exists</option>),
                ]
                break
            }
            case 'object': {
                inners = [
                    (<option value={'has'}>Has</option>),
                    (<option value={'exists'}>Exists</option>),
                ]
                break
            }
            case 'date': {
                inners = [
                    (<option value={'gt'}>After</option>),
                    (<option value={'lt'}>Before</option>),
                    (<option value={'gte'}>After or On</option>),
                    (<option value={'lte'}>Before or On</option>),
                    (<option value={'between'}>Between</option>),
                    (<option value={'exists'}>Exists</option>),
                ]
                break
            }
            default: break
        }
        return (<div key={index} className="b1 inners" style={{width: 'auto'}}>
            <label>{name.split('_').join(' ').toUpperCase()}</label>
            <select name={name} value={this.state.filter} onChange={e => this.change(e.target.value)} className="b1">
                <option value={''}>Select One</option>
                {inners}
            </select>
            {this.state.filter && <FilterField name={name} value={this.state.value} type={type} mode={typeof this.state.value !== 'object' ? { name: this.state.filter, value: this.state.value } : { ...this.state.value, name: this.state.filter }} remove={name => this.setState({ value: null, filter: null })} change={(name, value, index, key) => {
                this.setState({
                    value: key ? (() => {
                        let a = (typeof this.state.value === 'object') ? { ...this.state.value } : { value: this.state.value }
                        a[key] = value
                        console.log(a)
                        return a
                    })() : value
                })
            }}></FilterField>}
            {(this.state.value || this.state.filter === 'exists') && <div className="b1">
                <button onClick={() => {
                    this.props.change(this.state.name, this.state.filter, this.state.value, this.state.index)
                    this.setState({value: null, filter: ''})
                }}>Add Filter</button>
            </div>}
        </div>)
    }
}
export default FilterSelect
import { Component } from 'react'
import './toggle.css'
class Toggle extends Component {
    state = { tog: this.props.init || false }
    change = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ tog: !this.state.tog }, () => {if (typeof this.props.change === 'function') this.props.change(!this.state.tog)})
    }

    render() {
        return (<>
            <input type="checkbox" name={this.props.name} className="hidden" checked={this.state.tog}></input>
            <div onClick={this.change} className="togCont rel">
                <div className="tog togNeg">

                </div>
                <div className="tog togPos">

                </div>
                <div className="toggler" style={this.state.tog ? { top: 0, left: 0 } : { top: 0, right: 0 }}></div>
            </div></>)
    }
}
export default Toggle
import React, { Component } from 'react'
import ArrayInput from '../helpers/inputs/arrayinput'
import YesNo from '../helpers/inputs/yesno'
import TitleAllocations from '../helpers/inputs/titleallocations'
import NotesInput from '../helpers/inputs/notesinput'
import SelectInput from '../helpers/inputs/select-input'
import DateInput from '../helpers/inputs/dateinput'
import Accordian from '../helpers/display/accordian'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
import FileInput from '../helpers/inputs/fileinput'
import NumberInput from '../helpers/inputs/numberinput'
class WorkForm extends Component {
    state = { titles: [], notes: [], contacts: [], company: '', name: '', products: [], keyPoints: [], territories: [], }
    componentDidMount() {
        if (this.props._id) {
            let profile = window.app.state.workOrders?.find(a => a._id === this.props._id)
            if (profile) this.setState({ ...profile })
        }
    }
    componentDidUpdate() {
        if (this.state._id !== this.props._id) {
            let profile = window.app.state.workOrders?.find(a => a._id === this.props._id)
            if (profile) {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), ...profile })
            } else {
                this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id })
            }
        }
    }
    componentWillUnmount() {
        this.settlePending()
    }
    pending = []
    handlePending = _id => {
        if (this.pending.includes(_id)) {
            this.pending = [...this.pending].filter(u => u !== _id)
        } else {
            this.pending.push(_id)
        }
    }
    settlePending = () => {
        if (this.pending?.length < 1) return
        window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
            console.log(e)
        })
    }
    submit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.state.subbing) return
        this.setState({ subbing: true })
        this.props.add(e.target).then(r => {
            this.pending = []
            this.setState({ ...r, subbing: false })
            window.flash('Work Order Saved')
        }).catch(e => {
            this.setState({ subbing: false })
            window.flash(e)
        })
    }
    render() {
        window.workOrderForm = this
        return <><div className="modal right fade show" onClick={this.props.close} style={{ display: 'block' }} id="add_contact" tabIndex={1} role="dialog" aria-modal="true">
            <div className="modal-dialog" onClick={e => e.stopPropagation()} role="document">
                <button type="button" className="close md-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} Work Order</h4>
                        <div className="b2 close xs-close" style={{ width: '40%' }}>
                            {this.props._id && <button className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
                            <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete={'new-password'} onSubmit={this.submit}>
                                    {<Accordian open={true} header="General Information" content={<div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="col-form-label">Name</label>
                                            <input className="form-control" autoComplete="none" type="text" placeholder="Work Order Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} name="name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Amount</label>
                                            <NumberInput value={this.state.amount} name="amount" change={e => this.setState({ amount: e })} min={0} prepend="$" />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Description</label>
                                            <textarea name="description" value={this.state.description} className="form-control" onChange={e => this.setState({ description: e.target.value })} F />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Company</label>
                                            <SelectInput name="company" value={this.state.company} change={v => this.setState({ company: v })} options={window.app.state.companies?.filter(a => a && (this.props.crm ? a.crm === this.props.crm : true)).map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Contacts</label>
                                            <ArrayInput name="contacts" items={window.app.state.users?.filter(a => !window.isInternal(a.type) && (this.props.crm ? a.crm === this.props.crm : true)) || []} selected={this.state.contacts || []} listTemplate={(u) => (<div>{window.app.state.users?.find(a => a._id === u)?.name || u}</div>)} template={(u, i) => (<option key={i} value={u._id}>{u.name}</option>)} />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label className="col-form-label">Titles</label>
                                            <ArrayInput name="titles" items={window.app.state.titles} selected={this.state.titles} listTemplate={(u) => (<div>{window.app.state.titles?.find(a => a._id === u)?.title || u}</div>)} template={(u, i) => (<option key={i} value={u._id}>{u.title}</option>)} />
                                        </div> */}
                                        <div className="col-md-6">
                                            <label className="col-form-label">Status</label>
                                            <SelectInput name="status" value={this.state.status} change={v => this.setState({ status: v })} options={window.workOrderStatuses?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Type</label>
                                            <SelectInput name="type" value={this.state.type} change={v => this.setState({ type: v })} options={window.workOrderTypes?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))} />
                                        </div>
                                    </div>} />}
                                    {<Accordian open={true} header="Details" content={<div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="col-form-label">Start Date</label>
                                            <DateInput name="startDate" value={this.state.startDate} change={e => this.setState({ startDate: e })} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">End Date</label>
                                            <DateInput name="endDate" value={this.state.endDate} change={e => this.setState({ endDate: e })} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="col-form-label">Notes</label>
                                            <NotesInput name="notes" notes={this.state.notes} change={note => {
                                                this.handlePending(note)
                                                this.setState({
                                                    notes: [...(this.state.notes || []), note]
                                                })
                                            }} remove={_id => {
                                                this.handlePending(_id)
                                                this.setState({ notes: [...this.state.notes].filter(u => u !== _id) }, () => {
                                                    if (this.state._id) window.app.request('/user', 'post', { user: { _id: this.state._id, notes: this.state.notes } }).then(r => {
                                                        window.app.setState({
                                                            users: [...window.app.state.users].map(u => {
                                                                if (u._id === _id) return ({ ...r.user, name: `${r.user.firstName} ${r.user.lastName}` })
                                                                return u
                                                            })
                                                        })
                                                    }).catch(e => window.flash(`ISSUE REMOVING NOTE: ${JSON.stringify(e)}`))
                                                })
                                            }} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label">Assigned To</label>
                                            <SelectInput name="assignedTo" value={this.state.assignedTo} change={v => this.setState({ assignedTo: v })} options={window.app.state.users?.filter(a => a && window.isInternal(a.type) && (this.props.crm ? a.crm === this.props.crm : true)).map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))} />
                                        </div>
                                    </div>} />}
                                    <div className="text-center py-3">
                                        <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-rounded" onClick={this.props.close}>Cancel</button>
                                    </div>
                                    <input type="hidden" name="assignedTo" value={this.state.assignedTo} />
                                    <input type="hidden" name="crm" value={this.props.crm} />
                                    <input type="hidden" name="_id" value={this.state._id} />
                                    <input type="text" className="hidden" autoComplete="on" readOnly={true} />
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > <div className="modal-backdrop fade show"></div></>
    }
}
export default WorkForm
// class Dot extends HTMLElement {
//     constructor() {
//         super()
//         this.interval = 0
//     }
//     render() {
//         this.innerHTML = `${(() => {
//             let s = ''
//             for (let i = 0; i <= this.interval; i++) s += '.'
//             return s
//         })()}`
//     }
//     connectedCallback() {
//         this.render()
//         setInterval(() => {
//             this.interval++
//             if (this.interval >= 3) this.interval = 0
//             this.render()
//         }, 1000)
//     }
// }
// customElements.define('dot-dot', Dot)
import React, { useState, useEffect } from 'react'
function DotDot(props) {
    let [interval, changeInterval] = useState(0)
    useEffect(() => {
        let j = setInterval(() => {
            interval++
            if (interval >= 3) {
                for (let i = interval; i >= 1; i--) {
                    interval--
                }
            } 
            changeInterval(interval)
        }, 1000)
        return () => {
            clearInterval(j)
        }
    }, [])
    let s = ''
    for (let i = 0; i <= interval; i++) s += '.'
    return <span>{s}</span>
}
export default DotDot
import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import Table from "../helpers/display/table";
import 'antd/dist/antd.css'
import "../helpers/display/antdstyle.css"
import { Link } from "react-router-dom";
import User from '../views/user'
import ChangeImage from '../forms/changeimage'
import CForm from '../forms/cform'
import ImportCSV from '../forms/importcsv';
import Filters from '../helpers/filters'
import { BlockMapBuilder } from "draft-js";


class Users extends Component {

  state = {
    currentList: [],
    currentFilters: [],
    selectedItems: [],
    colors: []
  };

  componentDidMount() {
    window.users = this
  }
  clearSelection = (id) => {
    this.setState({ selectedItems: [...this.state.selectedItems].filter(i => i !== id) })
  }
  delete = (id) => {
    window.app.deleteUser(id)
    this.clearSelection(id)
  }

  getBadgeCode = (colorcode) => {
    if (colorcode.toLowerCase() === "hot") {
      return {
        backgroundImage: "linear-gradient(90deg, rgba(255,29,0,1) 0%, rgba(240,67,45,1) 49%, rgba(226,132,120,1) 100%)",
        color: "#fff",
        fontWeight: "bold",
      };
    } else if (colorcode.toLowerCase() === "preferred") {
      return {
        backgroundImage: "linear-gradient(90deg, rgba(0,255,7,1) 0%, rgba(65,247,70,1) 49%, rgba(143,255,157,1) 100%)",
        color: "#000",
        fontWeight: "bold",
      };
    } else if (colorcode.toLowerCase() === "warm") {
      return {
        backgroundImage: "linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(245,239,13,1) 49%, rgba(210,213,57,1) 100%)",
        color: "#000",
        fontWeight: "bold",
      };
    } else if (colorcode.toLowerCase() === "cold") {
      return {
        backgroundImage: "linear-gradient(90deg, rgba(7,83,241,1) 0%, rgba(45,117,230,1) 49%, rgba(2,147,240,1) 100%)",
        color: "#fff",
        fontWeight: "bold",
      };
    }
  };

  // Page Rendering starts here...
  render() {
    let helmet = <Helmet>
      <title>{this.props.type ? window.capitalize(this.props.type + 's') : 'Users'}</title>
      <meta name="description" content="Reactify Blank Page" />
    </Helmet>
    const { add, type, data } = this.props;
    const currentList = window.filter(data, this.state.currentFilter || [])
    const rowSelection = {
      selectedRowKeys: this.state.selectedItems,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedItems: selectedRows.map(u => u._id) })
      },
      getCheckboxProps: (record) => ({
        disabled: false
      }),
    };

    const columns = (() => {
      switch (type) {
        case 'contact': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <><a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a></>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => a.title.length - b.title.length,
          },
          {
            title: "Company",
            dataIndex: "company",
            render: (text, record) => (
              <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
            ),
            sorter: (a, b) => window.nameCompare((() => {
              return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
            })(), (() => {
              return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
            })())
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => a.phone.length - b.phone.length,
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge badge-gradient-success">{text}</span>
            ),
            sorter: (a, b) => a.relationshipType - b.relationshipType,
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'lead' }) }}>Convert to Lead</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'prospect' }) }}>Convert to Prospect</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ view: record._id, edit: true }) }}>Edit Client</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Client</a>}
                </div>
              </div>
            ),
          },
        ];
        case 'client': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <><a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a></>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => a.title.length - b.title.length,
          },
          {
            title: "Company",
            dataIndex: "company",
            render: (text, record) => (
              <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
            ),
            sorter: (a, b) => window.nameCompare((() => {
              return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
            })(), (() => {
              return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
            })())
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => a.phone.length - b.phone.length,
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge badge-gradient-success">{text}</span>
            ),
            sorter: (a, b) => a.relationshipType - b.relationshipType,
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'lead' }) }}>Convert to Lead</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'prospect' }) }}>Convert to Prospect</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ view: record._id, edit: true }) }}>Edit Client</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Client</a>}
                </div>
              </div>
            ),
          },
        ];
        case 'customer': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <><a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a></>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Company",
            dataIndex: "company",
            render: (text, record) => (
              <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
            ),
            sorter: (a, b) => window.nameCompare((() => {
              return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
            })(), (() => {
              return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
            })())
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge badge-gradient-success">{text}</span>
            ),
            sorter: (a, b) => { },
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'lead' }) }}>Convert to Lead</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'prospect' }) }}>Convert to Prospect</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'contact' }) }}>Convert to Contact</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ view: record._id, edit: true }) }}>Edit Customer</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Customer</a>}
                </div>
              </div>
            ),
          },
        ];
        case 'prospect': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a>
              </>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Company",
            dataIndex: "company",
            render: (text, record) => (
              <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
            ),
            sorter: (a, b) => window.nameCompare((() => {
              return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
            })(), (() => {
              return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
            })())
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{record.mobilePhone !== 0 ? record.mobilePhone : record.homePhone}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge badge-gradient-success">{text}</span>
            ),
            sorter: (a, b) => { },
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'contact' }) }}>Convert to Contact</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'lead' }) }}>Convert to Lead</a>}
                  {record.type !== 'admin' && (this.props.crm === 'acquisitions' ? <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'client' }) }}>Convert to Client</a> : <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'customer' }) }}>Convert to Customer</a>)}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ edit: true, view: record._id }) }}>Edit Prospect</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Prospect</a>}
                </div>
              </div>
            ),
          },
        ]
        case 'lead': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a>
              </>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Company",
            dataIndex: "company",
            render: (text, record) => (
              <>{window.app.state.companies?.find(a => a._id === record.company)?.name || record.company}</>
            ),
            sorter: (a, b) => window.nameCompare((() => {
              return window.app.state.companies?.find(u => u._id === a.company)?.name || a.company
            })(), (() => {
              return window.app.state.companies?.find(u => u._id === b.company)?.name || b.company
            })())
          },
          {
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{record.mobilePhone !== 0 ? record.mobilePhone : record.homePhone}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge badge-gradient-success">{text}</span>
            ),
            sorter: (a, b) => { },
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'contact' }) }}>Convert to Contact</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'prospect' }) }}>Convert to Prospect</a>}
                  {record.type !== 'admin' && (this.props.crm === 'acquisitions' ? <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'client' }) }}>Convert to Client</a> : <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'customer' }) }}>Convert to Customer</a>)}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ edit: true, view: record._id }) }}>Edit Lead</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Lead</a>}
                </div>
              </div>
            ),
          },
        ]
        case 'vendor': return [
          {
            title: "Full Name",
            dataIndex: "name",
            render: (text, record) => (
              <>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#">{record.firstName + ' ' + record.lastName}</a>
              </>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            // This targets the Vendors phone details on the FE
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{record.workPhone !== 0 ? record.workPhone : record.mobilePhone}</>
            ),
            sorter: (a, b) => { },
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            title: "Relationship",
            dataIndex: "relationshipType",
            render: (text, record) => (
              <span className="badge" style={this.getBadgeCode(text)}>
                  {text}
                </span>
            ),
            sorter: (a, b) => { },
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { this.setState({ edit: true, view: record._id }) }}>Edit Vendor</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete Vendor</a>}
                </div>
              </div>
            ),
          },
        ]
        default: return [
          {
            title: `${this.props.type ? window.capitalize(this.props.type) : ''} Name`,
            dataIndex: "name",
            render: (text, record) => (
              <>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} className="avatar"><img className="avatar" alt="" src={record.profilePic} /></a>
                <a onClick={e => { e.preventDefault(); this.setState({ view: record._id }) }} href="#" >{record.firstName + ' ' + record.lastName}</a>
              </>
            ),
            sorter: (a, b) => window.nameCompare(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
          },
          {
            title: "Email Address",
            dataIndex: "email",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.email, b.email),
          },
          {
            // This targets the Users phone details on the FE
            title: "Phone",
            dataIndex: "phone",
            render: (text, record) => (
              <>{record.mobilePhone !== 0 ? record.mobilePhone : record.workPhone}</>
            ),
            sorter: (a, b) => a.phone < b.phone ? -1 : a.phone === b.phone ? 0 : 1,
          },
          {
            title: "Type",
            dataIndex: "type",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.type, b.type),
          },
          {
            title: "CRM",
            dataIndex: "crm",
            render: (text, record) => (
              <>{text}</>
            ),
            sorter: (a, b) => window.nameCompare(a.crm, b.crm),
          },
          this.state.selectedItems?.length > 0 ? {} : {
            title: "Action",
            dataIndex: "status",
            render: (text, record) => (
              <div className="dropdown dropdown-action">
                <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.setState({ _id: record._id, changeImage: true }) }}>Change Image</a>
                  {/* {type !== 'contact' && record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'contact' }) }}>Convert to Contact</a>}
                    {type !== 'lead' && record.type !== 'admin' && <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'lead' }) }}>Convert to Lead</a>}
                    {type !== 'client' && type !== 'customer' && record.type !== 'admin' && (this.props.crm === 'acquisitions' ? <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'client' }) }}>Convert to Client</a> : <a className="dropdown-item" onClick={e => { window.app.editUser({ _id: record._id, type: 'customer' }) }}>Convert to Customer</a>)} */}
                  {<a className="dropdown-item" href="#" onClick={e => { this.setState({ edit: true, view: record._id }) }}>Edit {this.props.type ? window.capitalize(this.props.type) : ''}</a>}
                  {record.type !== 'admin' && <a className="dropdown-item" href="#" onClick={e => { window.sure(`Delete ${record.name}`, () => this.delete(record._id)) }}>Delete {this.props.type ? window.capitalize(this.props.type) : ''}</a>}
                </div>
              </div>
            ),
          },
        ]
      }
    })();
    return (
      <>
        {helmet}
        <div className="content container-fluid">
          <div className="page-header pt-3 mb-0 ">
            <div className="crms-title row bg-white">
              <div className="col ">
                <h3 className="page-title m-0">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2">
                    <i className="feather-smartphone" />
                  </span> {type ? window.capitalize(type) : ''} </h3>
              </div>
              <div className="col text-right">
                <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                  <li className="breadcrumb-item active">{type ? window.capitalize(type + 's') : ''}</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="pointer" onClick={() => this.setState({ showFilters: !this.state.showFilters })}>{!this.state.showFilters ? 'Show' : 'Hide'} Filters</span>
              </div>
              <div className="col text-right">
                <ul className="list-inline-item pl-0">
                  <li className="list-inline-item">
                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ edit: true })}>New {type?.toUpperCase()}</button>
                    <button className="add btn btn-gradient-primary font-weight-bold text-white todo-list-add-btn btn-rounded" id="add-task" onClick={() => this.setState({ import: true })}>Import CSV</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c1" style={{ gridTemplateColumns: this.state.showFilters ? '1fr 5fr' : '1fr' }}>
            {this.state.showFilters && <Filters height={'70vh'} list={data} change={(list, filters) => { this.setState({ currentFilters: filters }) }} />}
            <div className="b1">
              {this.state.selectedItems?.length > 0 && <div className="b2">
                <button onClick={e => {
                  window.sure(`Delete ${this.state.selectedItems.length} Items`, () => {
                    let items = [...this.state.selectedItems]
                    for (let i = 0; i < items.length; i++) {
                      this.delete(items[i])
                    }
                  })
                }}>Delete Items</button>
              </div>}
              <Table
                style={{ overflowX: "auto" }}
                columns={columns}
                rowSelection={rowSelection}
                data={currentList.sort((a, b) => window.nameCompare((a.name || ((a.firstName && a.lastName) ? `${a.firstName} ${a.lastName}` : (a.firstName || a.lastName) ? (a.firstName || a.lastName) : '')), (b.name || ((b.firstName && b.lastName) ? `${b.firstName} ${b.lastName}` : (b.firstName || b.lastName) ? (b.firstName || b.lastName) : ''))))}
                mainKey={'_id'}
              />
            </div>
          </div>
        </div>
        {this.state.import && <ImportCSV templateName={this.props.template} template={['firstName', 'lastName', 'email', 'phone', 'website', 'linkedIn', 'facebook', 'twitter', 'instagram', 'tiktok', 'youtube', 'mobilePhone', 'phone', 'homePhone', 'workPhone', 'fax', 'industry', 'source', 'role', 'title']} dataType={type} import={data => {
          window.app.handleUser({ fields: { ...data, crm: this.props.crm, type: type } })
        }} close={() => this.setState({ import: false })} />}
        {this.state._id && this.state.changeImage && <ChangeImage close={e => this.setState({ changeImage: false, _id: '' })} _id={this.state._id} />}
        {this.state.edit ? <CForm _id={this.state.view} type={type} crm={this.props.crm} add={add} close={() => this.setState({ edit: false })} /> : this.state.view ? <User crm={this.props.crm} type={type} _id={this.state.view} edit={() => this.setState({ edit: true })} close={() => this.setState({ view: '' })}></User> : ''}
      </>
    );
  }
}

export default Users;

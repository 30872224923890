import React, { Component } from 'react'
class TabLink extends Component {
    render() {
        return (<a draggable={'false'} onClick={typeof this.props.onClick === 'function' ? this.props.onClick : e => {
            e.preventDefault()
            e.stopPropagation()
            let child = window.open(e.currentTarget.href, `${new Date().getTime()}`)
            child.focus()
        }} target="_blank" rel="noreferrer" style={{ width: 'auto' }} href={this.props.to}>{this.props.text || this.props.content || this.props.children || 'CLICK HERE'}</a>)
    }
}
export default TabLink

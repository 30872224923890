import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.min.css'
import DatePicker, { CalendarContainer } from 'react-datepicker'
class DateInput extends Component {
	render() {
		let { options, value, change } = this.props
		const CalContainer = ({ className, children }) => {
			return (
				<div className="calWrapper">
					<CalendarContainer className={className}>
						<div className="rel">{children}</div>
					</CalendarContainer>
				</div>
			);
		};
		return <><DatePicker
			selected={value}
			onChange={change}
			calendarContainer={CalContainer}
			className={'form-control'}
			name={this.props.name}
			autoComplete={'off'}
			icons={{
				up: "fa fa-angle-up",
				down: "fa fa-angle-down",
				next: 'fa fa-angle-right',
				previous: 'fa fa-angle-left'
			}}
			showYearDropdown
			{...options}
		/></>
	}
}
export default DateInput
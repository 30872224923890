import React, { Component } from 'react'
import NumberInput from './numberinput'
class TitleAllocations extends Component {
    state = { newline: '', select: '', allocation: 0 }
    componentDidMount() {
        if (this.props.selected?.length > 0) this.priorWatch = true
        this.setState({ selected: this.props.selected || [], delimiter: this.props.type === 'text' ? window.textdelim : ',' })
    }
    componentDidUpdate() {
        if (this.props.selected?.length > 0 && this.state.selected?.length < 1 && !this.priorWatch) { this.priorWatch = true; this.setState({ selected: this.props.selected || [], delimiter: this.props.type === 'text' ? window.textdelim : ',' }) }
    }
    render() {
        let { name } = this.props
        let { selected, newline } = this.state
        let change = () => {
            if (!this.state.select) return window.flash('Please select a title')
            if (this.state.allocation < 0) return window.flash('Please enter a positive allocation')
            if (this.props.total) {
                let current = this.state.selected.reduce((a, b) => a + b.allocation, 0)
                if (current + this.state.allocation > this.props.total) return window.flash('The total allocation of titles exceeds the deal total')
            }
            this.setState({ selected: [...this.state.selected, { title: this.state.select, allocation: this.state.allocation, description: this.state.newline }], newline: '', select: '', allocation: 0 })
        }
        return (<>
            <input type="text" value={selected && selected instanceof Array && JSON.stringify(selected) || 'null-input'} onChange={e => { }} className="hidden" name={name} />
            {selected && selected?.length > 0 && <ul>
                {selected?.map((u, i) => (<li key={i} className="arrayInput-item hide-x"><span style={{ display: 'inline-flex' }}>{typeof this.props.listTemplate === 'function' ? this.props.listTemplate(u) : `${(() => {
                    return window.app.state.titles?.find(a => a._id === u.title)?.title || 'ERROR_LOADING_TITLE'
                })()} - $${window.currency(u.allocation)}${u.description ? ` - ${u.description}` : ''}`}<i className="hidden-x la la-xing" onClick={e => {
                    e.preventDefault()
                    this.setState({ selected: [...this.state.selected].filter(a => a.title !== u.title) })
                }}></i></span></li>))}
            </ul>}
            <select className="form-control" value={this.state.select} onChange={e => this.setState({ select: e.target.value })}>
                <option value="">Select a Title</option>
                {window.app.state.titles?.filter(a => a && !(selected && selected.find(b => a._id === b.title))).map((u, i) => u && (<option key={i} value={u._id}>{u.title}</option>))}
            </select>
            <input className="form-control" placeholder="Description" type="text" value={newline} onChange={e => this.setState({ newline: e.target.value })} />
            <NumberInput value={this.state.allocation} min={0} prepend={"Amount: $"} append={<button className="btn btn-primary" style={{ margin: 0, padding: '4px', cursor: 'pointer' }} onClick={e => { e.preventDefault(); change(); }}>Add</button>} change={v => this.setState({ allocation: v })} />
        </>)
    }
}
export default TitleAllocations
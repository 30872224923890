import React, { Component } from 'react'
class ArrayDisplay extends Component {
    render() {
        let { items = [], template, className = '' } = this.props
        if (!items || !items instanceof Array || items.length < 1) return <></>
        return (<ul className={className}>
                {items && items instanceof Array && items.length > 0 && items?.map(typeof template === 'function' ? template : (u, i) => (<li key={i} className="arrayInput-item">{u}</li>))}
            </ul>)
    }
}
export default ArrayDisplay
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.min.css'
import DatePicker, { CalendarContainer } from 'react-datepicker'
class DateInput extends Component {
	state = { date: null }
	componentDidMount() {
		if (this.props.value) { this.setDate = true; this.setState({ date: new Date(this.props.value) }) }
	}
	componentDidUpdate() {
		if (!this.setDate && this.props.value) { this.setDate = true; this.setState({ date: new Date(this.props.value) }) }
	}
	change = e => {
		this.setState({ date: e }, () => {
			if (typeof this.props.change === 'function') this.props.change(e)
		})
	}
	render() {
		let { options } = this.props
		const CalContainer = ({ className, children }) => {
			return (
				<div className="calWrapper">
					<CalendarContainer className={className}>
						<div className="rel">{children}</div>
					</CalendarContainer>
				</div>
			);
		};
		return <DatePicker
			selected={this.state.date}
			onChange={(date) => this.change(date)}
			calendarContainer={CalContainer}
			className={'form-control'}
			name={this.props.name}
			autoComplete={'off'}
			icons={{
				up: "fa fa-angle-up",
				down: "fa fa-angle-down",
				next: 'fa fa-angle-right',
				previous: 'fa fa-angle-left'
			}}
			showYearDropdown
			{...options}
		/>
	}
}
export default DateInput
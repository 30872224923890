function AddField(props) {
    return (<div id="reportFields">
        <div id="reportHolder" className="b1">
            <form id="AFF" onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                let form = e.target
                let name = form.name.value
                let type = form.type.value
                if (typeof props.addField === 'function') props.addField(name, type)
                if (typeof props.close === 'function') props.close()
            }}>
                <div className="b2">
                    <div className="b1">
                        <label>Field Name</label>
                        <input type="text" name="name"></input>
                    </div>
                    <div className="b1">
                        <label>Field Name</label>
                        <select name="type">
                            <optgroup label="Single Value">
                                <option value="string">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                            </optgroup>
                            {/* <optgroup label="List">
                                <option value="object">Key/Value</option>
                                <option value="object">True/False</option>
                                <option value="array">Values</option>
                                <option value="array">Objects</option>
                            </optgroup> */}
                        </select>
                    </div>
                </div>
                <div className="b2">
                    <button type="submit">Add Field</button>
                    <button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (typeof props.close === 'function') props.close()
                    }}>Cancel</button>
                </div>
            </form>
        </div>
    </div>)
}
export default AddField
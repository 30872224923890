window.textdelim = '-----!-----'
window.reduceForm = form => {
  let list = []
  let inputs = Array.from(form.querySelectorAll('input')).reduce((a, b) => {
    if (b.type === 'file') { a.files.push({ name: b.name, files: b.files }) } else { a.others.push(b) }
    return a
  }, { files: [], others: [] })
  let { files, others } = inputs
  let selects = Array.from(form.querySelectorAll('select'))
  let texts = Array.from(form.querySelectorAll('textarea'))
  list.push(...others, ...selects, ...texts)
  let fields = list.reduce((a, b) => {
    let { name } = b
    if (name) {
      if (/PI\-/.test(name)) {
        if (!a['paymentInfo']) a['paymentInfo'] = {}
        if (/bankAddress/.test(name)) {
          if (/\-street/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['street'] = b.value
          } else if (/\-postal-code/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['postal-code'] = b.value
          } else if (/\-city/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['city'] = b.value
          } else if (/\-region/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['region'] = b.value
          } else if (/\-state/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['state'] = b.value
          } else if (/\-country/.test(name)) {
            if (!a['paymentInfo']['bankAddress']) a['paymentInfo']['bankAddress'] = {}
            a['paymentInfo']['bankAddress']['country'] = b.value
          }
        } else if (/billingAddress/.test(name)) {
          if (/\-street/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['street'] = b.value
          } else if (/\-postal-code/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['postalCode'] = b.value
          } else if (/\-city/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['city'] = b.value
          } else if (/\-region/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['region'] = b.value
          } else if (/\-state/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['state'] = b.value
          } else if (/\-country/.test(name)) {
            if (!a['paymentInfo']['billingAddress']) a['paymentInfo']['billingAddress'] = {}
            a['paymentInfo']['billingAddress']['country'] = b.value
          }
        } else {
          let aT = b.name.split('PI-')[1]
          if (b.type === 'checkbox') {
            a['paymentInfo'][aT] = b.checked
          } else {
            a['paymentInfo'][aT] = b.value
          }
        }
      } else if (/agreement\-/.test(name)) {
        if (!a['agreement']) a['agreement'] = {}
        let aT = b.name?.split('agreement-')[1]
        if (['terminationDate', 'effectiveDate', 'dateOfSignature', 'renewalDate', 'renewalExpiration', 'distributionTermEndDate', 'salesTermEndDate', 'deliveryDueDate', 'holdbackDate', 'triggerDeadline', 'paymentsBecomeDue'].includes(aT)) {
          if (aT && b.value) a['agreement'][aT] = new Date(b.value)
        } else if ([ 'otherExpenseCaps', 'blacklistedCountries', 'autoRenewNotes', 'notes', 'accountingNotes'].includes(aT)) {
          if (aT && b.value) {
            if (!b.value || b.value === 'null-input') {
              a['agreement'][aT] = []
            } else {
              a['agreement'][aT] = b.value.split(',')
            }
          }
        } else if (['additionalBonuses', 'grossCorridorRights', 'reservedRights', 'reservedTerritories', 'licensedRights', 'grossCorridorPlatforms', 'holdbacks'].includes(aT)) {
          if (aT && b.value) {
            if (!b.value || b.value === 'null-input') {
              a['agreement'][aT] = []
            } else {
              a['agreement'][aT] = b.value.split(window.textdelim)
            }
          }
        } else if (['grossCorridorPercentage', 'triggerAmount', 'incomeReservesPercentage', 'distributionFeePercentage', 'salesFeePercentage', 'pressCostCap', 'posterCostCap', 'trailerCostCap', 'expenseCapDistribution', 'expenseCapMarketing', 'expenseCapSales', 'MGAmount'].includes(aT)) {
          a['agreement'][aT] = window.parseNumber(b.value)
        } else if ([].includes(aT)) {
          try {
            a['agreement'][aT] = JSON.parse(b.value)
          } catch (e) {
            a['agreement'][aT] = []
          }
        } else if (aT) {
          if (b.type === 'checkbox') {
            a['agreement'][aT] = b.checked
          } else {
            a['agreement'][aT] = b.value
          }
        }
      } else if (/credentials\-/.test(name)) {
        let aT = name.split('credentials-').join('')
        try {
          a[aT] = JSON.parse(b.value)
        } catch (e) { }
      } else if (/\-street/.test(name)) {
        let aT = b.name.split('-street')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { street: b.value }
        } else {
          a[aT].street = b.value
        }
      } else if (/\-postal-code/.test(name)) {
        let aT = b.name.split('-postal-code')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { postalCode: b.value }
        } else {
          a[aT].postalCode = b.value
        }
      } else if (/\-city/.test(name)) {
        let aT = b.name.split('-city')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { city: b.value }
        } else {
          a[aT].city = b.value
        }
      } else if (/\-region/.test(name)) {
        let aT = b.name.split('-region')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { region: b.value }
        } else {
          a[aT].region = b.value
        }
      } else if (/\-state/.test(name)) {
        let aT = b.name.split('-state')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { state: b.value }
        } else {
          a[aT].state = b.value
        }
      } else if (/\-country/.test(name)) {
        let aT = b.name.split('-country')[0] + 'Address'
        if (!a[aT]) {
          a[aT] = { country: b.value }
        } else {
          a[aT].country = b.value
        }
      } else if (/dashboardLogin-/.test(name)) {
        let aT = b.name.split('dashboardLogin-')[1]
        if (!a['dashboardLogin']) {
          a['dashboardLogin'] = { [aT]: b.value }
        } else {
          a['dashboardLogin'][aT] = b.value
        }
      } else if ((b.type === 'date') || ['nextPayout', 'lastPayout', 'anniversary', 'dateOfBirth', 'salesLastContact', 'salesNextContact', 'conversionDate', 'contractStartDate', 'contractEndDate', 'onboardingStartDate', 'stage1DueDate', 'stage2DueDate', 'stage3DueDate', 'USReleaseDate', 'dateAdded', 'paymentsBecomeDue', 'triggerDeadline', 'terminationDate', 'effectiveDate', 'dateOfSignature', 'renewalDate', 'renewalExpiration', 'distributionTermEndDate', 'salesTermEndDate', 'deliveryDueDate', 'holdbackDate', 'salesLastContactDate', 'startDate', 'endDate', 'terminationDate', 'effectiveDate', 'dateOfSignature', 'renewalDate', 'renewalExpiration', 'distributionTermEndDate', 'salesTermEndDate', 'deliveryDueDate', 'holdbackDate', 'triggerDeadline', 'paymentsBecomeDue'].includes(name)) {
        a[b.name] = new Date(b.value)
      } else if (['internationalReleaseDates'].includes(name)) {
        try {
          a[b.name] = JSON.parse(b.value)
        } catch (e) {  }
      } else if (['contacts', 'products', 'royaltySplits','filmsPassed', 'filmsReviewing', 'territoriesOfInterest', 'rights',  'producerComparableTitles', 'notes', 'previouslyReleasedTitles', 'territories',  'genresOfInterest', 'genre', 'additionalLanguages', 'chainOfTitleNotes', 'technicalNotes', 'region', 'files', 'titles', 'directories', 'packages', 'reservedTerritories',  'stills','agreementNotes', 'otherGenres', 'technicalNotes', 'autoRenewNotes', 'filmsPassed', 'filmsReviewing',  'otherExpenseCaps', 'autoRenewNotes', 'notes', 'accountingNotes'].includes(name)) {
          if (!b.value || b.value === 'null-input') {
            a[b.name] = []
          } else {
            a[b.name] = b.value.split(',')
          }
      } else if (['reservedRights', 'licensedRights', 'producerCastList', 'holdbacks', 'additionalBonuses', 'grossCorridorRights', 'grossCorridorPlatforms', 'musicReleases','placementCosts', 'credentials', 'rightsOfInterest', 'thingsLike', 'cuepoints', 'keyPoints', 'producers', 'writers', 'cinematography', 'cast', 'crew', 'music', 'keywords', 'ratingReasons', 'reviews', 'festivals', 'festivalLaurels', 'catalogue', 'feesAndPercentages', 'distributorTypes', 'placementCosts', 'comparableTitles', 'stills', 'keywords', 'sellingPoints', 'filmCatalogue', 'reviews', 'festivals', 'producerComparableTitles', 'holdbacks', 'grossCorridorPlatforms', 'grossCorridorRights', 'additionalBonuses',  'comparableTitles', 'otherExpenseCaps',].includes(name)) {
        if (name === 'reservedRights') console.log(b.value)
        if (!b.value || b.value === 'null-input') {
            a[b.name] = []
          } else {
            a[b.name] = b.value.split(window.textdelim)
          }
      } else if ((b.type === 'number') || ['fps', 'probability', 'grossCorridorPercentage', 'triggerAmount', 'incomeReservesPercentage', 'distributionFeePercentage', 'salesFeePercentage', 'pressCostCap', 'posterCostCap', 'trailerCostCap', 'expenseCapDistribution', 'expenseCapMarketing', 'expenseCapSales', 'MGAmount'].includes(b.name)) {
        a[b.name] = window.parseNumber(b.value)
      } else if (['titleAllocations', 'producerPayments'].includes(b.name)) {
        try {
          a[b.name] = JSON.parse(b.value)
        } catch (e) {
          a[b.name] = []
        }
      } else {
        if (b.type === 'checkbox') {
          a[b.name] = b.checked
        } else {
          a[b.name] = b.value
        }
      }
    }
    return a
  }, {})
  return ({ fields, files })
}
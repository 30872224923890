import React, { Component } from 'react'
import Table from '../../helpers/display/table'
import Spinner from '../../helpers/display/spinner'
import DotDot from '../../helpers/display/dotdot'
import Toggle from '../../helpers/inputs/toggle2';
class TitleSuggestion extends Component {
    state = { suggestion: '' }
    render() {
        let { title, suggestions } = this.props.data
        return (<div className="b1 suggestionCont">
            <span>YOU ENTERED: <strong>{title}</strong></span>
            <div>
                <span className="suggestionSpan">Suggestions:</span>
                {suggestions?.data && suggestions.data.sort((a, b) => b.score - a.score).map((u, i) => (<div key={i} className="suggestion" style={{ border: '1px solid var(--p)' }} onClick={e => this.setState({ suggestion: window.app.state.titles?.find(a => a.title === u.text)._id })}>{u.text}</div>))}
            </div>
            <div className="b1">
                <label>Select A Title</label>
                <select className="form-control" value={this.state.suggestion} onChange={e => this.setState({ suggestion: e.target.value })}>
                    <option value="">Choose from all titles</option>
                    {window.app.state.titles?.map((u, i) => (<option key={i} value={u._id} >{u.title}</option>))}
                </select>
            </div>
            <button onClick={e => {
                if (this.state.suggestion) this.props.suggest(this.props.data.title, this.state.suggestion)
            }}>Confirm Selection</button>
        </div>)
    }
}
class BuyerSuggestion extends Component {
    state = { suggestion: '' }
    render() {
        let { company, suggestions } = this.props.data
        return (<div className="b1 suggestionCont">
            <span>YOU ENTERED: <strong>{company}</strong></span>
            <div>
                <span className="suggestionSpan">Suggestions:</span>
                {suggestions?.data && suggestions.data.sort((a, b) => b.score - a.score).map((u, i) => (<div key={i} className="suggestion" style={{ border: '1px solid var(--p)' }} onClick={e => this.setState({ suggestion: window.app.state.companies?.find(a => a.name === u.text)._id })}>{u.text}</div>))}
            </div>
            <div className="b1">
                <label>Select A Buyer</label>
                <select className="form-control" value={this.state.suggestion} onChange={e => this.setState({ suggestion: e.target.value })}>
                    <option value="">Choose from all buyers</option>
                    {window.app.state.companies?.filter(a => a.crm === 'film-sales').map((u, i) => (<option key={i} value={u._id} >{u.name}</option>))}
                </select>
            </div>
            <button onClick={e => {
                if (this.state.suggestion) this.props.suggest(company, this.state.suggestion)
            }}>Confirm Selection</button>
        </div>)
    }
}
class PlatformSuggestion extends Component {
    state = { suggestion: '' }
    render() {
        let { company, suggestions } = this.props.data
        return (<div className="b1 suggestionCont">
            <span>YOU ENTERED: <strong>{company}</strong></span>
            <div>
                <span className="suggestionSpan">Suggestions:</span>
                {suggestions?.data && suggestions.data.sort((a, b) => b.score - a.score).map((u, i) => (<div key={i} className="suggestion" style={{ border: '1px solid var(--p)' }} onClick={e => this.setState({ suggestion: window.app.state.companies?.find(a => a.name === u.text)._id })}>{u.text}</div>))}
            </div>
            <div className="b1">
                <label>Select A Platform</label>
                <select className="form-control" value={this.state.suggestion} onChange={e => this.setState({ suggestion: e.target.value })}>
                    <option value="">Choose from all platforms</option>
                    {window.app.state.companies?.filter(a => a.crm === 'film-distribution').map((u, i) => (<option key={i} value={u._id} >{u.name}</option>))}
                </select>
            </div>
            <button onClick={e => {
                if (this.state.suggestion) this.props.suggest(company, this.state.suggestion)
            }}>Confirm Selection</button>
        </div>)
    }
}
class ImportCSV extends Component {
    constructor(props) {
        super(props)
        this.file = React.createRef()
        this.check = React.createRef()
    }
    state = {
        imported: [],
        suggestions: [],
        requesting: false
    }
    import() {
        let that = new XMLHttpRequest()
        that.onerror = (e) => { this.setState({ requesting: false }); window.flash(e) }
        that.onabort = e => { this.setState({ requesting: false }); window.flash(e) }
        that.onreadystatechange = e => {
            if (that.readyState === 4) {
                this.setState({ requesting: false })
                let data
                try {
                    data = JSON.parse(that.responseText)
                } catch (e) {
                    console.log(that.responseText)
                    return window.flash(e)
                }
                if (!data || data.error) return window.flash(data.message || JSON.stringify(data))
                this.setState({ file: null, imported: data.imported, suggestions: data.validations, companySuggestions: data.companyValidations }, () => {
                    let input = this.file.current
                    if (input) input.files = null
                })
            }
        }
        let fd = new FormData()
        fd.append('csv', this.file.current.files[0])
        fd.append('validate', 'true')
        fd.append('type', this.props.dataType)
        this.setState({ requesting: true })
        that.open('POST', window.API + '/import-csv', true)
        that.setRequestHeader('authorization', window.app.state.auth)
        that.send(fd)
    }
    validate(item) {
        let listKey = this.props.dataType === 'sale' ? 'buyer' : 'platform'
        return item.title && item[listKey] && window.app.state.movies?.find(a => a._id == item.title || a.title == item.title) && window.app.state.companies?.find(a => a._id == item[listKey] || a.name == item[listKey])
    }
    getCSV = fields => {
        window.app.request('/gen-csv', 'post', { fields, name: this.props.name }).then(result => {
            let a = document.createElement('a')
            a.href = result.link
            a.target = '_blank'
            document.querySelector('body').append(a)
            a.click()
            a.remove()
        }).catch(e => window.flash(e))
    }
    saveImport = (force) => {
        let list = this.state.imported
        let invalid = []
        let final = []
        let listKey = this.props.dataType === 'sale' ? 'buyer' : 'platform'
        for (let i = 0; i < list.length; i++) {
            let a = this.validate(list[i])
            if (!a) { invalid.push({ item: list[i], index: i }) } else { final.push({ ...list[i], title: window.app.state.movies?.find(a => a._id == list[i].title || a.title == list[i].title)?._id, [listKey]: window.app.state.companies?.find(a => a._id == list[i][listKey] || a.name == list[i][listKey])?._id }) }
        }
        if (invalid.length > 0 && !force) {
            this.setState({ invalid, forceSend: true })
            return window.flash('SOME ITEMS WERE FOUND TO BE INVALID PLEASE CORRECT THOSE ITEMS AND TRY AGAIN')
        } else {
            for (let i = 0; i < invalid.length; i++) {
                let o = list.splice(invalid[i].index, 1)
            }
        }
        window.flash('Saving Entries')
        window.app.request(`${this.props.dataType === 'sale' ? '/sale' : '/payment'}`, 'post', { list: final, thirdParty: this.state.thirdParty, published: this.state.published }).then((r) => {
            let listKey = this.props.dataType === 'sale' ? 'sales' : 'payments'
            let list = r[listKey]
            if (!list) {
                if (typeof this.props.close === 'function') { this.props.close() } else { console.log('CAN"T CLOSE!!!') }
                return window.flash('NO ENTRIES RETURNED')
            }
            let data = [...window.app.state[listKey]]
            for (let i = 0; i < data.length; i++) {
                for (let z = 0; z < list.length; z++) {
                    if (data[i]._id === list[z]._id) {
                        data[i] = list.splice(z, 1)[0]
                        z = Infinity
                    }
                }
            }
            window.app.setState({ [listKey]: [...data, ...list] }, () => {
                window.flash('Entries Saved')
                if (typeof this.props.close === 'function') { this.props.close() } else { console.log('CAN"T CLOSE!!!') }
            })
        }).catch(e => { window.flash(e); this.setState({ forceSend: false }) })
    }
    validateNumber = v => {
        if (!v) return 0
        if (typeof v !== 'number') return window.parseNumber(v.replace('$', '').replace(',', ''))
        return v
    }
    validateNumbers = u => {
        let o = {}
        let k = Object.keys(u)
        for (let i = 0; i < k.length; i++) {
            if (['agreement_amount', 'received_amount', 'amount', 'income'].includes(k[i])) {
                let j = this.validateNumber(u[k[i]])
                o[k[i]] = this.props.dataType === 'expense' ? j >= 0 ? j * -1 : j : j
            } else {
                o[k[i]] = u[k[i]]
            }
        }
        return o
    }
    render() {
        let keyset = (() => this.state.imported.reduce((a, b) => {
            let k = Object.keys(b)
            for (let i = 0; i < k.length; i++) if (!a.includes(k[i])) a.push(k[i])
            return a
        }, []))()
        let instance = data => {
            let o = {}
            for (let i = 0; i < keyset.length; i++) o[keyset[i]] = data[keyset[i]] || null
            return Object.values(o)
        }
        let spin = () => (<div id="importCSV" className="b1">
            <Spinner></Spinner>
            <span>Validating title/company names<DotDot></DotDot></span>
        </div>)
        return (<>
            <div id="importData" onClick={this.props.close} className="modal fade show" style={{ display: 'block' }}>
                <div id="importChild" onClick={e => e.stopPropagation()} className="modal-dialog">
                    <div className="modal-content" style={{ overflowY: 'auto' }}>
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title">Import {this.props.dataType || 'Data'}</h4>
                            <button type="button" id="exit-pictures" className="close" onClick={this.props.close}>×</button>
                        </div>
                        {/* Modal body */}
                        <div className="modal-body c1" style={{ overflowY: 'visible' }}>
                            {this.state.forceSend ? <>
                                <div className="b1" style={{ paddingTop: '5vh' }}>
                                    <h2>Invalid Items Found</h2>
                                    <div className="b1">
                                        <span>Skip these items and import only validated items?</span>
                                        <div className="b2">
                                            <button onClick={e => {
                                                this.saveImport(true)
                                            }}>Yes</button>
                                            <button onClick={() => this.setState({ previewImport: false, forceSend: false, suggestions: this.state.invalidSuggest, invalidSuggest: null })}>No</button>
                                        </div>
                                    </div>
                                    <Table data={this.state.invalid.map(u => u.item)} columns={keyset.map(u => ({ title: u, dataIndex: u }))} />
                                </div>
                            </> : <>
                                {!this.state.requesting && !this.state.imported?.length > 0 && <div className="c1">
                                    <div className="b1">
                                        <input className="form-control" accept="text/csv" ref={this.file} type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                                    </div>
                                    <div className="b2 jfs">
                                        <Toggle tog={this.state.thirdParty} change={e => this.setState({ thirdParty: e })} /><span>Third Party Data?</span>
                                    </div>
                                    <div className="b2 jfs">
                                        <Toggle tog={this.state.published} change={e => this.setState({ published: e })} /><span>Published?</span>
                                    </div>
                                </div>}
                                <div className="b2 wrap">
                                    {this.props.template && !this.state.file && <button onClick={() => {
                                        this.getCSV(this.props.template)
                                    }}>Download Template</button>}{this.state.file && <button onClick={() => {
                                        this.import()
                                    }}>Import</button>}{this.state.imported?.length > 0 && <button onClick={() => {
                                        this.saveImport()
                                    }}>Save</button>}<button onClick={this.props.close}>Cancel</button>
                                </div>
                                {this.state.requesting ? spin() : <div id="importCSV" className="b1">
                                    {(this.state.suggestions?.length > 0 || this.state.companySuggestions?.length > 0 || this.freshInvalidate) ? (<>
                                        <button onClick={() => this.setState({
                                            suggestions: null, companySuggestions: null, imported: [...this.state.imported].map(u => {
                                                let a = window.app.state.titles?.find(a => u == a._id || a._id == u.title || a.title == window.namefilter(u.title))
                                                if (a) return ({ ...u, title: a._id })
                                                return u
                                            }).map(u => {
                                                let mainKey = this.props.dataType === 'sale' ? 'buyer' : 'platform'
                                                let a = window.app.state.companies?.find(a => u == a._id || a._id == u[mainKey] || a.name == u[mainKey])
                                                if (a) return ({ ...u, [mainKey]: a._id })
                                                return u
                                            }).map(a => this.validateNumbers(a)), invalidCompanies: this.state.companySuggestions, invalidSuggest: this.state.suggestions
                                        })}>Continue</button>
                                        <h3>Some of your items were not validated!</h3>
                                        <span>Please see choose from the suggestions below to validate your data, or continue without changing these items</span>
                                        {this.state.suggestions?.length > 0 && (<div className="b1 rel" style={{}}>
                                            <div className="c2" style={{ width: 'auto', minWidth: '90%', padding: '5%' }}>
                                                {this.state.suggestions.map((u, i) => <div key={i}><TitleSuggestion data={u} suggest={(title, value) => {
                                                    this.setState({
                                                        imported: [...this.state.imported].map(u => {
                                                            if (u.title === title) u.title = value
                                                            return u
                                                        }),
                                                        suggestions: this.state.suggestions.filter(u => u.title != title)
                                                    }, () => {
                                                        if (this.state.suggestions.length < 1) this.setState({
                                                            suggestions: null, imported: [...this.state.imported].map(u => {
                                                                let a = window.app.state.titles?.find(a => u == a._id || a._id == u || a._id == u.title || a.title == window.namefilter(u.title))
                                                                if (a) return ({ ...u, title: a._id })
                                                                return u
                                                            }).map(a => this.validateNumbers(a))
                                                        })
                                                    })
                                                }} /></div>)}
                                            </div>
                                        </div>)}
                                        {this.state.companySuggestions?.length > 0 && (<div className="b1 rel" style={{}}>

                                            <div className="c2" style={{ width: 'auto', minWidth: '90%', padding: '5%' }}>
                                                {this.state.companySuggestions.map((u, i) => <div key={i}>{(this.props.dataType === 'sale' ? <BuyerSuggestion data={u} suggest={(buyer, value) => {
                                                    this.setState({
                                                        imported: [...this.state.imported].map(u => {
                                                            if (u.buyer === buyer) u.buyer = value
                                                            return u
                                                        }),
                                                        companySuggestions: this.state.companySuggestions.filter(u => u.company != buyer)
                                                    }, () => {
                                                        if (this.state.companySuggestions.length < 1) this.setState({
                                                            companySuggestions: null, imported: [...this.state.imported].map(u => {
                                                                let a = window.app.state.companies?.find(a => u == a._id || a._id == u || a._id == u.buyer || a.name == window.namefilter(u.buyer))
                                                                if (a) return ({ ...u, buyer: a._id })
                                                                return u
                                                            }).map(a => this.validateNumbers(a))
                                                        })
                                                    })
                                                }} /> : <PlatformSuggestion data={u} suggest={(platform, value) => {
                                                    this.setState({
                                                        imported: [...this.state.imported].map(u => {
                                                            if (u.platform === platform) u.platform = value
                                                            return u
                                                        }),
                                                        companySuggestions: this.state.companySuggestions.filter(u => u.company != platform)
                                                    }, () => {
                                                        if (this.state.companySuggestions.length < 1) this.setState({
                                                            companySuggestions: null, imported: [...this.state.imported].map(u => {
                                                                let a = window.app.state.companies?.find(a => u == a._id || a._id == u || a._id == u.platform || a.name == window.namefilter(u.platform))
                                                                if (a) return ({ ...u, platform: a._id })
                                                                return u
                                                            }).map(a => this.validateNumbers(a))
                                                        })
                                                    })
                                                }} />)}</div>)}
                                            </div>
                                        </div>)}
                                    </>) : this.state.imported?.length > 0 ? <Table data={this.state.imported} columns={keyset.map(u => ({ title: u, dataIndex: u }))} /> : ''}
                                </div>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div></>)
    }
}
export default ImportCSV
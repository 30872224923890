import React, { Component } from 'react'
class Accordian extends Component {
    state = {
        open: false
    }
    componentDidMount() {
        if (typeof this.props.open === 'boolean') this.setState({ open: this.props.open })
    }
    render() {
        return (<div className={"tasks__item crms-task-item" + (this.state.open ? ' active' : '')}>
            <div className={"accordion-header js-accordion-header"} onClick={(e) => {
                e.preventDefault();
                this.setState({ open: !this.state.open })
            }}>{this.props.header} {typeof this.props.count === 'number' && <span className="badge badge-secondary float-right">{this.props.count}</span>}</div>
            <div className={(!this.state.open ? 'hidden' : '')} style={{backgroundColor: 'var(--l)', color: 'var(--d)'}}>
                <div className="accordion-body__contents">
                    {this.props.content}
                </div>
            </div>
        </div>)
    }
}
export default Accordian
import React, { Component } from 'react'
import DateInput from '../helpers/inputs/dateinput'
import DateInput2 from '../helpers/inputs/dateinput2'
import CountDown from '../helpers/timer'
import ArrayInput from '../helpers/inputs/arrayinput'
import SensitiveInput from '../helpers/inputs/sensitiveinput2'
import NotesInput from '../helpers/inputs/notesinput'
import InternationalDates from '../helpers/inputs/internationalDates'
import Accordian from '../helpers/display/accordian'
import YesNo from '../helpers/inputs/yesno'
import Spinner from '../helpers/display/spinner'
import DotDot from '../helpers/display/dotdot'
import CredentialInput from '../helpers/inputs/title-credentials'
import SelectInput from '../helpers/inputs/select-input'
import NumberInput from '../helpers/inputs/numberinput'
import ProducerPayments from '../helpers/inputs/producer-payment'
class FilmForm extends Component {
  state = { titles: [], festivalLaurels: [], cuepoints: [], festivals: [], reviews: [], additionalLanguages: [], ratingReasons: [], genre: [], region: [], keywords: [], producers: [], writers: [], cast: [], crew: [], reservedRights: [], reservedTerritories: [], sellingPoints: [], internationalReleaseDates: [], musicReleases: [], chainOfTitleNotes: [], technicalNotes: [] }
  componentDidMount() {
    if (this.props._id) {
      let profile = window.app.state.titles?.find(a => a._id === this.props._id)
      if (profile) this.setState({ ...profile }, () => {
        if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
        if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
      })
    } else if (this.props.type) {
      this.setState({ type: this.props.type })
    }
  }
  componentDidUpdate() {
    if (this.state._id !== this.props._id) {
      let profile = window.app.state.titles?.find(a => a._id === this.props._id)
      if (profile) {
        this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), ...profile }, () => {
          if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement)
          if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
        })
      } else {
        this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
      }
    } else if (!this.state._id && this.props.type !== this.state.type) {
      this.setState({ ...Object.keys(this.state).reduce((acc, v) => ({ ...acc, [v]: undefined }), {}), _id: this.props._id, type: this.props.type })
    }
  }
  componentWillUnmount() {
    this.settlePending()
  }
  pending = []
  handlePending = _id => {
    if (this.pending.includes(_id)) {
      this.pending = [...this.pending].filter(u => u !== _id)
    } else {
      this.pending.push(_id)
    }
  }
  settlePending = () => {
    if (this.pending?.length < 1) return
    window.app.request('/remove-pending', 'post', { notes: this.pending }).catch(e => {
      console.log(e)
    })
  }
  getPaymentInfo = _id => {
    if (this.getPI) return
    this.getPI = true
    window.app.getPaymentInfo(_id).then(pi => {
      this.getPI = false
      this.setState({ paymentInfo: pi })
    }).catch(e => {
      this.getPI = false
      window.flash(e)
    })
  }
  getAgreement = (_id, fresh) => {
    if (this.gainfo) return
    this.gainfo = true
    let agreement = window.app.state.agreements?.find(u => u._id === _id)
    if (agreement && !fresh) {
      this.gainfo = false
      this.setState({ agreement: agreement })
    } else {
      window.app.request('/get-agreement', 'post', { _id }).then(result => {
        this.gainfo = false
        this.setState({ agreement: result.agreement })
      }).catch(e => {
        this.gainfo = false
        window.flash(e)
      })
    }
  }
  submit = e => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.subbing) return
    this.setState({ subbing: true })
    this.props.add(e.target).then(title => {
      this.pending = []
      this.setState({ ...title, subbing: false }, () => {
        if (typeof this.state.agreement === 'string') this.getAgreement(this.state.agreement, true)
        if (typeof this.state.paymentInfo === 'string') this.getPaymentInfo(this.state.paymentInfo)
      })
      window.flash('Title Saved')
    }).catch(e => {
      this.setState({ subbing: false })
      window.flash(e)
    })
  }
  render() {
    window.filmForm = this
    let primaryDetails = () => <Accordian open={true} header="Primary Details" content={<div className="form-group row">
      <div className="col-md-12">
        <label className="col-form-label">Title Name <span className="text-danger">*</span></label>
        <input className="form-control" autoComplete="none" type="text" placeholder="Title Name" value={this.state.title} onChange={e => this.setState({ title: e.target.value })} name="title" />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Title AKA</label>
        <input className="form-control" autoComplete="none" type="text" placeholder="Title AKA" value={this.state.titleAKA} onChange={e => this.setState({ titleAKA: e.target.value })} name="titleAKA" />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Title Status</label>
        <select className="form-control" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} name="status">
          <option value="">Choose a Status</option>
          {window.titleStatuses?.map((u, i) => (<option key={i} value={u}>{u}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Contact</label>
        <select className="form-control" value={this.state.contact} onChange={e => this.setState({ contact: e.target.value })} name="contact">
          <option value="">Select One</option>
          {window.app.state.users?.filter(u => !window.isInternal(u.type) && u.crm === 'acquisitions').map((u, i) => (<option key={i} value={u._id}>{u.name || `${u.firstName} ${u.lastName}`}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Company</label>
        <select className="form-control" value={this.state.company} onChange={e => this.setState({ company: e.target.value })} name="company">
          <option value="">Choose a Company</option>
          {window.app.state.companies?.filter(a => a.crm === 'acquisitions').map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
        </select>
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Contract Start Date</label>
        <DateInput name="contractStartDate" value={this.state.contractStartDate} change={e => this.setState({ contractStartDate: new Date(e) })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Contract End Date</label>
        <DateInput name="contractEndDate" value={this.state.contractEndDate} change={e => { this.setState({ contractEndDate: new Date(e) }) }} />
      </div>
      <div className="col-md-4" style={{ justifyContent: 'center', alignItems: 'center' }}>
        <label className="col-form-label">Contract Term</label>
        <strong><CountDown noCount={true} starts={this.state.contractStartDate} expires={this.state.contractEndDate} /></strong>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Title Category</label>
        <select className="form-control" value={this.state.titleCategory} onChange={e => this.setState({ titleCategory: e.target.value })} name="titleCategory">
          <option value="">Choose a Category</option>
          {['Sales Only', 'Distro Only', 'Both', 'Service'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Primary Genre</label>
        <select className="form-control" value={this.state.primaryGenre} onChange={e => this.setState({ primaryGenre: e.target.value })} name="primaryGenre">
          <option value="">Select a Genre</option>
          {window.primaryGenres?.map((u, i) => (<option key={i} value={u}>{u}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Other Genres</label>
        <ArrayInput name="otherGenres" selected={this.state.otherGenres} items={window.secondaryGenres} change={(val, selected) => { this.setState({ otherGenres: selected }) }} />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Suggested Rating</label>
        <SelectInput name="rating" value={this.state.rating} placeholder="Select a Rating" change={e => this.setState({ rating: e })} options={window.filmRatings?.map((u, i) => (<option key={i} value={u}>{u}</option>))} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Runtime with Credits</label>
        <div className="input-group">
          <input className="form-control" autoComplete="none" type="number" min="0" value={this.state.runTimeWithCredits?.toString()} onChange={e => this.setState({ runTimeWithCredits: window.parseNumber(e.target.value) })} name="runTimeWithCredits" />
          <div className="input-group-append"><span className="input-group-text">Minutes</span></div>
        </div>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Runtime without Credits</label>
        <div className="input-group">
          <input className="form-control" autoComplete="none" type="number" min="0" value={this.state.runTimeWithoutCredits?.toString()} onChange={e => this.setState({ runTimeWithoutCredits: window.parseNumber(e.target.value) })} name="runTimeWithoutCredits" />
          <div className="input-group-append"><span className="input-group-text">Minutes</span></div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Primary Language</label>
        <select className="form-control" value={this.state.language} onChange={e => this.setState({ language: e.target.value })} name="language">
          <option value="">Select a Language</option>
          {window.languages?.map((u, i) => (<option key={i} value={u.name}>{u.name}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Additional Languages</label>
        <ArrayInput name="additionalLanguages" selected={this.state.additionalLanguages} items={window.languages?.map(u => u.name) || []} change={(val, selected) => { this.setState({ additionalLanguages: selected }) }} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Country of Origin</label>
        <select className="form-control" value={this.state.countryOrigin} onChange={e => this.setState({ countryOrigin: e.target.value })} name="countryOrigin">
          <option value="">Select a Country</option>
          {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
        </select>
      </div>
      <div className="col-md-8">
        <label htmlFor="">Estimated Budget</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="estimatedBudget" type="number" min="0" className={"form-control"} value={this.state.estimatedBudget?.toString()} onChange={e => { this.setState({ estimatedBudget: window.parseNumber(e.target.value) }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">IMDBPro Page</label>
        <input className="form-control" autoComplete="none" type="text" placeholder="IMDBPro Page" value={this.state.imdb} onChange={e => this.setState({ imdb: e.target.value })} name="imdb" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">IMDB Production Year</label>
        <input className="form-control" autoComplete="none" type="number" placeholder="IMDB Production Year" value={this.state.productionYear?.toString()} onChange={e => this.setState({ productionYear: window.parseNumber(e.target.value) })} name="productionYear" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Title Change Control</label>
        <YesNo name="titleChangeControl" value={this.state.titleChangeControl} change={e => this.setState({ titleChangeControl: e })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Right of Refusal Control</label>
        <select className="form-control" value={this.state.rightOfRefusalControl} onChange={e => this.setState({ rightOfRefusalControl: e.target.value })} name="rightOfRefusalControl">
          <option value="">Select One</option>
          {['yes', 'no', 'consultation required'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
    </div>}></Accordian>
    let technical = () => <Accordian header="Technical Information" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">Frames Per Second (FPS)</label>
        <select className="form-control" value={this.state.fps?.toString()} min="0" onChange={e => this.setState({ fps: window.parseNumber(e.target.value) })} name="fps">
          <option value="">Select One</option>
          {['24', '25', '30', '60'].map((u, i) => (<option key={i} value={u}>{u}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Film Resolution</label>
        <select className="form-control" value={this.state.resolution} onChange={e => this.setState({ resolution: e.target.value })} name="resolution">
          <option value="">Select One</option>
          {['1080', '2K', '4K', '8K'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">VR Ready</label>
        <YesNo name="VRReady" value={this.state.VRReady} change={e => this.setState({ VRReady: e })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Aspect Ratio</label>
        <select className="form-control" value={this.state.aspectRatio} onChange={e => this.setState({ aspectRatio: e.target.value })} name="aspectRatio">
          <option value="">Select One</option>
          {['16:9', '4:3', '2:1', '16:10', '9:16', '1:1', '1.85:1'].map((u, i) => (<option key={i} value={u}>{u}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Audio Configuration</label>
        <select className="form-control" value={this.state.audio} onChange={e => this.setState({ audio: e.target.value })} name="audio">
          <option value="">Select One</option>
          {['stereo', '5.1', '7.1'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Technical Notes</label>
        <NotesInput
          name="technicalNotes"
          placeholder="Technical Notes"
          notes={this.state.technicalNotes}
          change={note => {
            this.handlePending(note)
            this.setState({
              technicalNotes: [...this.state.technicalNotes, note]
            })
          }}
          remove={_id => {
            this.handlePending(_id)
            this.setState({ technicalNotes: [...this.state.technicalNotes].filter(u => u._id !== _id) })
          }} />
      </div>
    </div>}>
    </Accordian>
    let assets = () => <Accordian header="Approved Assets" content={<div className="form-group row">
      <div className="col-md-12">
        <label htmlFor="copyrightLine" className="col-form-label">Copyright Line</label>
        <textarea autoComplete="off" name="copyrightLine" type="text" className={"form-control"} value={this.state.copyrightLine} onChange={e => { this.setState({ copyrightLine: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="usingProducersKeyArt">Using Producer's Key Art</label>
        <YesNo name="usingProducersKeyArt" value={this.state.usingProducersKeyArt} change={e => this.setState({ usingProducersKeyArt: e })} />
      </div>
      <div className="col-md-6">
        <label htmlFor="">Using Producer's Trailer</label>
        <YesNo name="usingProducersTrailer" value={this.state.usingProducersTrailer} change={e => this.setState({ usingProducersTrailer: e })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="keyArt">Key Art</label>
        {this.state.keyArt && <img src={this.state.keyArt} alt={`${this.state.title} Key Art`} className="keyArtPreview"></img>}
        <input autoComplete="off" accept="image/jpeg,image/png,image/jpg" name="keyArt" type="file" className={"form-control"} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="trailer">Trailer</label>
        <input autoComplete="off" name="trailer" type="text" className={"form-control"} value={this.state.trailer} onChange={e => { this.setState({ trailer: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="youtubeTrailer">Youtube Trailer</label>
        <input autoComplete="off" name="youtubeTrailer" type="text" className={"form-control"} value={this.state.youtubeTrailer} onChange={e => { this.setState({ youtubeTrailer: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="tagline">Tagline</label>
        <input autoComplete="off" name="tagline" type="text" className={"form-control"} value={this.state.tagline} onChange={e => { this.setState({ tagline: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="screener">Screener</label>
        <input autoComplete="off" name="screener" type="text" className={"form-control"} value={this.state.screener} onChange={e => { this.setState({ screener: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="screenerPassword">Screener Password</label>
        <SensitiveInput placeholder={"Screener Password"} value={this.state.screenerPassword} change={e => { this.setState({ screenerPassword: e }) }} name="screenerPassword" />
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="shortSynopsis">Short Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="synopsisShort" type="text" className={"form-control"} value={this.state.synopsisShort} onChange={e => {
            if (e.target.value?.length > 140) return
            this.setState({ synopsisShort: e.target.value })
          }} />
          <span className="form-text text-muted">{this.state.synopsisShort?.length || 0}/140</span>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="synopsisMedium">Medium Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="synopsisMedium" type="text" className={"form-control"} value={this.state.synopsisMedium} onChange={e => {
            if (e.target.value?.length > 250) return
            this.setState({ synopsisMedium: e.target.value })
          }} />
          <span className="form-text text-muted">{this.state.synopsisMedium?.length || 0}/250</span>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="synopsisLong">Long Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="synopsisLong" type="text" className={"form-control"} value={this.state.synopsisLong} onChange={e => {
            this.setState({ synopsisLong: e.target.value })
          }} />
          <span className="form-text text-muted"></span>
        </div>
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="comparableTitles">Comparable Titles</label>
        <ArrayInput type="text" name="comparableTitles" selected={this.state.comparableTitles || []}></ArrayInput>
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="stills">Film Stills</label>
        <ArrayInput type="text" name="stills" selected={this.state.stills || []}></ArrayInput>
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="keywords">Tags/Keywords</label>
        <ArrayInput type="text" name="keywords" selected={this.state.keywords || []}></ArrayInput>
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="sellingPoints">Selling Points</label>
        <ArrayInput type="text" name="sellingPoints" selected={this.state.sellingPoints || []}></ArrayInput>
      </div>
    </div>} />
    let cast = () => <Accordian header="Cast/Crew" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="billingBlock">Billing Block</label>
        {this.state.billingBlock && <span>{this.state.billingBlock}</span>}
        <input autoComplete="off" name="billingBlock" type="file" className={"form-control"} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="creditObligationStatement">Credit Obligation Statement</label>
        {this.state.creditObligationStatement && <span>{this.state.creditObligationStatement}</span>}
        <input autoComplete="off" name="creditObligationStatement" type="file" className={"form-control"} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="director">Director</label>
        <input autoComplete="off" name="director" type="text" className={"form-control"} value={this.state.director} onChange={e => { this.setState({ director: e.target.value }) }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label" htmlFor="producers">Producers</label>
        <ArrayInput type="text" name="producers" selected={this.state.producers} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label" htmlFor="writers">Writers</label>
        <ArrayInput type="text" name="writers" selected={this.state.writers} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label" htmlFor="cast">Notable Cast</label>
        <ArrayInput type="text" name="cast" selected={this.state.cast} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label" htmlFor="crew">Notable Crew</label>
        <ArrayInput type="text" name="crew" selected={this.state.crew} />
      </div>
    </div>} />
    let marketing = () => <Accordian header="Marketing" content={<div className="form-group row">
      <div className="col-md-4">
        <label className="col-form-label">Cinando</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.cinando} onChange={e => this.setState({ cinando: e.target.value })} name="cinando" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Instagram</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.instagram} onChange={e => this.setState({ instagram: e.target.value })} name="instagram" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Facebook</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.facebook} onChange={e => this.setState({ facebook: e.target.value })} name="facebook" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Twitter</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.twitter} onChange={e => this.setState({ twitter: e.target.value })} name="twitter" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Vuulr</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.vuulr} onChange={e => this.setState({ vuulr: e.target.value })} name="vuulr" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Film Catalogue</label>
        <ArrayInput type="text" name="filmCatalogue" selected={this.state.filmCatalogue} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label" htmlFor="producersKeyArt">Producer's Key Art</label>
        {this.state.producerKeyArt && <span>{this.state.producerKeyArt}</span>}
        <input autoComplete="off" name="producerKeyArt" type="file" className={"form-control"} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Foreign Key Art</label>
        {this.state.foreignKeyArt && <span>{this.state.foreignKeyArt}</span>}
        <input autoComplete="off" name="foreignKeyArt" type="file" className={"form-control"} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">DVD Box Art</label>
        {this.state.DVDBoxArt && <span>{this.state.DVDBoxArt}</span>}
        <input autoComplete="off" name="DVDBoxArt" type="file" className={"form-control"} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Reviews</label>
        <ArrayInput type="text" name="reviews" selected={this.state.reviews} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Festivals</label>
        <ArrayInput type="text" name="festivals" selected={this.state.festivals} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Festival Wins</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.festivalWins} onChange={e => this.setState({ festivalWins: e.target.value })} name="festivalWins" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Festival Laurels</label>
        {this.state.festivalLaurels && <span>{this.state.festivalLaurels}</span>}
        <input autoComplete="off" name="festivalLaurels" className="form-control" type="file" />
      </div>
    </div>} />
    let onboarding = () => <Accordian header="Onboarding" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">Delivery Contact Name</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.deliveryContactName} onChange={e => this.setState({ deliveryContactName: e.target.value })} name="deliveryContactName" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Assets Folder</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.producerAssetsFolder} onChange={e => this.setState({ producerAssetsFolder: e.target.value })} name="producerAssetsFolder" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Certificate of Origin</label>
        {this.state.certificateOfOrigin && <span>{this.state.certificateOfOrigin}</span>}
        <input className="form-control" autoComplete="none" type="file" name="certificateOfOrigin" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Title Search Report</label>
        {this.state.titleSearchReport && <span>{this.state.titleSearchReport}</span>}
        <input className="form-control" autoComplete="none" type="file" name="titleSearchReport" />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Onboarding Start Date</label>
        <DateInput value={this.state.onboardingStartDate} onChange={e => this.setState({ onboardingStartDate: e })} name="onboardingStartDate" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 1 Due Date</label>
        <DateInput value={this.state.stage1DueDate} onChange={e => this.setState({ stage1DueDate: e })} name="stage1DueDate" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 1 Status</label>
        <select className="form-control" value={this.state.stage1} onChange={e => this.setState({ stage1: e.target.value })} name="stage1">
          <option value="">Select One</option>
          {['pending', 'in progress', 'completed'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 2 Due Date</label>
        <DateInput value={this.state.stage2DueDate} onChange={e => this.setState({ stage2DueDate: e })} name="stage2DueDate" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 2 Status</label>
        <select className="form-control" value={this.state.stage2} onChange={e => this.setState({ stage2: e.target.value })} name="stage2">
          <option value="">Select One</option>
          {['pending', 'in progress', 'completed'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 3 Due Date</label>
        <DateInput value={this.state.stage3DueDate} onChange={e => this.setState({ stage3DueDate: e })} name="stage3DueDate" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Stage 3 Status</label>
        <select className="form-control" value={this.state.stage3} onChange={e => this.setState({ stage3: e.target.value })} name="stage3">
          <option value="">Select One</option>
          {['pending', 'in progress', 'completed'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Title ID</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.titleID} onChange={e => this.setState({ titleID: e.target.value })} name="titleID" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Client Credentials</label>
        <CredentialInput selected={this.state.credentials || []} change={(e,v) => this.setState({credentials: v})} name="credentials" />
      </div>
    </div>} />
    let sales = () => <Accordian header="Sales Data" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">US Release Date</label>
        <DateInput value={this.state.USReleaseDate} onChange={e => this.setState({ USReleaseDate: e })} name="USReleaseDate" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">International Release Dates</label>
        <InternationalDates selected={this.state.internationalReleaseDates} name="internationalReleaseDates" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Revenue Estimates</label>
        <textarea className="form-control" name="revenueEstimates" value={this.state.revenueEstimates} onChange={e => this.setState({ revenueEstimates: e.target.value })} />
      </div>
    </div>} />
    let acquisitions = () => <Accordian header="Acquisitions" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">Strategy Packet</label>
        {this.state.acquisitionsStrategyPacket && <span>{this.state.acquisitionsStrategyPacket}</span>}
        <input className="form-control" autoComplete="none" type="file" name="acquisitionsStrategyPacket" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Costing Analysis</label>
        {this.state.costingAnalysis && <span>{this.state.costingAnalysis}</span>}
        <input className="form-control" autoComplete="none" type="file" name="costingAnalysis" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Client Information Form</label>
        {this.state.clientInformationForm && <span>{this.state.clientInformationForm}</span>}
        <input className="form-control" autoComplete="none" type="file" name="clientInformationForm" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Client Metadata Form</label>
        {this.state.clientMetadataForm && <span>{this.state.clientMetadataForm}</span>}
        <input className="form-control" autoComplete="none" type="file" name="clientMetadataForm" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Dropbox Folder</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.dropboxFolder} onChange={e => this.setState({ dropboxFolder: e.target.value })} name="dropboxFolder" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Trailer</label>
        <input autoComplete="off" name="producerTrailer" type="text" className={"form-control"} value={this.state.producerTrailer} onChange={e => this.setState({ producerTrailer: e.target.value })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Trailer Password</label>
        <SensitiveInput placeholder={"Trailer Password"} value={this.state.producerTrailerPassword} change={e => { this.setState({ producerTrailerPassword: e }) }} name="producerTrailerPassword" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Screener</label>
        <input autoComplete="off" name="producerScreener" type="text" className={"form-control"} value={this.state.producerScreener} onChange={e => this.setState({ producerScreener: e.target.value })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Screener Password</label>
        <SensitiveInput placeholder={"Screener Password"} value={this.state.producerScreenerPassword} change={e => { this.setState({ producerScreenerPassword: e }) }} name="producerScreenerPassword" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Producer's Cast List</label>
        <ArrayInput placeholder={"Producer Cast List"} type="text" selected={this.state.producerCastList || []} change={(e,v) => { this.setState({ producerCastList: v }) }} name="producerCastList" />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Producer's Short Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="producerSynopsisShort" type="text" className={"form-control"} value={this.state.producerSynopsisShort} onChange={e => {
            if (e.target.value?.length > 140) return
            this.setState({ producerSynopsisShort: e.target.value })
          }} />
          <span className="form-text text-muted">{this.state.producerSynopsisShort?.length || 0}/140</span>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Producer's Medium Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="producerSynopsisMedium" type="text" className={"form-control"} value={this.state.producerSynopsisMedium} onChange={e => {
            if (e.target.value?.length > 250) return
            this.setState({ producerSynopsisMedium: e.target.value })
          }} />
          <span className="form-text text-muted">{this.state.producerSynopsisMedium?.length || 0}/250</span>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Producer's Long Synopsis</label>
        <div className="form-group">
          <textarea autoComplete="off" name="producerSynopsisLong" type="text" className={"form-control"} value={this.state.producerSynopsisLong} onChange={e => {
            this.setState({ producerSynopsisLong: e.target.value })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Contra Piracy Submitted</label>
          <YesNo name="contraPiracySubmitted" value={this.state.contraPiracySubmitted} change={e => this.setState({ contraPiracySubmitted: e })} />
        </div>
      </div>
      <div className="col-md-6">
        <label htmlFor="">Producer's Comparable Titles</label>
        <ArrayInput type="text" name="producerComparableTitles" selected={this.state.producerComparableTitles || []}></ArrayInput>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Classification</label>
        <select className="form-control" value={this.state.classification} onChange={e => this.setState({ classification: e.target.value })} name="classification">
          <option value="">Select One</option>
          {window.titleClassifications?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Description</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.description} onChange={e => this.setState({ description: e.target.value })} name="description" />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Music Releases</label>
        <ArrayInput type="text" name="musicReleases" selected={this.state.musicReleases || []}></ArrayInput>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Chain of Title Notes</label>
        <NotesInput
          name="chainOfTitleNotes"
          placeholder="Chain of Title Notes"
          notes={this.state.chainOfTitleNotes}
          change={note => {
            this.handlePending(note)
            this.setState({
              chainOfTitleNotes: [...this.state.chainOfTitleNotes, note]
            })
          }}
          remove={_id => {
            this.handlePending(_id)
            this.setState({ chainOfTitleNotes: [...this.state.chainOfTitleNotes].filter(u => u._id !== _id) })
          }} />
      </div>
    </div>} />
    let agreement = () => <Accordian open={false} header="Agreement" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">Agreement Contact</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.contact} onChange={e => this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, contact: e.target.value } : { contact: e.target.value } })} name="agreement-contact">
          <option value="">Select One</option>
          {window.app.state.users?.filter(a => !window.isInternal(a.type)).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.email} - {a.type}</option>)}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Signatory Contact</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.signatory} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, signatory: e.target.value } : { signatory: e.target.value } })
        }} name="agreement-signatory">
          <option value="">Select One</option>
          {window.app.state.users?.filter(a => !window.isInternal(a.type)).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.email} - {a.type}</option>)}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Company</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.company} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, company: e.target.value } : { company: e.target.value } })
        }} name="agreement-company">
          <option value="">Select One</option>
          {window.app.state.companies?.filter(a => a.crm === 'acquisitions').map((a, i) => <option value={a._id} key={i}>{a.name}</option>)}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Agreement Owner</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.owner} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, owner: e.target.value } : { owner: e.target.value } })
        }} name="agreement-owner">
          <option value="">Select One</option>
          {window.app.state.users?.filter(a => window.isInternal(a.type)).map((a, i) => <option value={a._id} key={i}>{a.name} - {a.email} - {a.type}</option>)}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Organization</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.organization} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, organization: e.target.value } : { organization: e.target.value } })
        }} name="agreement-organization">
          <option value="">Select One</option>
          <option value="HOP">HOP</option>
          <option value="OMM">OMM</option>
          <option value="OMS">OMS</option>
          <option value="DM">DM</option>
          <option value="HOS">HOS</option>
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Assignment Rights</label>
        <YesNo name="agreement-assignmentRights" value={this.state.agreement?.assignmentRights} change={e => this.setState({ agreement: { ...this.state.agreement, assignmentRights: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Agreement Notes</label>
        <NotesInput name="agreement-notes" placeholder="Agreement Notes" notes={this.state.agreement?.notes || []} change={e => {
          this.handlePending(e)
          this.setState({ notes: this.state.agreement?.notes ? [...this.state.agreement?.notes, e] : [e] })
        }} remove={n => {
          this.handlePending(n)
          this.setState({ notes: this.state.agreement?.notes ? [...this.state.agreement?.notes].filter(a => a._id !== n) : [] })
        }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Status</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.status} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, status: e.target.value } : { status: e.target.value } })
        }} name="agreement-status">
          <option value="">Select One</option>
          {window.agreementStatuses?.map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Termination Date</label>
        <DateInput name={'agreement-terminationDate'} value={this.state.agreement?.terminationDate} change={e => this.setState({ agreement: { ...this.state.agreement, terminationDate: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">CMA Involved</label>
        <YesNo value={this.state.agreement?.CMA} name="agreement-CMA" change={e => this.setState({ agreement: { ...this.state.agreement, CMA: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Country of Law</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.countryOfLaw} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, countryOfLaw: e.target.value } : { countryOfLaw: e.target.value } })
        }} name="agreement-countryOfLaw">
          <option value="">Select One</option>
          {window.countries?.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
        </select>
      </div>
      <div className="col-md-4">
        <label className="col-form-label">State of Law</label>
        <select className="form-control" autoComplete="none" value={this.state.agreement?.stateOfLaw} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, stateOfLaw: e.target.value } : { stateOfLaw: e.target.value } })
        }} name="agreement-stateOfLaw">
          <option value="">Select One</option>
          {window.states?.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
        </select>
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Effective Date</label>
        <DateInput name="agreement-effectiveDate" value={this.state.agreement?.effectiveDate} change={e => this.setState({ agreement: { ...this.state.agreement, effectiveDate: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Date of Signature</label>
        <DateInput name="agreement-dateOfSignature" value={this.state.agreement?.dateOfSignature} change={e => this.setState({ agreement: { ...this.state.agreement, dateOfSignature: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Renewal Date</label>
        <DateInput name="agreement-renewalDate" value={this.state.agreement?.renewalDate} change={e => this.setState({ agreement: { ...this.state.agreement, renewalDate: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Renewal Expiration</label>
        <DateInput name="agreement-renewalExpiration" value={this.state.agreement?.renewalExpiration} change={e => this.setState({ agreement: { ...this.state.agreement, renewalExpiration: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Distribution Term End Date</label>
        <DateInput name="agreement-distributionTermEndDate" value={this.state.agreement?.distributionTermEndDate} change={e => this.setState({ agreement: { ...this.state.agreement, distributionTermEndDate: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Sales Term End Date</label>
        <DateInput name="agreement-salesTermEndDate" value={this.state.agreement?.salesTermEndDate} change={e => this.setState({ agreement: { ...this.state.agreement, salesTermEndDate: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Delivery Due Date</label>
        <DateInput name="agreement-deliveryDueDate" value={this.state.agreement?.deliveryDueDate} change={e => this.setState({ agreement: { ...this.state.agreement, deliveryDueDate: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Perpetuity</label>
        <YesNo value={this.state.agreement?.perpetuity} name="agreement-perpetuity" change={e => this.setState({ agreement: { ...this.state.agreement, perpetuity: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Auto Renew</label>
        <YesNo value={this.state.agreement?.autoRenew} name="agreement-autoRenew" change={e => this.setState({ agreement: { ...this.state.agreement, autoRenew: e } })} />
      </div>

      <div className="col-md-12">
        <label className="col-form-label">Auto Renew Notes</label>
        <NotesInput
          name="agreement-autoRenewNotes"
          placeholder="Auto Renew Notes"
          notes={this.state.agreement?.autoRenewNotes || []}
          change={note => {
            this.handlePending(note)
            this.setState({
              agreement: {
                ...this.state.agreement,
                autoRenewNotes: this.state.agreement?.autoRenewNotes ? [...this.state.agreement.autoRenewNotes, note] : [note]
              }
            })
          }}
          remove={_id => {
            this.handlePending(_id)
            this.setState({ agreement: { ...this.state.agreement, autoRenewNotes: this.state.agreement?.autoRenewNotes ? [...this.state.agreement?.autoRenewNotes].filter(u => u._id !== _id) : [] } })
          }} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Licensed Rights</label>
        <ArrayInput type="search" name="agreement-licensedRights" selected={this.state.agreement?.licensedRights || []} items={window.rights || []} change={(v, licensedRights) => this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, licensedRights } : { licensedRights } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Reserved Rights</label>
        <ArrayInput type="search" name="agreement-reservedRights" selected={this.state.agreement?.reservedRights || []} items={window.rights || []} change={(v, reservedRights) => this.setState({ agreement: { ...this.state.agreement, reservedRights } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Reserved Territories</label>
        <ArrayInput name="agreement-reservedTerritories" selected={this.state.agreement?.reservedTerritories || []} items={window.territories || []} change={(v, reservedTerritories) => this.setState({ agreement: this.state.agreement ? {...this.state.agreement, reservedTerritories} : {reservedTerritories} })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Holdback(s)</label>
        <ArrayInput type="text" name="agreement-holdbacks" selected={this.state.agreement?.holdbacks || []} change={(v, s) => this.setState({ agreement: { ...this.state.agreement, holdbacks: s } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Holdback Date</label>
        <DateInput name="agreement-holdbackDate" value={this.state.agreement?.holdbackDate} change={d => this.setState({ agreement: { ...this.state.agreement, holdbackDate: d } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Title Change Approval</label>
        <YesNo value={this.state.agreement?.titleChangeApproval} name="agreement-titleChangeApproval" change={e => this.setState({ agreement: { ...this.state.agreement, titleChangeApproval: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Title HOP Has Final Say</label>
        <YesNo value={this.state.agreement?.titleChangeFinal} name="agreement-titleChangeFinal" change={e => this.setState({ agreement: { ...this.state.agreement, titleChangeFinal: e } })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Gross Corridor Percentage</label>
        <NumberInput name="agreement-grossCorridorPercentage" value={this.state.agreement?.grossCorridorPercentage} change={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, grossCorridorPercentage: e } : { grossCorridorPercentage: e } })
        }} append="%" />
      </div>
      {/* <div className="col-md-6">
        <label className="col-form-label">Gross Corridor Platforms</label>
        <ArrayInput type="text" name="agreement-grossCorridorPlatforms" items={this.state.agreement?.grossCorridorPlatforms || []} change={(v, s) => this.setState({ agreement: { ...this.state.agreement, grossCorridorPlatforms: s } })} />
      </div> */}
      <div className="col-md-6">
        <label className="col-form-label">Gross Corridor Rights</label>
        <ArrayInput type="text" name="agreement-grossCorridorRights" selected={this.state.agreement?.grossCorridorRights || []} change={(v, s) => this.setState({ grossCorridorRights: s })} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Additional Bonuses</label>
        <ArrayInput type="text" name="agreement-additionalBonuses" selected={this.state.agreement?.additionalBonuses || []} change={(v, s) => this.setState({ agreement: { ...this.state.agreement, additionalBonuses: s } })} />
      </div>
    </div>} />
    let accounting = () => <Accordian header="Accounting" content={<div className="form-group row">
      <div className="col-md-6">
        <label className="col-form-label">Accounting Contact</label>
        <input autoComplete="none" type="text" className="form-control" name="agreement-accountingContact" placeholder="Accounting Contact" value={this.state.agreement?.accountingContact} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, accountingContact: e.target.value } : { accountingContact: e.target.value } })
        }} />
      </div>
      <div className="col-md-6">
        <label className="col-form-label">Accounting Cycle</label>
        <select className="form-control" value={this.state.agreement?.accountingCycle} onChange={e => this.setState({ agreement: { ...this.state.agreement, accountingCycle: e.target.value } })} name="agreement-accountingCycle">
          <option value="">Select One</option>
          {['daily', 'weekly', 'monthly', 'quarterly', 'semi-annual', 'annual', 'bi-annual'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Accounting Notes</label>
        <NotesInput
          name="agreement-accountingNotes"
          placeholder="Accounting Notes"
          notes={this.state.agreement?.accountingNotes}
          change={note => {
            this.handlePending(note)
            this.setState({
              agreement: this.state.agreement ? ({
                ...this.state.agreement,
                accountingNotes:
                  (this.state.agreement?.accountingNotes ? ([...this.state.agreement.accountingNotes, note]) : [])
              }) : {}
            })
          }}
          remove={_id => {
            this.handlePending(_id)
            this.setState({
              agreement: this.state.agreement ? ({
                ...this.state.agreement,
                accountingNotes:
                  (this.state.agreement.accountingNotes ? ([...this.state.agreement.accountingNotes].filter(u => u !== _id)) : [])
              }) : {}
            }, () => {
              window.app.removeCPINote(_id)
            })
          }} />
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Reports Due When</label>
        <select className="form-control" value={this.state.agreement?.reportsDueWhen} onChange={e => this.setState({ agreement: { ...this.state.agreement, reportsDueWhen: e.target.value } })} name="agreement-reportsDueWhen">
          <option value="">Select One</option>
          {['daily', 'weekly', 'monthly', 'quarterly', 'semi-annual', 'annual', 'bi-annual'].map((u, i) => (<option key={i} value={u}>{window.capitalize(u)}</option>))}
        </select>
      </div>
      <div className="col-md-12">
        <label className="col-form-label">Net Terms</label>
        <textarea autoComplete="none" className="form-control" rows={2} name="agreement-netTerms" placeholder="Net Terms" value={this.state.agreement?.netTerms} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, netTerms: e.target.value } : { netTerms: e.target.value } })
        }} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">Flat Deal Only</label>
        <YesNo name="agreement-flatDealOnly" value={this.state.agreement?.flatDealOnly} change={e => this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, flatDealOnly: e } : { flatDealOnly: e } })} />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">QB Item Code</label>
        <input className="form-control" autoComplete="none" type="text" value={this.state.agreement?.QBItemCode} onChange={e => {
          this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, QBItemCode: e.target.value } : { QBItemCode: e.target.value } })
        }} name="agreement-QBItemCode" />
      </div>
      <div className="col-md-4">
        <label className="col-form-label">IFTA Collections Submitted</label>
        <YesNo name="agreement-IFTACollectionsSubmitted" value={this.state.agreement?.IFTACollectionsSubmitted} change={e => this.setState({ agreement: this.state.agreement ? { ...this.state.agreement, IFTACollectionsSubmitted: e } : { IFTACollectionsSubmitted: e } })}></YesNo>
      </div>
      <div className="col-md-4">
        <label className="col-form-label" htmlFor="MGAmount">MG Amount</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-MGAmount" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.MGAmount?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, MGAmount: window.parseNumber(e.target.value) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="expenseCapSales">Sales Expense Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-expenseCapSales" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.expenseCapSales?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, expenseCapSales: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="expenseCapMarketing">Marketing Expense Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-expenseCapMarketing" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.expenseCapMarketing?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, expenseCapMarketing: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="expenseCapDistribution">Distribution Expense Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-expenseCapDistribution" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.expenseCapDistribution?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, expenseCapDistribution: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="trailerCostCap">Trailer Cost Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-trailerCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.trailerCostCap?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, trailerCostCap: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="posterCostCap">Poster Cost Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-posterCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.posterCostCap?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, posterCostCap: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="pressCostCap">Press Cost Cap</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-pressCostCap" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.pressCostCap?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, pressCostCap: Math.round(window.parseNumber(e.target.value)) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
        <label className="col-form-label" htmlFor="salesFeePercentage">Sales Fee %</label>
        <div className="input-group">
          <input autoComplete="off" name="agreement-salesFeePercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.salesFeePercentage?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, salesFeePercentage: window.parseNumber(e.target.value) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="distributionFeePercentage">Distribution Fee %</label>
        <div className="input-group">
          <input autoComplete="off" name="agreement-distributionFeePercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.distributionFeePercentage?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, distributionFeePercentage: window.parseNumber(e.target.value) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="incomeReservesPercentage">Income Reserves %</label>
        <div className="input-group">
          <input autoComplete="off" name="agreement-incomeReservesPercentage" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.incomeReservesPercentage?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, incomeReservesPercentage: window.parseNumber(e.target.value) } }) }} />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="paymentsBecomeDue">Payments Become Due</label>
        <DateInput name="agreement-paymentsBecomeDue" value={this.state.agreement?.paymentsBecomeDue} change={d => this.setState({ agreement: { ...this.state.agreement, paymentsBecomeDue: d } })} />
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="otherExpenseCaps">Other Expense Caps</label>
        <ArrayInput name="agreement-otherExpenseCaps" items={this.state.agreement?.otherExpenseCaps} change={(a, d) => this.setState({ agreement: { ...this.state.agreement, otherExpenseCaps: d } })} />
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="triggerDeadline">Trigger Deadling</label>
        <DateInput name="agreement-triggerDeadline" value={this.state.agreement?.triggerDeadline} change={d => this.setState({ agreement: { ...this.state.agreement, triggerDeadline: d } })} />
      </div>
      <div className="col-md-12">
        <label className="col-form-label" htmlFor="triggerAmount">Trigger Amount</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input autoComplete="off" name="agreement-triggerAmount" type="number" min={0} placeholder="N/A" className={"form-control"} value={this.state.agreement?.triggerAmount?.toString()} onChange={e => { this.setState({ agreement: { ...this.state.agreement, triggerAmount: window.parseNumber(e.target.value) } }) }} />
        </div>
      </div>
    </div>} />
    let paymentInfo = () => <Accordian header="Payment Information" content={(<>
      <div className="form-group row">
        <div className="col-md-12">
          <label className="col-form-label">Billing Address</label>
          <textarea autoComplete="none" className="form-control" rows={3} name="PI-billingAddress-street" placeholder="Billing Address" value={this.state.paymentInfo?.billingAddress?.street} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo.billingAddress, street: e.target.value } : { street: e.target.value } } : { billingAddress: { street: e.target.value } } })
          }} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Billing Region</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-billingAddress-region" placeholder="Billing Region" value={this.state.paymentInfo?.billingAddress?.region} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo.billingAddress, region: e.target.value } : { region: e.target.value } } : { billingAddress: { region: e.target.value } } })
          }} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-4">
          <input autoComplete="none" type="text" value={this.state.paymentInfo?.billingAddress?.city} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo.billingAddress, city: e.target.value } : { city: e.target.value } } : { billingAddress: { city: e.target.value } } })
          }} className="form-control" placeholder="Billing City" name="PI-billingAddress-city" />
        </div>
        <div className="col-md-4">
          <select value={this.state.paymentInfo?.billingAddress?.state} name="PI-billingAddress-state" className="form-control" onChange={e => {
            let o = this.state.paymentInfo?.billingAddress ? { ...this.state.paymentInfo?.billingAddress } : {}
            o.state = e.target.value
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: o } : { billingAddress: o } }, () => {
              if (typeof this.props.change === 'function') this.props.change(e)
            })
          }}>
            <option value="">Select a State</option>
            {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
        <div className="col-md-4">
          <input autoComplete="none" type="text" value={this.state.paymentInfo?.billingAddress?.postalCode} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: this.state.paymentInfo.billingAddress ? { ...this.state.paymentInfo.billingAddress, postalCode: e.target.value } : { postalCode: e.target.value } } : { billingAddress: { postalCode: e.target.value } } })
          }} className="form-control" placeholder="Billing Postal Code" name="PI-billingAddress-postal-code" />
        </div>
      </div><div className="form-group row">
        <div className="col-md-12">
          <select name="PI-billingAddress-country" className="form-control" value={this.state.paymentInfo?.billingAddress?.country} onChange={e => {
            let o = this.state.paymentInfo?.billingAddress ? { ...this.state.paymentInfo?.billingAddress } : {}
            o.country = e.target.value
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, billingAddress: o } : {} }, () => {
              if (typeof this.props.change === 'function') this.props.change(e)
            })
          }}>
            <option value="">Select a Country</option>
            {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-6">
          <label className="col-form-label">Bank Account Name</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-name" placeholder="Bank Account Name" value={this.state.paymentInfo?.name} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, name: e.target.value } : { name: e.target.value } })
          }} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Bank Name</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-bankName" placeholder="Bank Name" value={this.state.paymentInfo?.bankName} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankName: e.target.value } : { bankName: e.target.value } })
          }} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Bank Account Number</label>
          <SensitiveInput name="PI-accountNumber" placeholder="Account Number" value={this.state.paymentInfo?.accountNumber} change={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, accountNumber: e } : { accountNumber: e } })
          }} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Bank Routing Number</label>
          <SensitiveInput name="PI-routingNumber" placeholder="Routing Number" value={this.state.paymentInfo?.routingNumber} change={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, routingNumber: e } : { routingNumber: e } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">IBAN</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-IBAN" placeholder="IBAN" value={this.state.paymentInfo?.IBAN} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, IBAN: e.target.value } : { IBAN: e.target.value } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">SIC Code</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-SICCode" placeholder="SIC Code" value={this.state.paymentInfo?.SICCode} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, SICCode: e.target.value } : { SICCode: e.target.value } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">SWIFT Code</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-SWIFTCode" placeholder="SWIFT Code" value={this.state.paymentInfo?.SWIFTCode} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, SWIFTCode: e.target.value } : { SWIFTCode: e.target.value } })
          }} />
        </div>
        <div className="col-md-12">
          <label className="col-form-label">Sort Code</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-sortCode" placeholder="Sort Code" value={this.state.paymentInfo?.sortCode} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, sortCode: e.target.value } : { sortCode: e.target.value } })
          }} />
        </div>
        <div className="col-md-12">
          <label className="col-form-label">Bank Street Address</label>
          <textarea autoComplete="none" className="form-control" rows={3} name="PI-bankAddress-street" placeholder="Bank Street" value={this.state.paymentInfo?.bankAddress?.street} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo?.bankAddress, street: e.target.value } : { street: e.target.value } } : { bankAddress: { street: e.target.value } } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Bank Region/Province</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-region" placeholder="Bank Region" value={this.state.paymentInfo?.bankAddress?.region} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo?.bankAddress, region: e.target.value } : { region: e.target.value } } : { bankAddress: { region: e.target.value } } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Bank City</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-city" placeholder="Bank City" value={this.state.paymentInfo?.bankAddress?.city} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo?.bankAddress, city: e.target.value } : { city: e.target.value } } : { bankAddress: { city: e.target.value } } })
          }} />
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Bank State</label>
          <select className="form-control" name="PI-bankAddress-state" value={this.state.paymentInfo?.bankAddress?.state} onChange={e => {
            let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
            o.bankAddress = o.bankAddress ? { ...o.bankAddress, state: e.target.value } : { state: e.target.value }
            this.setState({ paymentInfo: o }, () => {
              if (typeof this.props.change === 'function') this.props.change(e)
            })
          }}>
            <option value="">Select Bank State</option>
            {window.states.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
        <div className="col-md-4">
          <label className="col-form-label">Bank Postal Code</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-bankAddress-postalCode" placeholder="Postal Code" value={this.state.paymentInfo?.bankAddress?.postalCode} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, bankAddress: this.state.paymentInfo.bankAddress ? { ...this.state.paymentInfo?.bankAddress, postalCode: e.target.value } : { postalCode: e.target.value } } : { bankAddress: { postalCode: e.target.value } } })
          }} />
        </div>
        <div className="col-md-8">
          <label className="col-form-label">Bank Country</label>
          <select className="form-control" name="PI-bankAddress-country" value={this.state.paymentInfo?.bankAddress?.country} onChange={e => {
            let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
            o.bankAddress = o.bankAddress ? { ...o.bankAddress, country: e.target.value } : { country: e.target.value }
            this.setState({ paymentInfo: o }, () => {
              if (typeof this.props.change === 'function') this.props.change(e)
            })
          }}>
            <option value="">Select Bank Country</option>
            {window.countries.map((u, i) => (<option key={i} value={u.code}>{u.name}</option>))}
          </select>
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Tax ID Number</label>
          <SensitiveInput name="PI-taxID" change={e => this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, taxID: e } : { taxID: e } })} value={this.state.paymentInfo?.taxID} placeholder="Tax ID" />
        </div>
        <div className="col-md-12">
          <label className="col-form-label">W9 or W8 BEN (.pdf)</label>
          <div className="c2" style={{ maxWidth: '80%' }}>
            <div className="b1">
              <span>W9</span>
              <input autoComplete="none" type="radio" checked={this.state.paymentInfo?.W9} onChange={e => {
                this.setState({ paymentInfo: { ...this.state.paymentInfo, W9: true, W8BEN: false } })
              }} />
            </div>
            <div className="b1">
              <span>W8BEN</span>
              <input autoComplete="none" type="radio" checked={this.state.paymentInfo?.W8BEN} onChange={e => {
                this.setState({ paymentInfo: { ...this.state.paymentInfo, W8BEN: true, W9: false } })
              }} />
            </div>
          </div>
          {this.state.paymentInfo?.W9 && <input accept=".pdf" type="file" className="form-control" name="PI-W9" />}
          {this.state.paymentInfo?.W8BEN && <input accept=".pdf" type="file" className="form-control" name="PI-W8BEN" />}
        </div>
        <div className="col-md-6">
          <label className="col-form-label">VAT</label>
          <input autoComplete="none" type="text" className="form-control" name="PI-VAT" placeholder="VAT" value={this.state.paymentInfo?.VAT} onChange={e => {
            this.setState({ paymentInfo: this.state.paymentInfo ? { ...this.state.paymentInfo, VAT: e.target.value } : { VAT: e.target.value } })
          }} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Currency Type</label>
          <select className="form-control" name="PI-currencyType" value={this.state.paymentInfo?.currencyType} onChange={e => {
            let o = this.state.paymentInfo ? { ...this.state.paymentInfo } : {}
            o.currencyType = e.target.value
            this.setState({ paymentInfo: o }, () => {
              if (typeof this.props.change === 'function') this.props.change(e)
            })
          }}>
            <option value="">Select a currency type</option>
            {window.currencyTypes?.map((u, i) => (<option key={i} value={u}>{u}</option>))}
          </select>
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Producer Payments</label>
          <ProducerPayments name="producerPayments" selected={this.state.producerPayments || []} change={e => this.setState({ producerPayments: e })} />
        </div>
        <div className="col-md-6">
          <label className="col-form-label">Last Payout</label>
          <DateInput2 name="lastPayout" value={this.state.lastPayout ? new Date(this.state.lastPayout) : null} change={e => this.setState({ lastPayout: e })} />
          <br></br>
          <label className="col-form-label">Next Payout</label>
          <DateInput2 name="nextPayout" value={this.state.nextPayout ? new Date(this.state.nextPayout) : null} change={e => this.setState({ nextPayout: e })} />
        </div>
      </div>
    </>)} />
    return (<><div onClick={this.props.close} className="modal right fade show" style={{ display: 'block' }} id="add_company" tabIndex={-1} role="dialog" aria-modal="true">
      <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
        <button type="button" className="close md-close" onClick={this.props.close} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center">{this.props._id ? 'Edit' : 'Add'} {this.state.title || 'Title'}</h4>
            <div className="b2 close xs-close" style={{ width: '40%' }}>
              {this.props._id && <button data-dismiss="modal" className="close" style={{ fontSize: '1rem', 'position': 'static' }} onClick={this.props.close}>Back</button>}
              <button className="close" style={{ 'position': 'static' }} type="button" data-dismiss="modal" onClick={this.props.close}>×</button>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                {this.state.subbing ? <div className="b1"><Spinner /><h3>SUBMITTING<DotDot /></h3></div> : <form autoComplete="none" onSubmit={this.submit}>
                  <input type="hidden" defaultValue={'hello'} className="hidden" autoComplete="on" readOnly={true} />
                  {primaryDetails()}
                  {technical()}
                  {assets()}
                  {cast()}
                  {marketing()}
                  {onboarding()}
                  {sales()}
                  {acquisitions()}
                  {agreement()}
                  {accounting()}
                  {paymentInfo()}
                  <div className="text-center py-3">
                    <button type="submit" className="border-0 btn btn-primary btn-gradient-primary btn-rounded">Save</button>&nbsp;&nbsp;
                    <button data-dismiss="modal" onClick={this.props.close} type="button" className="btn btn-secondary btn-rounded" >Cancel</button>
                  </div>
                  <input type="hidden" name="crm" value={this.props.crm} />
                  <input type="hidden" name="_id" value={this.state._id} />
                </form>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><div className="modal-backdrop fade show"></div></>)
  }
}
export default FilmForm
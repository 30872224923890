import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Accordian from '../helpers/display/accordian'
import { Link } from 'react-router-dom'
import TabLink from '../helpers/tablink'
import ActionForm from '../forms/actionform'
import CountDown from '../helpers/timer'
import Error from './404'
import LoadingData from '../helpers/display/loadingdata'
class FullAction extends Component {
    state = {
        edit: false,
        notFound: true
    }
    componentDidMount() {
        this.setState({ _id: this.props._id }, this.initTitle)
    }
    componentDidUpdate() {
        if (this.props._id !== this.state._id) this.setState({ _id: this.props._id }, this.initTitle)
    }
    initTitle = () => {
        let { _id } = this.state
        if (!_id) return this.setState({notFound: true})
        let t = window.app.state.reminders?.find(a => a._id === _id)
        if (!t) return this.setState({notFound: true})
        let m = Object.keys(this.state || {}).reduce((a, v) => ({ ...a, [v]: undefined }), {})
        let j = { ...m, ...t, edit: this.state.edit, view: this.state.view, _id: this.props._id, notFound: false }
        this.setState(j)
    }
    render() {
        window.actionfull = this
        if (this.state.notFound) return <Error />
        return (<>
            {this.state.edit && <ActionForm _id={this.props._id} add={form => {
                return new Promise((res, rej) => {
                    let add = this.props.add
                    if (!form || typeof add !== 'function') return rej('Cannot submit')
                    this.props.add(form).then(reminder => {
                        this.initTitle()
                        res(reminder)
                    }).catch(e => rej(e))
                })
            }} close={() => this.setState({ edit: false })} />}
            <Helmet>
                <title>Action</title>
                <meta name="description" content="An Octane Multimedia film" />
            </Helmet>
            <div className="crms-title row bg-white mb-4">
                <div className="col  p-0">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                            <i className="la la-film" />
                        </span> Action</h3>
                </div>
                <div className="col p-0 text-right">
                    <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to={"/actions"}>Actions</Link></li>
                        <li className="breadcrumb-item active">Action</li>
                    </ul>
                </div>
            </div>
            <div className="content container-fluid rel">
                <Accordian open={true} header="Details" content={<>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td className="border-0">Assigned To</td>
                                <td className="border-0">{window.app.state.users?.find(a => a._id === this.state.user)?.name || this.state.user}</td>
                            </tr>
                            <tr>
                                <td className="border-0">Created</td>
                                <td className="border-0">{window.formatDate(this.state.created)}</td>
                            </tr>
                            <tr>
                                <td className="border-0">Follow Up In</td>
                                <td className="border-0"><CountDown endText={'Today'} expires={this.state.date} /></td>
                            </tr>
                            <tr>
                                <td className="border-0">Type</td>
                                <td className="border-0">{this.state.reminderType}</td>
                            </tr>
                            {this.state.associate && <tr>
                                <td className="border-0">Associated To</td>
                                <td className="border-0">{this.state.associateType} - {(() => {
                                    switch (this.state.associateType) {
                                        case 'deal': {
                                            return <TabLink to={`/deal?_id=${this.state.associate}`}><div className="squareButton">Go To Deal</div></TabLink>
                                        }
                                        case 'company': {
                                            return <TabLink to={`/company?_id=${this.state.associate}`}><div className="squareButton">Go To Company</div></TabLink>
                                        }
                                        case 'title': {
                                            return <TabLink to={`/title?_id=${this.state.associate}`}><div className="squareButton">Go To Title</div></TabLink>
                                        }
                                        case 'user': {
                                            return <TabLink to={`/profile?_id=${this.state.associate}`}><div className="squareButton">Go To Profile</div></TabLink>
                                        }
                                        default: {
                                            return <TabLink to={`/profile?_id=${this.state.associate}`}><div className="squareButton">Go To Profile</div></TabLink>
                                        }
                                    }
                                })()}</td>
                            </tr>}
                            <tr>
                                <td className="border-0">Note</td>
                                <td className="border-0">{this.state.content}</td>
                            </tr>
                        </tbody>
                    </table>
                </>} />
                <button onClick={() => this.setState({ edit: true })}>Edit</button>
                <button onClick={() => window.app.deleteReminder(this.state._id)}>Delete</button>
            </div>
        </>)
    }
}
export default FullAction




import { withRouter, Route, Switch, Link } from 'react-router-dom';
import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import Header from './header'
import LogIn from './login'
import Settings from './settings'
import Error404 from './components/pages/404'
import Profile from './components/pages/profile'
import Messages from './components/pages/messages'
import Notifications from './components/pages/notifications'
import RegisterFilm from './components/pages/register-film'
import SidebarNav from './sidebar'
import Titles from './components/pages/titles'
import Title from './components/pages/title'
import Companies from './components/pages/companies'
import FullCompany from './components/pages/fullcompany'
import Users from './components/pages/users'
import Deals from './components/pages/deals'
import ArchivedTitles from './components/pages/archived/titles'
import ArchivedCompanies from './components/pages/archived/companies'
import ArchivedUsers from './components/pages/archived/users'
import ArchivedDeals from './components/pages/archived/deals'
import ArchivedAgreements from './components/pages/archived/agreements'
import ArchivedPitches from './components/pages/archived/pitches'
import ArchivedWorkOrders from './components/pages/archived/workorders'
import ArchivedProducts from './components/pages/archived/products'
import Products from './components/pages/products'
import Product from './components/pages/product'
import Pitches from './components/pages/pitches'
import Pitch from './components/pages/pitch'
import Deal from './components/pages/deal'
import Agreement from './components/pages/agreement'
import Agreements from './components/pages/agreements'
import Actions from './components/pages/actions'
import FullAction from './components/pages/fullaction'
import Reporting from './components/reporting/main/home.js'
import TabLink from './components/helpers/tablink'
import CountDown from './components/helpers/timer'
import Table from './components/helpers/display/table'
import Spinner from './components/helpers/display/spinner'
import Payments from './components/reporting/main/payments'
import DotDot from './components/helpers/display/dotdot'
import ST from './components/helpers/domain-redirect'
import ResetPass from './resetpass'
import WorkOrders from './components/pages/workorders';
import WorkOrder from './components/pages/work-order';
import 'antd/dist/antd.css';
import "./assets/css/bootstrap.min.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/feather.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/theme-settings.css";
import "./assets/css/style.css";
class App extends Component {
    init = () => {
        return ({
            location: '/',
            queries: this.queries(),
            profilePic: '',
            messages: [],
            notifications: [],
            type: localStorage.getItem('type') || '',
            auth: localStorage.getItem('auth') || this.cookies().auth || '',
            userID: localStorage.getItem('userID') || '',
            firstName: '',
            lastName: '',
            role: '',
            users: [],
            movies: [],
            models: [],
            fields: [],
            payments: [],
            paymentFields: [],
            companies: [],
            companyList: [],
            sales: [],
            pitches: [],
            products: [],
            deals: [],
            reminders: [],
            agreements: [],
            workOrders: [],
            draftEmails: [],
            emailTemplates: [],
            archivedTitles: [],
            archivedAgreements: [],
            archivedCompanies: [],
            archivedDeals: [],
            archivedUsers: [],
            archivedDrafts: [],
            archivedProducts: [],
            archivedWorkOrders: [],
            archivedPitches: [],
            archivedEmailTemplates: [],
            archiveMode: false,
            crm: '',
            NOSOUND: localStorage.getItem('NOSOUND') ? true : false,
            gotInitial: false
        })
    }
    state = this.init()
    inited = false
    queries() {
        let p = (window.location?.search ? window.location.search.substring(1) : '').split('=')
        let ar = []
        let pageQueries = []
        for (let i = 0; i < p.length; i++) ar.push(...p[i].split('&'))
        for (let i = 0; i < ar.length; i += 2) {
            let k = ar[i]
            let v = ar[i + 1]
            if (k && v) pageQueries.push({ key: k, value: v })
        }
        let o = {}
        for (let i = 0; i < pageQueries.length; i++) o[pageQueries[i].key] = pageQueries[i].value
        return o
    }
    cookies() {
        return document.cookie?.split('; ').reduce((a, b) => {
            if (b) {
                let c = b.split('=')
                a[c[0]] = c[1]
            }
            return a
        }, {})
    }
    componentDidMount() {
        window.app = this
        if (window.opener?.app?.state) {
            this.inited = true
            this.setState(window.opener.app.state, () => {
                if (!window.ws) window.newSocket()
            })
        } else if (this.state.auth) {
            this.inited = true
            this.initial()
        }
        this.setState({ 'location': this.props.location?.pathname, queries: this.queries() })
    }
    componentDidUpdate() {
        let queries = this.queries()
        if (this.state.location !== this.props.location?.pathname) {
            this.setState({ 'location': this.props.location?.pathname, queries })
        } else if (this.state.queries?._id !== queries?._id) {
            this.setState({ queries })
        }
        if (this.state.auth && !this.inited) {
            this.inited = true
            this.initial()
        }
    }
    refresh = () => {
        this.setState(this.init(), () => {
            this.initial()
        })
    }
    async initial() {
        if (!window.ws) window.newSocket()
        let admin = window.isAdmin(this.state.type)
        if (admin) {
            this.getSales()
            this.getPayments()
        }
        await Promise.allSettled(admin ? [
            this.getTitles(),
            this.getUsers(),
            this.getCompanies(),
            this.getDeals(),
            this.getCompanyNames(),
            this.getReminders(),
            this.getAgreements(),
            this.getProducts(),
            this.getPitches(),
            this.getWorkOrders(),
            this.getDraftEmails(),
            this.getEmailTemplates()
        ] : [
            this.getTitles(),
            this.getUsers(),
            this.getCompanies(),
            this.getDeals(),
            this.getReminders(),
            this.getCompanyNames(),
            this.getAgreements(),
            this.getProducts(),
            this.getPitches(),
            this.getWorkOrders(),
            this.getDraftEmails(),
            this.getEmailTemplates()
        ]).then((proms) => {
            let [titles, users] = proms
            proms.forEach(prom => {
                if (prom.status === 'rejected') {
                    window.flash(prom.reason)
                }
            })
            if (titles.status !== 'fulfilled' || users.status !== 'fulfilled') { window.flash('Failed to load titles/users'); return this.setState({ failedApp: true }) }
            this.setState({ gotInitial: true }, () => {

            })
        }).catch(e => { window.flash(e); this.setState({ failedApp: true }) })
    }
    gotPayments = false
    gotSales = false
    setTitleData = () => {
        let items = this.state.titles.sort((a, b) => window.nameCompare(a.title, b.title))
        let movies = this.state.titles.sort((a, b) => window.nameCompare(a.title, b.title))
        let fields = this.state.titles.map(u => u ? Object.entries(u) : null).filter(u => u).reduce((a, b) => {
            for (let i = 0; i < b.length; i++) if (!a.find(u => u.name === b[i][0])) {
                let t = typeof b[i][1] && b[i][1]?.type ? b[i][1].type : typeof b[i][1]
                a.push({
                    name: b[i][0],
                    type: t
                })
            };
            return a
        }, []).filter(z => !window.filterList.includes(z.name))
        let { visible } = window.reduceVisible(movies)
        this.setState({ items, movies: visible, fields })
    }
    getTitles = () => {
        return new Promise((res, rej) => {
            this.request('/movies', 'post').then(r => {
                let { visible, archived } = window.reduceVisible(r)
                this.setState({ titles: visible, archivedTitles: archived, gotTitles: true }, this.setTitleData)
                res(visible)
            }).catch(e => { window.flash(e); this.setState({ gotTitles: true }); rej(e) })
        })
    }
    playAudio = (src) => {
        if (this.state.NOSOUND || !src) return
        let ping = document.createElement('audio')
        ping.className = 'hidden'
        document.querySelector('body').append(ping)
        ping.src = src
        ping.addEventListener('loadeddata', () => {
            ping.play()
            ping.addEventListener('ended', () => {
                ping.remove()
            })
        })
    }
    playPing = () => {
        this.playAudio('https://octanetv.com/static/ping.wav')
    }
    playNotif = () => {
        this.playAudio('https://octanetv.com/static/notif.wav')
    }
    playUpdate = () => {
        this.playAudio('https://octanetv.com/static/update.mp3')
    }
    handleSocket = (type, data, error) => {
        if (!type || !data) return
        if (error) return window.flash(error)
        switch (type) {
            case 'notifications': {
                this.setState({ notifications: data })
                break
            }
            case 'messages': {
                this.setState({ messages: data })
                break
            }
            case 'message': {
                if (this.state.messages.find(u => u._id === data._id)) {
                    this.setState({
                        messages: [...this.state.messages].map(u => {
                            if (u._id === data._id) return data
                            return u
                        })
                    })
                } else {
                    if (data.to === this.state.userID) {
                        this.playPing()
                    }
                    this.setState({ messages: [...this.state.messages, data] })
                }
                break
            }
            case 'user-info': {
                this.setState({ profilePic: data.profilePic, firstName: data.firstname, lastName: data.lastName, email: data.email, crm: data.crm, name: data.firstName + ' ' + data.lastName?.[0] + '.', emailVerified: data.emailVerified, phone: data.phone, role: data.role, mailsync: data.mailsync, lastMailSync: data.lastMailSync })
                break
            }
            case 'notification': {
                if (this.state.notifications.find(u => u._id === data._id)) {
                    this.setState({
                        notifications: [...this.state.notifications].map(u => {
                            if (u._id === data._id) return data
                            return u
                        })
                    })
                } else {
                    this.setState({ notifications: [...this.state.notifications, data] }, () => this.playNotif())
                }
                break
            }
            case 'title': {
                this.setState({
                    titles: this.state.titles.find(a => a._id === data._id) ? [...this.state.titles].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.titles, data]
                }, () => this.playUpdate())
                break
            }
            case 'company': {
                this.setState({
                    companies: this.state.companies.find(a => a._id === data._id) ? [...this.state.companies].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.companies, data]
                }, () => this.playUpdate())
                if (!this.state.companyList.find(a => a._id === data._id)) this.setState({ companyList: [...this.state.companyList, { _id: data._id, name: data.name }] })
                break
            }
            case 'user': {
                this.setState({
                    users: this.state.users.find(a => a._id === data._id) ? [...this.state.users].map(u => {
                        if (u._id === data._id) return { ...data, name: `${data.firstName || ''}${data.lastName ? ` ${data.lastName}` : ''}`.trim() }
                        return u
                    }) : [...this.state.users, { ...data, name: `${data.firstName || ''}${data.lastName ? ` ${data.lastName}` : ''}`.trim() }]
                }, () => this.playUpdate())

                break
            }
            case 'action': {
                this.setState({
                    reminders: this.state.reminders.find(a => a._id === data._id) ? [...this.state.reminders].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.reminders, data]
                }, () => this.playUpdate())
                break
            }
            case 'deal': {
                this.setState({
                    deals: this.state.deals.find(a => a._id === data._id) ? [...this.state.deals].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.deals, data]
                }, () => this.playUpdate())

                break
            }
            case 'agreement': {
                this.setState({
                    agreements: this.state.agreements.find(a => a._id === data._id) ? [...this.state.agreements].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.agreements, data]
                }, () => this.playUpdate())

                break
            }
            case 'product': {
                this.setState({
                    products: this.state.products.find(a => a._id === data._id) ? [...this.state.products].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.products, data]
                }, () => this.playUpdate())

                break
            }
            case 'pitch': {
                this.setState({
                    pitches: this.state.pitches.find(a => a._id === data._id) ? [...this.state.pitches].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.pitches, data]
                }, () => this.playUpdate())

                break
            }
            case 'work-order': {
                this.setState({
                    workOrders: this.state.workOrders.find(a => a._id === data._id) ? [...this.state.workOrders].map(u => {
                        if (u._id === data._id) return data
                        return u
                    }) : [...this.state.workOrders, data]
                }, () => this.playUpdate())

                break
            }
            case 'title-removed': {
                this.setState({
                    titles: [...this.state.titles].filter(u => u._id !== data._id),
                    archivedTitles: [...this.state.archivedTitles, this.state.titles?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())
                break
            }
            case 'company-removed': {
                this.setState({
                    companies: [...this.state.companies].filter(u => u._id !== data._id),
                    archivedCompanies: [...this.state.archivedCompanies, this.state.companies?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())
                break
            }
            case 'user-removed': {
                this.setState({
                    users: [...this.state.users].filter(u => u._id !== data._id),
                    archivedUsers: [...this.state.archivedUsers, this.state.users?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'deal-removed': {
                this.setState({
                    deals: [...this.state.deals].filter(u => u._id !== data._id),
                    archivedDeals: [...this.state.archivedDeals, this.state.deals?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'agreement-removed': {
                this.setState({
                    agreements: [...this.state.agreements].filter(u => u._id !== data._id),
                    archivedAgreements: [...this.state.archivedAgreements, this.state.agreements?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'pitch-removed': {
                this.setState({
                    pitches: [...this.state.pitches].filter(u => u._id !== data._id),
                    archivedPitches: [...this.state.archivedPitches, this.state.pitches?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'work-order-removed': {
                this.setState({
                    workOrders: [...this.state.workOrders].filter(u => u._id !== data._id),
                    archivedWorkOrders: [...this.state.archivedWorkOrders, this.state.workOrders?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'product-removed': {
                this.setState({
                    products: [...this.state.products].filter(u => u._id !== data._id),
                    archivedProducts: [...this.state.archivedProducts, this.state.products?.find(a => a._id === data._id)].filter(a => a)
                }, () => this.playUpdate())

                break
            }
            case 'title-deleted': {
                this.setState({
                    titles: [...this.state.titles].filter(u => u._id !== data._id),
                    archivedTitles: [...this.state.archivedTitles].filter(a => a._id !== data._id)
                }, () => this.playUpdate())
                break
            }
            case 'company-deleted': {
                this.setState({
                    companies: [...this.state.companies].filter(u => u._id !== data._id),
                    archivedCompanies: [...this.state.archivedCompanies].filter(a => a._id !== data._id)
                }, () => this.playUpdate())
                break
            }
            case 'user-deleted': {
                this.setState({
                    users: [...this.state.users].filter(u => u._id !== data._id),
                    archivedUsers: [...this.state.archivedUsers].filter(a => a._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'deal-deleted': {
                this.setState({
                    deals: [...this.state.deals].filter(u => u._id !== data._id),
                    archivedDeals: [...this.state.archivedDeals].filter(a => a._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'agreement-deleted': {
                this.setState({
                    agreements: [...this.state.agreements].filter(u => u._id !== data._id),
                    archivedAgreements: [...this.state.archivedAgreements].filter(a => a._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'pitch-deleted': {
                this.setState({
                    pitches: [...this.state.pitches].filter(u => u._id !== data._id),
                    archivedPitches: [...this.state.archivedPitches].filter(a => a._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'work-order-deleted': {
                this.setState({
                    workOrders: [...this.state.workOrders].filter(u => u._id !== data._id),
                    archivedWorkOrders: [...this.state.archivedWorkOrders].filter(a => a._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'product-deleted': {
                this.setState({
                    products: [...this.state.products].filter(u => u._id !== data._id),
                    archivedProducts: [...this.state.archivedProducts].filter(a => a._id !== data._id)
                }, () => this.playUpdate())
                break
            }
            case 'action-removed': {
                this.setState({
                    reminders: [...this.state.reminders].filter(u => u._id !== data._id)
                }, () => this.playUpdate())

                break
            }
            case 'payment': {
                if (data instanceof Array) {
                    let d = [...this.state.payments]
                    for (let i = 0; i < d.length; i++) {
                        for (let z = 0; z < data.length; z++) {
                            if (d[i]._id === data[z]._id) {
                                d[i] = data.splice(z, 1)[0]
                                z = Infinity
                            }
                        }
                    }
                    d.push(...data)
                    this.setState({ payments: d }, () => this.playUpdate())
                    let c = window.title || window.titlec
                    if (c) {
                        let cur = c.state?._id
                        if (cur) {
                            let updates = data.filter(a => a.title === cur)
                            let mas = c.state.payments?.map(u => {
                                let c = updates.findIndex(a => a._id === u._id)
                                return updates.splice(c, 1)[0] || u
                            }) || []
                            c.setState({ payments: [...mas, ...updates] })
                        }
                    }
                } else {
                    this.setState({
                        payments: this.state.payments.find(a => a._id === data._id) ? [...this.state.payments].map(u => {
                            if (u._id === data._id) return data
                            return u
                        }) : [...this.state.payments, data]
                    }, () => this.playUpdate())

                }
                let c = window.title || window.titlec
                if (c) {
                    let cur = c.state?._id
                    if (cur && data.title === cur) {
                        c.setState({ payments: c.state.payments?.find(a => a._id === data._id) ? [...c.state.payments].map(u => {
                            if (u._id === data._id) return data
                            return u
                        }) :[...c.state.payments, data] })
                    }
                }
                break
            }
            case 'sale': {
                if (data instanceof Array) {
                    let d = [...this.state.sales]
                    for (let i = 0; i < d.length; i++) {
                        for (let z = 0; z < data.length; z++) {
                            if (d[i]._id === data[z]._id) {
                                d[i] = data.splice(z, 1)[0]
                                z = Infinity
                            }
                        }
                    }
                    d.push(...data)
                    this.setState({ sales: d }, () => this.playUpdate())
                    let c = window.title || window.titlec
                    if (c) {
                        let cur = c.state?._id
                        if (cur) {
                            let updates = data.filter(a => a.title === cur)
                            let mas = c.state.sales?.map(u => {
                                let c = updates.findIndex(a => a._id === u._id)
                                return updates.splice(c, 1)[0] || u
                            }) || []
                            c.setState({ sales: [...mas, ...updates] })
                        }
                    }
                } else {
                    this.setState({
                        sales: this.state.sales.find(a => a._id === data._id) ? [...this.state.sales].map(u => {
                            if (u._id === data._id) return data
                            return u
                        }) : [...this.state.sales, data]
                    }, () => this.playUpdate())
                    let c = window.title || window.titlec
                    if (c) {
                        let cur = c.state?._id
                        if (cur && data.title === cur) {
                            c.setState({ sales: c.state.sales?.find(a => a._id === data._id) ? [...c.state.sales].map(u => {
                                if (u._id === data._id) return data
                                return u
                            }) :[...c.state.sales, data] })
                        }
                    }
                }
                break
            }
            case 'payment-removed': {
                this.setState({ payments: [...this.state.payments].filter(u => u._id !== data._id) }, () => this.playUpdate())
                let c = window.title || window.titlec
                if (c) {
                    let cur = c.state?._id
                    if (cur && data.title === cur) {
                        c.setState({ payments: [...c.state.payments]?.filter(a => a._id !== data._id) })
                    }
                }
                break
            }
            case 'sale-removed': {
                this.setState({ sales: [...this.state.sales].filter(u => u._id !== data._id) }, () => this.playUpdate())
                let c = window.title || window.titlec
                if (c) {
                    let cur = c.state?._id
                    if (cur && data.title === cur) {
                        c.setState({ sales: [...c.state.sales]?.filter(a => a._id !== data._id) })
                    }
                }
                break
            }
            case 'sale-deleted': {
                this.setState({ sales: [...this.state.sales].filter(u => u._id !== data._id) }, () => this.playUpdate())
                let c = window.title || window.titlec
                if (c) {
                    let cur = c.state?._id
                    if (cur && data.title === cur) {
                        c.setState({ sales: [...c.state.sales]?.filter(a => a._id !== data._id) })
                    }
                }
                break
            }
            case 'payment-deleted': {
                this.setState({ payments: [...this.state.payments].filter(u => u._id !== data._id) }, () => this.playUpdate())
                let c = window.title || window.titlec
                if (c) {
                    let cur = c.state?._id
                    if (cur && data.title === cur) {
                        c.setState({ payments: [...c.state.payments]?.filter(a => a._id !== data._id) })
                    }
                }
                break
            }
            default: return console.log('Invalid type', type)
        }
    }
    request = (page, method, data) => {
        return new Promise((res, rej) => {
            let options = { method: typeof method === 'string' ? method.toUpperCase() : 'GET', headers: { 'Content-Type': 'application/json', userid: this.state.userID, authorization: this.state.auth } }
            if (data) options.body = JSON.stringify(data)
            fetch(window.API + page, options).then(r => r.json()).then(r => {
                if (r.error) return rej(r.message || JSON.stringify(r))
                res(r)
            }).catch(e => rej(e))
        })
    }
    getUserInfo = () => {
        let r = this.state.users.find(a => a._id === this.state.userID)
        if (r) this.setState({ crm: r.crm, profilePic: r.profilePic, firstName: r.firstname, lastName: r.lastName, email: r.email, crm: r.crm, name: r.firstName + ' ' + r.lastName?.[0] + '.', emailVerified: r.emailVerified, phone: r.phone, role: r.role, mailsync: r.mailsync, lastMailSync: r.lastMailSync })
    }
    getCompanyNames = () => {
        return new Promise((res, rej) => {
            this.request('/company-list', 'post').then(r => {
                this.setState({ companyList: r.companies })
                res()
            }).catch(e => rej(e))
        })
    }
    getCompanies = () => {
        return new Promise((res, rej) => {
            this.request('/companies', 'post').then(resp => {
                let { archived, visible } = window.reduceVisible(resp.companies?.filter(u => u.crm))
                this.setState({ companies: visible, archivedCompanies: archived })
                return res()
            }).catch(e => rej(e))
        })
    }
    getDeals = () => {
        return new Promise((res, rej) => {
            this.request('/deals', 'post').then(r => {
                let { visible, archived } = window.reduceVisible(r.deals)
                this.setState({ deals: visible, archivedDeals: archived })
                res(visible)
            }).catch(e => { window.flash(e); rej(e) })
        })
    }
    getAgreement = _id => {
        return new Promise((res, rej) => {
            let agreement = window.app.state.agreements?.find(u => u._id === _id)
            if (agreement) {
                res(agreement)
            } else {
                window.app.request('/get-agreement', 'post', { _id }).then(result => {
                    res(result.agreement)
                }).catch(e => {
                    rej(e)
                })
            }
        })
    }
    getPaymentInfo = _id => {
        return new Promise((res, rej) => {
            window.app.request('/get-payment-information', 'post', { _id }).then(result => {
                res(result.paymentInfo)
            }).catch(e => {
                rej(e)
            })
        })
    }
    getAgreements = () => {
        return new Promise((res, rej) => {
            this.request('/agreements', 'post').then(r => {
                let { archived, visible } = window.reduceVisible(r.agreements)
                this.setState({ agreements: visible, archivedAgreements: archived })
                res(visible)
            }).catch(e => { window.flash(e); rej(e) })
        })
    }
    viewNotification = _id => {
        this.request('/view-notification', 'post', { _id }).then(r => {
            this.setState({
                notifications: [...this.state.notifications].map(u => {
                    if (u._id === _id) return ({ ...u, viewed: true })
                    return u
                })
            })
        }).catch(e => window.flash(e))
    }
    getTitleEmails = _id => {
        return new Promise((res, rej) => {
            this.request('/emails-with-title', 'post', { title: _id }).then(({ emails = [] }) => res(emails)).catch(e => rej(e))
        })
    }
    getCompanyEmails = _id => {
        return new Promise((res, rej) => {
            this.request('/emails-with-company', 'post', { company: _id }).then(({ emails = [] }) => res(emails)).catch(e => rej(e))
        })
    }
    getUserEmails = _id => {
        return new Promise((res, rej) => {
            this.request('/emails-with-user', 'post', { user: _id }).then(({ emails = [] }) => res(emails)).catch(e => rej(e))
        })
    }
    removeNotification = _id => {
        this.request('/delete-notification', 'post', { _id }).then(r => {
            this.setState({ notifications: [...this.state.notifications].filter(u => u._id !== _id) })
        }).catch(e => window.flash(e))
    }
    addDeal = data => {
        return new Promise((res, rej) => {
            if (!data) return rej('Missing Data')
            let { files, fields } = data
            if (!files && !fields) return rej('Missing Data')
            let fd = new FormData()
            if (files?.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i]?.files?.length > 0) {
                        for (let z = 0; z < files[i].files.length; z++) {
                            fd.append(files[i].name, files[i].files[z])
                        }
                    }
                }
            }
            fd.append('deal', JSON.stringify(fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) { console.log(r.message || JSON.stringify(r)); return rej('Error updating deal') }
                        this.setState({
                            deals: this.state.deals.find(a => a._id === r.deal?._id) ? [...this.state.deals].map(u => {
                                if (u._id === r.deal._id) { return r.deal }
                                return u
                            }) : [...this.state.deals, r.deal]
                        }, () => { res(r.deal) })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/deal', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    addPitch = data => {
        return new Promise((res, rej) => {
            if (!data) return rej('Missing Data')
            let { files, fields } = data
            if (!files && !fields) return rej('Missing Data')
            let fd = new FormData()
            if (files?.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i]?.files?.length > 0) {
                        for (let z = 0; z < files[i].files.length; z++) {
                            fd.append(files[i].name, files[i].files[z])
                        }
                    }
                }
            }
            fd.append('pitch', JSON.stringify(fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) { console.log(r.message || JSON.stringify(r)); return rej('Error updating deal') }
                        this.setState({
                            pitches: this.state.pitches.find(a => a._id === r.pitch?._id) ? [...this.state.pitches].map(u => {
                                if (u._id === r.pitch._id) { return r.pitch }
                                return u
                            }) : [...this.state.pitches, r.pitch]
                        }, () => { res(r.pitch) })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/pitch', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    addProduct = data => {
        return new Promise((res, rej) => {
            if (!data) return rej('Missing Data')
            let { files, fields } = data
            if (!files && !fields) return rej('Missing Data')
            let fd = new FormData()
            if (files?.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i]?.files?.length > 0) {
                        for (let z = 0; z < files[i].files.length; z++) {
                            fd.append(files[i].name, files[i].files[z])
                        }
                    }
                }
            }
            fd.append('product', JSON.stringify(fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) { console.log(r.message || JSON.stringify(r)); return rej('Error updating deal') }
                        this.setState({
                            products: this.state.products.find(a => a._id === r.product?._id) ? [...this.state.products].map(u => {
                                if (u._id === r.product._id) { return r.product }
                                return u
                            }) : [...this.state.products, r.product]
                        }, () => { res(r.product) })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/product', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    saveTemplate = template => {
        return new Promise((res, rej) => {
            this.request('/email-template', 'post', { template }).then(result => {
                if (result.template) {
                    this.setState({
                        emailTemplates: this.state.emailTemplates.find(a => a._id === result.template?._id) ? [...this.state.emailTemplates].map(u => {
                            if (u._id === result.template._id) return result.template
                            return u
                        }) : [...this.state.emailTemplates, result.template]
                    })
                }
                res(result.tempalte)
            }).catch(e => {
                rej(e)
            })
        })
    }
    deleteTemplate = _id => {
        if (!_id) return window.flash('Missing _id')
        this.request('/delete-email-template', 'post', { _id }).then(result => {
            this.setState({ emailTemplates: [...this.state.emailTemplates].filter(u => u._id !== _id) })
        }).catch(e => {
            window.flash(e)
        })
    }
    removeDeal = _id => {
        this.request('/deal', 'delete', { _id }).then(r => {
            this.setState({ deals: [...this.state.deals].filter(u => u._id !== _id) })
        }).catch(e => window.flash(e))
    }
    dealForm = form => {
        return new Promise((res, rej) => {
            this.addDeal(window.reduceForm(form)).then(deal => res(deal)).catch(e => rej(e))
        })
    }
    productForm = form => {
        return new Promise((res, rej) => {
            this.addProduct(window.reduceForm(form)).then(deal => res(deal)).catch(e => rej(e))
        })
    }
    pitchForm = form => {
        return new Promise((res, rej) => {
            this.addPitch(window.reduceForm(form)).then(deal => res(deal)).catch(e => rej(e))
        })
    }
    workOrderForm = form => {
        return new Promise((res, rej) => {
            this.addWorkOrder(window.reduceForm(form)).then(deal => res(deal)).catch(e => rej(e))
        })
    }
    addWorkOrder = data => {
        return new Promise((res, rej) => {
            if (!data) return rej('Missing Data')
            let { files, fields } = data
            if (!files && !fields) return rej('Missing Data')
            let fd = new FormData()
            if (files?.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i]?.files?.length > 0) {
                        for (let z = 0; z < files[i].files.length; z++) {
                            fd.append(files[i].name, files[i].files[z])
                        }
                    }
                }
            }
            fd.append('workOrder', JSON.stringify(fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) { console.log(r.message || JSON.stringify(r)); return rej('Error updating work order') }
                        this.setState({
                            workOrders: this.state.workOrders.find(a => a._id === r.workOrder?._id) ? [...this.state.workOrders].map(u => {
                                if (u._id === r.workOrder._id) { return r.workOrder }
                                return u
                            }) : [...this.state.workOrders, r.workOrder]
                        }, () => { res(r.workOrder) })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/work-order', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    addAgreement = data => {
        return new Promise((res, rej) => {
            if (!data) return rej('Missing Data')
            let { files, fields } = data
            if (!files && !fields) return rej('Missing Data')
            let fd = new FormData()
            if (files?.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i]?.files?.length > 0) {
                        for (let z = 0; z < files[i].files.length; z++) {
                            fd.append(files[i].name, files[i].files[z])
                        }
                    }
                }
            }
            fd.append('agreement', JSON.stringify(fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) { console.log(r.message || JSON.stringify(r)); return rej('Error updating agreement') }
                        this.setState({
                            agreements: this.state.agreements.find(a => a._id === r.agreement?._id) ? [...this.state.agreements].map(u => {
                                if (u._id === r.agreement._id) { return r.agreement }
                                return u
                            }) : [...this.state.agreements, r.agreement]
                        }, () => { return res(r.agreement); })
                    } catch (e) {
                        return rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/agreement', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    agreementForm = form => {
        return new Promise((res, rej) => {
            this.addAgreement(window.reduceForm(form)).then(r => res(r)).catch(e => rej(e))
        })
    }
    companyForm = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.addCompany(data).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    reminderForm = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            if (!data?.fields?.date) {
                return rej('Invalid date')
            }
            if (new Date(data.fields.date).getTime() - new Date().getTime() < 1000 * 60 * 60 * 18 * -1) {
                return rej('Date has already passed.')
            }
            this.addReminder({ ...data, fields: { ...data.fields, date: window.shiftDate(data.fields.date) } }).then(r => res(r)).catch(e => rej(e))
        })

    }
    getUsers = () => {
        return new Promise((res, rej) => {
            this.request('/users', 'post').then(r => {
                let { visible, archived } = window.reduceVisible(r.users.map(u => ({ ...u, name: `${u.firstName} ${u.lastName}` })))
                this.setState({ users: visible, archivedUsers: archived }, () => this.getUserInfo())
                res(visible)
            }).catch(e => { window.flash(e); rej(e) })
        })
    }
    startMailSync = () => {
        window.app.request('/start-syncing-mail', 'post', { user: this.state._id }).then(result => {
            this.setState({ mailsync: true })
            window.flash('Your Email is Syncing')
        }).catch(e => {
            console.log(e)
            window.flash('Your Email is Syncing')
        })
    }
    stopMailSync = () => {
        window.app.request('/stop-syncing-mail', 'post', { user: this.state._id }).then(result => {
            this.setState({ mailsync: false })
            window.flash('Email has stopped syncing')
        }).catch(e => {
            console.log(e)
            window.flash('Failed to stop syncing mail')
        })
    }
    addCompany = data => {
        return new Promise((res, rej) => {
            let fd = new FormData()
            if (data.files?.length > 0) {
                for (let i = 0; i < data.files.length; i++) {
                    if (data.files[i]?.files?.length > 0) {
                        for (let z = 0; z < data.files[i].files.length; z++) {
                            fd.append(data.files[i].name, data.files[i].files[z])
                        }
                    }
                }
            }
            fd.append('company', JSON.stringify(data.fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) return rej('Error updating company')
                        this.setState({
                            companies: this.state.companies.find(a => a._id === r.company?._id) ? [...this.state.companies].map(u => {
                                if (u._id === r.company._id) { return r.company }
                                return u
                            }) : [...this.state.companies, r.company]
                        }, () => {
                            res(r.company);
                        })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/company', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    addReminder = data => {
        return new Promise((res, rej) => {
            let { fields } = data
            this.request('/reminder', 'post', { reminder: { ...fields } }).then(r => {
                this.setState({
                    reminders: this.state.reminders.find(a => a._id === r.reminder?._id) ? [...this.state.reminders].map(u => {
                        if (u._id === r.reminder._id) { return r.reminder }
                        return u
                    }) : [...this.state.reminders, r.reminder]
                }, () => { res(r.reminder) })
            }).catch(e => rej(e))
        })
    }
    titleForm = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.addTitle(data).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    getEmail = (email) => {
        return new Promise((res, rej) => {
            this.request('/get-email', 'post', { _id: email }).then(r => {
                let { email } = r
                res(email)
            }).catch(e => rej(e))
        })
    }
    savePayment = data => {
        return new Promise((res, rej) => {
            window.app.request('/payment', 'post', { payment: data }).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    saveSale = data => {
        return new Promise((res, rej) => {
            window.app.request('/sale', 'post', { sale: data }).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    deletePayment = _id => {
        return new Promise((res, rej) => {
            window.app.request('/remove-payment', 'post', { _id }).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    deleteSale = _id => {
        return new Promise((res, rej) => {
            window.app.request('/remove-sale', 'post', { _id }).then(r => res(r)).catch(e => rej(e))
        })
    }
    paymentForm = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.savePayment(data.fields).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    saleForm = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.saveSale(data.fields).then(r => {
                res(r)
            }).catch(e => rej(e))
        })
    }
    addTitle = data => {
        return new Promise((res, rej) => {
            let fd = new FormData()
            if (data.files) for (let i = 0; i < data.files.length; i++) for (let z = 0; z < data.files[i].files.length; z++) fd.append(data.files[i].name, data.files[i].files[z])
            fd.append('title', JSON.stringify(data.fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) return rej('Error updating title')
                        this.setState({
                            titles: this.state.titles.find(a => a._id === r.title?._id) ? [...this.state.titles].map(u => {
                                if (u._id === r.title._id) { return r.title }
                                return u
                            }) : [...this.state.titles, r.title]
                        }, () => { res(r.title); })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/title', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    editCompany = e => {
        e.preventDefault()
        let form = e.target
        let data = window.reduceForm(form)
        let fd = new FormData()
        fd.append('company', JSON.stringify(data.fields))
        let specSheet = data.files?.find(a => a.name === 'deliverySpecsSheet')?.files[0]
        if (specSheet) fd.append('deliverySpecsSheet', specSheet)
        let that = new XMLHttpRequest()
        that.onabort = e => window.flash(e)
        that.onerror = e => window.flash(e)
        that.onreadystatechange = () => {
            if (that.readyState === 4) {
                try {
                    let r = JSON.parse(that.responseText)
                    this.setState({
                        companies: [...this.state.companies].map(u => {
                            if (u._id === r.company._id) return r.company
                            return u
                        })
                    })
                } catch (e) {
                    window.flash(e)
                }
            }
        }
        that.open('POST', window.API + '/company', true)
        that.setRequestHeader('userid', this.state.userID)
        that.setRequestHeader('authorization', this.state.auth)
        that.send(fd)
    }
    sendEmail = (state) => {
        return new Promise((res, rej) => {
            let validateMessage = m => {
                if (m.subject?.length < 1) return rej('Invalid Subject')
                if (m.to?.length < 1) return rej('Invalid to recipients')
                if (m.from?.length < 1) return rej('Invalid from address')
            }
            validateMessage(state)
            let htmlContent = window.createHTML(state.message), textContent = JSON.parse(state.message).blocks.map(u => u.text).join(' ')
            if (state.appendContent) {
                htmlContent += `<p><br></p>
                <p></p>
                <p></p>
                <p>---</p>
                <p><br></p>`
                htmlContent += window.createHTML(state.appendContent)
                textContent += `
                
                
                ---
                `
                textContent += JSON.parse(state.appendContent).blocks.map(u => u.text).join(' ')
            }
            this.request('/send-email', 'post', { htmlContent, textContent, attachments: state.attachments, _id: state._id, to: state.to, from: state.from, subject: state.subject, cc: state.cc, labels: state.labels, bcc: state.bcc, sendAt: state.sendAt, thread: state.thread }).then(r => {
                if (state._id) {
                    this.setState({ draftEmails: [...this.state.draftEmails].filter(u => u._id !== state._id) })
                }
                return res(r)
            }).catch(e => {
                return rej(e)
            })
        })
    }
    handleDraft = (state) => {
        return new Promise((res, rej) => {
            let validateMessage = m => {
                if (m.subject?.length < 1) return rej('Invalid Subject')
                if (m.to?.length < 1) return rej('Invalid to recipients')
                if (m.from?.length < 1) return rej('Invalid from address')
            }
            validateMessage(state)
            this.request('/send-email', 'post', { to: state.to, from: state.from, subject: state.subject, message: state.message, appendContent: state.appendContent, cc: state.cc, bcc: state.bcc, attachments: state.attachments, labels: state.labels, draft: state._id || true, thread: state.thread }).then(r => {
                if (r.draft) this.setState({
                    draftEmails: this.state.draftEmails ? this.state.draftEmails.find(a => a._id === r.draft._id) ? [...this.state.draftEmails].map(u => {
                        if (u._id === r.draft._id) return r.draft
                        return u
                    }) : [...this.state.draftEmails, r.draft] : [r.draft]
                })
                return res(r)
            }).catch(e => {
                return rej(e)
            })
        })
    }
    handleUser = data => {
        return new Promise((res, rej) => {
            let fd = new FormData()
            if (data.files) for (let i = 0; i < data.files.length; i++) for (let z = 0; z < data.files[i].files?.length || 0; z++) fd.append(data.files[i].name, data.files[i].files[z])
            fd.append('user', JSON.stringify(data.fields))
            let that = new XMLHttpRequest()
            that.onabort = e => rej(e)
            that.onerror = e => rej(e)
            that.onreadystatechange = () => {
                if (that.readyState === 4) {
                    try {
                        let r = JSON.parse(that.responseText)
                        if (r.error) return rej(r.message || JSON.stringify(r))
                        this.setState({
                            users: this.state.users.find(a => a._id === r.user._id) ? [...this.state.users].map(u => {
                                if (u._id === r.user._id) return ({ ...r.user, name: `${r.user.firstName} ${r.user.lastName}` })
                                return u
                            }) : [...this.state.users, ({ ...r.user, name: `${r.user.firstName} ${r.user.lastName}` })]
                        }, () => {
                            res({ ...r.user, name: `${r.user.firstName} ${r.user.lastName}` })
                        })
                    } catch (e) {
                        rej(e)
                    }
                }
            }
            that.open('POST', window.API + '/user', true)
            that.setRequestHeader('userid', this.state.userID)
            that.setRequestHeader('authorization', this.state.auth)
            that.send(fd)
        })
    }
    getDraftEmails = () => {
        return new Promise((res, rej) => {
            this.request('/draft-emails', 'post').then(r => {
                let { visible, archived } = window.reduceVisible(r.drafts)
                this.setState({ draftEmails: visible, archivedDrafts: archived })
                res(visible)
            }).catch(e => { window.flash(e); rej(e) })
        })
    }
    getEmailTemplates = async () => {
        return new Promise((res, rej) => {
            this.request('/email-templates', 'post').then(r => {
                let { visible, archived } = window.reduceVisible(r.templates)
                this.setState({ emailTemplates: visible, archivedEmailTemplates: archived })
                res(visible)
            }).catch(e => { window.flash(e); rej(e) })
        })
    }
    addContact = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'contact' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addVendor = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'vendor' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addProspect = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'prospect' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addCustomer = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'customer' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addClient = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'client' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addLead = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            this.handleUser({ ...data, fields: { ...data.fields, type: 'lead' } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    addUser = form => {
        return new Promise((res, rej) => {
            let data = window.reduceForm(form)
            let type = data.fields.type
            if (!type || !window.isInternal(type)) return rej('Invalid user type')
            if (!window.isSuper(window.app.state.type)) return rej('Not authorized to make that type of request')
            this.handleUser({ ...data, fields: { ...data.fields } }).then(r => res(r)).catch(e => rej(e))
        })
    }
    editUser = d => new Promise((res, rej) => this.handleUser({ fields: d }).then(r => res(r)).catch(e => rej(e)))
    uploadProfileImage = (e) => {
        e.preventDefault()
        let form = e.target
        let data = new FormData(form)
        data.append('image', form['image'].files[0])
        let that = new XMLHttpRequest()
        that.onabort = (e) => window.flash(e)
        that.onerror = (e) => window.flash(e)
        that.onreadystatechange = () => {
            if (that.readyState === 4) {
                let response = JSON.parse(that.responseText)
                if (response.error) { return window.flash(response.message || JSON.stringify(response)) }
                window.app.setState({ profilePic: response.profilePic }, () => { window.flash('Data Saved!') })
            }
        }
        that.open('POST', window.API + "/upload-profile-picture", true)
        that.setRequestHeader('authorization', window.app.state.auth)
        that.setRequestHeader('userid', window.app.state.userID)
        that.send(data)
    }
    getReminders = async () => {
        return await this.request('/reminders', 'post').then(r => {
            this.setState({ reminders: r.reminders })
        }).catch(e => window.flash(e))
    }
    getPayments() {
        return new Promise((res, rej) => {
            this.gotPayments = true
            window.app.request('/payments', 'post').then(info => {
                let { payments } = info
                this.setState({
                    payments: payments, paymentFields: payments.map(u => Object.entries(u)).reduce((a, b) => {
                        for (let i = 0; i < b.length; i++) if (!a.find(u => u.name === b[i][0])) {
                            let val = {
                                name: b[i][0],
                                type: b[i][1] && b[i][1].type ? b[i][1].type : typeof b[i][1]
                            }
                            a.push(val)
                        }
                        return a
                    }, []).filter(z => !window.paymentFilters?.includes(z.name)), gotPayments: true
                }, () => {
                    res()
                })
            }).catch(e => { rej(e) })
        })
    }
    getSales = () => {
        return new Promise((res, rej) => {
            this.gotSales = true
            this.request('/sales', 'post').then(resp => {
                this.setState({ sales: resp.sales, gotSales: true })
                return res()
            }).catch(e => rej(e))
        })
    }
    getPitches = () => {
        return new Promise((res, rej) => {
            this.request('/pitches', 'post').then(resp => {
                let { visible, archived } = window.reduceVisible(resp.pitches)
                this.setState({ pitches: visible, archivedPitches: archived })
                return res(visible)
            }).catch(e => rej(e))
        })
    }
    getWorkOrders = () => {
        return new Promise((res, rej) => {
            this.request('/work-orders', 'post').then(resp => {
                let { visible, archived } = window.reduceVisible(resp.workOrders)
                this.setState({ workOrders: visible, archivedWorkOrders: archived })
                return res(visible)
            }).catch(e => rej(e))
        })
    }
    getProducts = () => {
        return new Promise((res, rej) => {
            this.request('/products', 'post').then(resp => {
                let { visible, archived } = window.reduceVisible(resp.products)
                this.setState({ products: visible, archivedProducts: archived })
                return res(visible)
            }).catch(e => rej(e))
        })
    }
    getNotes(list) {
        return new Promise((res, rej) => {
            window.app.request('/notes', 'post', { notes: list }).then(resp => {
                return res(resp.notes)
            }).catch(e => rej(e))
        })
    }
    getModels() {
        return new Promise((res, rej) => {
            window.app.request('/models').then(data => {
                this.setState({ models: data.models })
                res(data.models)
            }).catch(e => {
                window.app.logOut();
                window.flash(e)
                rej(e)
            })
        })
    }
    addField = (name, type) => {
        if (!this.state.fields.find(u => u.name === name)) {
            this.setState({ fields: [...this.state.fields, { name, type }] })
        } else {
            window.flash('Field already exists')
        }
    }
    deleteUser = _id => {
        this.request('/delete-user', 'post', { _id }).then(() => {
            this.setState({ users: [...this.state.users].filter(u => u._id !== _id), archivedUsers: [...this.state.archivedUsers, this.state.users?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('User Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteTitle = _id => {
        this.request('/delete-title', 'post', { _id }).then(() => {
            this.setState({ titles: [...this.state.titles].filter(u => u._id !== _id), archivedTitles: [...this.state.archivedTitles, this.state.titles?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Title Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteDeal = _id => {
        this.request('/delete-deal', 'post', { _id }).then(() => {
            this.setState({ deals: [...this.state.deals].filter(u => u._id !== _id), archivedDeals: [...this.state.archivedDeals, this.state.deals?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Deal Removed')
            })
        }).catch(e => window.flash(e))
    }
    deletePitch = _id => {
        this.request('/delete-pitch', 'post', { _id }).then(() => {
            this.setState({ pitches: [...this.state.pitches].filter(u => u._id !== _id), archivedPitches: [...this.state.archivedPitches, this.state.pitches?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Pitch Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteWorkOrder = _id => {
        this.request('/delete-work-order', 'post', { _id }).then(() => {
            this.setState({ workOrders: [...this.state.workOrders].filter(u => u._id !== _id), archivedWorkOrders: [...this.state.archivedWorkOrders, this.state.workOrders?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Work Order Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteProduct = _id => {
        this.request('/delete-product', 'post', { _id }).then(() => {
            this.setState({ products: [...this.state.products].filter(u => u._id !== _id), archivedProducts: [...this.state.archivedProducts, this.state.products?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Product Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteCompany = _id => {
        this.request('/delete-company', 'post', { _id }).then(() => {
            this.setState({ companies: [...this.state.companies].filter(u => u._id !== _id), archivedCompanies: [...this.state.archivedCompanies, this.state.companies?.find(a => a._id === _id)].filter(u => u) }, () => {
                window.flash('Company Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteAgreement = _id => {
        this.request('/delete-agreement', 'post', { _id }).then(r => {
            this.setState({ agreements: [...this.state.agreements].filter(u => u._id !== _id), archivedAgreements: [...this.state.archivedAgreements, this.state.agreements?.find(a => a._id === _id)].filter(u => u) })
        }).catch(e => window.flash(e))
    }
    restoreAgreement = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-agreement', 'post', { _id }).then(r => {
                this.setState({
                    agreements: this.state.agreements.find(a => a._id === r.result?._id) ? [...this.state.agreements].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.agreements, r.result], archivedAgreements: [...this.state.archivedAgreements].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreUser = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-user', 'post', { _id }).then(r => {
                this.setState({
                    users: this.state.users.find(a => a._id === r.result?._id) ? [...this.state.users].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.users, r.result], archivedUsers: [...this.state.archivedUsers].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreProduct = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-product', 'post', { _id }).then(r => {
                this.setState({
                    products: this.state.products.find(a => a._id === r.result?._id) ? [...this.state.products].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.products, r.result], archivedProducts: [...this.state.archivedProducts].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restorePitch = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-pitch', 'post', { _id }).then(r => {
                this.setState({
                    pitches: this.state.pitches.find(a => a._id === r.result?._id) ? [...this.state.pitches].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.pitches, r.result], archivedPitches: [...this.state.archivedPitches].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreWorkOrder = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-work-order', 'post', { _id }).then(r => {
                this.setState({
                    workOrders: this.state.workOrders.find(a => a._id === r.result?._id) ? [...this.state.workOrders].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.workOrders, r.result], archivedWorkOrders: [...this.state.archivedWorkOrders].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreDeal = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-deal', 'post', { _id }).then(r => {
                this.setState({
                    deals: this.state.deals.find(a => a._id === r.result?._id) ? [...this.state.deals].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.deals, r.result], archivedDeals: [...this.state.archivedDeals].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreTitle = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-title', 'post', { _id }).then(r => {
                this.setState({
                    titles: this.state.titles.find(a => a._id === r.result?._id) ? [...this.state.titles].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.titles, r.result], archivedTitles: [...this.state.archivedTitles].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    restoreCompany = _id => {
        return new Promise((res, rej) => {
            this.request('/restore-company', 'post', { _id }).then(r => {
                this.setState({
                    companies: this.state.companies.find(a => a._id === r.result?._id) ? [...this.state.companies].map(u => {
                        if (u._id === r.result._id) { return r.result }
                        return u
                    }) : [...this.state.companies, r.result], archivedCompanies: [...this.state.archivedCompanies].filter(u => u._id !== _id)
                }, () => { return res(r.result); })
            }).catch(e => rej(e))
        })
    }
    permDeleteUser = _id => {
        this.request('/user', 'delete', { _id }).then(() => {
            this.setState({ users: [...this.state.users].filter(u => u._id !== _id), archivedUsers: [...this.state.archivedUsers].filter(u => u._id !== _id) }, () => {
                window.flash('User Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeleteTitle = _id => {
        this.request('/title', 'delete', { _id }).then(() => {
            this.setState({ titles: [...this.state.titles].filter(u => u._id !== _id), archivedTitles: [...this.state.archivedTitles].filter(u => u._id !== _id) }, () => {
                window.flash('Title Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeleteDeal = _id => {
        this.request('/deal', 'delete', { _id }).then(() => {
            this.setState({ deals: [...this.state.deals].filter(u => u._id !== _id), archivedDeals: [...this.state.archivedDeals].filter(u => u._id !== _id) }, () => {
                window.flash('Deal Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeletePitch = _id => {
        this.request('/pitch', 'delete', { _id }).then(() => {
            this.setState({ pitches: [...this.state.pitches].filter(u => u._id !== _id), archivedPitches: [...this.state.archivedPitches].filter(u => u._id !== _id) }, () => {
                window.flash('Pitch Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeleteWorkOrder = _id => {
        this.request('/work-order', 'delete', { _id }).then(() => {
            this.setState({ workOrders: [...this.state.workOrders].filter(u => u._id !== _id), archivedWorkOrders: [...this.state.archivedWorkOrders].filter(u => u._id !== _id) }, () => {
                window.flash('Work Order Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeleteProduct = _id => {
        this.request('/product', 'delete', { _id }).then(() => {
            this.setState({ products: [...this.state.products].filter(u => u._id !== _id), archivedProducts: [...this.state.archivedProducts].filter(u => u._id !== _id) }, () => {
                window.flash('Product Removed')
            })
        }).catch(e => window.flash(e))
    }
    permDeleteCompany = _id => {
        this.request('/company', 'delete', { _id }).then(() => {
            this.setState({ companies: [...this.state.companies].filter(u => u._id !== _id), archivedCompanies: [...this.state.archivedCompanies].filter(u => u._id !== _id) }, () => {
                window.flash('Company Removed')
            })
        }).catch(e => window.flash(e))
    }
    deleteReminder = _id => {
        this.request('/reminder', 'delete', { _id }).then(() => {
            this.setState({ reminders: [...this.state.reminders].filter(u => u._id !== _id) }, () => {
                window.flash('Cancelled Reminder')
            })
        }).catch(e => window.flash(e))
    }

    render() {
        window.app = this
        if (this.state.location === '/reset-password') return <ResetPass />
        if (!this.state.auth || !this.state.userID) return <LogIn></LogIn>
        if (this.state.failedApp) return <h1>An error occured. Please reload the page to continue. <div className="b2"><button onClick={() => window.location = '/'}>RELOAD</button> <button onClick={() => window.logOut()}>LOG OUT</button></div></h1>
        if (!this.state.gotInitial) return <div className="loading-screen"><div className="b1 loading-screen-content"><big-logo class="sidebar-logo"></big-logo><Spinner /><span className="loading-screen-text">Loading App Data<DotDot /></span></div></div>
        return <div className="main-wrapper">
            <Header crm={this.state.crm} messages={this.state.messages?.filter(a => !a.read && a.from !== this.state.userID)} notifications={this.state.notifications.filter(a => !a.viewed)} profilePic={this.state.profilePic} name={this.state.name} type={this.state.type} />
            <SidebarNav archived={this.state.archiveMode} crm={this.state.crm} _id={this.state.userID} role={this.state.role} profilePic={this.state.profilePic} name={this.state.name}></SidebarNav>
            <div className="page-wrapper">
                <Switch>
                    <Route exact path="/">
                        <Helmet>
                            <title>Dashboard</title>
                            <meta name="description" content="Register a new title" />
                        </Helmet>
                        <div className="content container-fluid rel">
                            <div className="page-header pt-3 mb-0 nobot">
                                <div className="crms-title row bg-white mb-4">
                                    <div className="col  p-0">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                                <i className="la la-home" />
                                            </span> Dashboard</h3>
                                    </div>
                                    <div className="col p-0 text-right">
                                        <ul className="breadcrumb bg-white float-right m-0 pl-0 pr-0">
                                            <li className="breadcrumb-item active">Dashboard</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {!this.state.shownNotif && <div className="card" style={{ margin: '10px 0' }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div id="notifcount" className="b2 rel">
                                                <Link to={'/notifications'}><><i className="fa fa-bell" /> You have <strong>{window.app.state?.notifications ? [...window.app.state.notifications].filter(u => !u.viewed).length : 0}</strong> new notifications.</></Link>
                                                <button style={{ padding: '2px', margin: '3px', fontSize: '.8rem' }} onClick={() => this.setState({ shownNotif: !this.state.shownNotif })} id="xbutton" className="xbutton">X</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="doscol">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Upcoming Activites</h3>
                                        <div className="row">
                                            <div className="col-md">
                                                {this.state.reminders?.length > 0 ? <Table
                                                    style={{ overflowX: "auto" }}
                                                    columns={[
                                                        {
                                                            title: 'Date',
                                                            dataIndex: "date",
                                                            render: (text, record) => (
                                                                <>{window.formatDate(record.date)} - <CountDown since={true} endText={'Today'} expires={record.date} /></>
                                                            ),
                                                            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                                        },
                                                        {
                                                            title: 'Associated To',
                                                            dataIndex: "associate",
                                                            render: (text, record) => (
                                                                <>{(() => {
                                                                    switch (record.associateType) {
                                                                        case 'deal': {
                                                                            return <Link to={`/deal?_id=${record.associate}`}><div className="squareButton">Go To Deal</div></Link>
                                                                        }
                                                                        case 'company': {
                                                                            return <Link to={`/company?_id=${record.associate}`}><div className="squareButton">Go To Company</div></Link>
                                                                        }
                                                                        case 'title': {
                                                                            return <Link to={`/title?_id=${record.associate}`}><div className="squareButton">Go To Title</div></Link>
                                                                        }
                                                                        case 'user': {
                                                                            return <Link to={`/profile?_id=${record.associate}`}><div className="squareButton">Go To Profile</div></Link>
                                                                        }
                                                                        default: {
                                                                            return <Link to={`/profile?_id=${record.associate}`}><div className="squareButton">Go To Profile</div></Link>
                                                                        }
                                                                    }
                                                                })()}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.associateType, b.associateType),
                                                        },
                                                        {
                                                            title: 'Action',
                                                            dataIndex: "content",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.content, b.content),
                                                        },
                                                        {
                                                            title: 'Assigned To',
                                                            dataIndex: "user",
                                                            render: (text, record) => (
                                                                <><TabLink to={`/profile?_id=${record.user}`} content={<>{record.user && window.app.state.users?.find(a => a._id === record.user)?.name || record.user}</>} /></>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare((window.app.state.users?.find(z => z._id === a.user)?.name || a.user), (window.app.state.users?.find(z => z._id === b.user)?.name || b.user)),
                                                        },
                                                        {
                                                            title: 'Created By',
                                                            dataIndex: "createdBy",
                                                            render: (text, record) => (
                                                                <><TabLink to={`/profile?_id=${record.createdBy}`} content={<>{window.app.state.users?.find(a => a._id === record.createdBy)?.name || record.createdBy}</>} /></>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare((window.app.state.users?.find(z => z._id === a.createdBy)?.name || a.createdBy), (window.app.state.users?.find(z => z._id === b.createdBy)?.name || b.createdBy)),
                                                        },
                                                        {
                                                            title: 'Created On',
                                                            dataIndex: "created",
                                                            render: (text, record) => (
                                                                <>{window.formatDate(record.created)}</>
                                                            ),
                                                            sorter: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
                                                        },
                                                        {
                                                            title: "Action",
                                                            dataIndex: "status",
                                                            render: (text, record) => (
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        {/* <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); window.app.setState({ viewingReminder: record._id }) }}>View Reminder</a> */}
                                                                        <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); window.app.deleteReminder(record._id) }}>Delete Reminder</a>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                    data={this.state.reminders?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).filter((u) => new Date(u.date).getTime() + 1000 * 60 * 60 * 24 > new Date().getTime())}
                                                    mainKey={(record) => record._id}
                                                /> : <span><strong>No Upcoming Actions</strong></span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Other Pages</h3>
                                        <div className="row">
                                            <div className="col">
                                                <span><strong>Octane Multimedia Apps</strong></span>
                                                <ul style={{ margin: 0, padding: 0 }}>
                                                    <li><ST domain="app.octanemultimedia.com">Client Portal</ST></li>
                                                    <li><ST domain="policies.octanemultimedia.com">Policies</ST></li>
                                                    <li><ST domain="policies.octanemultimedia.com">SOPs</ST></li>
                                                </ul>
                                                <span><strong>Delivery Minds Apps</strong></span>
                                                <ul style={{ margin: 0, padding: 0 }}>
                                                    <li><ST domain="portal.deliveryminds.com">Portal</ST></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Goal Tracker</h3>
                                        <div className="row">
                                            <div className="col-md">

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/register-a-film" component={RegisterFilm}></Route>
                    <Route exact path="/product">
                        <Product _id={this.state.queries['_id'] && this.state.products?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/actions">
                        <Actions actions={this.state.reminders} add={this.reminderForm} />
                    </Route>
                    <Route exact path="/action">
                        <FullAction actions={this.state.reminders} _id={this.state.queries['_id'] && this.state.reminders?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} add={this.reminderForm} />
                    </Route>
                    <Route exact path="/agreement">
                        <Agreement _id={this.state.queries['_id'] && this.state.agreements?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/agreements">
                        <Agreements crm={this.state.crm} agreements={['acquisitions', 'film-sales', 'distribution'].includes(this.state.crm) ? this.state.agreements || [] : ['corporate-sales'].includes(this.state.crm) ? this.state.agreements?.filter(a => a.type !== 'title') || [] : this.state.agreements} add={this.agreementForm} remove={this.removeAgreement} />
                    </Route>
                    <Route exact path="/profile">
                        <Profile _id={this.state.queries['_id'] && this.state.users?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/pitch">
                        <Pitch _id={this.state.queries['_id'] && this.state.pitches?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/pitches">
                        <Pitches crm={this.state.crm} data={this.state.pitches} add={this.pitchForm} remove={this.deletePitch} />
                    </Route>
                    <Route exact path="/work-order">
                        <WorkOrder _id={this.state.queries['_id'] && this.state.workOrders?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/work-orders">
                        <WorkOrders crm={this.state.crm} data={this.state.workOrders} add={this.workOrderForm} remove={this.deleteWorkOrder} />
                    </Route>
                    <Route exact path="/deal">
                        <Deal _id={this.state.queries['_id'] && this.state.deals?.find(a => a && a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} />
                    </Route>
                    <Route exact path="/deals">
                        <Deals crm={this.state.crm} deals={this.state.deals} add={this.dealForm} remove={this.removeDeal} />
                    </Route>
                    <Route exact path="/products">
                        <Products crm={this.state.crm} data={this.state.products} add={this.productForm} remove={this.deleteProduct} />
                    </Route>
                    <Route path="/contacts">
                        <Users type="contact" data={this.state.users?.filter(a => a && a.type === 'contact')} add={this.addContact}></Users>
                    </Route>
                    <Route exact path="/film-sales/contacts">
                        <Users type="contact" crm="film-sales" data={this.state.users?.filter(a => a && a.type === 'contact' && a.crm === 'film-sales')} add={this.addContact}></Users>
                    </Route>
                    <Route exact path="/film-distribution/contacts">
                        <Users type="contact" crm="film-distribution" data={this.state.users?.filter(a => a && a.type === 'contact' && a.crm === 'film-distribution')} add={this.addContact}></Users>
                    </Route>
                    <Route exact path="/corporate-sales/contacts">
                        <Users type="contact" crm="corporate-sales" data={this.state.users?.filter(a => a && a.type === 'contact' && a.crm === 'corporate-sales')} add={this.addContact}></Users>
                    </Route>
                    <Route exact path="/acquisitions/contacts">
                        <Users type="contact" crm="acquisitions" data={this.state.users?.filter(a => a && a.type === 'contact' && a.crm === 'acquisitions')} add={this.addContact}></Users>
                    </Route>
                    <Route exact path="/vendors">
                        <Users type="vendor" crm={this.state.crm} data={this.state.users?.filter(a => a && a.type === 'vendor')} add={this.addVendor}></Users>
                    </Route>
                    <Route exact path="/companies">
                        <Companies data={this.state.companies} add={this.companyForm}></Companies>
                    </Route>
                    <Route exact path="/film-sales/companies">
                        <Companies crm="film-sales" data={this.state.companies.filter(a => a && a.crm === 'film-sales')} add={this.companyForm}></Companies>
                    </Route>
                    <Route exact path="/acquisitions/companies">
                        <Companies crm="acquisitions" data={this.state.companies.filter(a => a && a.crm === 'acquisitions')} add={this.companyForm}></Companies>
                    </Route>
                    <Route exact path="/film-distribution/companies">
                        <Companies crm="film-distribution" data={this.state.companies.filter(a => a && a.crm === 'film-distribution')} add={this.companyForm}></Companies>
                    </Route>
                    <Route exact path="/corporate-sales/companies">
                        <Companies crm="corporate-sales" data={this.state.companies.filter(a => a && a.crm === 'corporate-sales')} add={this.companyForm}></Companies>
                    </Route>
                    <Route exact path="/leads">
                        <Users type="lead" data={this.state.users?.filter(a => a.type === 'lead')} add={this.addLead}></Users>
                    </Route>
                    <Route exact path="/film-sales/leads">
                        <Users type="lead" crm="film-sales" data={this.state.users?.filter(a => a.type === 'lead' && a.crm === 'film-sales')} add={this.addLead}></Users>
                    </Route>
                    <Route exact path="/film-distribution/leads">
                        <Users type="lead" crm="film-distribution" data={this.state.users?.filter(a => a.type === 'lead' && a.crm === 'film-distribution')} add={this.addLead}></Users>
                    </Route>
                    <Route exact path="/corporate-sales/leads">
                        <Users type="lead" crm="corporate-sales" data={this.state.users?.filter(a => a.type === 'lead' && a.crm === 'corporate-sales')} add={this.addLead}></Users>
                    </Route>
                    <Route exact path="/acquisitions/leads">
                        <Users type="lead" crm="acquisitions" data={this.state.users?.filter(a => a.type === 'lead' && a.crm === 'acquisitions')} add={this.addLead}></Users>
                    </Route>
                    <Route exact path="/customers">
                        <Users type="customer" data={this.state.users?.filter(a => a.type === 'customer')} add={this.addCustomer}></Users>
                    </Route>
                    <Route exact path="/film-sales/customers">
                        <Users type="customer" crm="film-sales" data={this.state.users?.filter(a => a.type === 'customer' && a.crm === 'film-sales')} add={this.addCustomer}></Users>
                    </Route>
                    <Route exact path="/film-distribution/customers">
                        <Users type="customer" crm="film-sales" data={this.state.users?.filter(a => a.type === 'customer' && a.crm === 'film-distribution')} add={this.addCustomer}></Users>
                    </Route>
                    <Route exact path="/corporate-sales/customers">
                        <Users type="customer" crm="corporate-sales" data={this.state.users?.filter(a => a.type === 'customer' && a.crm === 'corporate-sales')} add={this.addCustomer}></Users>
                    </Route>
                    <Route exact path="/acquisitions/clients">
                        <Users type="client" crm="acquisitions" data={this.state.users?.filter(a => a.type === 'client' && a.crm === 'acquisitions')} add={this.addClient}></Users>
                    </Route>
                    <Route exact path="/prospects">
                        <Users type="propspect" data={this.state.users?.filter(a => a.type === 'prospect')} add={this.addProspect}></Users>
                    </Route>
                    <Route exact path="/film-sales/prospects">
                        <Users type="prospect" crm="film-sales" data={this.state.users?.filter(a => a.type === 'prospect' && a.crm === 'film-sales')} add={this.addProspect}></Users>
                    </Route>
                    <Route exact path="/film-distribution/prospects">
                        <Users type="prospect" crm="film-distribution" data={this.state.users?.filter(a => a.type === 'prospect' && a.crm === 'film-distribution')} add={this.addProspect}></Users>
                    </Route>
                    <Route exact path="/corporate-sales/prospects">
                        <Users type="prospect" crm="corporate-sales" data={this.state.users?.filter(a => a.type === 'prospect' && a.crm === 'corporate-sales')} add={this.addProspect}></Users>
                    </Route>
                    <Route exact path="/acquisitions/prospects">
                        <Users type="prospect" crm="acquisitions" data={this.state.users?.filter(a => a.type === 'prospect' && a.crm === 'acquisitions')} add={this.addProspect}></Users>
                    </Route>
                    <Route exact path="/archived-deals">
                        <ArchivedDeals deals={this.state.archivedDeals} />
                    </Route>
                    <Route exact path="/archived-agreements">
                        <ArchivedAgreements agreements={this.state.archivedAgreements} />
                    </Route>
                    <Route exact path="/archived-companies">
                        <ArchivedCompanies data={this.state.archivedCompanies} />
                    </Route>
                    <Route exact path="/archived-users" >
                        <ArchivedUsers data={this.state.archivedUsers} />
                    </Route>
                    <Route exact path="/archived-titles">
                        <ArchivedTitles titles={this.state.archivedTitles} />
                    </Route>
                    <Route exact path="/archived-pitches" >
                        <ArchivedPitches data={this.state.archivedPitches} />
                    </Route>
                    <Route exact path="/archived-work-orders" >
                        <ArchivedWorkOrders data={this.state.archivedWorkOrders} />
                    </Route>
                    <Route exact path="/archived-products">
                        <ArchivedProducts data={this.state.archivedProducts} />
                    </Route>
                    <Route exact path="/users" >
                        <Users data={this.state.users.filter(a => window.isInternal(a.type))} add={this.addUser} ></Users>
                    </Route>
                    <Route exact path="/titles">
                        <Titles crm={window.isAdmin(this.state.type) ? '' : 'acquisitions'} titles={this.state.titles} add={this.titleForm} ></Titles>
                    </Route>
                    <Route exact path="/acquisitions/titles">
                        <Titles crm={'acquisitions'} titles={this.state.titles} add={this.titleForm} ></Titles>
                    </Route>
                    <Route exact path="/reporting">
                        {this.state.gotPayments && this.state.gotSales ? <div id="reportCont" className="content container-fluid">
                            <Reporting auth={this.state.auth} userID={this.state.userID} fields={this.state.fields} models={this.state.models} movies={this.state.movies} paymentFields={this.state.paymentFields} sales={this.state.sales} companies={this.state.companies} payments={this.state.payments}></Reporting>
                        </div> : <div className="b1">
                            <Spinner />
                            <h3>Loading Payments<DotDot /></h3>
                        </div>}
                    </Route>
                    <Route path="/title">
                        <Title _id={this.state.queries['_id'] && this.state.titles?.find(a => a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} add={this.titleForm}></Title>
                    </Route>
                    <Route path="/company">
                        <FullCompany _id={this.state.queries['_id'] && this.state.companies?.find(a => a._id === this.state.queries['_id']) ? this.state.queries['_id'] : ''} add={this.companyForm} />
                    </Route>
                    <Route exact path="/settings" component={Settings}></Route>
                    <Route exact path="/messages">
                        <Messages recipients={this.state.users?.filter(a => a._id !== this.state.userID && window.isInternal(a.type))} messages={this.state.messages} />
                    </Route>
                    <Route exact path="/payments">
                        <Payments gotPayment={this.state.gotPayments} type="payment" sales={this.state.sales} companies={this.state.companies} edit={true} payments={this.state.payments.filter(a => a.income >= 0)} fields={this.state.paymentFields} movies={this.state.movies} />
                    </Route>
                    <Route exact path="/expenses">
                        <Payments gotPayment={this.state.gotPayments} type="expense" sales={this.state.sales} companies={this.state.companies} edit={true} payments={this.state.payments.filter(a => a.income < 0)} fields={this.state.paymentFields} movies={this.state.movies} />
                    </Route>
                    <Route exact path="/sales">
                        <Payments gotPayment={this.state.gotSales} type="sale" sales={this.state.sales} companies={this.state.companies} edit={true} payments={this.state.payments} fields={this.state.paymentFields} movies={this.state.movies} />
                    </Route>
                    <Route exact path="/notifications">
                        <Notifications notifications={this.state.notifications} />
                    </Route>
                    <Route path="*" component={Error404}></Route>
                </Switch>
            </div>
        </div >
    }
}

export default withRouter(App)